import { Box } from '@mui/system';
import React from 'react';
import withContext, { chain } from '../utils/WithContext';
import { getRectFromProperties } from './Infinite';
import InfiniteContext from './InfiniteContext';
import InfiniteGesture from './InfiniteGesture.js';


/**
 * In charge of rendering an element in the infinite component.
 */
class InfiniteStaticElement extends React.Component {

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        // the ref use the fwd ref if any, create one if not provided.
        this.element = this.props.forwardedRef || React.createRef();
    }

    /**
     * 
     */
    setClickGestureListener() {
        this.gesture.condition(({ event }) => event.which == 1 && !event.metaKey);
    }

    /**
     * 
     */
    componentDidMount() {
        this.gesture = new InfiniteGesture(this.element.current);
        this.setPositionStyle();
        if (!this.props.static) {
            //this.element.current.addEventListener('mousedown', this.handleMouseDown.bind(this));
            //this.element.current.addEventListener('click', this.handleMouseDown.bind(this));
            this.gesture.listenMouseEvents();
            this.setClickGestureListener();
        }
    }

    handleMouseDown(event) {
        event.stopPropagation();
    }

    /**
     * 
     */
    componentDidUpdate() {
        this.setPositionStyle();
    }

    /**
     * 
     */
    setPositionStyle() {
        const { coord, size, align, center } = this.props;
        let { x, y, w, h } = getRectFromProperties({ coord, size, align, center });
        x += this.props.translate.x - this.props.offset.x;
        y += this.props.translate.y - this.props.offset.y;
        this.element.current.style.left = `${x}px`;
        this.element.current.style.top = `${y}px`;
        if (w) this.element.current.style.width = `${w}px`;
        if (h) this.element.current.style.height = `${h}px`;
    }

    /**
     * 
     * @returns 
     */
    render() {
        //console.log('Infinite Static render', this.props)
        return <Box
            ref={this.element}
            sx={{
                position: 'absolute',
                pointerEvents: 'auto',
                zIndex: 10,
                userSelect: 'text',
                ...this.props.sx,
            }}>
            {this.props.children}
        </Box>
    }
}

/**
 * 
 */
const InfiniteStaticElementWithContexts = chain(
    withContext(InfiniteContext)(({ offset, translate }) => ({ offset, translate })),
)(InfiniteStaticElement);

/**
 * Add a fwd ref
 */
export default React.forwardRef((props, ref) => <InfiniteStaticElementWithContexts
    forwardedRef={ref}
    {...props}
/>);