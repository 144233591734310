import Editor from "@monaco-editor/react";
import { CircularProgress } from '@mui/material';
import React from 'react';
import withContext, { chain } from '../utils/WithContext';

import ArtifactContext from "./ArtifactContext";
import ArtifactEditorContext from './ArtifactEditorContext';
import CacheContext from "../cache/CacheContext";

/**
 * 
 */
class ArtifactEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            /* tabs state */
            selectedTabIndex: -1, // the index of the current tab displayed

            /* files state */
            //selectedFileLoading: false, // is the file loading 
            loaded: false,

        }

        this.lastFileTreeClick = {};
        this.isLoadingFile = {};
        this.isEditingFile = {};
        this.editTimeout = {};

        this.editor = null// = React.createRef();
        this.monaco = null// = React.createRef();

        this.files = {};
    }

    /**
     * 
     */
    componentDidMount() {
        this.setEditorState();
    }

    /**
     * 
     * @returns 
     */
    shouldComponentUpdate() {
        // set state
        if (this.editor && this.monaco && this.props.artifact && this.props.filepath) {
            const fileuid = `${this.props.artifact.id}${this.props.filepath}`;
            const file = this.files[fileuid];
            if (file) {
                file.viewState = this.editor.saveViewState();
                file.selection = this.editor.getSelection();
            }
        }
        return true;
    }

    /**
     * 
     * @param {*} prevProps 
     */
    componentDidUpdate() {
        this.setEditorState();
    }

    /**
     * 
     * @param {*} prevFilepath 
     */
    setEditorState = () => {
        // editor not ready
        if (!this.editor || !this.monaco) return;
        // set state
        const artifact = this.props.artifact;
        const filepath = this.props.filepath;
        if (artifact && filepath) {
            const fileuid = `${artifact.id}${filepath}`;
            this.editor.setModel(this.files[fileuid].model);
            const { selection, viewState } = this.files[fileuid];
            if (viewState) this.editor.restoreViewState(viewState);
            if (selection) this.editor.setSelection(selection);
            //this.editor.focus();
        }
    }

    /**
     * 
     * @param {*} monaco 
     */
    handleEditorWillMount = monaco => {
        monaco.editor.defineTheme('myTheme', {
            base: 'vs',
            inherit: true,
            rules: [],
            colors: {
                'editor.background': '#FFFFFF',//'#edf2fa',
            },
        });
    }

    /**
     * 
     * @param {*} editor 
     * @param {*} monaco 
     */
    handleEditorDidMount = (editor, monaco) => {
        console.log("EDITOR MOUNT")
        this.editor = editor;
        this.monaco = monaco;
        this.editor.updateOptions({ contextmenu: false });
        //this.loadFile(this.props.filepath);
        if (!this.state.loaded) {
            this.setState({ loaded: true })
        }
    }

    /**
     * Called when the model value has changed.
     * @param {*} value 
     * @param {*} event 
     * @param {*} param 
     */
    onDidChangeContent = (artifact, filepath, value) => {
        const fileuid = `${artifact.id}${filepath}`;
        if (this.isLoadingFile[fileuid]) return; // we sync the model with the artifact value, no need to edit the artifact value
        if (this.editTimeout[fileuid]) {
            clearTimeout(this.editTimeout[fileuid]);
        }
        this.editTimeout[fileuid] = setTimeout(() => {
            this.isEditingFile[fileuid] = true;
            artifact.editFile(filepath, value);
            this.isEditingFile[fileuid] = false;
        }, 0);
    }

    /**
     * Call when the file is loaded the first time and each time the file is edited.
     * @param {*} filepath 
     */
    loadFile = (artifact, filepath) => {
        const fileuid = `${artifact.id}${filepath}`;
        if (this.isEditingFile[fileuid]) return; // we sync the artifact value with the model value, no need to edit the model value
        if (!this.files.hasOwnProperty(fileuid)) {
            var model = this.monaco.editor.createModel('', 'javascript');
            model.onDidChangeContent(() => this.onDidChangeContent(artifact, filepath, model.getValue()));
            artifact.getFile(filepath).then(text => {
                this.isLoadingFile[fileuid] = true;
                model.setValue(text);
                this.isLoadingFile[fileuid] = false;
            });
            this.files[fileuid] = { model };
        } else {
            var { model } = this.files[fileuid];
            artifact.getFile(filepath).then(text => {
                this.isLoadingFile[fileuid] = true;
                model.setValue(text);
                this.isLoadingFile[fileuid] = false;
            });
        }
    }

    /**
     * 
     * @param {*} filepath 
     */
    unloadFile = (artifact, filepath) => {
        const fileuid = `${artifact.id}${filepath}`;
        if (this.files.hasOwnProperty(fileuid)) {
            delete this.files[fileuid];
        }
    }

    /**
     * 
     * @returns 
     */
    render() {
        return (
            <>
                <Editor
                    theme="myTheme"
                    width="100%"
                    height="100%"
                    loading={<CircularProgress size={26} thickness={6} sx={{ color: "rgba(0,0,0,.2)" }} />}
                    defaultLanguage="javascript"
                    defaultValue={""}
                    //onChange={this.handleEditorChange}
                    beforeMount={this.handleEditorWillMount}
                    onMount={this.handleEditorDidMount}
                />

                {this.state.loaded && <ArtifactEditorContext.Provider value={{
                    artifact: this.props.artifact,
                    loadFile: this.loadFile,
                    unloadFile: this.unloadFile,
                }}>
                    {this.props.children}
                </ArtifactEditorContext.Provider>}
            </>
        );
    }
}


export default chain(
    withContext(CacheContext)(({ getArtifact }) => ({ getArtifact })),
)(ArtifactEditor)