
import Icon from '@mdi/react';
import { Link } from '@mui/material';
import * as React from 'react';


class SystemLink extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { forwardedRef, href, sx, icon, children, ...otherProps } = this.props;

        return <Link
            {...otherProps}
            ref={forwardedRef}
            href={href}
            variant={'body'}
            underline="hover"
            sx={{
                color: '#0969DA',
                cursor: 'pointer',
                ...sx,
            }}
        >
            {children}
            {icon && <Icon style={{
                verticalAlign: 'middle',
                marginLeft: '2px',
            }}
                path={icon}
                size={.5} />}
        </Link>
    }
}

/**
 * Add a fwd ref
 */
export default React.forwardRef((props, ref) => <SystemLink
    forwardedRef={ref}
    {...props}
/>);