import uuid from 'react-uuid';
import { downloadArtifactFile, getArtifactFiles } from '../BackendFunctions';
import RedoUndo from './RedoUndo';
import RedoUndoString, { RedoUndoHistoryEvents } from './RedoUndoString';

/**
 * 
 * @param {*} artifact 
 * @param {*} filepath 
 * @returns 
 */
const createHistoryListener = (artifact, filepath) => {
    return ({ type, timestamp }) => {
        if (type == RedoUndoHistoryEvents.REDO_ADDED) {
            artifact.redoList.push({ filepath, timestamp });
        }
        else if (type == RedoUndoHistoryEvents.UNDO_ADDED) {
            artifact.undoList.push({ filepath, timestamp });
        }
        else if (type == RedoUndoHistoryEvents.REDO_REMOVED) {
            artifact.redoList.splice(artifact.redoList.findLastIndex(o => o.filepath === filepath), 1);
        }
        else if (type == RedoUndoHistoryEvents.UNDO_REMOVED) {
            artifact.undoList.splice(artifact.undoList.findLastIndex(o => o.filepath === filepath), 1);
        }
        else if (type == RedoUndoHistoryEvents.REDO_CLEARED) {
            Object.entries(artifact.modifiedFiles).forEach(([o, redoUndoStr]) => redoUndoStr.clear());
            artifact.redoList = [];
        }
    }
}

/**
 * 
 */
class Artifact {

    /**
     * 
     * @param {*} name 
     * @param {*} browserStorage 
     */
    constructor(name, browserStorage) {

        this.id = uuid();

        this.name = name;
        this.browserStorage = browserStorage;

        //window.addEventListener("storage", event => { console.log(event) });

        this.version = 0;
        this.versions = {};

        //this.setSrc(0, src || null);
        //this.setSrc(src || null);

        // folders
        this.cachedFolders = {};
        this.cachedFoldersPromises = {};
        this.modifiedFolders = new RedoUndo({}, value => {
            this.structureChangeListeners.forEach(fn => {
                fn(value);
            });
        });

        // files
        this.cachedFilesKeys = {};
        this.modifiedFiles = {};
        this.undoList = [];
        this.redoList = [];

        // listeners
        this.versionChangeListeners = [];
        this.structureChangeListeners = [];
        this.fileChangeListeners = {};
        this.fileChangeListener = filepath => event => {
            if (!this.fileChangeListeners.hasOwnProperty(filepath)) return;
            this.fileChangeListeners[filepath].forEach(fn => {
                fn(event);
            });
        }

        // download cache
        this.downloadCache = {};
    }

    /**
     * 
     * @returns 
     */
    getName() {
        return this.name;
    }

    // VERSIONING

    /**
     * increment the version number of the artifact
     */
    incrementVersion() {
        this.version++;
        this.versionChangeListeners.forEach(fn => {
            fn(this.version, this.name);
        });
    }

    /**
     * Returns the artifact version
     * The version in inscremented each time the value changes
     * @returns 
     */
    getVersion() {
        return this.version;
    }

    /**
     * 
     * @returns 
     */
    lastSavedVersion() {
        if (Object.keys(this.versions).length === 0) return null;
        return Math.max(Object.keys(this.versions));
    }

    /**
     * 
     * @returns 
     */
    hasChangesNotSaved = () => {
        return this.lastSavedVersion() !== null && !this.getSrc();
    }

    /**
     * Set the path of the remote artifact for the given version
     * @param {*} version 
     * @param {*} src 
     */
    setSrc({bucket, path}) {
        this.versions[this.version] = { bucket, path };
        this.versionChangeListeners.forEach(fn => {
            fn(this.version, this.name);
        });
    }

    /**
     * Returns the src of the given version. 
     * @returns 
     */
    getSrc(version) {
        if (version === undefined) {
            return this.versions[this.version];
        } else {
            return this.versions[version];
        }
    }

    /**
     * 
     * @returns 
     */
    /*getLastSavedVersion() {
        var keys = Object.keys(this.versions);
        if (keys.length == 0) return null;
        return Math.max(...keys);
    }*/

    // ADD REMOVE LISTENERS METHODS

    /**
     * Add a new structure listener. Listen for files and folders creation / deletion
     * @param {*} fn 
     */
    addStructureChangeListener(fn) {
        this.structureChangeListeners.push(fn);
    }

    /**
     * Remove a structure listener
     * @param {*} fn 
     */
    removeStructureChangeListener(fn) {
        this.structureChangeListeners.splice(this.structureChangeListeners.findIndex(f => f === fn), 1);
    }

    /**
     * Add a new listener for the provided file. Listen only changes coming from an undo or redo action.
     * @param {*} filepath 
     * @param {*} fn 
     */
    addFileChangeListener(filepath, fn) {
        if (!this.fileChangeListeners.hasOwnProperty(filepath)) {
            this.fileChangeListeners[filepath] = [];
        }
        this.fileChangeListeners[filepath].push(fn);
    }

    /**
     * Remove a file listener.
     * @param {*} filepath 
     * @param {*} fn 
     * @returns 
     */
    removeFileChangeListener(filepath, fn) {
        if (!this.fileChangeListeners.hasOwnProperty(filepath))
            return;

        var arr = this.fileChangeListeners[filepath];
        arr.splice(arr.findIndex(f => f === fn), 1);
        if (arr.length == 0) {
            delete this.fileChangeListeners[filepath];
        } /*else {
            this.fileChangeListeners[filepath] = arr; // needed ?
        }*/
    }

    /**
     * 
     * @param {*} fn 
     */
    addVersionChangeListener(fn) {
        this.versionChangeListeners.push(fn);
    }

    /**
     * 
     * @param {*} fn 
     */
    removeVersionChangeListener(fn) {
        this.versionChangeListeners.splice(this.versionChangeListeners.findIndex(f => f === fn), 1);
    }

    // DO UNDO METHODS

    /**
     * 
     */
    undoLastChangeOnStructure() {
        this.incrementVersion();
        this.modifiedFolders.undo();
    }

    /**
     * 
     */
    redoLastChangeOnStructure() {
        this.incrementVersion();
        this.modifiedFolders.redo();
    }

    /**
     * 
     * @returns 
     */
    isStructureModified() {
        return this.modifiedFolders.isModified();
    }

    /**
     * vb
     * @returns 
     */
    undoLastChangeOnFiles(duration = 200) {
        this.incrementVersion();
        // get the list of undo to apply
        var undo = this.undoList[this.undoList.length - 1];
        if (!undo) return;
        var list = [undo];
        var index = 1;
        var timestampLimit = undo.timestamp - duration;
        var loop = true;
        while (loop) {
            index++;
            undo = this.undoList[this.undoList.length - index];
            if (!undo) loop = false;
            else {
                if (undo.timestamp < timestampLimit) loop = false;
                else list.push(undo);
            }
        }
        list.forEach(p => this.modifiedFiles[p.filepath].undo());
    }

    /**
     * 
     * @returns 
     */
    redoLastChangeOnFiles() {
        this.incrementVersion();
        // get the list of redo to apply
        var redo = this.redoList[this.redoList.length - 1];
        if (!redo) return;
        var list = [redo];
        var index = 1;
        var timestampLimit = redo.timestamp + 200;
        var loop = true;
        while (loop) {
            index++;
            redo = this.redoList[this.redoList.length - index];
            if (!redo) loop = false;
            else {
                if (redo.timestamp > timestampLimit) loop = false;
                else list.push(redo);
            }
        }
        list.forEach(p => this.modifiedFiles[p.filepath].redo());
    }

    /**
     * 
     * @param {*} filepath 
     * @returns 
     */
    undoLastChangeOnFile(filepath) {
        this.incrementVersion();
        if (this.modifiedFiles.hasOwnProperty(filepath)) {
            return this.modifiedFiles[filepath].undo();
        }
    }

    /**
     * 
     * @param {*} filepath 
     * @returns 
     */
    redoLastChangeOnFile(filepath) {
        this.incrementVersion();
        if (this.modifiedFiles.hasOwnProperty(filepath)) {
            return this.modifiedFiles[filepath].redo();
        }
    }

    // ACTIONS METHODS

    /**
     * 
     * @param {*} path 
     * @returns 
     */
    getLocalFolder(path) {
        var modifiedFolders = this.modifiedFolders.get();

        if (modifiedFolders.hasOwnProperty(path)) {
            return modifiedFolders[path];
        }
        else if (this.cachedFolders.hasOwnProperty(path)) {
            return this.cachedFolders[path];
        }
        else {
            return null;
        }
    }

    /**
     * Return the file content stored on the browser
     * @param {*} path folder path or full filepath
     * @param {*} name 
     * @returns 
     */
    getLocalFile(filepath) {
        // file has been edited
        if (this.modifiedFiles.hasOwnProperty(filepath)) {
            var doUndoStr = this.modifiedFiles[filepath];
            return doUndoStr.get();
        }
        // from here the file is not edited 
        else if (this.cachedFilesKeys.hasOwnProperty(filepath)) {
            var key = this.cachedFilesKeys[filepath];
            var value = this.browserStorage.get(key);
            if (value) return value;
        }
        return null;
    }

    /**
     * 
     * @param {*} filepath 
     * @returns 
     */
    getLocalFileAsJson(filepath) {
        const text = this.getLocalFile(filepath);
        return JSON.parse(text);
    }

    /**
     * 
     * @param {*} path 
     * @param {*} name 
     */
    getLocalFileVersion(filepath) {
        // file has been edited
        if (this.modifiedFiles.hasOwnProperty(filepath)) {
            var doUndoStr = this.modifiedFiles[filepath];
            return doUndoStr.getVersion();
        }
        // from here the file is not edited 
        else if (this.cachedFilesKeys.hasOwnProperty(filepath)) {
            return 0;
        } else {
            return null;
        }
    }

    /**
     * Async function
     * @param {*} path 
     * @returns 
     */
    getFolder(path) {
        var modifiedFolders = this.modifiedFolders.get();
        if (modifiedFolders.hasOwnProperty(path)) {
            return Promise.resolve(modifiedFolders[path]);
        }
        else if (this.cachedFoldersPromises.hasOwnProperty(path)) {
            return this.cachedFoldersPromises[path];
        }
        else {
            var src = this.getSrc(0);
            if (src == null) {
                const folder = { folder: path, folders: [], files: [] };
                this.modifiedFolders.edit(modifiedFolders => {
                    modifiedFolders[path] = folder;
                    return modifiedFolders;
                });
                return Promise.resolve(folder);
            } else {
                // In case multiple fownload at the same time, 
                // with the cache, we call only one time the backend.
                this.cachedFoldersPromises[path] = getArtifactFiles(src.bucket, src.path, path).then(json => {
                    this.cachedFolders[path] = json;
                    this.structureChangeListeners.forEach(fn => {
                        fn(json);
                    });
                    return json;
                });
                return this.cachedFoldersPromises[path];
            }
        }
    }


    /**
     * Async function
     * @param {*} filepath 
     * @returns 
     */
    createFolder(filepath) {
        this.incrementVersion();
        // get the parent folder
        var path = filepath.substring(0, filepath.lastIndexOf('/'));
        path = path.substring(0, path.lastIndexOf('/') + 1);
        const name = filepath.substring(path.length, filepath.lastIndexOf('/'));
        return this.getFolder(path).then(({ folder, files, folders }) => {
            if (!folders.includes(name)) {
                var value = {
                    folder,
                    files,
                    folders: [...folders, name],
                }
                this.modifiedFolders.edit(modifiedFolders => {
                    modifiedFolders[path] = value;
                    return modifiedFolders;
                });
            } else {
                return Promise.reject("Folder already exists.");
            }
        });
    }

    /**
     * 
     * @param {*} filepath 
     * @param {*} toFilepath 
     */
    moveFolder(filepath, toFilepath) {
        // TODO
    }

    /**
     * Async function
     * @param {*} filepath 
     * @returns 
     */
    deleteFolder(filepath) {
        this.incrementVersion();
        // remove the last /
        filepath = filepath.substring(0, filepath.length - 1);
        // get the parent folder   
        const path = filepath.substring(0, filepath.lastIndexOf('/') + 1);
        const name = filepath.substring(filepath.lastIndexOf('/') + 1);
        return this.getFolder(path).then(({ folder, files, folders }) => {
            if (folders.includes(name)) {
                var arr = [...folders];
                arr.splice(arr.findIndex(f => f === name), 1);
                var value = {
                    folder,
                    files,
                    folders: arr,
                }
                this.modifiedFolders.edit(modifiedFolders => {
                    modifiedFolders[path] = value;
                    return modifiedFolders;
                });
            } else {
                return Promise.reject("Folder does not exist.");
            }
        });
    }

    /**
     * Async function
     * @param {*} filepath
     * @returns 
     */
    getFile(filepath) {
        // file has been edited
        if (this.modifiedFiles.hasOwnProperty(filepath)) {
            var doUndoStr = this.modifiedFiles[filepath];
            return Promise.resolve(doUndoStr.get());
        }
        // from here the file is not edited 
        else if (this.cachedFilesKeys.hasOwnProperty(filepath)) {
            var key = this.cachedFilesKeys[filepath];
            var value = this.browserStorage.get(key);
            if (value) return Promise.resolve(value);
        }
        var src = this.getSrc(0);
        if (src == null) {
            return Promise.reject(`File ${filepath} does not exist.`);
        } else {
            // In case multiple download at the same time, 
            // with the cache, we call only one time the backend.
            if (!this.downloadCache.hasOwnProperty(filepath)) {
                this.downloadCache[filepath] = downloadArtifactFile(src.bucket, src.path, filepath).then(text => {
                    // try to store the file in the local storage
                    var key = this.browserStorage.set(text);
                    if (key != null) {
                        this.cachedFilesKeys[filepath] = key;
                    }
                    return text;
                });
            }
            return this.downloadCache[filepath];
        }
    }

    /**
     * 
     * @param {*} filepath 
     * @returns 
     */
    getFileAsJson(filepath) {
        return this.getFile(filepath).then(text => JSON.parse(text))
    }

    /**
     * 
     * @param {*} path 
     * @returns 
     */
    isModifiedFile(filepath) {
        return this.modifiedFiles.hasOwnProperty(filepath);
    }

    /**
     * Async function
     * @param {*} filepath 
     * @param {*} text 
     * @returns 
     */
    createFile(filepath, text) {
        this.incrementVersion();
        // get the parent folder
        const path = filepath.substring(0, filepath.lastIndexOf('/') + 1);
        const name = filepath.substring(path.length);
        return this.getFolder(path).then(({ folder, files, folders }) => {
            if (!files.includes(name)) {
                // update the parent folder
                var value = {
                    folder,
                    files: [...files, name],
                    folders,
                }
                this.modifiedFolders.edit(modifiedFolders => {
                    modifiedFolders[path] = value;
                    return modifiedFolders;
                });
                // create the file
                console.log("Creating file ", filepath)
                this.modifiedFiles[filepath] = new RedoUndoString(
                    text,
                    this.fileChangeListener(filepath),
                    createHistoryListener(this, filepath)
                );
                return text;
            } else {
                return Promise.reject("File already exists.");
            }
        });
    }

    /**
     * Async function
     * @param {*} filepath 
     * @param {*} text 
     * @returns 
     */
    editFile(filepath, text) {
        this.incrementVersion();
        var originalValue = this.getLocalFile(filepath);
        if (!originalValue) throw new Error('File does not exist locally');

        var doUndoString;
        if (this.modifiedFiles.hasOwnProperty(filepath)) {
            doUndoString = this.modifiedFiles[filepath];
        } else {
            doUndoString = new RedoUndoString(
                originalValue,
                this.fileChangeListener(filepath),
                createHistoryListener(this, filepath)
            );
            this.modifiedFiles[filepath] = doUndoString;
        }
        if (text != originalValue) {
            doUndoString.set(text); // set the new value
        }
    }

    /**
     * 
     * @param {*} filepath 
     * @param {*} toFilepath 
     */
    moveFile(filepath, toFilepath) {
        // TODO
    }

    /**
     * 
     * @param {*} filepath 
     */
    deleteFile(filepath) {
        this.incrementVersion();
        // get the parent folder   
        const path = filepath.substring(0, filepath.lastIndexOf('/') + 1);
        const name = filepath.substring(path.length);
        return this.getFolder(path).then(({ folder, files, folders }) => {
            if (files.includes(name)) {
                var arr = [...files];
                arr.splice(arr.findIndex(f => f === name), 1);
                var value = {
                    folder,
                    files: arr,
                    folders,
                }
                this.modifiedFolders.edit(modifiedFolders => {
                    modifiedFolders[path] = value;
                    return modifiedFolders;
                });
            } else {
                return Promise.reject("File does not exist.");
            }
        });
    }

    // SYNC METHODS

    /**
     * 
     * @returns 
     */
    getAllChanges() {
        /*var lastSavedVersion = this.getLastSavedVersion();
        if (this.version == lastSavedVersion) {
            return {
                src: this.getSrc(this.version)
            }
        }*/
        var changes = {
            deletedFolders: [],
            deletedFiles: [],
            createdFolders: [],
            createdFiles: [],
            modifiedFiles: [],
        }
        var modifiedFolders = this.modifiedFolders.get();
        Object.entries(modifiedFolders).forEach(([path, { files, folders }]) => {
            var cache = this.cachedFolders[path] || {};
            var cacheFiles = cache.files || [];
            var cacheFolders = cache.folders || [];
            changes.createdFiles.push(...files.filter(name => !cacheFiles.includes(name)).map(name => `${path}${name}`));
            changes.deletedFiles.push(...cacheFiles.filter(name => !files.includes(name)).map(name => `${path}${name}`));
            changes.createdFolders.push(...folders.filter(name => !cacheFolders.includes(name)).map(name => `${path}${name}`));
            changes.deletedFolders.push(...cacheFolders.filter(name => !folders.includes(name)).map(name => `${path}${name}`));
        });

        changes.modifiedFiles = Object.entries(this.modifiedFiles)
            .filter(([path, undoRedoStr]) => !changes.createdFiles.includes(path) && !changes.deletedFiles.includes(path) && undoRedoStr.isModified())
            //.filter(([, undoRedoStr]) => undoRedoStr.isModified())
            .map(([path]) => path);

        // build the result object
        // only keep entries with at least one element.
        var result = {};
        //var src = this.getSrc(0);
        //if (src) result.src = src;
        Object.entries(changes).forEach(([k, v]) => {
            if (v.length > 0) result[k] = v;
        });

        console.log('All changes for ' + this.id, JSON.stringify(result, null, 2));

        return result;
    }

}

export default Artifact;