
import * as React from 'react';
import CacheContext from './CacheContext.js';
import BrowserStorage from '../artifact/BrowserStorage.js';
import { getService, getServices } from '../BackendFunctions.js';
import Artifact from '../artifact/Artifact.js';

/**
 * 
 */
class CacheProvider extends React.Component {

    constructor(props) {
        super(props);
        // the browser storage to use
        this.browserStorage = new BrowserStorage();
        // service cache
        this.servicesCache = {}; // todo should we limit the size of the cache ? do we store them on the local storage ?
        // all references of artifacts requested
        this.artifacts = {};
    }

    /**
     * 
     * @param {*} serviceId 
     * @param {*} versionId 
     * @returns 
     */
    getService = (serviceId, versionId) => {
        return this.getServices([`${serviceId}:${versionId}`])
            .then(services => services[0]);
    }

    /**
     * 
     * @param {*} serviceIds 
     * @returns 
     */
    getServices = serviceIds => {
        if (!serviceIds) serviceIds = ['functions', 'lambdas', 'buckets', 'modules'];
        serviceIds = serviceIds.map(serviceId => {
            let [serviceName, versionId] = serviceId.split(':');
            if (!versionId) versionId = 'LATEST';
            return `${serviceName}:${versionId}`;
        });
        const allCachedServices = Object.keys(this.servicesCache);
        const notCachedServices = serviceIds.filter(serviceId => !allCachedServices.includes(serviceId));
        if (notCachedServices.length > 0) {
            const splitServices = notCachedServices.map(s => s.split(':'));
            const latestServices = splitServices.filter(([, versionId]) => versionId === 'LATEST').map(([serviceName, versionId]) => `${serviceName}:${versionId}`);
            const versionServices = splitServices.filter(([, versionId]) => versionId !== 'LATEST').map(([serviceName, versionId]) => `${serviceName}:${versionId}`);
            if (latestServices.length > 0) {
                const promise = getServices(latestServices.map(s => s.split(':')[0]));
                latestServices.map(s => s.split(':')).forEach(([serviceName, versionId]) => this.servicesCache[`${serviceName}:${versionId}`] = promise.then(services => {
                    // get the service
                    const service = services.find(service => service.serviceId === serviceName);
                    // set the service with the real version in cache also
                    this.servicesCache[`${service.serviceId}:${service.versionId}`] = Promise.resolve(service);
                    // return the service
                    return service;
                }));
            }
            if (versionServices.length > 0) {
                versionServices.map(s => s.split(':'))
                    .forEach(([serviceName, versionId]) => this.servicesCache[`${serviceName}:${versionId}`] = getService(serviceName, versionId));
            }
        }
        return Promise.all(serviceIds.map(serviceId => this.servicesCache[serviceId]));
    }

    /**
     * 
     * @param {*} path 
     * @param {*} src 
     * @returns 
     */
    createArtifact = path => {
        if (!path.startsWith('file:')) throw new Error('Artifact path must starts by "file:"')
        if (this.artifacts.hasOwnProperty(path)) throw new Error(`Artifact ${path} already exists.`);
        const name = path.substring(5); // remove the 'file:'
        const artifact = new Artifact(name, this.browserStorage);
        this.artifacts[path] = artifact;
        return artifact;
    }

    /**
     * 
     * @param {*} path 
     * @returns 
     */
    existsArtifact = path => {
        return this.artifacts.hasOwnProperty(path);
    }

    /**
     * 
     * @param {*} path 
     * @returns 
     */
    getArtifact = path => {
        if (!path.startsWith('file:')) {
            throw new Error('Artifact path must starts by "file:"')
        }
        if (!this.artifacts.hasOwnProperty(path)) {
            debugger;
            throw new Error(`Artifact ${path} does not exist.`);
        }
        return this.artifacts[path];
    }

    /**
     * 
     * @param {*} artifact 
     * @returns 
     */
    preloadArtifact = artifact => {
        const folderPromise = artifact.getFolder('/');
        return artifact.getFileAsJson('/resource.json').then(resourceClass => {
            const [serviceId, versionId] = resourceClass.service.split(':');
            return this.getService(serviceId, versionId || 'LATEST').then(service => {
                // select the file to preload.
                return folderPromise.then(folder => {
                    const main = service.resource.main;
                    if (main && folder.files.includes(main)) {
                        return `/${main}`;
                    } else {
                        // auto detect
                        if (folder.files.includes('package.json')) {
                            return artifact.getFileAsJson('/package.json').then(pkg => {
                                // get the main file declared in the package.json file.
                                let main = pkg.main;
                                if (!main.startsWith('/')) main = `/${main}`;
                                return main;
                            });
                        }
                    }
                }).then(main => {
                    if (main) {
                        return artifact.getFile(main).then(text => {
                            if (serviceId === 'modules') {
                                // preload resources and services
                                const module = JSON.parse(text);
                                const resoucesPromises = Object.entries(module).map(([resourceId, resource]) => {
                                    let a;
                                    if (this.existsArtifact(`file:${artifact.name}/${resourceId}`)) {
                                        a = this.getArtifact(`file:${artifact.name}/${resourceId}`);
                                    } else {
                                        a = this.createArtifact(`file:${artifact.name}/${resourceId}`);
                                        a.setSrc({
                                            bucket: 'apps',
                                            path: resource.resource
                                        });
                                    }
                                    return a.getFileAsJson('/resource.json');
                                });
                                return Promise.all(resoucesPromises).then(resources => {
                                    const services = [...new Set(resources.map(resource => resource.service))];
                                    return this.getServices(services);
                                });
                            }
                        });
                    }
                });
            });
        }).catch(() => console.error('Preload failed'));
    }

    /**
     * 
     * @returns 
     */
    render() {
        return <CacheContext.Provider value={{
            artifacts: this.artifacts,
            getService: this.getService.bind(this),
            getServices: this.getServices.bind(this),
            createArtifact: this.createArtifact.bind(this),
            existsArtifact: this.existsArtifact.bind(this),
            getArtifact: this.getArtifact.bind(this),
            preloadArtifact: this.preloadArtifact.bind(this),
        }}>
            {this.props.children}
        </CacheContext.Provider>
    }
}

export default CacheProvider;
