// TODO REMOVE THIS CLASS - Ca sert plus a rien !

import firebase from "firebase/app";
import "firebase/storage";

export const StringFormat = {
    RAW: "raw",
    BASE64: "base64",
    BASE64URL: "base64url",
    DATA_URL: "data_url",
}

export function bucket(name) {
    return new Bucket(name);
}

export function reference(bucket, fullPath) {
    return new Reference(bucket, fullPath);
}

export function listAll(reference) {
    return new Promise((resolve, reject) => {
        reference.firebaseRef.listAll()
            .then((res) => {
                let prefixes = res.prefixes.map((folderRef) => ({
                    name: folderRef.name,
                    fullPath: folderRef.fullPath,
                    bucket: folderRef.bucket
                }));
                let items = res.items.map((folderRef) => ({
                    name: folderRef.name,
                    fullPath: folderRef.fullPath,
                    bucket: folderRef.bucket
                }));
                resolve({ prefixes, items })
            }).catch((err) => {
                reject(err)
            });
    });
}

export function getDownloadURL(reference) {
    return reference.firebaseRef.getDownloadURL();
}

export function getMetadata(reference) {
    return reference.firebaseRef.getMetadata();
}

export function updateMetadata(reference, metadata) {
    return reference.firebaseRef.updateMetadata(metadata);
}

// todo implement progress etc etc .. error handling ??
export function uploadString(reference, str, format, metadata) {
    return new Promise((resolve, reject) => {
        reference.firebaseRef.putString(str, format, metadata)
            .then((snapshot) => {
                console.log(snapshot);
                resolve(true); // 
            })
            .catch(err => reject(err));
    });
}

// todo implement progress etc etc .. error handling ??
export function uploadJson(reference, jsonString, metadata) {
    const blob = new Blob([jsonString], { type: 'application/json' });
    return new Promise((resolve, reject) => {
        reference.firebaseRef.put(blob, metadata)
            .then((snapshot) => {
                console.log(snapshot);
                resolve(true); // 
            })
            .catch(err => reject(err));
    });
}


class Reference {

    constructor(bucket, fullPath) {
        this.bucket = bucket;
        this.fullPath = fullPath;
        this.firebaseRef = bucket.firebaseRef.child(fullPath);
    }

}


class Bucket {

    constructor(name) {
        this.name = name;
        this.firebaseRef = firebase.app().storage('gs://' + name).ref();
    }




    /* uploadBlob(filename, blob, metadata) {
         let progress = null;
         let p = new Promise((resolve, reject) => {
             //var blob = new Blob([content], metadata)
             firebase.app().storage('gs://' + this.name).ref().child(filename).put(blob)
                 .on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                     (snapshot) => {
                         if (progress) {
                             progress({
                                 bytesTransferred: snapshot.bytesTransferred, 
                                 totalBytes: snapshot.totalBytes
                             });
                         }
                         switch (snapshot.state) {
                             case firebase.storage.TaskState.PAUSED: // or 'paused'
                                 //console.log('Upload is paused');
                                 break;
                             case firebase.storage.TaskState.RUNNING: // or 'running'
                                 //console.log('Upload is running');
                                 break;
                         }
                     },
                     (error) => {
                         // A full list of error codes is available at
                         // https://firebase.google.com/docs/storage/web/handle-errors
                         switch (error.code) {
                             case 'storage/unauthorized':
                                 // User doesn't have permission to access the object
                                 break;
                             case 'storage/canceled':
                                 // User canceled the upload
                                 break;
 
                             // ...
 
                             case 'storage/unknown':
                                 // Unknown error occurred, inspect error.serverResponse
                                 break;
                         }
                         reject(error);
                     },
                     () => {
                         resolve(filename);
                     }
                 );
         });
         p.progress = (handler) => {
             progress = handler;
             return p;
         }
         return p;
     }*/


}
