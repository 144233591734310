import { mdiAlphaJBoxOutline, mdiChevronRight, mdiCodeBraces, mdiCubeOutline, mdiDotsVertical, mdiFilePlusOutline, mdiFolderOpenOutline, mdiFolderOutline, mdiFolderPlusOutline, mdiInformationOutline, mdiLanguageJavascript, mdiLanguagePython, mdiTextLong } from '@mdi/js';
import Icon from '@mdi/react';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import 'firebase/firestore';
import React from 'react';
import ServiceSelectMini from '../app/ServiceSelectMini';
import AutoFocusInput from '../system/AutoFocusInput';
import SystemIconButton from '../system/SystemIconButton';
import SystemIconButtonSmall from '../system/SystemIconButtonSmall';
import SystemPopper from '../system/SystemPopper';
import SystemToolbar from '../system/SystemToolbar';

export const getFileIcon = pathOrName => {
    const name = pathOrName.substring(pathOrName.indexOf('/') + 1);
    if (name === 'resource.json') return mdiCubeOutline;
    else if (name.endsWith('.js')) return mdiLanguageJavascript;
    else if (name.endsWith('.json')) return mdiCodeBraces;
    else if (name.endsWith('.py')) return mdiLanguagePython;
    else if (name.endsWith('.java')) return mdiAlphaJBoxOutline;
    else if (name.endsWith('.md')) return mdiInformationOutline;
    else return mdiTextLong;
}

/**
 * Events
 */
export const ArtifactBrowserEvents = {
    FILE_SELECTED: 'file-selected',
    FILE_CREATED: 'file-created',
    FILE_DELETED: 'file-deleted',
    FOLDER_SELECTED: 'folder-selected',
    FOLDER_CREATED: 'folder-created',
    FOLDER_DELETED: 'folder-deleted',
}

class ArtifactBrowser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            artifactVersion: undefined, // the version of the artifact (put in state to force rerender when the structure change)

            expandedNodeIds: ['fold:/'], // list of expanded nodes

            displayCreateFile: false, // whether or not we should display the input to create a file
            displayCreateFolder: false, // whether or not we should display the input to create a folder
        };

        this.lastFileTreeClick = {};

        this.input = React.createRef();
    }

    /**
     * 
     */
    componentDidMount() {
        this.props.artifact.addStructureChangeListener(this.handleStructureChangeListener.bind(this));
        this.props.artifact.getFolder('/');
    }

    componentDidUpdate() {

    }

    /**
     * 
     * @param {*} event 
     */
    handleStructureChangeListener(event) {
        this.setState({
            artifactVersion: this.props.artifact.getVersion()
        })
    }

    /**
     * 
     * @returns 
     */
    getSelectedFolder() {
        const nodeId = this.props.selectedNodeId;
        if (!nodeId) return '/';
        const type = nodeId.substring(0, 4);
        if (type == 'fold') {
            return nodeId.substring(5);
        } else if (type == 'file') {
            const filepath = nodeId.substring(5);
            return filepath.substring(0, filepath.lastIndexOf('/') + 1);
        } else {
            // ignore
            // this is called when clicking on the create file / folder inputs.
        }
    }

    /**
 * 
 * @param {*} filepath 
 */
    /*selectFile = filepath => {
        this.onNodeSelect(null, `file:${filepath}`);
    }*/

    /**
     * 
     * @param {*} event 
     * @param {*} nodeId 
     */
    onNodeSelect = (event, nodeId) => {
        /*this.setState({
            selectedNodeId: nodeId,
        });*/
        const type = nodeId.substring(0, 4);
        const filepath = nodeId.substring(5);
        if (type == 'file') {
            this.props.onFileTreeChange(ArtifactBrowserEvents.FILE_SELECTED, filepath);
        } else if (type == 'fold') {
            this.props.onFileTreeChange(ArtifactBrowserEvents.FOLDER_SELECTED, filepath);
        }
    }

    /**
     * 
     * @param {*} event 
     * @param {*} nodeIds 
     */
    onNodeToggle = (event, expandedNodeIds) => {
        this.setState({ expandedNodeIds });
    }

    /**
     * 
     * @param {*} filepath 
     * @returns 
     */
    handleFileDelete = filepath => event => {
        event.stopPropagation();
        this.props.artifact.deleteFile(filepath);
        // select another node
        /*this.setState({
            selectedNodeId: 'fold:/',
        });*/
        this.props.onFileTreeChange(ArtifactBrowserEvents.FILE_DELETED, filepath);
    }

    /**
     * 
     * @param {*} filepath 
     * @returns 
     */
    handleFolderDelete = filepath => event => {
        event.stopPropagation();
        this.props.artifact.deleteFolder(filepath);
        // select another node
        /*this.setState({
            selectedNodeId: 'fold:/',
        });*/
        this.props.onFileTreeChange(ArtifactBrowserEvents.FOLDER_DELETED, filepath);
    }

    /**
     * 
     * @param {*} nodeId 
     * @returns 
     */
    handleFolderRename = nodeId => event => {
        event.stopPropagation();
        this.setState({
            renameNodeId: nodeId
        });
    }

    /**
     * 
     * @param {*} event 
     */
    displayCreateFile = () => {
        const state = {};
        if (!this.state.expandedNodeIds.includes(this.props.selectedNodeId)) {
            state.expandedNodeIds = [this.props.selectedNodeId, ...this.state.expandedNodeIds];
        }
        state.displayCreateFile = true;
        state.displayCreateFolder = false;
        this.setState(state, () => {
            //this.input.current.focus();
        });
    }

    /**
     * 
     * @param {*} event 
     */
    displayCreateFolder = () => {
        const state = {};
        if (!this.state.expandedNodeIds.includes(this.props.selectedNodeId)) {
            state.expandedNodeIds = [this.props.selectedNodeId, ...this.state.expandedNodeIds]
        }
        state.displayCreateFile = false;
        state.displayCreateFolder = true;
        this.setState(state, () => {
            //this.input.current.focus();
        });
    }

    /**
     * 
     * @param {*} folder 
     * @returns 
     */
    handleCreateFile = folder => value => {
        if (!value) {
            this.setState({
                displayCreateFile: false,
                displayCreateFolder: false,
            });
        } else {
            const filepath = `${folder}${value}`;
            return this.props.artifact.createFile(filepath, '{}').then(() => {
                //var nodeId = `file:${filepath}`;
                this.setState({
                    displayCreateFile: false,
                    displayCreateFolder: false,
                    //selectedNodeId: nodeId,
                });
                this.props.onFileTreeChange(ArtifactBrowserEvents.FILE_CREATED, filepath);
            });
        }
    }

    /**
     * 
     * @param {*} folder 
     * @returns 
     */
    handleCreateFolder = folder => value => {
        if (!value) {
            this.setState({
                displayCreateFile: false,
                displayCreateFolder: false,
            });
        } else {
            const filepath = `${folder}${value}/`;
            return this.props.artifact.createFolder(filepath).then(() => {
                // update and rerender the tree
                //var nodeId = `fold:${filepath}`;
                this.setState({
                    displayCreateFile: false,
                    displayCreateFolder: false,
                    //selectedNodeId: nodeId,
                });
                this.props.onFileTreeChange(ArtifactBrowserEvents.FOLDER_CREATED, filepath);
            });
        }
    }

    /**
     * 
     * @param {*} path 
     * @param {*} level 
     * @returns 
     */
    getDisplayCreateFolderItem = (path, level, value) => {
        return <StyledTreeItem ref={this.input} level={level} nodeId={'new'} icon={<Icon path={mdiFolderOutline} color={'#0e87ff'} size={.6} />} label={<NewItemInput defaultValue={value} onChange={this.handleCreateFolder(path)} />} >
            <StyledTreeItem level={level + 1} sx={{ height: 0, display: 'none' }} />
        </StyledTreeItem>
    }

    /**
     * 
     * @param {*} path
     * @param {*} level
     * @returns 
     */
    getDisplayCreateFileItem = (path, level) => {
        return <StyledTreeItem ref={this.input} level={level} nodeId={'new'} label={<NewItemInput onChange={this.handleCreateFile(path)} />} />
    }

    /**
     * 
     * @param {*} path folder path (/ or /folderA or /folderA/SubFolder)
     * @returns 
     */
    getFileTreeComponent = path => {
        // the level (used to indent the filetree tree)
        const level = path.split('/').length - 1;

        // the items to display
        var items = [];

        // get the folder already loaded. This means you cannot directly open a deep node. You need the use to go level by level.
        var folder = this.props.artifact.getLocalFolder(path);

        if (folder == null) {
            if (this.state.displayCreateFolder && this.getSelectedFolder() === path) {
                items.push(this.getDisplayCreateFolderItem(path, level));
            }
            else if (this.state.displayCreateFile && this.getSelectedFolder() === path) {
                items.push(this.getDisplayCreateFileItem(path, level));
            }
        } else {
            // display the create folder input at the top if requested
            if (this.state.displayCreateFolder && this.getSelectedFolder() === path) {
                items.push(this.getDisplayCreateFolderItem(path, level));
            }

            // then display folders in alphabetic order
            folder.folders.sort().forEach(name => {
                if (this.state.renameNodeId === `fold:${path}${name}/`) {
                    items.push(this.getDisplayRenameFolderItem(path, level, name));
                } else {
                    items.push(<StyledTreeItem
                        level={level}
                        TransitionProps={{ timeout: 0 }}
                        nodeId={`fold:${path}${name}/`}
                        label={<FileTreeLabel name={name} handleRename={() => alert('TODO')} handleDelete={this.handleFolderDelete(`${path}${name}/`)} />}
                        onClick={() => this.props.artifact.getFolder(`${path}${name}/`)}
                    >
                        {this.getFileTreeComponent(`${path}${name}/`)}
                    </StyledTreeItem>);
                }
            });

            // then display the create file input if requested
            if (this.state.displayCreateFile && this.getSelectedFolder() == path) {
                items.push(this.getDisplayCreateFileItem(path, level));
            }

            // at the end, display the files in alphabetic order
            folder.files.sort().forEach(name => {
                items.push(<StyledTreeItem
                    level={level}
                    fileDisplayed={`${path}${name}` === this.props.selectedFilepath}
                    TransitionProps={{ timeout: 0 }}
                    nodeId={`file:${path}${name}`}
                    //color={this.props.artifact.isModifiedFile(`${path}${name}`) ? '#E79627' : null}
                    label={<FileTreeLabel name={name} handleRename={() => alert('TODO')} handleDelete={this.handleFileDelete(`${path}${name}`)} />}
                    icon={<Icon path={getFileIcon(name)} size={.6} color={'#0e87ff'} />}
                    onClick={() => { }}
                />);

            });
        }
        if (items.length == 0) items.push(<TreeItem sx={{ display: 'none' }} />)
        return items;
    }

    render() {
        const { title, artifact, selectedNodeId, onFileTreeChange, ...otherProps } = this.props;
        return (
            <Box {...otherProps}>
                <SystemToolbar
                    open
                    icon={mdiChevronRight}
                    title={this.props.name || 'Files'}
                    actions={<>
                        <SystemIconButton path={mdiFilePlusOutline} onClick={this.displayCreateFile.bind(this)} />
                        <SystemIconButton path={mdiFolderPlusOutline} onClick={this.displayCreateFolder.bind(this)} />
                        <SystemIconButton path={mdiDotsVertical} />
                    </>}>
                    <TreeView
                        expanded={this.state.expandedNodeIds}
                        selected={this.props.selectedNodeId}
                        onNodeToggle={this.onNodeToggle.bind(this)}
                        onNodeSelect={this.onNodeSelect.bind(this)}
                        defaultExpanded={['1']}
                        defaultCollapseIcon={<Icon path={mdiFolderOpenOutline} color={'#0e87ff'} size={.7} />}
                        defaultExpandIcon={<Icon path={mdiFolderOutline} color={'#0e87ff'} size={.7} />}
                        defaultEndIcon={<Icon path={mdiTextLong} color={'#0e87ff'} size={.7} />}
                    >
                        {this.getFileTreeComponent('/')}
                    </TreeView>
                </SystemToolbar>
            </Box>
        )
    }

}

const NewItemInput = styled((props) => (
    <AutoFocusInput {...props}></AutoFocusInput>
))(({ theme }) => ({
    //fontFamily: '"IBM Plex Sans", sans-serif',
    width: '100%',
    padding: '0',
    //background: '#FFF',
    color: '#333',
    fontSize: 14,
    height: 18,
    //outline: '1px solid blue',
    //borderRadius: '1px',
    '& .MuiInputBase-input': {
        padding: '0 0px'
    }
}));


const StyledTreeItem = React.forwardRef(({ level, color, fileDisplayed, ...otherProps }, ref) => {
    return <TreeItem
        ref={ref}
        sx={{
            "& .MuiTreeItem-content": {
                color: color || '#3d4047',
                minHeight: '24px',
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: `${0 + level * 8}px`,
                paddingRight: '0px',
                borderRadius: '5px',
                //cursor: 'pointer',
                border: '2px solid transparent',//#87fcc4',
                backgroundColor: fileDisplayed ? '#e6f3ff' : 'transparent',
                "&.Mui-focused": {
                    //border: '2px solid #0e87ff',//#87fcc4',
                },
                '&:hover': {
                    backgroundColor: '#ebebea',
                    '& .MuiTreeItem-label .actions': {
                        visibility: 'visible'
                    },
                    '& .MuiTreeItem-label .actions': {
                        visibility: 'visible'
                    }
                },
                '&.Mui-selected': {
                    backgroundColor: fileDisplayed ? '#e6f3ff' : '#e6f3ff',
                    //border: '2px solid #0e87ff',//#87fcc4',

                    border: fileDisplayed ? 'none' : '2px solid transparent',
                    borderLeft: fileDisplayed ? '2px solid #0e87ff' : '2px solid transparent',

                    borderTopLeftRadius: fileDisplayed ? 0 : '5px',
                    borderBottomLeftRadius: fileDisplayed ? 0 : '5px',

                    //color: 'white',
                    /*"&.Mui-focused": {
                        border: '2px solid #0e87ff',//#87fcc4',
                        backgroundColor: fileDisplayed ? '#e6f3ff' : 'transparent',
                    },*/

                    ":hover": {
                        backgroundColor: '#f3f3f3',
                        //border: '2px solid #0e87ff',
                        '& .MuiTreeItem-label .actions .MuiIconButton-root': {
                            //color: 'white'
                        }
                    },
                },
                '& .MuiTreeItem-label': {
                    minHeight: 'initial',
                    //paddingTop: '1px',
                    //paddingBottom: '1px',
                },
                '& .MuiTreeItem-label .actions': {
                    visibility: 'hidden'
                },
                '& .MuiTreeItem-iconContainer': {
                    //borderLeft: '1px solid blue',
                }
            },
        }}
        TransitionProps={{
            timeout: 0
        }}
        {...otherProps}
    />
});

class FileTreeLabel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
        this.element = React.createRef();
    }

    open = open => {
        this.setState({ open });
    }

    handleClick = event => {
        this.open(true);
        event.stopPropagation();
    }

    render() {
        return <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body" sx={{ fontWeight: 400, flexGrow: 1 }}>
                {this.props.name}
            </Typography>
            <Box className={'actions'}>
                <SystemIconButtonSmall ref={this.element} path={mdiDotsVertical} onClick={this.handleClick.bind(this)} />
                <SystemPopper
                    anchorEl={this.element.current}
                    onClickAway={() => this.open(false)}
                    open={this.state.open}
                >
                    <ServiceSelectMini />
                </SystemPopper>
            </Box>
        </Box>
    }
}

export default ArtifactBrowser;