
import { Box } from '@mui/material';
import * as React from 'react';
import InfiniteGesture from '../infinite/InfiniteGesture.js';


/**
 * 
 */
class SystemResizable extends React.Component {

    constructor(props) {
        super(props);
        this.container = React.createRef();
        this.element = React.createRef();
        this.inside = false;
        this.dragging = false;
    }

    /**
     * 
     */
    componentDidMount() {
        this.gesture = new InfiniteGesture(this.element.current);
        this.gesture.listenMouseEvents();
        this.setElementDragGestureListener();

        this.container.current.setAttribute('sticky', false);
        this.container.current.setAttribute('dragging', false);

        this.element.current.addEventListener('mousemove', event => {
            this.inside = true;
            if (!this.id && event.which == 0) { // TODO event.which == 0 ne marche sur FF
                document.body.classList.add("colResizeCursors"); // only way to override all cursor
                const sticky = this.container.current.getAttribute('sticky');
                if (sticky === 'false') {
                    this.id = setTimeout(() => {
                        this.element.current.style.backgroundColor = '#2da7fa';
                        this.id = null;
                    }, 350);
                } else {
                    this.element.current.style.backgroundColor = '#2da7fa';
                }
            }
        });
        this.element.current.addEventListener('mouseup', event => {
            //if (this.inside && this.props.mousedown == 0) {
            if (this.inside) {
                document.body.classList.add("colResizeCursors"); // only way to override all cursor
                const sticky = this.container.current.getAttribute('sticky');
                if (sticky === 'false') {
                    this.id = setTimeout(() => {
                        this.element.current.style.backgroundColor = '#2da7fa';
                        this.id = null;
                    }, 350);
                } else {
                    this.element.current.style.backgroundColor = '#2da7fa';
                }
            }
        });
        this.element.current.addEventListener('mouseleave', event => {
            if (!this.inside) return;
            this.inside = false;
            if (!this.dragging) {
                if (this.id) {
                    clearTimeout(this.id);
                    this.id = null;
                }
                document.body.classList.remove("colResizeCursors");
                this.element.current.style.removeProperty('background-color');
            }
        });
    }

    /**
     * 
     */
    setElementDragGestureListener() {
        const sign = this.props.side == 'left' ? -1 : 1;
        let startsWidth;
        this.gesture.condition(({ event }) => true)
            .onStart(({ event }) => {
                this.element.current.style.backgroundColor = '#2da7fa';
                this.container.current.setAttribute('dragging', 'start');
                if (!(event && event.which == 1 && !event.metaKey)) return;
                const rect = this.container.current.getBoundingClientRect();
                startsWidth = rect.width;
                this.dragging = true;
                this.props.onSizeChange(startsWidth, 'start');
            })
            .onMove(({ deltaX }) => {
                this.props.onSizeChange(startsWidth + sign * deltaX, 'move');
            })
            .onStop(({ deltaX }) => {
                this.container.current.setAttribute('dragging', false);
                this.dragging = false;
                this.props.onSizeChange(startsWidth + sign * deltaX, 'stop');
                if (!this.inside) {
                    if (this.id) {
                        clearTimeout(this.id);
                        this.id = null;
                    }
                    document.body.classList.remove("colResizeCursors");
                    this.element.current.style.removeProperty('background-color');
                }
            })
            .onCancel(() => {

            });
    }

    /**
     * 
     * @returns 
     */
    render() {
        const { children, side = 'right', sx, ...otherProps } = this.props;

        return <Box
            ref={this.container}
            {...otherProps}
            sx={{
                ...sx,
                width: 0,
                position: 'relative',
                '&[sticky="true"] > div.handle': {
                    //display: (side == 'right') ? 'default' : 'none',
                    //height: '100%',
                },
                '&[overlap="true"][sticky="false"] > div.shadow': {
                    //boxShadow: (side == 'right') ? '#888888 -6px 0px 17px 0px' : '#888888 6px 0px 17px 0px',
                    //boxShadow: '#888888 -6px 0px 17px 0px',
                    boxShadow: '#888888 -6px 0px 17px 0px',

                },
                '&[overlap="true"][sticky="false"] > div:last-child': {
                    opacity: .7,
                },
                /*'&[init="true"] > div:last-child': {
                  flexDirection: (side == 'right') ? 'row' : 'row-reverse',
                },*/
            }}>

                <Box className='shadow'
                    sx={{
                        position: 'absolute',
                        zIndex: 100,
                        top: 0,
                        bottom: 0,
                        width: 4,
                        margin: '4px 0 4px 0',
                        borderRadius: '3px',
                        backgroundColor: 'transparent',
                        transition: 'all .2s',
                        ...(side === 'right') ? {
                            right: -6,
                        } : {},
                        ...(side === 'left') ? {
                            left: -6,
                        } : {},
                    }} />
                    
                <Box
                    ref={this.element}
                    sx={{
                        position: 'absolute',
                        zIndex: 100,
                        top: 0,
                        bottom: 0,
                        width: 4,
                        margin: '4px 0 4px 0',
                        borderRadius: '3px',
                        backgroundColor: 'transparent',
                        transition: 'all .2s',
                        ...(side === 'right') ? {
                            right: -6,
                        } : {},
                        ...(side === 'left') ? {
                            left: -6,
                        } : {},
                    }} />

                <Box
                    className='handle'
                    sx={{
                        position: 'absolute',
                        zIndex: 10,
                        top: 0,
                        bottom: 0,
                        margin: 'auto 0',
                        width: 4,
                        height: '30px',
                        backgroundColor: 'rgba(0,0,0,.1)',
                        borderRadius: '3px',
                        ...(side === 'right') ? {
                            right: -6,
                        } : {},
                        ...(side === 'left') ? {
                            left: -6,
                        } : {},
                    }} />

            <Box sx={{
                transition: 'opacity .4s',
                overflow: 'hidden',
                display: 'flex',
                height: '100%',
            }}>
                {this.props.children}
            </Box>
        </Box>
    }
}


export default SystemResizable;
