import { createSlice } from '@reduxjs/toolkit';
import { createId } from '../utils/idgenerator.js';

const initialState = {/*
    // module.json
    resources: {},

    // layout.json
    layout: {
        resources: {},
        viewport: {}
    },

    // edit (not saved in a file)
    edit: {
        cursor: 'default',
        resources: {},
        elements: {},
        selected: [],
        viewport: {
            container: { x: 0, y: 0 },
            delta: { x: 0, y: 0 },
            offset: { x: 0, y: 0 },
            x: 0,
            y: 0,
            w: 0,
            h: 0
        },
    },*/
};

/**
 * module slice
 */
export const slice = createSlice({
    name: 'module',
    initialState,
    reducers: {


        clear: (state) => {
            state.services = {};
            state.resources = {};
            state.architecture = {};
            state.layout.resources = {};
            state.layout.viewport = {};
            state.edit = {
                cursor: 'default',
                resources: {},
                elements: {},
                selected: [],
                viewport: {
                    container: { x: 0, y: 0 },
                    delta: { x: 0, y: 0 },
                    offset: { x: 0, y: 0 },
                    x: 0,
                    y: 0,
                    w: 0,
                    h: 0
                },
            }
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        setGraph: (state, action) => {
            console.log('setGraph')
            state.services = action.payload.services;
            state.resources = action.payload.resources;
            state.architecture = action.payload.architecture;
        },

        /**
         * 
         * @param {*} state
         * @param {*} action
         */
        addService: (state, action) => {
            let { serviceId, versionId } = action.payload;
            state.services[serviceId] = versionId
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addResource: (state, action) => {
            let { resourceId, resource } = action.payload;
            state.resources[resourceId] = resource;
        },

        addResourceInstance: (state, action) => {
            let { resourceId, resource, layout } = action.payload;
            state.architecture[resourceId] = resource;
            state.layout.resources[resourceId] = layout;
        },

        removeResource: () => {
            // TODO
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        removeResourceInstance: (state, action) => {
            let { resourceId } = action.payload;
            // remove all connections where the resource is references
            Object.values(state.architecture).forEach(resource => {
                Object.keys(resource).filter(k => !['name', 'description', 'service', 'src'].includes(k)).forEach(k => {
                        // go through all objects, the 'resource' key, is specific to dependencies, 
                        // so if we found a resource value equals to the resource ID, we have to remove it
                        resource[k] = Object.fromEntries(Object.entries(resource[k])
                        .filter(([name, object]) => {
                            return object.resource != resourceId
                        }))
                })
            });
            if (state.edit.selected.includes(resourceId)) {
                state.edit.selected.splice(state.edit.selected.findIndex(i => i === resourceId), 1);
            }
            delete state.layout.resources[resourceId];
            delete state.edit.resources[resourceId];
            delete state.architecture[resourceId];
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        setResourceName: (state, action) => {
            state.resources[action.payload.resourceId].name = action.payload.name
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        setResourceInstanceName: (state, action) => {
            state.architecture[action.payload.resourceId].name = action.payload.name
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        removeResourceName: (state, action) => {
            delete state.resources[action.payload.resourceId].name;
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        removeResourceInstanceName: (state, action) => {
            delete state.architecture[action.payload.resourceId].name;
        },
        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        setResourceDescription: (state, action) => {
            state.resources[action.payload.resourceId].description = action.payload.description
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        setResourceInstanceDescription: (state, action) => {
            state.architecture[action.payload.resourceId].description = action.payload.description
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        removeResourceDescription: (state, action) => {
            delete state.resources[action.payload.resourceId].description;
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        removeResourceInstanceDescription: (state, action) => {
            delete state.architecture[action.payload.resourceId].description;
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addDependency: (state, action) => {
            let a = action.payload;
            if (!state.resources[a.resourceId].hasOwnProperty(a.name)) {
                state.resources[a.resourceId][a.name] = {}
            }
            state.resources[a.resourceId][a.name][a.dependencyName] = a.dependency;
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        removeDependency: (state, action) => {
            let a = action.payload;
            if (state.resources[a.resourceId].hasOwnProperty(a.dependencyGroup)) {
                delete state.resources[a.resourceId][a.dependencyGroup][a.name];
            }
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        renameDependency: (state, action) => {
            let a = action.payload;
            if (state.resources[a.resourceId].hasOwnProperty(a.dependencyGroup)) {
                var dependencies = state.resources[a.resourceId][a.dependencyGroup];
                state.resources[a.resourceId][a.dependencyGroup] = Object.fromEntries(Object.entries(dependencies).map(
                    ([key, value], index) => [index == a.index ? a.newName : key, value]
                ));
            }
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        updateDependency: (state, action) => {
            let a = action.payload;
            if (state.resources[a.resourceId].hasOwnProperty(a.dependencyGroup)) {
                var dependencies = state.resources[a.resourceId][a.dependencyGroup];
                if (dependencies.hasOwnProperty(a.name)) {
                    dependencies[a.name][a.key] = a.value;
                }
            }
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addVariable: (state, action) => {
            let a = action.payload;
            if (!state.resources[a.resourceId].hasOwnProperty(a.type)) {
                state.resources[a.resourceId][a.type] = {}
            }
            state.resources[a.resourceId][a.type][a.name] = a.variable;
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        removeVariable: (state, action) => {
            let a = action.payload;
            if (state.resources[a.resourceId].hasOwnProperty('variables')) {
                delete state.resources[a.resourceId].variables[a.name];
            }
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        renameVariable: (state, action) => {
            let a = action.payload;
            if (state.resources[a.resourceId].hasOwnProperty(a.type)) {
                var variables = state.resources[a.resourceId][a.type];
                state.resources[a.resourceId][a.type] = Object.fromEntries(Object.entries(variables).map(
                    ([key, value], index) => [index == a.index ? a.newName : key, value]
                ));
            }
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        updateVariable: (state, action) => {
            let a = action.payload;
            if (!state.resources[a.resourceId].hasOwnProperty(a.type)) {
                state.resources[a.resourceId][a.type] = {};
            }
            var variables = state.resources[a.resourceId][a.type];
            if (!variables.hasOwnProperty(a.name)) {
                variables[a.name] = {}; 
            }
            variables[a.name][a.key] = a.value;
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        setConfigurationValue: (state, action) => {
            let a = action.payload;
            if (!state.resources[a.resourceId].hasOwnProperty('configuration')) {
                state.resources[a.resourceId].configuration = {}
            }
            var configuration = state.resources[a.resourceId].configuration;
            configuration[a.name] = a.value;
        },



        // LAYOUT

        setLayout: (state, action) => {
            console.log('setLayout')
            state.layout.resources = action.payload.resources || {};
            state.layout.viewport = action.payload.viewport || {};
        },

        removeFromLayout: (state, action) => {
            let r = state.layout.resources, a = action.payload;
            delete r[a];
        },

        /**
         * Set the position of a resource (x, y)
         */
        updateResourcePosition: (state, action) => {
            let r = state.layout.resources, a = action.payload;
            if (Array.isArray(a)) {
                a.forEach(o => {
                    if (!r.hasOwnProperty(o.resourceId)) r[o.resourceId] = {};
                    //if (o.resourceId) r[o.resourceId].resourceId = o.resourceId; // TODO: IS IT NEEDED ?
                    if (o.x) r[o.resourceId].x = o.x;
                    if (o.y) r[o.resourceId].y = o.y;
                    if (o.w) r[o.resourceId].w = o.w;
                    if (o.h) r[o.resourceId].h = o.h;
                });
            } else {
                if (!r.hasOwnProperty(a.resourceId)) r[a.resourceId] = {};
                //if (a.resourceId) r[a.resourceId].resourceId = a.resourceId; // TODO: IS IT NEEDED ?
                if (a.x) r[a.resourceId].x = a.x;
                if (a.y) r[a.resourceId].y = a.y;
                if (a.w) r[a.resourceId].w = a.w;
                if (a.h) r[a.resourceId].h = a.h; // h and w should be in the edit slice !!!!!!!!!!!!!!!
            }
        },

        move: (state, action) => {
            let r = state.layout.resources, a = action.payload;
            r[a.resourceId].x += a.deltaX;
            r[a.resourceId].y += a.deltaY;
        },

        removeResourcePosition: (state, action) => {
            let resourceId = action.payload.resourceId;
            delete state.layout.resources[resourceId];
        },

        // cannot group a group !
        group: (state, action) => {
            let a = action.payload;
            if (!a || a.length == null || a.length == 0) return;

            let minX = null, minY = null, maxX = null, maxY = null;
            var parentId = 'g/' + createId();
            let ids = [];

            // get all resources ()
            a.forEach(id => {
                let p = state.layout.resources[id];
                if (p.type != 'group') {
                    ids.push(id);
                }
            });

            // compute group size
            /*ids.forEach(id => {
                let p = state.layout.resources[id];
                if (minX == null || p.x < minX) minX = p.x;
                if (minY == null || p.y < minY) minY = p.y;
                if (maxX == null || p.x + p.w > maxX) maxX = p.x + p.w;
                if (maxY == null || p.y + p.h > maxY) maxY = p.y + p.h;
            });*/

            // create the group before mofifying resources, just to be sure it is rendered before (not sure it is needed )
            /*state.layout.resources[parentId] = {
                type: 'group',
                resourceId: parentId,
            };*/

            // set the new parent
            ids.forEach(id => {
                let child = state.layout.resources[id];
                child.parent = parentId;
            });
        },

        ungroup: (state, action) => {
            let groupId = action.payload; // the group id
            Object.entries(state.layout.resources).filter(([, r]) => r.parent == groupId).map(([, r]) => {
                delete r.parent;
            });
        },

        // ********** EDIT **********

        addElement: (state, action) => {
            let elementId = action.payload.id;
            let json = action.payload;
            delete json.elementId;
            state.edit.elements[elementId] = action.payload;
        },

        removeElement: (state, action) => {
            let elementId = action.payload.id;
            delete state.edit.elements[elementId];
        },


        setConnecting: (state, action) => {
            let r = state.edit.resources, a = action.payload;
            if (!r.hasOwnProperty(a.resourceId)) r[a.resourceId] = {};
            r[a.resourceId].connecting = true;

            state.edit.connecting = {
                resourceId: a.resourceId,
                dependencyGroup: a.dependencyGroup,
                dependencyName: a.dependencyName,
                value: a.value,
                x: a.x,
                y: a.y
            }
        },

        removeConnecting: (state, action) => {
            let r = state.edit.resources, a = action.payload;
            if (r.hasOwnProperty(a.resourceId)) {
                delete r[a.resourceId].connecting;
            }
            delete state.edit.connecting;
        },

        setCursor: (state, action) => {
            state.edit.cursor = action.payload;
        },

        setInfiniteSelector: (state, action) => {
            let a = action.payload;
            state.edit.selector = {
                x1: a.x,
                y1: a.y,
                x2: a.x,
                y2: a.y,
            }
        },

        updateInfiniteSelector: (state, action) => {
            let a = action.payload;
            state.edit.selector.x2 = a.x;
            state.edit.selector.y2 = a.y;
        },

        removeInfiniteSelector: (state, action) => {
            delete state.edit.selector;
        },

        setResourceMoving: (state, action) => {
            let r = state.edit.resources, a = action.payload;
            if (Array.isArray(a)) {
                a.forEach(o => {
                    if (!r.hasOwnProperty(o.resourceId)) r[o.resourceId] = {};
                    r[o.resourceId].moving = {
                        x: o.x,
                        y: o.y
                    };
                });
            } else {
                if (!r.hasOwnProperty(a.resourceId)) r[a.resourceId] = {};
                if (a.resourceId) r[a.resourceId].resourceId = a.resourceId;
                r[a.resourceId].moving = {
                    x: a.x,
                    y: a.y
                };
            }
        },


        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        removeResourceMoving: (state, action) => {
            let r = state.edit.resources, a = action.payload;
            if (Array.isArray(a)) {
                a.forEach(id => {
                    if (r.hasOwnProperty(id)) {
                        delete r[id].moving;
                        if (Object.keys(r[id]).length === 0) {
                            delete r[id];
                        }
                    }
                });
            } else {
                if (r.hasOwnProperty(a.resourceId)) {
                    delete r[a.resourceId].moving;
                    if (Object.keys(r[a.resourceId]).length === 0) {
                        delete r[a.resourceId];
                    }
                }
            }
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        /*setOpened: (state, action) => {
            var resourceId = action.payload.id;
            var r = state.edit.resources;
            // unselect selected resources
            let remove = (id) => {
                if (r.hasOwnProperty(id)) {
                    delete r[id].selected;
                    if (Object.keys(r[id]).length === 0) {
                        delete r[id];
                    }
                }
                state.edit.selected.splice(state.edit.selected.findIndex(i => i === id), 1);
            }
            var list = [...state.edit.selected];
            list.forEach(remove);

            // select this one
            if (!r.hasOwnProperty(resourceId)) r[resourceId] = {};
            r[resourceId].selected = true;
            state.edit.selected.push(resourceId);

            // set as opened
            state.edit.opened = resourceId;
        },*/

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         * @returns 
         */
        /*setClosed: (state, action) => {
            if (!state.edit.opened) return;
            var resourceId = state.edit.opened;
            delete state.edit.opened;
            const index = state.edit.selected.indexOf(resourceId);
            if (index > -1) {
                state.edit.selected.splice(index, 1);
                if (state.edit.resources.hasOwnProperty(resourceId)) {
                    delete state.edit.resources[resourceId].selected;
                    if (Object.keys(state.edit.resources[resourceId]).length === 0) {
                        delete state.edit.resources[resourceId];
                    }
                }
            }
        },*/


        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        setResourceSelecting: (state, action) => {
            let r = state.edit.resources, a = action.payload;

            let selecting = state.edit.selecting;
            selecting.forEach(id => {
                if (r.hasOwnProperty(id)) {
                    delete r[id].selecting;
                    if (Object.keys(r[id]).length === 0) {
                        delete r[id];
                    }
                }
            });
            if (Array.isArray(a)) {
                state.edit.selecting = a;
                a.forEach(id => {
                    if (!r.hasOwnProperty(id)) r[id] = {};
                    r[id].selecting = true;
                });
            } else {
                state.edit.selecting = [a.resourceId];
                if (!r.hasOwnProperty(a.resourceId)) r[a.resourceId] = {};
                r[a.resourceId].selecting = true;
            }
        },

        /**
         * 
         * @param {*} state 
         * @param {*} action 
         */
        removeResourceSelecting: (state, action) => {
            let r = state.edit.resources, a = action.payload;
            let remove = (id) => {
                const index = state.edit.selecting.indexOf(id);
                if (index > -1) {
                    state.edit.selecting.splice(index, 1);
                    if (r.hasOwnProperty(id)) {
                        delete r[id].selecting;
                        if (Object.keys(r[id]).length === 0) {
                            delete r[id];
                        }
                    }
                }
            }
            if (Array.isArray(a)) {
                a.forEach(id => remove(id));
            } else {
                remove(a.resourceId);
            }
        },

        updateViewportPosition: (state, action) => {
            let r = state.edit.viewport, a = action.payload;
            if (a.container) r.container = a.container;
            if (a.offset) r.offset = a.offset;
            if (a.delta) r.delta = a.delta;
            if (a.x) r.x = a.x;
            if (a.y) r.y = a.y;
            if (a.w) r.w = a.w;
            if (a.h) r.h = a.h;
        },

        setViewportMoving: (state, action) => {
            let a = action.payload;
            state.edit.viewport.moving = a;
        },

        removeViewportMoving: (state, action) => {
            delete state.edit.viewport.moving;
        },

        setSelected: (state, action) => {
            let r = state.edit.resources;
            let newList = action.payload || [];
            var oldList = state.edit.selected;

            var toRemove = oldList.filter(i => !newList.includes(i));
            var toAdd = newList.filter(i => !oldList.includes(i));

            let add = id => {
                if (!r.hasOwnProperty(id)) r[id] = {};
                let p = r[id];
                p.selected = true;
                state.edit.selected.push(id);
            }

            let remove = (id) => {
                if (r.hasOwnProperty(id)) {
                    delete r[id].selected;
                    if (Object.keys(r[id]).length === 0) {
                        delete r[id];
                    }
                }
                state.edit.selected.splice(state.edit.selected.findIndex(i => i === id), 1);
            }
            toRemove.forEach(remove);
            toAdd.forEach(add);
        },

        select: (state, action) => {
            let r = state.edit.resources;
            let a = action.payload;
            if (!a || a.length == null || a.length == 0) return;

            let add = id => {
                if (!r.hasOwnProperty(id)) r[id] = {};
                let p = r[id];
                p.selected = true;
                state.edit.selected.push(id);
            }
            if (Array.isArray(a)) {
                a.forEach(add);
            } else {
                add(a);
            }
        },

        // TODO pb sur la selection quand on unselect un ensemble comprennant un group

        unselect: (state, action) => {
            let r = state.edit.resources, a = action.payload;
            let remove = (id) => {
                if (r.hasOwnProperty(id)) {
                    delete r[id].selected;
                    if (Object.keys(r[id]).length === 0) {
                        delete r[id];
                    }
                }
                state.edit.selected.splice(state.edit.selected.findIndex(i => i === id), 1);
            }
            if (!a) {
                var list = [...state.edit.selected];
                list.forEach(remove);
            } else if (Array.isArray(a)) {
                a.forEach(remove);
            } else {
                remove(a);
            }
        },


    }
});

export const {
    clear,

    setGraph,
    addDependency,
    removeDependency,
    renameDependency,
    updateDependency,
    addVariable,
    removeVariable,
    renameVariable,
    updateVariable,
    setConfigurationValue,
    addResource,
    removeResource,
    setResourceName,
    removeResourceName,
    setResourceDescription,
    removeResourceDescription,

    setLayout,
    updateResourcePosition, // rename in setResourcePosition 
    removeResourcePosition,
    move,
    removeFromLayout,
    group,
    ungroup,

    addElement,
    removeElement,
    setConnecting,
    removeConnecting,
    setResourceMoving,
    removeResourceMoving,
    updateViewportPosition,
    setViewportMoving,
    removeViewportMoving,
    setInfiniteSelector,
    updateInfiniteSelector,
    removeInfiniteSelector,
    setResourceSelecting,
    removeResourceSelecting,
    setOpened,
    setClosed,
    select,
    unselect,
    setSelected,
    setCursor,

    // resource instances
    addResourceInstance,
    removeResourceInstance,
    setResourceInstanceName,
    removeResourceInstanceName,
    setResourceInstanceDescription,
    removeResourceInstanceDescription,

    // services
    addService

} = slice.actions;

export default slice.reducer;
