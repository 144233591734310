import { Box, ClickAwayListener, MenuItem, MenuList, Popper, Typography } from '@mui/material';
import React from 'react';
import AppContext from '../app/AppContext';
import ResourceIcon from '../system/ResourceIcon';
import withContext, { chain } from '../utils/WithContext';
import Icon from '@mdi/react';
import ModuleContext from './ModuleContext';
import { createId } from '../utils/idgenerator.js';
import ResourceContext from '../resource/ResourceContext';
import CacheContext from '../cache/CacheContext';

const compare = (a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
}

/**
 * Wrap any resource by this "technical" component:
 *  - create a filesystem connected to the backend
 *  - automatically generate the resourceName
 * 
 */
class ResourceSelector extends React.Component {

    constructor(props) {
        super(props);
        this.element = React.createRef();
        this.state = {
            selected: null,
        }
    }

    /**
     * 
     */
    componentDidMount() {
        this.props.getServices().then(services => {
            this.setState({ 
                services,
                rendered: true

            })
        });
        // wait for the component really displayed
        // to avoid the popup to "blink"
        //this.setState({
        //    rendered: true
        //})
    }

    /**
     * 
     * @param {*} resource 
     * @param {*} variant 
     * @returns 
     */
    handleSelect = (serviceId, versionId) => async () => {
        await this.addResource(serviceId, versionId);
    }

    /**
     * 
     * @param {*} serviceId 
     * @param {*} versionId 
     * @param {*} param2 
     */
    addResource = async (serviceId, versionId) => {
        // create default files
        const resourceId = 'a' + createId();
        const artifactPath = `${this.props.artifact.name}/${resourceId}`;

        debugger
        var newArtifact = this.props.createArtifact(`file:${artifactPath}`); // TODO attention au colision ?

        await newArtifact.createFile('/resource.json', `{
    "name": "my-resource",
    "author": "gdosser",
    "description": "No description set.",
    "service": "${serviceId}:${versionId}"
}
`);


        await newArtifact.createFile('/package.json', `{
    "name": "myFunction",
    "version": "1.0.1",
    "description": "myFunction service",
    "main": "index.js",
    "type": "module",
    "scripts": {
        "start": "node index.js"
    },
    "dependencies": {}
}
`);

        await newArtifact.createFile('/index.js', `import functions from '@service/functions';
functions.http((req, res) => {
    res.status(200).send("HELLO WORLD")
});
`);

        await newArtifact.createFile('/README.md', 'this is a sample file created for each resource.');
        // update module
        const module = this.props.artifact.getLocalFileAsJson(this.props.filepath);

        module[resourceId] = {
            resource: `file:${artifactPath}`,
            layout: { x: this.props.infinite.x, y: this.props.infinite.y, w: 40, h: 40 }
        }

        this.props.artifact.editFile(this.props.filepath, JSON.stringify(module, null, 2));
    }


    /**
     * 
     * @param {*} color 
     * @param {*} icon 
     * @returns 
     */
    handleChange = (color, icon) => () => {
        this.setState({
            selected: {
                color, icon
            }
        });
    }

    /**
     * 
     * @returns 
     */
    getMenuItems = () => {
        var items = [];

        if (!this.state.services) return items;

        Object.values(this.state.services).forEach(service => {
            /*var variants;
            if (!service.resource.variants) variants = [{
                name: service.resource.name,
                description: service.resource.description,
            }];*/
            /*else {
                variants = service.resource.variants.map(variant => ({
                    name: service.resource.name,
                    variantName: variant.name,
                    description: variant.description || service.resource.description,
                }));
            }*/
            // functions-javascript:1.0/
                items.push(<MenuItem
                    //onClick={this.handleSelect(service.resource.name, variant.variantId).bind(this)}
                    onClick={this.handleSelect(service.serviceId, service.versionId).bind(this)}
                    onFocus={this.handleChange(service.color, service.icon).bind(this)}
                    onMouseOver={this.handleChange(service.color, service.icon).bind(this)}
                    sx={{
                        alignItems: 'center',
                        pr: 2,
                        pt: '2px',
                        pb: '2px',
                    }}>
                    {true && <ResourceIcon
                        variant={'contained'}
                        width={28}
                        height={28}
                        color={service.color}
                        path={service.icon}
                        sx={{
                            flexGrow: 0,
                            flexShrink: 0,
                            mr: 1,
                            mt: '3px',
                        }} />}
                        <Icon path={service.icon} size={.5} />
                    <Box sx={{ ml: 1, display: 'flex', flexDirection: 'column' }}>
                        <Box>
                            <Typography display="inline" variant='body' sx={{ fontWeight: 500 }}>{service.serviceId}</Typography>
                        </Box>
                        {false && <Typography variant='body2' sx={{ color: "#00000060" }}>{service.description}</Typography>}
                    </Box>
                </MenuItem>);
        })
        return items;
    }

    render() {
        return <>
           <ResourceIcon
                ref={this.element}
                variant={'contained'}
                width={'48px'}
                height={'48px'}
                color={this.state.selected?.color}
                path={this.state.selected?.icon}
                opacity={1}
                radius={9}
                sx={{
                    left: this.props.x - 20,
                    top:  this.props.y - 20,
                    visibility: this.state.selected ? 'visible' : 'hidden',
                }}
            />
            <Popper anchorEl={this.element.current}
                placement={"right-start"}
                open={this.state.rendered}
                style={{
                    zIndex: 80,
                    pointerEvents: 'all',
                }}
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8],
                        },
                    },
                ]}
            >
                <ClickAwayListener onClickAway={() => this.props.onClickAway && this.props.onClickAway()}>
                        <Box sx={{
                            minWidth: 180,
                            width: 180,
                            maxHeight: 420,
                            overflowY: 'auto',
                            //backgroundColor: '#d4e7eb',
                            //border: '1px solid rgba(0,0,0,.02)',
                            backgroundColor: "#e6ecee", //"#cfebf0",// "#d4e7eb",
                            border: '1px solid rgb(67, 118, 67, .05)',
                            //boxShadow: "0px 2px 10px rgba(50, 50, 106, 0.2);",
                        }}>
                            <MenuList autoFocusItem dense>
                                {this.getMenuItems()}
                            </MenuList>
                        </Box>
                </ClickAwayListener>
            </Popper>
        </>
    }
}

export default chain(
    withContext(CacheContext)(({ getServices, getArtifact, createArtifact }) => ({ getServices, getArtifact, createArtifact })),
    withContext(ResourceContext)(({ artifact }) => ({ artifact })),
    withContext(ModuleContext)(({ filepath }) => ({ filepath }))
)(ResourceSelector)

