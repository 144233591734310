import { mdiButtonCursor, mdiButtonPointer, mdiCursorDefaultOutline, mdiCursorMove, mdiFormatText, mdiImageFilterCenterFocusWeak, mdiMapOutline, mdiMoveResize, mdiMoveResizeVariant, mdiRedo, mdiUndo, mdiViewGridPlusOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, ButtonBase, Divider } from '@mui/material';
import React from 'react';
import { connect } from "react-redux";
import { setCursor } from "../redux/module_slice";
import ResourceContext from '../resource/ResourceContext.js';
import Toolbar from '../system/Toolbar.js';
import withContext, { chain } from '../utils/WithContext';

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const ToolbarButton = ({ path, background = 'transparent' , color = '#3a313e', size = .6, ...otherProps }) => (
    <ButtonBase {...otherProps} sx={{
        background, color, minWidth: '42px', minHeight: '34px', height: '34px',
        '&:hover': { color: '#1c9c9c' },
        '&:disabled': { color: '#BBB' },
        cursor: 'default',
    }}>
        <Icon path={path} size={size}></Icon>
    </ButtonBase>
)

/**
 * 
 */
class ActionToolbars extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            undoCount: false,
            redoCount: false,
        }

        this.props.artifact.addFileChangeListener('/architecture.json', () => {
            this.setState({
                undoCount: this.props.artifact.undoList.length,// > 0,
                redoCount: this.props.artifact.redoList.length,// > 0,
            });
        });

        /*this.props.artifact.addFileChangeListener('/layout.json', () => {
            this.setState({
                undoCount: this.props.artifact.undoList.length,//> 0,
                redoCount: this.props.artifact.redoList.length,// > 0,
            });
        });*/

        this.setState({
            undoCount: this.props.artifact.undoList.length,// > 0,
            redoCount: this.props.artifact.redoList.length,//> 0,
        });
    }

    handleSelectCursor = cursor => event => {
        this.props.setCursor(cursor);
    }

    render() {
        // return the menu to display
        return <>
            <Box sx={{
                position: 'absolute',
                top: 30,
                left: '50%',
                transform: 'translate(-50%, 0%)',
                zIndex: 1000,
                backgroundColor: "#e6ecee",
                //boxShadow: "0px 2px 10px rgba(50, 50, 106, 0.02);",
                //p: '8px 6px',
                border: '1px solid rgba(0,0,0,.06)',
            }}>
                <Toolbar sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                    <ToolbarButton color={this.props.cursor == 'default' ? '#fff' : '#3a313e'} background={this.props.cursor == 'default' ? '#3a313e' : 'transparent'} path={mdiCursorDefaultOutline} onClick={this.handleSelectCursor('default').bind(this)} />
                    <ToolbarButton color={this.props.cursor == 'grab' ? '#fff' : '#3a313e'} background={this.props.cursor == 'grab' ? '#3a313e' : 'transparent'} path={mdiCursorMove} onClick={this.handleSelectCursor('grab').bind(this)} />
                    <ToolbarButton color={this.props.cursor == 'add' ? '#fff' : '#3a313e'} background={this.props.cursor == 'add' ? '#3a313e' : 'transparent'} path={mdiViewGridPlusOutline} onClick={this.handleSelectCursor('add').bind(this)} />
                    <ToolbarButton color={this.props.cursor == 'text' ? '#87fcc4' : '#3a313e'} background={this.props.cursor == 'text' ? '#3a313e' : 'transparent'} path={mdiFormatText} onClick={this.handleSelectCursor('text').bind(this)} />
                </Toolbar>

            </Box>
            <Box sx={{
                position: 'absolute',
                bottom: 20,
                right: 20,
                zIndex: 1000,
                backgroundColor: "#F8F9FA",
                border: '1px solid rgb(67, 118, 67, .05)',
                p: '2px 8px',
                color: '#000',
                //border: '1px solid rgba(0,0,0,.01)',

            }}>
                <Toolbar>
                    <ToolbarButton path={mdiImageFilterCenterFocusWeak}></ToolbarButton>
                    <ToolbarButton path={mdiMapOutline}></ToolbarButton>
                </Toolbar>
            </Box>
        </>
    }
}


const mapStateToProps = state => ({
    cursor: state.module.edit.cursor
});

const mapDispatchToProps = {
    setCursor
};


export default chain(
    withContext(ResourceContext)(({ artifact }) => ({ artifact })),
    connect(mapStateToProps, mapDispatchToProps),
)(ActionToolbars)