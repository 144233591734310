import { Box, Typography } from "@mui/material";


export default ({ name, mandatory, description, children, helper, ...otherProps }) => (
    <Box {...otherProps}>

        {name && helper && <Box sx={{ mb: '8px', display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
            <Typography sx={{ fontWeight: 500 }}
                variant="body2">
                {name} {mandatory && <span style={{ color: '#c32424' }}>*</span>}
            </Typography>
            <Box sx={{ flexGrow: 1 }}></Box>
            {helper}
        </Box>}

        {name && !helper && <Typography component='div' sx={{ mb: '8px', fontWeight: 400, color: 'rgba(0,0,0,.5)' }}
            variant="body2">
            {name} {mandatory && <span style={{ color: '#c32424' }}>*</span>}
        </Typography>}

        {description && <Typography component='div' sx={{ mb: '8px', flexGrow: 1, fontWeight: 400 }}
            variant="body2">
            {description}
        </Typography>}

        {children}

    </Box>
)

