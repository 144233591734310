import Icon from '@mdi/react';
import { InputAdornment, InputBase } from '@mui/material';

import * as React from 'react';


class SystemTextInput extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { sx, startAdornment, forwardedRef, ...otherProps } = this.props;
        
        // Adorment
        let startAdornmentComponent = null;
        if (startAdornment) {
            startAdornmentComponent = (
                <InputAdornment position="start">
                    <Icon style={{ verticalAlign: 'inherit', color: '#888' }} path={startAdornment} size={.7} />
                </InputAdornment>
            )
        }
        return <InputBase
            ref={forwardedRef}
            {...otherProps}
            sx={{
                ...sx,
                padding: '4px 8px 4px 8px',
                color: '#222',
                fontSize: 14,
                borderRadius: '5px',
                '&.Mui-focused': {
                    outline: '1px solid rgb(15,135,255)',
                }
            }}
            startAdornment={startAdornmentComponent}
        />
    }
}

/**
 * Add a fwd ref
 */
export default React.forwardRef((props, ref) => <SystemTextInput
    forwardedRef={ref}
    {...props}
/>);