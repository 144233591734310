import { Avatar, CircularProgress, Divider, FormControl, FormControlLabel, InputAdornment, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { createApp } from '../BackendFunctions';
import Guillaume from '../component/guillaume.jpeg';
import Button from '../system/Button';
import Input from '../system/Input';
import InputSection from '../system/InputSection';
import TitledBox from '../system/TitledBox';

const styles = (theme) => ({
    main: {
        background: '#FFF',
        marginTop: 30,
        padding: 40,
        paddingTop: 30,
        paddingBottom: 40,
        maxWidth: 900,
        margin: 'auto',
    }
});


class PageCreateApp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: null,
            //provider: 'GCP',
            //location: 'europe-west1'
        }
    }

    onChange = (field) => (event) => {
        this.setState({
            [field]: event.target.value
        })
    }

    create() {
        this.setState({
            creating: true
        });
        createApp({
            name: this.state.name
        }).then(app => {
            this.setState({
                creating: false
            });
            this.props.navigate(`/apps/${app.id}`);
        }).catch(err => {
            this.setState({
                creating: false
            });
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <TitledBox
                className={classes.main}
                primary
                name='Create a new app'
                description='An app has to deployed on an env.'>

                <Divider sx={{ mb: 2 }} />

                <InputSection sx={{ mb: 2 }} name='App owner'>
                    <Input
                        readOnly
                        sx={{ width: '300px', fontWeight: 500, backgroundColor: 'transparent' }}
                        value={"Guillaume"}
                        startAdornment={<InputAdornment position="start">
                            <Avatar sx={{ width: 28, height: 28 }} alt="Remy Sharp" src={Guillaume} />
                        </InputAdornment>}
                    >
                    </Input>
                </InputSection>

                <Divider sx={{ mt: 2, mb: 2 }} />

                <InputSection mandatory sx={{ mb: 2, width: '300px' }} name='App name'>
                    <Input sx={{ width: '100%' }} readOnly={this.state.creating} onChange={this.onChange('name').bind(this)} />
                </InputSection>

                <InputSection sx={{ width: '100%' }} name="Description" >
                    <Input sx={{ width: '100%' }} readOnly={this.state.creating} onChange={this.onChange('description').bind(this)} />
                </InputSection>

                <Divider sx={{ mt: 2, mb: 2 }} />

                {false && <InputSection
                    name="Project location"
                    mandatory
                    description="A project is backed by a cloud provider instance. A project is backed by a cloud provider instance. A project is backed by a cloud provider instance. A project is backed by a cloud provider instance.">
                    <FormControl sx={{ width: '320px' }} size="small" >
                        <Select
                            readOnly={this.state.creating}
                            variant="standard"
                            inputProps={{
                                sx: { padding: '6px 2px 6px 8px', background: '#EEE', color: '#333', fontSize: 14 }
                            }}

                            MenuProps={{
                                sx: { fontSize: 10 },
                                PaperProps: {
                                    sx: {
                                        maxHeight: '300px',
                                        '& .MuiMenuItem-root': {
                                            padding: '2px 12px 2px 12px',
                                        },
                                    },
                                },
                                MenuListProps: {
                                    dense: true,
                                }
                            }}
                            disableUnderline
                            value={'europe-west1'}
                            onChange={this.onChange('location').bind(this)}
                        >
                            <MenuItem disabled sx={{ fontSize: 12, fontWeight: 500 }}>NORTH AMERICA</MenuItem>
                            <MenuItem value={"europe-west1"}>europe-west1 (GCP)</MenuItem>
                            <MenuItem disabled sx={{ mt: 2, fontSize: 12, fontWeight: 500 }}>EUROPE</MenuItem>
                            <MenuItem value={"europe-west2"}>europe-west2 (GCP)</MenuItem>
                            <MenuItem value={"europe-west3"}>europe-west3 (GCP)</MenuItem>
                            <MenuItem value={"europe-west4"}>europe-west4 (GCP)</MenuItem>
                            <MenuItem disabled sx={{ mt: 2, fontSize: 12, fontWeight: 500 }}>ASIA</MenuItem>
                            <MenuItem value={"europe-westd2"}>europe-west2 (GCP)</MenuItem>
                            <MenuItem value={"europe-westd3"}>europe-west3 (GCP)</MenuItem>
                            <MenuItem value={"europe-westd4"}>europe-west4 (GCP)</MenuItem>
                            <MenuItem value={"eursope-westd2"}>europe-west2 (GCP)</MenuItem>
                            <MenuItem value={"eursope-westd3"}>europe-west3 (GCP)</MenuItem>
                            <MenuItem value={"eursope-westd4"}>europe-west4 (GCP)</MenuItem>
                        </Select>
                    </FormControl>
                </InputSection>}

                {false && <Divider sx={{ mt: 2, mb: 2 }} />}

                {false && <InputSection name="Template" >
                    <FormControl size="small" readOnly={this.state.creating}>
                        <RadioGroup defaultValue="none">
                            <FormControlLabel componentsProps={{ typography: { fontSize: 14 } }} value="static" control={<Radio size="small" />} label="Static website hosting project" />
                            <FormControlLabel componentsProps={{ typography: { fontSize: 14 } }} value="dynamic" control={<Radio size="small" />} label="Dynamic website hosting project" />
                            <FormControlLabel componentsProps={{ typography: { fontSize: 14 } }} value="api" control={<Radio size="small" />} label="API hosting project" />
                        </RadioGroup>
                    </FormControl>
                </InputSection>}

                {false && <Divider sx={{ mt: 2, mb: 2 }} />}

                {this.state.creating ? <CircularProgress sx={{ ml: 2 }} size={25} thickness={6} /> : <Button variant="contained" onClick={this.create.bind(this)}>Create Cloud App</Button>}

            </TitledBox>

        );
    }
}

export default withStyles(styles)(PageCreateApp);