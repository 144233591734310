import { mdiArrowRight, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import React from 'react';
import ResourceContext from '../resource/ResourceContext';
import AutoFocusInput from '../system/AutoFocusInput';
import SystemIconButton from '../system/SystemIconButton';
import SystemLink from '../system/SystemLink';
import SystemPopper from '../system/SystemPopper';
import SystemToolbar from '../system/SystemToolbar';
import withContext, { chain } from '../utils/WithContext';
import AppContext from './AppContext';
import DependencyTile from './DependencyTile';
import ServiceSelectMini from './ServiceSelectMini';
import CacheContext from '../cache/CacheContext';
import SystemButtonSecondary from '../system/SystemButtonSecondary';
import ResourceIconButton from '../system/ResourceIconButton';

/**
 * 
 */
class Dependencies extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openAddNewDependencyPanel: false,
            serviceId: null,
            stepIndex: 0,
            role: null,

            displaySelectService: false,
            displayCreateService: null,
        };

        this.button = React.createRef();
    }

    /**
     * 
     */
    handleDependenciesToolbarActions = action => {
        if (action == 'add') {
            this.setState({
                openAddNewDependencyPanel: true,
                stepIndex: 0,
            });
        }
    }


    handleAddNewDependencyToolbarActions = action => {
        if (action == 'close') {
            this.setState({
                openAddNewDependencyPanel: false,
                //stepIndex: 0,
            });
        }
    }

    handleDialogClose = () => {
        this.setState({
            openAddNewDependencyPanel: false,
            //stepIndex: 1,
        });
    }

    handleServiceSelect = (serviceId, versionId) => {
        this.setState({
            serviceId,
            stepIndex: 1,
        });
    }

    handleServiceRoleSelect = role => {
        this.setState({
            role,
            stepIndex: 2,
        })
    }

    handleDependencyCreated = () => {
        this.setState({
            openAddNewDependencyPanel: false,
        });
    }

    handleSelectDepenedency = name => {
        if (this.props.onSelect) {
            this.props.onSelect(name);
        }
    }

    handleDisplaySelectService = displaySelectService => {
        this.setState({ displaySelectService });
    }

    handleDisplayCreateInput = service => {
        this.setState({
            displayCreateService: service,
            displaySelectService: false
        });
    }


    handleCreateService = name => {
        const resourceJson = { ...this.props.resourceClass };
        if (!resourceJson.hasOwnProperty('dependencies')) {
            resourceJson.dependencies = {}
        }
        const json = { service: this.state.displayCreateService.serviceId };
        resourceJson.dependencies[name] = json;
        this.props.artifact.editFile('/resource.json', JSON.stringify(resourceJson, null, 2));

        this.setState({ displayCreateService: null });
        this.handleSelectDepenedency(name);
    }



    /**
     * 
     * @returns 
     */
    render() {
        return (<Box sx={{
            ...this.props.sx
        }}>

            {/* The dependencies list with the toolbar */}
            <SystemToolbar
                open
                title={`Dependencies (${Object.keys(this.props.resourceClass.dependencies || {}).length})`}
                actions={<SystemIconButton ref={this.button} path={mdiPlus} onClick={() => this.handleDisplaySelectService(true)} />}>
                {Object.entries(this.props.resourceClass.dependencies || {}).map(([name, dependency]) => <>
                    <DependencyTile
                        {...(this.props.selected == name) ? {
                            backgroundColor: '#e6f3ff',
                            border: '2px solid #0e87ff'
                        } : {
                            border: '2px solid transparent'
                        }}
                        dependencyName={name}
                        dependency={dependency}
                        onClick={() => this.handleSelectDepenedency(name)}
                    />
                </>
                )}

                {this.state.displayCreateService && <Box sx={{
                    width: '100%',
                    height: 24,
                    padding: '0px 4px 0px 8px',
                    border: '2px solid transparent',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '5px',
                    gap: '8px',
                }}>
                    <Box
                        sx={{
                            flexShrink: 0,
                            display: 'flex',
                            alignItems: 'center',
                            width: '15px',
                            justifyContent: 'center',
                        }}>
                        <Icon color={'#E91E63'} path={this.state.displayCreateService.icon} size={.6} />
                    </Box>

                    <NewItemInput defaultValue={''} onChange={value => this.handleCreateService(value)} />
                </Box>}

                {!this.state.displayCreateService && Object.entries(this.props.resourceClass.dependencies || {}).length === 0 && <Box sx={{ padding: '0 18px', lineHeight: 1.2}}>
                        <Typography sx={{ color: 'rgba(0,0,0,.4)' }} variant={'body'}>This resource does not have any dependency.</Typography>
                        <Box>
                            <SystemLink icon={mdiArrowRight} href={'#'}>Learn more about dependencies</SystemLink>
                            </Box>
                    </Box>}

            </SystemToolbar>

            {/* Select service popup */}
            <SystemPopper
                animation={'top'}
                anchorEl={this.button.current}
                placement={'bottom-start'}
                onClickAway={() => this.handleDisplaySelectService(false)}
                open={this.state.displaySelectService}
                sx={{
                    backgroundColor: '#dbf1f0', //'#e7eeef', //'#ebebef', //'#f4f5f7',
                    borderRadius: '6px',
                }}
            >
                <ServiceSelectMini onSelect={this.handleDisplayCreateInput.bind(this)} />
            </SystemPopper>


            {false && Object.entries(this.props.resourceClass.dependencies || {}).length == 0 && <Box sx={{
                padding: '8px 32px',
                color: '#00000080'
            }}>
                <Typography variant={'body'} component={'p'}>This resource does not have any dependency.</Typography>
                <SystemLink href={'#'} icon={mdiArrowRight}>Learn more about dependencies</SystemLink>
            </Box>}

        </Box>
        )
    }

}

export default chain(
    withContext(CacheContext)(({ getService }) => ({ getService })),
    withContext(ResourceContext)(({ artifact, resourceClass }) => ({ artifact, resourceClass })),
)(Dependencies);


const NewItemInput = styled((props) => (
    <AutoFocusInput {...props}></AutoFocusInput>
))(({ theme }) => ({
    //fontFamily: '"IBM Plex Sans", sans-serif',
    width: '100%',
    padding: '0',
    //background: '#FFF',
    color: '#333',
    fontSize: 13,
    height: 18,
    //outline: '1px solid blue',
    //borderRadius: '1px',
    '& .MuiInputBase-input': {
        height: 'initial',
        padding: '0px'
    }
}));