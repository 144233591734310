import { InputBase } from '@mui/material';
import React from 'react';


class AutoFocusInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: null
        }
        this.element = React.createRef();
    }

    componentDidMount() {
        this.element.current.addEventListener('keyup', event => {
            if (event.key === 'Enter' || event.keyCode === 13) {
                this.handleSubmit();
            }
        });
    }
    
    handleChange = event => {
        this.setState({
            value: event.target.value
        });
    }

    handleSubmit = () => {
        if (this.props.onChange) this.props.onChange(this.state.value)
    }

    // todo backdrop not working => should we migrate to new mui ?
    render() {
        var { onChange, ...props } = this.props;
        return <InputBase
            ref={this.element}
            autoFocus
            onChange={this.handleChange}
            onBlur={this.handleSubmit}
            {...props} />
    }

}

export default AutoFocusInput;
