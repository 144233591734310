import React from 'react';
import withContext, { chain } from '../utils/WithContext';
import InfiniteContext from './InfiniteContext';
import InfiniteLine from './InfiniteLine';
import { getRectFromProperties } from './Infinite';


// line intercept math by Paul Bourke http://paulbourke.net/geometry/pointlineplane/
// Determine the intersection point of two line segments
// Return FALSE if the lines don't intersect
const getIntersection = (x1, y1, x2, y2, x3, y3, x4, y4) => {

    // Check if none of the lines are of length 0
    if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
        return false;
    }

    var denominator = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1))

    // Lines are parallel
    if (denominator === 0) {
        return false;
    }

    let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
    let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

    // is the intersection along the segments
    if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
        return false;
    }

    // Return a object with the x and y coordinates of the intersection
    let x = x1 + ua * (x2 - x1);
    let y = y1 + ua * (y2 - y1);

    return { x, y };
}

/**
 * 
 */
class InfiniteConnection extends React.Component {

    constructor(props) {
        super(props);
    }

    /**
     * 
     * @param {*} id 
     * @returns 
     */
    getSquareSides = properties => {
        const p = getRectFromProperties(properties);
        return [
            [p.x, p.y, p.x + p.w, p.y],
            [p.x, p.y + p.h, p.x + p.w, p.y + p.h],
            [p.x, p.y, p.x, p.y + p.h],
            [p.x + p.w, p.y, p.x + p.w, p.y + p.h],
        ]
    }

    /**
     * 
     * @param {*} x 
     * @param {*} y 
     * @returns 
     */
    getElementsAt = (x, y) => {
        return Object.entries(this.props.properties).filter(([id, p]) => {
            const rect = getRectFromProperties(p);
            return x >= rect.x && x <= rect.x + rect.w && y >= rect.y && y <= rect.y + rect.h; 
        });
    }

    /**
     * 
     * @param {*} edge 
     * @returns 
     */
    getCoordAndSide = edge => {
        var edgeId = edge.id;
        let edgeCoord = null;
        let edgeSides = [];

        // compute the x, y in the infinite referentiel
        if (edge.referentiel) {
            const coordInRef = this.props.getClientCoordInInfiniteReferentiel({x: edge.x, y: edge.y});
            edge.x = coordInRef.x;
            edge.y = coordInRef.y;
        }

        if (!edgeId && edge.shouldConnect) {
            // get all resource within this coord
            const elements = this.getElementsAt(edge.x, edge.y).filter(([id, p]) => edge.shouldConnect(id));
            if (elements.length > 0) {
                edgeId = elements[0][0]; // [id, p]
            }
        }

        if (edgeId) {
            // connection starts at the provided element id
            const edgeProperties = this.props.properties[edgeId];
            edgeSides = this.getSquareSides(edgeProperties);
            edgeCoord = { 
                x: edgeProperties.coord.x,// - edgeProperties.size.w / 2 + edgeProperties.center.x, // compute the "center of gravity" of the element
                y: edgeProperties.coord.y,// - edgeProperties.size.h / 2 + edgeProperties.center.y, // compute the "center of gravity" of the element
            }
        } else {
            edgeCoord = {
                x: edge.x,
                y: edge.y,
            };
        }
        return [edgeCoord, edgeSides];
    }

    /**
     * 
     * @param {*} start 
     * @param {*} end 
     * @returns 
     */
    getLine = (start, end) => {
        const [startCoord, startSides] = this.getCoordAndSide(start);
        const [endCoord, endSides] = this.getCoordAndSide(end);

        var line = [
            startCoord.x,
            startCoord.y,
            endCoord.x,
            endCoord.y
        ];

        var startPosition = startCoord;
        startSides.forEach(([x1, y1, x2, y2]) => {
            var [x3, y3, x4, y4] = line;
            var intersection = getIntersection(x1, y1, x2, y2, x3, y3, x4, y4);
            if (intersection) startPosition = intersection;
        });

        var endPosition = endCoord;
        endSides.forEach(([x1, y1, x2, y2]) => {
            var [x3, y3, x4, y4] = line;
            var intersection = getIntersection(x1, y1, x2, y2, x3, y3, x4, y4);
            if (intersection) endPosition = intersection;
        });

        return {
            start: startPosition,
            end: endPosition,
        }
    }

    render() {
        //console.log('DISPLAY CONNNECTILN', this.props.start, this.props.end, !this.props.properties.hasOwnProperty(this.props.start) || !this.props.properties.hasOwnProperty(this.props.end))
        if(!(this.props.start.id && this.props.properties.hasOwnProperty(this.props.start.id)) 
        || (this.props.end.id && !this.props.properties.hasOwnProperty(this.props.end.id))) {
            return <></>;
        }
        const  { start , end } = this.getLine(this.props.start, this.props.end);
        return <InfiniteLine
            text={this.props.text}
            start={start}
            end={end}
            thickness={1}
            selectPadding={3}
            color={'#345'}
        />
    }
}

export default chain(
    withContext(InfiniteContext)(({ properties, getClientCoordInInfiniteReferentiel }) => ({ properties, getClientCoordInInfiniteReferentiel })),
)(InfiniteConnection)