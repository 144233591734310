import { mdiChevronUp } from '@mdi/js';
import Icon from '@mdi/react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import 'firebase/firestore';
import React from 'react';
import AWS from '../system/AWS.png';
import AZR from '../system/AZR.png';
import GCP from '../system/GCP.png';
import withContext, { chain } from '../utils/WithContext';
import ImplementationArtifactComponent from './ImplementationArtifactComponent';
import ImplementationDeletedArtifactComponent from './ImplementationDeletedArtifactComponent';
import ImplementationNewArtifactComponent from './ImplementationNewArtifactComponent';
import ServiceContext from './ServiceContext';


const providers = [
    {
        id: 'gcp',
        name: 'Google Cloud',
        img: GCP,
    },
    {
        id: 'azr',
        name: 'Microsoft Azure',
        img: AZR,
    },
    {
        id: 'aws',
        name: 'Amazon Web Services',
        img: AWS,
    },
    {
        id: 'hby',
        name: 'Hobby',
        img: GCP,
    },
]

/**
 * 
 */
class ImplementationPanelComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: 'gcp',
        }
    }

    handleSelect = selected => {
        this.setState({ selected });
    }

    render() {
        return <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            width: '360px',
        }}>

            <Typography
                variant={'body'}
                sx={{
                    fontWeight: 500,
                }}>
                {'Implementations'}
            </Typography>

            <Typography
                variant={'body'}
                sx={{
                    fontWeight: 400,
                    color: '#666',
                    marginBottom: '8px'
                }}
                component={'p'}>
                {'All artifact must be implemented and provide the same behaviors for each Cloud Provider.'}
            </Typography>

            {providers.map(({ id, name, img }) =>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        padding: '12px 8px',
                        borderRadius: '8px',
                        cursor: 'default',
                        backgroundColor: this.state.selected === id ? 'rgba(255,255,255,.35)' : 'rgba(255,255,255,.2)',
                        opacity: this.state.selected === id ? '1' : '.7',
                        transition: 'background-color .2s',
                        '&:hover': {
                            backgroundColor: this.state.selected === id ? 'rgba(255,255,255,.35)' : 'rgba(255,255,255,.35)',
                        }
                    }}
                    onClick={() => this.handleSelect(id)}>

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0 8px',
                        gap: '14px',
                    }}>
                        <Box
                            component="img"
                            sx={{
                                flexGrow: 0,
                                flexShrink: 0,
                                display: 'block',
                                height: 20,
                                width: 20,
                            }}
                            src={img}
                        />
                        
                        <Typography
                            variant={'body'}
                            sx={{
                                fontWeight: this.state.selected === id ? 500 : 400,
                                flexGrow: 1,
                            }}>
                            {name}
                        </Typography>

                        <Icon style={{
                            transform: this.state.selected === id ? 'none' : 'rotate(180deg)',
                            transition: `transform .2s`,
                        }} path={mdiChevronUp} size={.7} />

                    </Box>

                    <Box sx={{
                        display: this.state.selected === id ? 'flex' : 'none',
                        flexDirection: 'column',
                        padding: '0 0 0 32px',
                    }}>
                        {[['Service', '_service'],
                        ['NodeJS SDK', '_layers_nodejs'],
                        ['Python SDK', '_layers_python'],
                        ['Java SDK', '_layers_java']]
                            .map(([artifactPrettyName, suffix]) => {
                                const artifactName = `${id}${suffix}`;
                                const sources = this.props.service.sources || {};
                                if (sources[artifactName]) {
                                    return <ImplementationArtifactComponent
                                        artifactPrettyName={artifactPrettyName}
                                        artifactName={artifactName} />
                                } else if (this.props.isArtifactDeleted(artifactName)) {
                                    return <ImplementationDeletedArtifactComponent
                                        artifactPrettyName={artifactPrettyName}
                                        artifactName={artifactName} />
                                } else {
                                    return <ImplementationNewArtifactComponent
                                        artifactPrettyName={artifactPrettyName}
                                        artifactName={artifactName} />
                                }
                            })}
                    </Box>
                </Box>)}

        </Box>
    }
}

export default chain(
    withContext(ServiceContext)(({ service, isArtifactDeleted }) => ({ service, isArtifactDeleted })),
)(ImplementationPanelComponent);
