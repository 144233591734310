import { mdiBucketOutline, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Grow, Typography } from '@mui/material';
import React from 'react';
import AppContext from '../app/AppContext.js';
import DependencyTile from '../app/DependencyTile.js';
import SystemDivider from '../system/SystemDivider.js';
import SystemIconButton from '../system/SystemIconButton.js';
import SystemPopupToolbar from '../system/SystemPopupToolbar.js';
import SystemToolbar from '../system/SystemToolbar.js';
import withContext, { chain } from '../utils/WithContext';
import DragValue from './DragValue.js';
import ModuleContext from './ModuleContext.js';
import InfiniteGesture from '../infinite/InfiniteGesture.js';
import CacheContext from '../cache/CacheContext.js';

/**
 * 
 */
class ResourcePanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dx: 0,
            dy: 0,
        }
        this.element = React.createRef();
        this.gesture = null;
    }

    componentDidMount() {
        // create gesture listeners
        this.gesture = new InfiniteGesture(this.element.current);
        this.gesture.listenMouseEvents();
        this.setDragGestureListener();

    }

    setDragGestureListener() {
        let dx, dy;
        this.gesture.condition(({ event }) => event.which == 1)
        .onStart(({ event }) => {
            dx = this.state.dx;
            dy = this.state.dy;
        })
        .onMove(({ deltaX, deltaY }) => {
            this.setState({
                dx: dx + deltaX,
                dy: dy + deltaY,
            });
        })
        .onStop(({ deltaX, deltaY }) => {
            this.setState({
                dx: dx + deltaX,
                dy: dy + deltaY,
            });
        });
    }

    handleClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {

        const resourceClass = this.props.resources[this.props.resourceId];

        return <Box ref={this.element}
        sx={{
            display: this.props.resourceId ? 'block' : 'none',
            position: 'absolute',
            top: 20 + this.state.dy,
            right: 20 - this.state.dx,
            width: 280,
        }}>
            <Grow timeout={120} in={true}>
                <Box
                    sx={{
                        //width: '100%',
                        //height: '100%',
                        minHeight: 400,
                        backgroundColor: '#f0f1f3', //'#f4f5f7',
                        borderRadius: '6px',
                        transition: 'background-color .2s',
                        paddingBottom: '16px',
                    }}>

                    <SystemPopupToolbar
                        icon={<Icon
                            path={mdiBucketOutline}
                            size={.7}
                            style={{
                                color: '#E91E63',
                            }} />}
                        title={this.props.resourceId}
                        actions={
                            <SystemIconButton
                                path={mdiClose}
                                onClick={this.handleClose.bind(this)} />
                        } />

                    <Box sx={{ padding: '0 16px 0 16px' }}>
                        <SystemToolbar open title={'Triggers'}>
                            <Typography variant={'body'}>{'No triggers'}</Typography>
                        </SystemToolbar>
                    </Box>

                    <SystemDivider />

                    <Box sx={{ padding: '0 16px 0 16px' }}>
                        <SystemToolbar open title={'Dependencies'}>
                            {resourceClass && Object.entries(resourceClass.dependencies || {}).map(([name, dependency]) => (
                                <DependencyTile
                                    dependencyName={name}
                                    dependency={dependency}
                                    value={
                                        <DragValue resourceId={this.props.resourceId} dependencyName={name} dependency={dependency} />
                                    } />))}
                        </SystemToolbar>
                    </Box>

                    <SystemDivider />


                    <Box sx={{ padding: '0 16px 0 16px' }}>
                        <SystemToolbar open title={'Variables'}>
                        <Typography variant={'body'}>{'No variables'}</Typography>
                        </SystemToolbar>
                    </Box>
                </Box>
            </Grow>
        </Box>
    }
}




export default chain(
    withContext(CacheContext)(({ getArtifact }) => ({ getArtifact })),
    withContext(ModuleContext)(({ module, resources }) => ({ module, resources })),
)(ResourcePanel)




/*
<InfiniteStaticElement
                coord={{
                    x: this.props.layout.resources[this.props.resourceId].x + 35,
                    y: this.props.layout.resources[this.props.resourceId].y - 35,
                }}
                size={{
                    //w: 50,
                }}
            >

                <Box sx={{
                    p: 1,
                    pl: '4px',
                    pr: '4px',
                    width: '100%',
                    height: '100%',
                    border: '1px solid rgba(0,0,0,.06)',
                    backgroundColor: '#e6ecee',
                    borderRadius: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>


<IconButton sx={{ mb: 1, p: '4px', background: '#e6ecee', color: 'black', border: '1px solid rgba(0,0,0,.01)' }}>
                    <Icon path={mdiRelationManyToMany} size={.6} />
                </IconButton>
                
                <IconButton sx={{ mb: 1, p: '4px', background: '#e6ecee', color: 'black', border: '1px solid rgba(0,0,0,.01)' }}>
                    <Icon path={mdiTuneVariant} size={.6} />
                </IconButton>

                <IconButton sx={{ p: '4px', background: '#e6ecee', color: 'black', border: '1px solid rgba(0,0,0,.01)' }}>
                    <Icon path={mdiCogOutline} size={.6} />
                </IconButton>
                </Box>

            </InfiniteStaticElement>

*/


/**
 * 
 * <Box sx={{
                        position: 'relative',
                    }}>

                        <Box sx={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'rows',
                            alignItems: 'center',
                        }}>


                            <ContentEditable
                                //onChange={this.handleNameChange.bind(this)}
                                blurOnEnter
                                sx={{
                                    ml: 1,
                                    pl: 1,
                                    pr: 1,
                                    pt: '4px',
                                    pb: '4px',
                                    fontWeight: 500,
                                    wordWrap: 'break-word',
                                    flex: '1 1 0',
                                    minWidth: 0,
                                    //letterSpacing: '-.6px',
                                    color: '#000'
                                }}
                                variant="h6"
                                component={'p'}>
                                {this.props.resourceId || "Unnamed"}
                            </ContentEditable>



                            <Box sx={{ flexGrow: 1 }} />

                            <IconButton sx={{ p: '4px', background: 'rgba(0,0,0,.06)', color: 'black', border: '1px solid rgba(0,0,0,.01)' }}>
                                <Icon path={mdiOpenInApp} size={.6} />
                            </IconButton>
                            <IconButton sx={{ p: '4px', background: 'rgba(0,0,0,.06)', color: 'black', border: '1px solid rgba(0,0,0,.01)' }}>
                                <Icon path={mdiDotsVertical} size={.6} />
                            </IconButton>
                        </Box>
                    </Box>

                    <Divider light sx={{ mt: 3, borderBottom: '1px solid rgba(0, 0, 0, 0.04)' }} />

                    <SystemToolbar
                        sx={{
                            marginBottom: '1px'
                        }}
                        title={'Dependencies'}
                    />

                    {resourceClass && Object.entries(resourceClass.dependencies || {}).map(([name, dependency]) => (
                        <DependencyTile
                            name={name}
                            serviceId={dependency.service}
                            versionId={'LATEST'}
                            roles={dependency.roles}
                            triggers={dependency.triggers}
                            description={dependency.description}
                            adornment={
                                <DragValue resourceId={this.state.resourceId} dependency={name} />
                            } />))}

                    
                    {false && <><Divider light sx={{ mt: 3, borderBottom: '1px solid rgba(0, 0, 0, 0.04)' }} />

                    <Toolbar sx={{
                        pt: '6px',
                        pb: '6px',
                        pl: 2,
                        pr: 2,
                        alignItems: 'center',
                        color: '#353535',
                        background: 'rgba(0,0,0,.01)'
                    }}>
                        <Icon path={mdiTuneVariant} size={.6} style={{ marginRight: 10 }} />
                        <Typography sx={{ fontWeight: 600 }} variant="body">Variables</Typography>
                        <Box sx={{ flexGrow: 1 }}></Box>

                    </Toolbar>

                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '8px', pl: 2, pr: 2, mt: 1 }}>
                        <Box sx={{ flexShrink: 0 }}>
                            <Icon path={mdiQualityHigh} size={.6} />
                        </Box>
                        <Box sx={{ flexGrow: 1, pt: '2px' }}>
                            <Typography sx={{ fontWeight: 500, color: '#000000AA' }} variant={'body'} component={'p'}>API</Typography>
                            <Typography sx={{
                                fontWeight: 400, color: '#FFF', backgroundColor: '#80F', fontWeight: 800,
                                fontSize: '8px',
                                width: 'fit-content',
                                padding: '2px 5px',
                            }} variant={'body2'} component={'p'}>EXECUTOR</Typography>
                            <Typography sx={{ fontWeight: 400, color: '#00000060' }} variant={'body2'} component={'p'}>Main api</Typography>
                        </Box>
                        <IconButton sx={{ p: '4px', background: 'rgba(0,255,0,.1)', color: 'black', border: '1px solid rgba(0,0,0,.01)' }}>
                            <Icon path={mdiTarget} size={.6} />
                        </IconButton>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '8px', pl: 2, pr: 2, mt: 1 }}>
                        <Box sx={{ flexShrink: 0 }}>
                            <Icon path={mdiDatabaseOutline} size={.6} />
                        </Box>
                        <Box sx={{ flexGrow: 1, pt: '2px' }}>
                            <Typography sx={{ fontWeight: 500, color: '#000000AA' }} variant={'body'} component={'p'}>MainDB</Typography>
                            <Typography sx={{
                                fontWeight: 400, color: '#FFF', backgroundColor: '#F80', fontWeight: 800,
                                fontSize: '8px',
                                width: 'fit-content',
                                padding: '2px 5px',
                            }} variant={'body2'} component={'p'}>EDITOR</Typography>
                            <Typography sx={{ fontWeight: 400, color: '#00000060' }} variant={'body2'} component={'p'}>the database to use</Typography>
                        </Box>

                        <IconButton sx={{ p: '4px', background: 'rgba(0,0,0,.06)', color: 'black', border: '1px solid rgba(0,0,0,.01)' }}>
                            <Icon path={mdiTarget} size={.6} />
                        </IconButton>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '8px', pl: 2, pr: 2, mt: 1 }}>
                        <Box sx={{ flexShrink: 0 }}>
                            <Icon path={mdiFunction} size={.6} />
                        </Box>
                        <Box sx={{ flexGrow: 1, pt: '2px' }}>
                            <Typography sx={{ fontWeight: 500, color: '#000000AA' }} variant={'body'} component={'p'}>GetUserStories</Typography>
                            <Typography sx={{ fontWeight: 400, color: '#00000060' }} variant={'body2'} component={'p'}>il s'agit de la description de la dependency</Typography>
                        </Box>
                        <IconButton sx={{ p: '4px', background: 'rgba(0,0,0,.06)', color: 'black', border: '1px solid rgba(0,0,0,.01)' }}>
                            <Icon path={mdiTarget} size={.6} />
                        </IconButton>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '8px', pl: 2, pr: 2, mt: 1 }}>
                        <Box sx={{ flexShrink: 0 }}>
                            <Icon path={mdiApi} size={.6} />
                        </Box>
                        <Box sx={{ flexGrow: 1, pt: '2px' }}>
                            <Typography sx={{ fontWeight: 500, color: '#000000AA' }} variant={'body'} component={'p'}>API</Typography>
                            <Typography sx={{ fontWeight: 400, color: '#00000060' }} variant={'body2'} component={'p'}>Main api</Typography>
                        </Box>
                        <IconButton sx={{ p: '4px', background: 'rgba(0,0,0,.06)', color: 'black', border: '1px solid rgba(0,0,0,.01)' }}>
                            <Icon path={mdiTarget} size={.6} />
                        </IconButton>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '8px', pl: 2, pr: 2, mt: 1 }}>
                        <Box sx={{ flexShrink: 0 }}>
                            <Icon path={mdiDatabaseOutline} size={.6} />
                        </Box>
                        <Box sx={{ flexGrow: 1, pt: '2px' }}>
                            <Typography sx={{ fontWeight: 500, color: '#000000AA' }} variant={'body'} component={'p'}>MainDB</Typography>
                            <Typography sx={{ fontWeight: 400, color: '#00000060' }} variant={'body2'} component={'p'}>the database to use</Typography>
                        </Box>
                        <IconButton sx={{ p: '4px', background: 'rgba(0,0,0,.06)', color: 'black', border: '1px solid rgba(0,0,0,.01)' }}>
                            <Icon path={mdiTarget} size={.6} />
                        </IconButton>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '8px', pl: 2, pr: 2, mt: 1 }}>
                        <Box sx={{ flexShrink: 0 }}>
                            <Icon path={mdiFunction} size={.6} />
                        </Box>
                        <Box sx={{ flexGrow: 1, pt: '2px' }}>
                            <Typography sx={{ fontWeight: 500, color: '#000000AA' }} variant={'body'} component={'p'}>GetUserStories</Typography>
                            <Typography sx={{
                                fontWeight: 400, color: '#FFF', backgroundColor: '#880', fontWeight: 800,
                                fontSize: '8px',
                                width: 'fit-content',
                                padding: '2px 5px',
                            }} variant={'body2'} component={'p'}>PUBLISHER</Typography>
                            <Typography sx={{ fontWeight: 400, color: '#00000060' }} variant={'body2'} component={'p'}>Gradle, npm, Yarn, Twine, pip et NuGet. CodeArtifact peut récupérer automatiquement les packages logiciels à la demande à partir des dépôts de packages publics afin que vous puissiez accéder aux dernières versions des dépendances des applications.</Typography>
                        </Box>
                        <IconButton sx={{ p: '4px', background: 'rgba(0,0,0,.06)', color: 'black', border: '1px solid rgba(0,0,0,.01)' }}>
                            <Icon path={mdiTarget} size={.6} />
                        </IconButton>
                    </Box>

                    <Divider light sx={{ mt: 3, borderBottom: '1px solid rgba(0, 0, 0, 0.04)' }} />

                    <Toolbar sx={{
                        pt: '6px',
                        pb: '6px',
                        pl: 2,
                        pr: 2,
                        alignItems: 'center',
                        color: '#353535',
                        background: 'rgba(0,0,0,.01)'
                    }}>
                        <Icon path={mdiCogOutline} size={.6} style={{ marginRight: 10 }} />
                        <Typography sx={{ fontWeight: 600 }} variant="body">Configuration</Typography>
                        <Box sx={{ flexGrow: 1 }}></Box>

                    </Toolbar>

                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '8px', pl: 2, pr: 2, mt: 1 }}>
                        <Box sx={{ flexShrink: 0 }}>
                            <Icon path={mdiQualityHigh} size={.6} />
                        </Box>
                        <Box sx={{ flexGrow: 1, pt: '2px' }}>
                            <Typography sx={{ fontWeight: 500, color: '#000000AA' }} variant={'body'} component={'p'}>API</Typography>
                            <Typography sx={{
                                fontWeight: 400, color: '#FFF', backgroundColor: '#80F', fontWeight: 800,
                                fontSize: '8px',
                                width: 'fit-content',
                                padding: '2px 5px',
                            }} variant={'body2'} component={'p'}>EXECUTOR</Typography>
                            <Typography sx={{ fontWeight: 400, color: '#00000060' }} variant={'body2'} component={'p'}>Main api</Typography>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '8px', pl: 2, pr: 2, mt: 1 }}>
                        <Box sx={{ flexShrink: 0 }}>
                            <Icon path={mdiDatabaseOutline} size={.6} />
                        </Box>
                        <Box sx={{ flexGrow: 1, pt: '2px' }}>
                            <Typography sx={{ fontWeight: 500, color: '#000000AA' }} variant={'body'} component={'p'}>MainDB</Typography>
                            <Typography sx={{
                                fontWeight: 400, color: '#FFF', backgroundColor: '#F80', fontWeight: 800,
                                fontSize: '8px',
                                width: 'fit-content',
                                padding: '2px 5px',
                            }} variant={'body2'} component={'p'}>EDITOR</Typography>
                            <Typography sx={{ fontWeight: 400, color: '#00000060' }} variant={'body2'} component={'p'}>the database to use</Typography>
                        </Box>

                        <IconButton sx={{ p: '4px', background: 'rgba(0,0,0,.06)', color: 'black', border: '1px solid rgba(0,0,0,.01)' }}>
                            <Icon path={mdiTarget} size={.6} />
                        </IconButton>
                    </Box>


                    {false && <Box sx={{
                        position: 'absolute',
                        top: '20px',
                        left: '-26px',
                        width: '47px',
                        height: '1px',
                        backgroundColor: 'black',
                        //borderTop: '1px solid white',
                        //borderBottom: '1px solid white',
                        zIndex: 10000
                    }} />}


                    {false && <Box sx={{
                        position: 'absolute',
                        top: '17px',
                        left: '18px',
                        width: '7px',
                        height: '7px',
                        borderRadius: '5px',
                        backgroundColor: 'blue',
                        border: '1px solid white',
                        zIndex: 10000
                    }} />}
                    </>}
                    
 */