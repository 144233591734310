import { mdiAlphaJBoxOutline, mdiCodeBraces, mdiCubeOutline, mdiInformationOutline, mdiLanguageJavascript, mdiLanguagePython, mdiTextLong } from "@mdi/js";

export const getFileIcon = pathOrName => {
    const name = pathOrName.substring(pathOrName.indexOf('/') + 1);
    if (name === 'resource.json') return mdiCubeOutline;
    else if (name.endsWith('.js')) return mdiLanguageJavascript;
    else if (name.endsWith('.json')) return mdiCodeBraces;
    else if (name.endsWith('.py')) return mdiLanguagePython;
    else if (name.endsWith('.java')) return mdiAlphaJBoxOutline;
    else if (name.endsWith('.md')) return mdiInformationOutline;
    else return mdiTextLong;
}

/**
 * 
 */
export default class EditorArtifactFile {

    constructor(artifact, filepath, view) {
        this.artifact = artifact;
        this.filepath = filepath;
        this.view = view ?? 'code';
    }

    // mandatory for tab
    getView() {
        return this.view;
    }

    // mandatory for tab
    getIcon() {
        return getFileIcon(this.filepath);
    }

    // mandatory for tab
    getTitle() {
        return this.filepath;
    }

    // needed for code & service views
    getArtifact() {
        return this.artifact;
    }

    // needed for code & service views
    getFilepath() {
        return this.filepath;
    }

}