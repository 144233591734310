import { Box, Typography } from '@mui/material';
import React from 'react';
import withContext, { chain } from '../utils/WithContext';
import InfiniteContext from './InfiniteContext';

/**
 * 
 */
class InfiniteLine extends React.Component {

    constructor(props) {
        super(props);
        this.element = React.createRef();
    }

    /**
     * 
     * @param {*} x1 
     * @param {*} y1 
     * @param {*} x2 
     * @param {*} y2 
     * @param {*} thickness 
     * @returns 
     */
    getStyleToConnect(x1, y1, x2, y2, thickness) {
        // distance
        let length = Math.sqrt(((x1 - x2) * (x1 - x2)) + ((y1 - y2) * (y1 - y2)))

        // center
        let cx = ((x2 + x1) / 2) - (length / 2),
            cy = ((y2 + y1) / 2) - (thickness / 2);

        // angle
        let angle = Math.atan2((y2 - y1),
            (x2 - x1)) * (180 / Math.PI);

        return {
            height: thickness + 'px',
            left: cx + "px",
            top: cy + "px",
            width: length + 'px',
            transform: 'rotate(' + angle + 'deg)',
        }
    }

    /**
     * 
     */
    componentDidMount() {
        this.updateLine();
    }

    /**
     * 
     */
    componentDidUpdate() {
        this.updateLine();
    }

    /**
     * Update style to reposition the line.
     */
    updateLine() {
        // compute the style in order to make the line connecting both divs
        let style = this.getStyleToConnect(
            this.props.start.x + this.props.translate.x - this.props.offset.x,
            this.props.start.y + this.props.translate.y - this.props.offset.y,
            this.props.end.x + this.props.translate.x - this.props.offset.x,
            this.props.end.y + this.props.translate.y - this.props.offset.y,
            this.props.thickness + 2 * this.props.selectPadding
        );
        // apply style WITHOUT triggering render.
        Object.keys(style).forEach((key) => {
            this.element.current.style[key] = style[key];
        });
    }

    render() {
        return <Box
            ref={this.element}
            sx={{
                position: 'absolute',
                padding: this.props.selectPadding + 'px 0px',
                display: 'flex',
                alignItems: 'center',
            }}>

            {/* Line */}
            <Box sx={{
                    width: '100%',
                    height: '100%',
                    borderBottomLeftRadius: '2px',
                    borderTopLeftRadius: '2px',
                    backgroundColor: this.props.color,
                    //boxShadow: '0 0 4px rgba(0,0,0,0.3)'
                }} />

            {/* Arrow */}
            <Box sx={{
                    borderBottom: '3px solid transparent',
                    borderLeft: '9px solid #000',
                    borderLeftColor: this.props.color,
                    borderTop: '3px solid transparent',
                }} />

            {/* Text */}

            {this.props.text && <Box
                sx={{
                    position: 'absolute',
                    left: '50%',
                    top: -16,
                    transform: 'translateX(-50%)',
                    //borderRadius: '9px',
                    backgroundColor: '#FDFDFD',
                    color: '#5C5C5C', //this.props.color,
                    //border: '1px solid ' + this.props.color,
                    padding: '1px 6px',
                    //boxShadow: '0 0 4px rgba(0,0,0,0.3)',
                }}>
                    <Typography variant={'body2'} sx={{
                                            //fontFamily: 'Menlo, Monaco, "Courier New", monospace',
                                            fontSize: 12,
                                            fontWeight: 500,
                                            //letterSpacing: '-.2px',
                    }}>{this.props.text}</Typography>
                    </Box>}

        </Box>
    }
}

export default chain(
    withContext(InfiniteContext)(({ translate, offset }) => ({ translate, offset })),
)(InfiniteLine)