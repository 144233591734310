import { Box } from '@mui/material';
import React from 'react';
import withContext, { chain } from '../utils/WithContext';
import { getDiffFields } from '../utils/utils.js';
import { getRectFromProperties } from './Infinite';
import InfiniteContext from './InfiniteContext';
import InfiniteElementContext from './InfiniteElementContext.js';
import InfiniteGesture from './InfiniteGesture.js';

/**
 * 
 */
class InfiniteElement extends React.Component {

    constructor(props) {
        super(props);
        this.element = this.props.forwardedRef || React.createRef();
        this.state = {
            coord: this.props.coord,
            size: this.props.size,
            align: this.props.align,
        }
        this.lastClickTimestamp = 0;
    }

    /**
     * 
     */
    setClickGestureListener() {
        this.gesture.condition(({ event }) => this.props.mode.type == 'default' && !this.props.selected && event.which == 1 && !event.metaKey)
        .onStop(({ duration, distance }) => {
                if (duration < 200 && distance < 50) {
                    this.props.setSelectedElements([this.props.id]);
                }
            });
    }

    /**
     * 
     */
    setDoubleClickListener() {
        this.gesture.condition(({ event }) => /*!this.props.selected &&*/ event.which == 1 && !event.metaKey)
            .onStop(({ duration, distance }) => {
                if (duration < 200 && distance < 50) {
                    var timestamp = new Date().getTime();
                    const deltaT = timestamp - this.lastClickTimestamp;
                    this.lastClickTimestamp = timestamp;
                    if (this.props.onDoubleClick && deltaT > 0 && deltaT < 200) {
                        this.props.onDoubleClick(this.props.id)
                    }
                }
            });
    }

    /**
     * 
     * @param {*} coord 
     * @param {*} deltaX 
     * @param {*} deltaY 
     * @returns 
     */
    getCoord = (coord, { deltaX, deltaY }) => {
        if (this.props.grid) {
            deltaX = Math.round(deltaX / this.props.grid.x) * this.props.grid.x;
            deltaY = Math.round(deltaY / this.props.grid.y) * this.props.grid.y;
        }
        return {
            x: coord.x + deltaX,
            y: coord.y + deltaY,
        }
    }

    /**
     * 
     */
    setElementDragGestureListener() {
        let startCoord;
        let version;
        let startOffsetTop;
        let startOffsetLeft;
        const parent = this.element.current.parentNode;
        this.gesture.condition(({ event }) => this.props.mode.type == 'default' && !this.props.controlled && event && event.which == 1 && !event.metaKey)
            .onStart(() => {
                startCoord = { ...this.props.properties.coord };
                version = this.props.version;
                startOffsetTop = parent.offsetTop;
                startOffsetLeft = parent.offsetLeft;
            })
            .onMove(({ deltaX, deltaY }) => {
                const coord = this.getCoord(startCoord, { deltaX: deltaX - parent.offsetLeft + startOffsetLeft, deltaY: deltaY - parent.offsetTop + startOffsetTop });
                /*if (this.state.coord.x != coord.x || this.state.coord.y != coord.y) {
                    this.setState({ coord });
                }*/
                this.props.updateContextProperties({ [this.props.id]: { coord } });
            })
            .onStop(({ deltaX, deltaY }) => {
                const coord = this.getCoord(startCoord, { deltaX: deltaX - parent.offsetLeft + startOffsetLeft, deltaY: deltaY - parent.offsetTop + startOffsetTop });
                /*if (this.state.coord.x != coord.x || this.state.coord.y != coord.y) {
                    this.setState({ coord });
                }*/
                this.props.updateContextProperties({ [this.props.id]: { coord } });
                // the drag is finished call the position change listener if any
                if (this.props.onElementPositionChanged && (deltaX != 0 || deltaY != 0)) this.props.onElementPositionChanged(this.props.id, coord);
            })
            .cancelWhen(() => this.props.version != version)
            .onCancel(() => {
                console.log('CANCEL')
                if (this.state.dragging) {

                    this.props.updateContextProperties({ [this.props.id]: { coord: startCoord } });

                    /*this.setState({
                        coord: startCoord,
                    })*/
                }
            });
    }

    /**
     * 
     */
    componentDidMount() {
        // create gesture listeners
        this.gesture = new InfiniteGesture(this.element.current);
        this.gesture.listenMouseEvents();
        this.setClickGestureListener();
        this.setDoubleClickListener();
        this.setElementDragGestureListener();

        // dom element does not change, set it in the global context only at mount time.
        this.props.updateContextDom(this.props.id, this.element.current);
        
        if (!this.props.controlled) {

            const rect = this.element.current.getBoundingClientRect();
            const size = {
                w: rect.width,
                h: rect.height,
            }

            this.setPositionStyle(this.props.coord, size, this.props.align, this.props.center);
            // only element with id are in the global context
            // TODO bacth state update ?

            this.props.updateContextProperties({
                [this.props.id]: {
                    coord: this.props.coord,
                    align: this.props.align,
                    center: this.props.center,
                    size,
                }
            });

        }


    }

    /**
     * 
     * @param {*} nextProps 
     * @param {*} nextState 
     * @returns 
     */
    shouldComponentUpdate(nextProps, nextState) {
        const updateStateFields = ['dragging', 'size', 'align'];
        const updatePropsFields = ['properties', 'elementId', 'version', 'offset', 'translate', 'selected', 'selecting', 'grid'];

        // if controlled the coord are managed by the one controlling this element.
        if (!this.props.controlled) updateStateFields.push('coord');

        const fields = [...getDiffFields(nextProps, this.props, updatePropsFields),
        ...getDiffFields(nextState, this.state, updateStateFields)];

        return fields.length > 0;
    }

    /**
     * 
     * @param {*} oldProps 
     */
    componentDidUpdate(oldProps) {

        const rect = this.element.current.getBoundingClientRect();
        const size = {
            w: rect.width,
            h: rect.height,
        }

        /*if (oldProps.version != this.props.version) {
            this.setState({
                coord: this.props.coord,
                size,
                align: this.props.align,
            });
        }*/

        if (!this.props.controlled) {
            this.setPositionStyle(this.props.properties.coord, size, this.props.properties.align, this.props.properties.center);
        }

        const sizeUpdated = (size.w !== this.props.properties.size.w) || (size.h !== this.props.properties.size.h);
        if (sizeUpdated) {
            this.props.updateContextProperties({
                [this.props.id]: {
                    //coord: this.props.properties.coord,
                    size,
                    //align: this.props.align,
                    //center: this.props.center,
                }
            });
        }

    }

    /**
     * 
     */
    componentWillUnmount() {
        if (this.gesture) this.gesture.unlistenMouseEvents();
    }

    /**
     * 
     */
    setPositionStyle(coord, size, align, center) {
        let { x, y } = getRectFromProperties({ coord, size, align, center });
        // update the position only if this component is not in a group (selected group for instance)
        // in that cases, the group is in charge of controling the position of this element.
        x += this.props.translate.x - this.props.offset.x;
        y += this.props.translate.y - this.props.offset.y;
        this.element.current.style.left = `${x}px`;
        this.element.current.style.top = `${y}px`;


        //this.element.current.style.width = `${w}px`;
        //this.element.current.style.height = `${h}px`;
    }

    /**
     * 
     * @returns 
     */
    render() {
        //console.log('Infinite Element render', this.props.id, this.props.version)
        return <InfiniteElementContext.Provider value={{
            selecting: this.props.selecting,
            selected: this.props.selected,
        }}>
            <Box
                ref={this.element}
                sx={{
                    position: 'absolute',
                    pointerEvents: 'auto',
                    zIndex: 10,
                    //outline: this.props.selected ? '1px solid blue' : this.props.selecting ? '1px solid red' : 'none',
                }}>
                {this.props.children}
            </Box>
        </InfiniteElementContext.Provider>
    }

}

/**
 * 
 */
const InfiniteElementWithContexts = chain(
    withContext(InfiniteContext)(({ properties, mode, offset, translate, grid, selectingElements, selectedElements, setSelectedElements, onElementPositionChanged, updateContextProperties, updateContextDom, onDoubleClick }, props) => {
        return {
            mode,
            offset,
            translate,
            selected: selectedElements.includes(props.id),
            selecting: selectingElements.includes(props.id),
            controlled: selectedElements.includes(props.id),
            properties: properties[props.id],
            grid,

            // functions
            setSelectedElements,
            onElementPositionChanged,
            updateContextProperties,
            updateContextDom,
            onDoubleClick,
        }
    })
)(InfiniteElement)

/**
 * Add a fwd ref
 */
export default React.forwardRef((props, ref) => <InfiniteElementWithContexts
    forwardedRef={ref}
    {...props}
/>);