import { createTheme } from '@mui/material/styles';
// belle couleur: #7dd0d0

export default createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    typography: {
        //fontFamily: "system-ui, -apple-system, Segoe UI, Roboto, Cantarell, sans-serif"//"'IBM Plex Sans Thai Looped', sans-serif;" //"Menlo,Monaco,'Courier New',monospace;"
        //fontFamily: '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif',
        fontFamily: '"IBM Plex Sans", sans-serif',
    },
    palette: {
        background: {
            default: "#FFF"
        },
        text: {
            primary: '#000',
        },
        primary: {
            main: '#000', //'#3a313e',//'#22eab5',
            light: '#555',
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                h1: {
                    fontSize: 52,
                    fontWeight: 800,
                },
                h2: {
                    fontSize: 40,
                    fontWeight: 600,
                },
                h3: {
                    fontSize: 26,
                    fontWeight: 500,
                },
                h4: {
                    fontSize: 20,
                    fontWeight: 500,
                },
                h5: {
                    fontSize: 18,
                },
                h6: {
                    fontSize: 16,
                },
                body: {
                    fontSize: 14,
                },
                body2: {
                    fontSize: 13,
                },
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: 32,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 3,
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#3a313e',
                    borderRadius: 0
                },
                arrow: {
                    color: '#3a313e',
                }
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    //padding: 0,
                    minHeight: 18,
                    paddingTop: 6,
                    paddingBottom: 6,
                    whiteSpace: 'normal',
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'rgba(0,0,0,.6)'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 12,
                    fontWeight: 400,
                    color: 'rgba(0,0,0,.6)'
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: 0,
                    //height: 32,
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    //padding: 6,
                    paddingTop: 6,
                    paddingBottom: 6,
                    paddingLeft: 12,
                    paddingRight: 12,
                    minHeight: 32,
                    height: 32,
                    cursor: 'default',
                    color: '#00000039',
                    transition: 'background-color .15s',
                    //borderRadius: '3px',
                    //borderRadius: 3,
                    "&:hover": {
                        color: '#000000CC',
                        //color: '#666',
                        backgroundColor: '#e6ecee99',//'#95f8dd',
                        //textDecoration: "none",
                    },
                    "&.Mui-selected": {
                        color: '#000000',
                        backgroundColor: '#f8f9fa'
                        //backgroundColor: '#87fcc422'
                        //color: "#FFFFFFCC",
                        //fontWeight: 500,
                        //backgroundColor: '#FA0AFA',
                        //textDecoration: "underline",
                        //textUnderlineOffset: '4px',
                    },
                }

            }
        },
        MuiTreeItem: {
            styleOverrides: {
                label: {
                    //cursor: 'default',
                    fontSize: 13,
                    minHeight: 22,
                    //paddingBottom: 2,
                    //paddingTop: 2,
                },
                group: {
                    marginLeft: 0
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    cursor: 'default',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                root: {

                }
            }
        }
        
    },

});