import firebase from "firebase/app";
import "firebase/auth";


export function onAuthStateChanged(callback) {
    firebase.auth().onAuthStateChanged(callback);
}

export function getIdToken() {
    return firebase.auth().currentUser.getIdToken(/* forceRefresh */ false);
}


