import { Box } from '@mui/material';
import React from 'react';

class InfiniteContainer extends React.Component {

    constructor(props) {
        super(props);
        this.element = React.createRef();
    }

    /*componentDidMount() {
        new ResizeObserver(entries => {
            // this will get called whenever div dimension changes
             entries.forEach(entry => {
               console.log('width', entry.contentRect.width);
               console.log('height', entry.contentRect.height);
             });
             this.forceUpdate()
           }).observe(this.element.current);

    }*/

    render() {
        const { sx, ...otherProps } = this.props;
        return (
            <Box 
            ref={this.element}
            sx={{
                ...sx,
                position: 'relative',
                //width: '100%',
                //height: '100%',
                overflow: 'hidden',
                margin: 'auto',
                backgroundColor: '#e5e5f7',
                background: 'radial-gradient(circle, transparent 20%, #e5e5f7 20%, #e5e5f7 80%, transparent 80%, transparent), radial-gradient(circle, transparent 20%, #e5e5f7 20%, #e5e5f7 80%, transparent 80%, transparent) 25px 25px, linear-gradient(#adadad 2px, transparent 2px) 0 -1px, linear-gradient(90deg, #adadad 2px, #e5e5f7 2px) -1px 0',
                backgroundSize: '50px 50px, 50px 50px, 25px 25px, 25px 25px',
            }} {...otherProps}>
                {this.props.children}
            </Box>
        );
    }

}

export default InfiniteContainer;
