
import Tooltip from '@mui/material/Tooltip';

export default ({ title = '', ...props }) => (
    <Tooltip
        title={title}
        enterDelay={1000}
        arrow
        TransitionProps={{ timeout: 0 }}
        {...props}
    />
)
