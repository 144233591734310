import Box from '@mui/material/Box';
import { withStyles } from '@mui/styles';
import firebase from "firebase/app";
import 'firebase/firestore';
import React from 'react';
import { Outlet } from "react-router-dom";
import { onAuthStateChanged } from '../sdk/Auth.js';
import AuthenticationContext from './AuthenticationContext';

const styles = theme => ({
    root: {
        height: '100%',
        //backgroundColor: '#FAFAFA',
        //backgroundImage: "radial-gradient(rgb(180,180,180) 0.5px, transparent 0.5px)",
        //backgroundSize: "20px 20px",
        //[theme.breakpoints.down('md')]: {
            //backgroundColor: '#FAFAFA',
        //}
    }
});

/**
 * 
 */
class Authentication extends React.Component {

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            user: undefined, // the current user auth (from firebase auth)
            userInfo: undefined,
        }
        this.unsubscribeAuthState = null;
        this.unsubscribeUserInfoListener = null;
    }

    /**
     * 
     */
    componentDidMount() {
        this.unsubscribeAuthState = onAuthStateChanged(this.onAuthStateChanged.bind(this));
    }

    /**
     * 
     */
    componentWillUnmount() {
        if (this.unsubscribeAuthState) {
            this.unsubscribeAuthState();
            this.unsubscribeAuthState = null;
        }
        if (this.unsubscribeUserInfoListener) {
            this.unsubscribeUserInfoListener();
            this.unsubscribeUserInfoListener = null;
        }
    }

    /**
     * 
     * @param {*} user 
     */
    onAuthStateChanged(user) {
        if (this.unsubscribeUserInfoListener && user?.uid != this.state.user?.uid) {
            this.unsubscribeUserInfoListener();
            this.unsubscribeUserInfoListener = null;
        }
        if (user) {
            // user is logged in
            this.setState({
                user,
            });
            const db = firebase.firestore();
            this.unsubscribeUserInfoListener = db.collection("users").doc(this.state.user.uid).onSnapshot(doc => {
                this.setState({
                    userInfo: doc.data()
                });
            });
        } else {
            // user not logged in, redirect to signin page
            //this.props.navigate('/signin');
            this.setState({
                user: null,
                userInfo: null,
            });
        }
    }


    /**
     * 
     * @returns 
     */
    render() {
        const { classes } = this.props;
        return (
            <Box className={classes.root}>
                {this.state.user && this.state.userInfo && <AuthenticationContext.Provider value={{
                    user: this.state.user,
                    userInfo: this.state.userInfo,
                }}>
                    <Outlet />
                </AuthenticationContext.Provider>}
            </Box>
        )
    }
}

export default withStyles(styles)(Authentication);