import Box from '@mui/material/Box';
import React from 'react';
import ArtifactEditor from '../artifact/ArtifactEditor';
import ArtifactEditorFile from '../artifact/ArtifactEditorFile';
import EditorContext from '../editor/EditorContext.js';
import withContext, { chain } from '../utils/WithContext';


class CodeView extends React.Component {

    constructor(props) {
        super(props);
    }

    /**
     * 
     * @returns 
     */
    render() {
        const selectedTab = this.props.selectedTabId ? this.props.getTab(this.props.selectedTabId) : null;
        return <Box sx={{
            flexGrow: 1,
            display: this.props.selectedTabId ? 'block' : 'none',
        }}>
            <ArtifactEditor
                artifact={selectedTab ? selectedTab.getArtifact() : null}
                filepath={selectedTab ? selectedTab.getFilepath() : null}>

                {this.props.tabsIds.map(tabId => {
                    const tab = this.props.getTab(tabId);
                    return <ArtifactEditorFile
                        artifact={tab.getArtifact()}
                        filepath={tab.getFilepath()}
                    />
                })}

            </ArtifactEditor>
        </Box>
    }
}

export default chain(
    withContext(EditorContext)(({ getTab }) => ({ getTab })),
)(CodeView);