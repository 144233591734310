import { Avatar, TableRow, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { withStyles } from '@mui/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import AuthenticationContext from '../authentication/AuthenticationContext';
import Guillaume from '../component/guillaume.jpeg';
import withContext, { chain } from '../utils/WithContext';

const styles = theme => ({
    root: {
        backgroundColor: '#d4e7eb',
        width: 400,
        height: 550,
        margin: 'auto',
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
    },

    content: {
        padding: '20px',
    },
});

/**
 * 
 */
class Apps extends React.Component {

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
    }


    /**
     * 
     * @returns 
     */
    render() {
        const { classes } = this.props;

        return (
            <Box className={classes.root}>
                <Avatar sx={{ m: 'auto', mt: 3, mb: 3, width: 80, height: 80, border: '4px solid #fff' }} src={Guillaume} />
                <Box className={classes.content} sx={{ flexGrow: 1 }}>
                    <TableContainer>
                        <Table size="small">
                            <TableBody>
                                {this.props.userInfo.apps?.map(app => (
                                    <TableRow key={app.id}>
                                        <TableCell><Typography variant='body'><Link to={`/apps/${app.id}`}>{app.name}</Link></Typography></TableCell>
                                        <TableCell><Typography variant='body'>{app.id}</Typography></TableCell>
                                        <TableCell sx={{ width: 40 }}><Avatar sx={{ m: 'auto', width: 28, height: 28, border: '2px solid white' }} src={Guillaume} /></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        )
    }
}

export default chain(
    withContext(AuthenticationContext)(({ user, userInfo }) => ({ user, userInfo })),
    withStyles(styles),
)(Apps);
