import { mdiAccountOutline, mdiCheck, mdiLightningBolt } from '@mdi/js';
import Icon from '@mdi/react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import ResourceContext from '../resource/ResourceContext';
import SystemLoading from '../system/SystemLoading';
import SystemToolbar from '../system/SystemToolbar';
import withContext, { chain } from '../utils/WithContext';
import AppContext from './AppContext';
import CacheContext from '../cache/CacheContext';


class ServiceFunctionalitiesSelectMini extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            service: null,
        };
    }

    /**
   * 
   */
    componentDidMount() {
        if (this.props.dependency && this.props.dependency.service) {
            this.update();
        }
    }

    /**
     * 
     * @param {*} oldProps 
     */
    componentDidUpdate(oldProps) {
        if (oldProps.dependency?.service !== this.props.dependency?.service) {
            this.update();
        }
    }

    /**
     * 
     */
    update = () => {
        if (!this.props.dependency?.service) {
            this.setState({ service: null });
        } else {
            this.props.getService(this.props.dependency.service, 'LATEST').then(service => this.setState({ service }));
        }
    }

    handleSelect = role => {
        if (this.props.dependency.roles && this.props.dependency.roles.includes(role)) {
            this.handleRemoveNewFunctionality(role);
        } else {
            this.handleAddNewFunctionality(role);
        }
    }

    handleAddNewFunctionality = role => {
        const resourceJson = { ...this.props.resourceClass };
        if (!resourceJson.hasOwnProperty('dependencies')) return;
        const dependency = resourceJson.dependencies[this.props.dependencyName] || {};
        if (!dependency.hasOwnProperty('roles')) {
            dependency.roles = [];
        }
        if (!dependency.roles.includes(role)) dependency.roles.push(role);
        resourceJson.dependencies[this.props.dependencyName] = dependency;
        this.props.artifact.editFile('/resource.json', JSON.stringify(resourceJson, null, 2));
    }

    handleRemoveNewFunctionality = role => {
        const resourceJson = { ...this.props.resourceClass };
        if (!resourceJson.hasOwnProperty('dependencies')) return;
        const dependency = resourceJson.dependencies[this.props.dependencyName] || {};
        if (!dependency.hasOwnProperty('roles')) {
            dependency.roles = [];
        }
        dependency.roles = dependency.roles.filter(r => r !== role);
        resourceJson.dependencies[this.props.dependencyName] = dependency;
        this.props.artifact.editFile('/resource.json', JSON.stringify(resourceJson, null, 2));
    }

    /**
     * 
     * @returns 
     */
    render() {
        if (!this.state.service) return <SystemLoading />;

        const { sx } = this.props;
        return (
            <Box
                sx={{
                    ...sx,
                    width: '100%',
                    maxWidth: 600,
                    position: 'relative',
                    overflow: 'auto',
                    dislay: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                }}>

                <Box>
                    <SystemToolbar title={'Roles'} />

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                    }}>
                        {this.state.service.resource.roles && this.state.service.resource.roles
                            .map(role => (<Box sx={{
                                padding: '8px 16px',
                                borderRadius: '4px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '2px',
                                cursor: 'default',
                                backgroundColor: '#f1f1f1',
                                '&:hover': {
                                    backgroundColor: '#ececec'
                                }
                            }}
                            onClick={() => this.handleSelect(role.name)}>
                                <Box sx={{
                                    borderRadius: '4px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                }}>
                                    <Box
                                        sx={{
                                            flexShrink: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '15px',
                                            justifyContent: 'center',
                                        }}>
                                        <Icon color={'#E91E63'} path={mdiAccountOutline} size={.6} />
                                    </Box>

                                    <Typography
                                        variant={'body'}
                                        sx={{
                                            fontWeight: 400,
                                            color: '#000', //'#3d4047',
                                            flexGrow: 1,
                                        }}
                                    >
                                        {role.name}
                                    </Typography>

                                    {this.props.dependency.roles && this.props.dependency.roles.includes(role.name) ? <Icon path={mdiCheck} size={.6} /> : <></>}
                                </Box>
                                <Box>

                                    <Typography
                                        sx={{
                                            flexGrow: 1,
                                            fontWeight: 400,
                                            color: '#6b7280'
                                        }}
                                        variant={'body'}
                                        component={'p'}>
                                        {role.description}
                                    </Typography>
                                </Box>
                            </Box>
                            ))}
                    </Box>
                </Box>
                <Box>
                    <SystemToolbar title={'Triggers'} />

                    {this.state.service.resource.triggers && this.state.service.resource.triggers
                        .map(trigger => (<Box sx={{
                            height: 32,
                            padding: '0px 8px 0px 16px',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            cursor: 'default',
                            '&:hover': {
                                backgroundColor: '#ececec'
                            }
                        }}
                            onClick={() => this.handleSelect(trigger.name)}>
                            <Box
                                sx={{
                                    flexShrink: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '15px',
                                    justifyContent: 'center',
                                }}>
                                <Icon color={'#E91E63'} path={mdiLightningBolt} size={.6} />
                            </Box>

                            <Typography
                                variant={'body'}
                                sx={{
                                    fontWeight: 400,
                                    color: '#000', //'#3d4047',
                                    flexGrow: 1,
                                }}
                            >
                                {trigger.name}
                            </Typography>

                            {this.props.dependency.triggers && this.props.dependency.triggers.includes(trigger.name) ? <Icon path={mdiCheck} size={.6} /> : <></>}
                        </Box>
                        ))}
                </Box>
            </Box>
        )
    }
}

export default chain(
    withContext(CacheContext)(({ getService }) => ({ getService })),
    withContext(ResourceContext)(({ artifact, resourceClass }, props) => {
        return { 
        artifact,
        resourceClass, 
        dependency: resourceClass.dependencies ? resourceClass.dependencies[props.dependencyName] : null
     }}),
)(ServiceFunctionalitiesSelectMini);
