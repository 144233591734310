import React from 'react';
import ArtifactContext from './ArtifactContext';

class ArtifactProvider extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <ArtifactContext.Provider value={{
            artifact: this.props.artifact,
        }}>
            {this.props.children}
        </ArtifactContext.Provider>
    }
}

export default ArtifactProvider;