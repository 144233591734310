import { mdiTarget } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, IconButton } from '@mui/material';
import React from 'react';
import withContext, { chain } from '../utils/WithContext';
import { getRectFromProperties } from './Infinite';
import InfiniteContext from './InfiniteContext';
import InfiniteGesture from './InfiniteGesture.js';

/**
 * 
 */
class InfiniteMap extends React.Component {

    constructor(props) {
        super(props);
        this.viewport = React.createRef();
    }

    setViewportDragGestureListener() {
        var startX = null, startY = null;
        var infiniteStartX = null, infiniteStartY = null;
        let handleStart = () => {
            startX = this.viewport.current.offsetLeft;
            startY = this.viewport.current.offsetTop;

            infiniteStartX = this.props.infiniteElement.offsetLeft;
            infiniteStartY = this.props.infiniteElement.offsetTop;

            //this.infinite.current.style.cursor = "grab";
        };

        let handleMove = ({ deltaX, deltaY }) => {
            // move the element
            this.viewport.current.style.left = startX + deltaX + 'px';
            this.viewport.current.style.top = startY + deltaY + 'px';
            //this.infinite.current.style.cursor = 'grabbing';
            //this.props.setViewportMoving({ x: startX + deltaX, y: startY + deltaY });
        };

        let handleStop = ({ deltaX, deltaY }) => {
            // move the element
            //this.viewport.current.style.left = startX + deltaX + 'px';
            //this.viewport.current.style.top = startY + deltaY + 'px';

            this.props.infiniteElement.style.left = startX - deltaX / this.ratioX + 'px';
            this.props.infiniteElement.style.top = startY - deltaY / this.ratioY + 'px';
            //this.props.removeViewportMoving();
            /*this.props.updateViewportPosition({
                x: this.props.viewport.x - deltaX,
                y: this.props.viewport.y - deltaY
            });*/
            //this.props.computeDimensions({ dx: -deltaX / this.ratioX, dy: -deltaY / this.ratioY  });
            //this.setCursor();
        };

        this.gesture.condition(({ event }) => event.which == 1 && !event.metaKey)
            .onStart(handleStart)
            .onMove(handleMove)
            .onStop(handleStop);

    }

    componentDidMount() {
        // create gesture listeners
        this.gesture = new InfiniteGesture(this.viewport.current);
        this.gesture.listenMouseEvents();
        this.setViewportDragGestureListener();
    }

    getEncapsulatedRect = (viewport, properties) => {
        let rect = null;
        const rects = [viewport, { x: 0, y: 0, w: 0, h: 0 }, ...Object.values(properties).map(p => getRectFromProperties(p))];
        rects.forEach(r => {
            if (!rect) rect = r;
            else {
                const rx = rect.x, ry = rect.y, rw = rect.w, rh = rect.h;
                const minX = (r.x < rx) ? r.x : rx;
                const minY = (r.y < ry) ? r.y : ry;
                const maxX = (r.x + r.w > rx + rw) ? r.x + r.w : rx + rw;
                const maxY = (r.y + r.h > ry + rh) ? r.y + r.h : ry + rh;
                rect = { x: minX, y: minY, w: maxX - minX, h: maxY - minY };
            }
        });
        // get the same ratio than the container
        const parentRatio = this.props.width / this.props.height;
        if (parentRatio >= 1) { // width = 300 > height = 300
            if (rect.w / rect.h <= parentRatio) { // 2 / 1
                rect.x -= (((rect.h * parentRatio) - rect.w) / 2); // ((1 * 3) - 2) / 2 = 0.5
                rect.w += (((rect.h * parentRatio) - rect.w));
            } else if (rect.w / rect.h > parentRatio) { // rect.w > ratio * rect.h
                rect.y -= ((rect.w - (rect.h * parentRatio)) / 2);
                rect.h += ((rect.w - (rect.h * parentRatio)));
            }
        } else {
            if (rect.w / rect.h < parentRatio) {
                rect.y -= (((rect.w / parentRatio) - rect.h) / 2);
                rect.h += (((rect.w / parentRatio) - rect.h));
            } else if (rect.w / rect.h >= parentRatio) {
                rect.x -= ((rect.w - (rect.h / parentRatio)) / 2);
                rect.w += ((rect.w - (rect.h / parentRatio)));
            }
        }
        // add a padding
        // TODO
        return rect;
    }

    getMapPositionPercentage = (rect, p) => {
        const r = getRectFromProperties(p);
        //const max = Math.max(this.props.width, this.props.height);
        this.ratioX = this.props.width / rect.w
        this.ratioY = this.props.height / rect.h
        return {
            x: this.props.width * (r.x - rect.x) / rect.w,
            y: this.props.height * (r.y - rect.y) / rect.h,
            w: this.props.width * r.w / rect.w,
            h: this.props.height * r.h / rect.h,
        }
    }

    getRectPercentage = (rect, viewport) => {
        //const ratio = Math.max(rect.w, rect.h) / Math.min(rect.w, rect.h);
        return {
            x: this.props.width * (viewport.x - rect.x) / rect.w,
            y: this.props.height * (viewport.y - rect.y) / rect.h,
            w: this.props.width * viewport.w / rect.w,
            h: this.props.height * viewport.h / rect.h,
        }
    }

    render() {
        const v = {
            x: - this.props.translate.x,
            y: - this.props.translate.y,
            w: this.props.viewport.w,
            h: this.props.viewport.h,
        }
        const rect = this.getEncapsulatedRect(v, this.props.properties);
        const viewport = this.getRectPercentage(rect, v);
        const center = this.getRectPercentage(rect, { x: 0, y: 0, w: 1, h: 1 });
        return <Box
            sx={{
                ...this.props.sx,
                width: this.props.width,
                height: this.props.height,
            }}>
            <Box
                ref={this.viewport}
                sx={{
                    position: 'absolute',
                    top: viewport.y + 'px',
                    left: viewport.x + 'px',
                    width: viewport.w + 'px',
                    height: viewport.h + 'px',
                    border: '1px solid rgba(0,0,255,.6)',
                    backgroundColor: 'rgba(0,0,255,.006)',
                }} />



            {false && <Box
                sx={{
                    position: 'absolute',
                    top: (center.y) + 'px',
                    left: (center.x) + 'px',
                    transform: 'translate(-50%, -50%)'
                }} >
                <IconButton sx={{
                    padding: '4px',
                    background: 'rgba(0,0,0,.06)',
                    border: '1px solid rgba(0,0,0,.01)',
                    color: 'black',
                }}>
                    <Icon path={mdiTarget} size={.6} />
                </IconButton>
            </Box>}

            {false && <Box
                sx={{
                    position: 'absolute',
                    top: (center.y - 5) + 'px',
                    left: (center.x - 1) + 'px',
                    width: '2px',
                    height: '10px',
                    backgroundColor: '#F2F2F2',
                }} />}

            {false && <Box
                sx={{
                    position: 'absolute',
                    top: (center.y - 1) + 'px',
                    left: (center.x - 5) + 'px',
                    width: '10px',
                    height: '2px',
                    backgroundColor: '#F2F2F2',
                }} />}
            {Object.entries(this.props.properties || {}).map(([id, p]) => {
                const { x, y, w, h } = this.getMapPositionPercentage(rect, p);
                return <Box
                    sx={{
                        position: 'absolute',
                        top: y + 'px',
                        left: x + 'px',
                        width: w + 'px',
                        height: h + 'px',
                        backgroundColor: 'rgba(0,0,255,.5)',
                        border: '1px solid rgba(0,0,0,.03)',
                    }} />
            })}
        </Box>
    }
}

export default chain(
    withContext(InfiniteContext)(({ properties, viewport, translate, infiniteElement, computeDimensions }) => ({ properties, viewport, translate, infiniteElement, computeDimensions })),
)(InfiniteMap)