import { Box } from '@mui/material';
import React from 'react';
import InfiniteContext from './InfiniteContext';
import InfiniteGesture from './InfiniteGesture.js';
import InfiniteMap from './InfiniteMap.js';
import InfiniteSelected from './InfiniteSelected.js';
import InfiniteSelecting from './InfiniteSelecting.js';


export const getRectFromProperties = properties => {
    // we assume here that the position is mapped on the grid !
    var { x, y } = properties.coord;
    var { w, h } = properties.size;
    if (properties.align == 'center') {
        x -= properties.center?.x ? properties.center.x : properties.size.w / 2;
        y -= properties.center?.y ? properties.center.y : properties.size.h / 2;
    } else {
        // TODO if needed
    }
    return { x, y, w, h }
}

/**
 * 
 * @param {*} rectA 
 * @param {*} rectB 
 * @returns 
 */
export const isRectOverlapRect = (rectA, rectB) => {
    if (!rectA || !rectB) return false;
    return !(rectA.x + rectA.w < rectB.x
        || rectA.x > rectB.x + rectB.w
        || rectA.y + rectA.h < rectB.y
        || rectA.y > rectB.y + rectB.h);
}

/**
 * 
 */
class Infinite extends React.Component {

    constructor(props) {
        super(props);

        // the state
        this.state = {
            // positions
            viewport: { x: 0, y: 0, w: 0, h: 0 },
            offset: { x: 0, y: 0 },
            delta: { x: 0, y: 0 },
            translate: { x: 0, y: 0 },

            // elements
            selectingElements: [],
            selectedElements: [],
            selectedComponentKey: 0,

            // rects 
            selectingRect: null,
            selectedRect: null,

            // nodes data
            properties: {}, // node props
            doms: {}, // node dom elements

            // connections between nodes
            connections: {},
        }

        // background pattern size
        this.patternSize = {
            width: 16,
            height: 16,
        };

        // ref
        this.infinite =  React.createRef();

        // async state update to avoid multiple update when we modify several time in the same nodejs process
        this.asyncId = null;
        this.asyncState = {};

        this.initialized = false;
    }

    componentDidMount() {
        // create gesture listeners
        this.gesture = new InfiniteGesture(this.infinite.current);
        this.gesture.listenMouseEvents();

        // set the gesture in charge of draging the "background".
        this.setInfiniteDragGestureListener();

        // mode DEFAULT
        this.setSelectorGestureListener();
        this.setClickGestureListener();
        
        // mode ADD
        this.setAddElementGestureListener();

        this.infinite.current.addEventListener("wheel", this.handleWheelEvent.bind(this));


        // compute the dimensions of the infinite element
        window.addEventListener('resize', () => {
            //this.computeDimensions();
        });
        // position the (0,0) at he middle of the container
        var rect = this.infinite.current.parentNode.getBoundingClientRect();

        const delta = { dx: rect.width / 2, dy: rect.height / 2 };
        this.updateDimensions(delta).then(() => {
            this.initialized = true;
        });
    }

    /**
     * 
     * @param {*} state 
     */
    setStateLogged = state => {
        console.log('Infinite State Update', this.state);
        this.setState(state);
    }

    /**
     * 
     * @param {*} state 
     * @param {*} cb 
     */
    setStateAsync = (state, cb) => {
        if (this.asyncId) clearTimeout(this.asyncId);
        this.asyncState = { ...this.asyncState, ...state };
        if (cb) this.asyncCbs = [...this.asyncCbs, cb];
        this.asyncId = setTimeout(() => {
            this.setStateLogged(this.asyncState, () => {
                this.asyncCbs.forEach(fn => fn())
            });
            this.asyncId = null;
            this.asyncState = {};
            this.asyncCbs = [];
        }, 0);
    }

    /**
     * 
     * @param {*} id 
     * @param {*} element 
     */
    updateContextDom = (id, element) => {
        this.setState(state => {
            const doms = state.doms; // TODO !! use clone ?
            doms[id] = element;
            return state;
        });
    }


    /**
     * 
     * @param {*} id 
     * @param {*} props 
     */
    updateContextProperties = props => {
        this.setState(state => {
            const properties = state.properties; // TODO !! use clone ?
            Object.entries(props).forEach(([id, p]) => {
                properties[id] = {
                    ...(properties[id] || {}),
                    ...p,
                }
            })
            return state;
        });
    }

    /**
     * 
     * @param {*} ids 
     */
    setSelectedElements = selectedElements => {
        const selectedRect = this.getEncapsulatedRect(selectedElements);
        this.setState({
            selectingRect: null,
            selectingElements: [],
            selectedElements,
            selectedRect,
            selectedComponentKey: this.state.selectedComponentKey + 1,
        });
        if (this.props.onElementsSelected) {
            this.props.onElementsSelected(selectedElements);
        }
    }

    /**
     * Prevent the context menu to be displayed
     * @param {*} event 
     * @returns 
     */
    handleContextMenu(event) {
        if (!event.metaKey) event.preventDefault();
        return false;
    }

    /**
     * Get client position in the infinite referentiel
     * @param {*} coord 
     * @param {*} grid 
     * @returns 
     */
    getClientCoordInInfiniteReferentiel = (coord, opt_grid) => {
        const x = coord.x - this.state.viewport.x - this.state.translate.x;
        const y = coord.y - this.state.viewport.y - this.state.translate.y;
        if (!opt_grid) {
            return { x, y }
        } else {
            return {
                x: Math.round(x / opt_grid.x) * opt_grid.x,
                y: Math.round(y / opt_grid.y) * opt_grid.y,
            };
        }
    }

    /**
     * Get client position in the container referentiel
     * @param {*} coord 
     * @param {*} grid 
     * @returns 
     */
    getClientCoordInViewportReferentiel = (coord, opt_grid) => {
        const x = coord.x - this.state.viewport.x;
        const y = coord.y - this.state.viewport.y;
        if (!opt_grid) {
            return { x, y }
        } else {
            const ix = x - this.state.translate.x;
            const iy = y - this.state.translate.y;
            return {
                x: x + Math.round(ix / opt_grid.x) * opt_grid.x - ix, // apply the same delta as if located in the infinite element
                y: y + Math.round(iy / opt_grid.y) * opt_grid.y - iy,
            }
        }
    }

    /**
     * 
     */
    setClickGestureListener() {
        this.gesture.condition(({ event }) => this.props.mode.type === 'default' && event.which == 1 && !event.metaKey)
            .cancelWhen(({ distance }) => distance > 0)
            .onStop(({ event }) => {
                this.setState({
                    selectedElements: [],
                })
            });
    }

    /**
     * 
     */
    setAddElementGestureListener() {
        this.gesture.condition(({ event }) => this.props.mode.type === 'add' && event.which == 1 && !event.metaKey)
            .cancelWhen(({ distance }) => distance > 10)
            .onStop(({ event }) => {
                const client = { x: event.clientX, y: event.clientY }
                this.props.onAddElement({
                    position: {
                        raw: {
                            viewport: this.getClientCoordInViewportReferentiel(client),
                            infinite: this.getClientCoordInInfiniteReferentiel(client),
                        },
                        grid: {
                            viewport: this.getClientCoordInViewportReferentiel(client, this.props.grid),
                            infinite: this.getClientCoordInInfiniteReferentiel(client, this.props.grid),
                        }
                    },
                    data: this.props.mode.data
                });
            });
    }

    /*
    setMetaClickGestureListener() {
        this.gesture.condition(({ event }) => event.which == 1 && event.metaKey || event.which == 2)
            .onStop(({ event, duration, distance }) => {
                if ((duration < 200 && distance < 200)
                    || (duration >= 200 && distance == 0)) {
                    const client = { x: event.clientX, y: event.clientY }
                    this.props.onMetaClick({
                        position: {
                            raw: {
                                viewport: this.getClientCoordInViewportReferentiel(client),
                                infinite: this.getClientCoordInInfiniteReferentiel(client),
                            },
                            grid: {
                                viewport: this.getClientCoordInViewportReferentiel(client, this.props.grid),
                                infinite: this.getClientCoordInInfiniteReferentiel(client, this.props.grid),
                            }
                        }
                    });
                }
            });
    }*/

    /**
     * 
     */
    setInfiniteDragGestureListener() {
        var startX = null, startY = null;
        let handleStart = () => {
            startX = this.infinite.current.offsetLeft;
            startY = this.infinite.current.offsetTop;
            //this.infinite.current.style.cursor = "grab";
        };

        let handleMove = ({ deltaX, deltaY }) => {
            // move the element
            this.infinite.current.style.left = startX + deltaX + 'px';
            this.infinite.current.style.top = startY + deltaY + 'px';
            //this.infinite.current.style.cursor = 'grabbing';
            //this.props.setViewportMoving({ x: startX + deltaX, y: startY + deltaY });
        };

        let handleStop = ({ deltaX, deltaY }) => {
            // move the element
            this.infinite.current.style.left = startX + deltaX + 'px';
            this.infinite.current.style.top = startY + deltaY + 'px';
            //this.props.removeViewportMoving();
            /*this.props.updateViewportPosition({
                x: this.props.viewport.x - deltaX,
                y: this.props.viewport.y - deltaY
            });*/
            this.computeDimensions({ dx: deltaX, dy: deltaY });
            //this.setCursor();
        };

        this.gesture.condition(({ event }) => event.which == 3 && !event.metaKey)
            .onStart(handleStart)
            .onMove(handleMove)
            .onStop(handleStop);

        /*this.gesture.condition(({ event }) => event.which == 1 && (!this.props.cursor || this.props.cursor == 'add' || this.props.cursor == 'grab'))
            .onStart(handleStart)
            .onMove(handleMove)
            .onStop(handleStop);*/
    }

    /**
     * 
     */
    setSelectorGestureListener() {
        var startCoord = null;
        this.gesture.condition(
            ({ event }) => this.props.mode.type == 'default' && event.which == 1)//&& event.target == this.infinite.current && this.props.cursor == 'default')//,
            // () => this.props.store.getState().module.edit.viewport.moving != null)
            .onStart(({ event }) => {
                startCoord = this.getClientCoordInInfiniteReferentiel({ x: event.clientX, y: event.clientY });
                // unselect selected nodes when starting a new selection 
                if (this.state.selectedElements && this.state.selectedElements.length > 0) {
                    this.setSelectedElements([]);
                }
            })
            .onMove(({ event, distance }) => {
                if (distance < 10) return;
                const coord = this.getClientCoordInInfiniteReferentiel({ x: event.clientX, y: event.clientY });
                const selectingRect = {
                    x: Math.min(startCoord.x, coord.x),
                    y: Math.min(startCoord.y, coord.y),
                    w: Math.abs(coord.x - startCoord.x),
                    h: Math.abs(coord.y - startCoord.y),
                };
                const selectingElements = this.computeSelectingList(selectingRect);
                this.setState({
                    selectingRect,
                    selectingElements,
                });
            })
            .onStop(({ event, distance }) => {
                if (distance < 10) return; // TODO 
                const coord = this.getClientCoordInInfiniteReferentiel({ x: event.clientX, y: event.clientY });
                const selectingRect = {
                    x: Math.min(startCoord.x, coord.x),
                    y: Math.min(startCoord.y, coord.y),
                    w: Math.abs(coord.x - startCoord.x),
                    h: Math.abs(coord.y - startCoord.y),
                };
                this.setSelectedElements(this.computeSelectingList(selectingRect));
            });

    }

    handleWheelEvent = event => {
        let { deltaX, deltaY } = event;

        if (event.metaKey || event.altKey) {
            deltaX = - deltaY;
            deltaY = 0;
        } else {
            deltaX = - deltaX;
            deltaY = - deltaY;
        }

        const startX = this.infinite.current.offsetLeft;
        const startY = this.infinite.current.offsetTop;

        this.infinite.current.style.left = startX + deltaX + 'px';
        this.infinite.current.style.top = startY + deltaY + 'px';

        this.computeDimensions({ dx: deltaX, dy: deltaY });
    }

    /**
     * 
     * @param {*} elements 
     * @returns 
     */
    getEncapsulatedRect = elements => {
        let rect = null;
        elements.forEach(id => {
            const r = getRectFromProperties(this.state.properties[id]);
            if (!rect) rect = r;
            else {
                const rx = rect.x, ry = rect.y, rw = rect.w, rh = rect.h;
                const minX = (r.x < rx) ? r.x : rx;
                const minY = (r.y < ry) ? r.y : ry;
                const maxX = (r.x + r.w > rx + rw) ? r.x + r.w : rx + rw;
                const maxY = (r.y + r.h > ry + rh) ? r.y + r.h : ry + rh;
                rect = { x: minX, y: minY, w: maxX - minX, h: maxY - minY };
            }
        });
        return rect;
    }

    /**
     * 
     * @param {*} rect 
     * @returns 
     */
    computeSelectingList = rect => {
        const elements = [];
        Object.entries(this.state.properties).forEach(([id, p]) => {
            const r = getRectFromProperties(p);
            if (isRectOverlapRect(r, rect)) {
                elements.push(id);
            }
        });
        return elements;
    }

    /**
     * 
     * @param {*} translate 
     * @returns 
     */
    updateDimensions = (translate = { dx: 0, dy: 0 }, ) => {
        if (!this.infinite.current) return;

        // the viewport position
        var rect = this.infinite.current.parentNode.getBoundingClientRect();

        // skip when nothing is visible
        if (rect.width == 0 || rect.height == 0) return;

        const tx = this.state.translate.x + translate.dx;
        const ty = this.state.translate.y + translate.dy;

        let viewport = {
            x: rect.x,
            y: rect.y,
            w: rect.width,
            h: rect.height,
        }

        // the 'window' to display
        let window = {
            minX: tx,
            minY: ty,
            maxX: tx + viewport.w,
            maxY: ty + viewport.h
        }

        // add a padding of the size of the viewport
        let box = {
            minX: window.minX - viewport.w,
            minY: window.minY - viewport.h,
            maxX: window.maxX + viewport.w,
            maxY: window.maxY + viewport.h,
        }

        // compute the offset
        let p = this.patternSize;
        const offsetX = tx + Math.floor((box.minX - window.minX - tx) / p.width) * p.width;
        const offsetY = ty + Math.floor((box.minY - window.minY - ty) / p.height) * p.height;

        // compute the width and height
        var w = (box.maxX - box.minX);
        var h = (box.maxY - box.minY);

        // apply changes
        this.infinite.current.style.width = w + 'px';
        this.infinite.current.style.height = h + 'px';
        this.infinite.current.style.left = offsetX + 'px';
        this.infinite.current.style.top = offsetY + 'px';

        // set in the global state the new offset and delta to be used by children
        return new Promise(resolve => this.setState({
            viewport,
            translate: {
                x: tx,
                y: ty,
            },
            delta: {
                x: box.minX - offsetX,
                y: box.minY - offsetY,
            },
            offset: {
                x: offsetX,
                y: offsetY
            }
        }, resolve));
    }

    /**
     * 
     * @param {*} translate 
     */
    computeDimensions = translate => {
        if (this.initialized) {
            return this.updateDimensions(translate);
        } else {
            return Promise.resolve();
        }
    }

    // todo, clean that code
    setCursor() {
        let cursor;
        if (this.props.cursor == 'default') {
            cursor = 'default';
        } else if (this.props.cursor == 'add') {
            cursor = 'crosshair';
        } else if (this.props.cursor == 'text') {
            cursor = 'text';
        } else {
            cursor = 'grab';
        }
        this.infinite.current.style.cursor = cursor;
    }

    /**
     * 
     * @returns 
     */
    render() {
        //console.log('Infinite render')
        //console.log(JSON.stringify(this.state, null, 2))
        const { sx } = this.props;
        return (
            <Box
                ref={this.infinite}
                // deactivate the default system context menu
                onContextMenu={event => {
                    if (!event.metaKey) event.preventDefault();
                    return false;
                }}
                sx={{
                    position: 'absolute',
                    overflow: 'hidden',
                    userSelect: 'none',
                    outline: '2px dashed rgba(0,0,0,.5)', // do not set a border ! use only outline
                    backgroundColor: '#fdfdfd',
                    backgroundSize: '16px 16px',
                    backgroundImage: 'radial-gradient(#E4E4E4 1px, transparent 0px)',
                    backgroundPosition: '8px 8px',
                    cursor: this.props.mode.cursor || 'default',
                    ...sx,
                }}>
                <InfiniteContext.Provider value={{
                    // the cursor mode
                    mode: this.props.mode,

                    // the grid size
                    grid: this.props.grid,

                    getClientCoordInInfiniteReferentiel: this.getClientCoordInInfiniteReferentiel.bind(this),

                    // the infinite dom element
                    infiniteElement: this.infinite.current,
                    computeDimensions: this.computeDimensions.bind(this),

                    // positions
                    viewport: this.state.viewport,
                    translate: this.state.translate,
                    offset: this.state.offset,
                    delta: this.state.delta,

                    // global context
                    properties: this.state.properties,
                    doms: this.state.doms,
                    updateContextProperties: this.updateContextProperties.bind(this),
                    updateContextDom: this.updateContextDom.bind(this),

                    // selecting and selected nodes
                    selectedElements: this.state.selectedElements,
                    selectingElements: this.state.selectingElements,
                    selectingRect: this.state.selectingRect,
                    selectedRect: this.state.selectedRect,
                    setSelectedElements: this.setSelectedElements.bind(this),

                    //
                    onElementPositionChanged: this.props.onElementPositionChanged,
                    onDoubleClick: this.props.onDoubleClick,
                }}>

                    {this.props.children}

                    <InfiniteSelecting />
                    {this.state.selectedRect && <InfiniteSelected key={this.state.selectedComponentKey} />}

                    {true && <InfiniteMap
                        sx={{
                            position: 'fixed',
                            zIndex: '100000',
                            bottom: 30,
                            right: 30,
                            borderRadius: '4px',
                            backgroundColor: '#ebebef',
                            //boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 6px',
                        }}
                        //width={this.state.viewport.w > this.state.viewport.h ? 400 : 400 * this.state.viewport.w / this.state.viewport.h}
                        //height={this.state.viewport.h > this.state.viewport.w ? 400 : 400 * this.state.viewport.h / this.state.viewport.w}
                        width={220}
                        height={220}
                    />}

                </InfiniteContext.Provider>
            </Box>
        );
    }
}

export default Infinite;
/**
 * Add a fwd ref
 */
/*export default React.forwardRef((props, ref) => <Infinite
    ref={ref}
    {...props}
/>);*/