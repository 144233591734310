import { mdiArrowRight, mdiCircleSmall, mdiCursorDefaultOutline, mdiCursorMove, mdiDrag, mdiImageFilterCenterFocusWeak, mdiPlus, mdiRedo, mdiShapeSquarePlus, mdiSquareOutline, mdiSquareRoundedOutline, mdiUndo, mdiViewList } from '@mdi/js';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import React from 'react';
import CacheContext from '../cache/CacheContext';
import ModuleContext from '../module/ModuleContext';
import ResourceContext from '../resource/ResourceContext';
import AutoFocusInput from '../system/AutoFocusInput';
import ResourceIcon from '../system/ResourceIcon';
import SystemIconButton from '../system/SystemIconButton';
import SystemPopper from '../system/SystemPopper';
import withContext, { chain } from '../utils/WithContext';
import ResourceTile from './ResourceTile';
import SystemIconButtonLarge from '../system/SystemIconButtonLarge';
import SystemButton from '../system/SystemButton';
import SystemDivider from '../system/SystemDivider';
import SystemButtonSecondary from '../system/SystemButtonSecondary';
import { Typography } from '@mui/material';
import Icon from '@mdi/react';
import ResourceIconButton from '../system/ResourceIconButton';

/**
 * 
 */
class Resources extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            module: null,
            services: null,
            displayAddPopup: false,
            displayListPopup: false,
        };

        this.menu = React.createRef();
    }


    componentDidMount() {
        this.props.artifact.addFileChangeListener('/module.json', ({ type, value }) => {
            const module = JSON.parse(value);
            this.setState({ module });
        });
        this.props.artifact.getFolder('/').then(async ({ files }) => {
            const module = await this.props.artifact.getFileAsJson('/module.json');
            this.setState({ module });
        });
        this.props.getServices().then(services => {
            this.setState({ 
                lastAddResourceService: services[0],
                services
             });
        });
    }



    componentWillUnmount() {
        // TODO remove listener
    }

    handleDisplayAddPopup = displayAddPopup => {
        this.setState({ displayAddPopup });
    }
    handleDisplayListPopup = displayListPopup => {
        this.setState({ displayListPopup });
    }

    handleDisplayCreateInput = service => {
        this.setState({
            displayCreateService: service,
            displayAddPopup: false
        });
    }

    handleCreateResource = async (name) => {
        const module = { ...this.state.module };

        if (module.hasOwnProperty(name)) {
            // TODO error message
            return;
        }

        module[name] = {
            resource: `testarchi-565322/main/aa2py9vro/e8b1b0f43a31f2eb2702c82bfc6de47d8abe0853.zip`,
        }

        this.props.artifact.editFile('/module.json', JSON.stringify(module, null, 2));
        this.setState({ displayCreateService: null });
        this.handleSelectResource(name);
    }

    /**
     * 
     * @param {*} name 
     */
    handleSelectResource = name => {
        if (this.props.onSelect) {
            this.props.onSelect(name);
        }
    }

    handleSetModeAdd = (service, displayAddPopup) => {
        if (service) {
            this.props.setMode({
                type: 'add',
                cursor: 'crosshair',
                //cursor: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23000000" height="18" viewBox="0 0 24 24" width="18"><path d="${service.icon}"/></svg>'), auto`,
                data: {
                    service
                }
            });
        }
        this.setState({ 
            //lastAddResourceService: service,
            displayAddPopup
         });
    }

    handleSetModeDefault = () => {
        this.props.setMode({
            type: 'default'
        })
    }

    handleSetModeDrag = () => {
        this.props.setMode({
            type: 'drag',
            cursor: 'grab'
        })
    }

    /**
      * 
      * @returns 
      */
    render() {
        if (!this.state.module || !this.state.services) return <></>;

        return (<Box
            ref={this.menu}
            sx={{
                ...this.props.sx,
                //backgroundColor: '#ffffff',
                //boxShadow: '0 3px 8px #00000016',
                //borderRadius: '6px',
                //padding: '6px',
                transition: 'background-color .2s',
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
            }}>



            <Box sx={{
                borderRadius: '4px',
                color: (this.state.displayAddPopup || this.props.mode.type === 'add') ? '#009688' : '#000000',
                backgroundColor: (this.state.displayAddPopup || this.props.mode.type === 'add') ? '#e3fbf8' : '#ffffff',
            }}>
                <SystemIconButtonLarge path={mdiShapeSquarePlus} onClick={() => this.handleSetModeAdd(null, true)} />
            </Box>

            <Box sx={{
                borderRadius: '4px',
                color: (!this.state.displayAddPopup && this.props.mode.type === 'default') ? '#009688' : '#000000',
                backgroundColor: (!this.state.displayAddPopup && this.props.mode.type === 'default') ? '#e3fbf8' : '#ffffff',
            }}>
                <SystemIconButtonLarge path={mdiCursorDefaultOutline} onClick={() => this.handleSetModeDefault()} />
            </Box>


            <Box sx={{
                backgroundColor: '#ffffff',
                borderRadius: '4px',
                margin: '16px 0',
            }}>
                <SystemIconButtonLarge path={mdiImageFilterCenterFocusWeak} onClick={() => this.handleDisplayListPopup(true)} />
            </Box>

            <Box sx={{
                backgroundColor: '#ffffff',
                borderRadius: '4px',
            }}>
                <SystemIconButtonLarge path={mdiUndo} onClick={() => this.handleDisplayListPopup(true)} />
            </Box>

            <Box sx={{
                backgroundColor: '#ffffff',
                borderRadius: '4px',
            }}>
                <SystemIconButtonLarge path={mdiRedo} onClick={() => this.handleDisplayListPopup(true)} />
            </Box>

            <SystemPopper
                animation={'left'}
                anchorEl={this.menu.current}
                placement={'right-start'}
                onClickAway={() => this.handleDisplayAddPopup(false)}
                open={this.state.displayAddPopup}
                sx={{
                    backgroundColor: '#dbf1f0', //'#e7eeef', //'#ebebef', //'#f4f5f7',
                    borderRadius: '6px',
                }}
            >
                <Box sx={{ padding: '16px 16px 12px 16px', borderBottom: '1px solid rgba(0,0,0,.03)'}}>
                                    <SystemButtonSecondary sx={{ width: '100%' }}>
                                        <>EXPLORE</>
                                        <Icon path={mdiArrowRight} size={.6}/>
                                        </SystemButtonSecondary>
                                    </Box>
                <Box sx={{
                    width: 128,
                    gap: '8px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    padding: '12px 16px 16px',
                }}>
                    {this.state.services.map(service => (<ResourceIconButton
                            width={'44px'}
                            height={'44px'}
                            //selected={this.props.mode.type === 'add' && this.props.mode.data.service.serviceId === service.serviceId}
                            path={service.icon}
                            opacity={1}
                            radius={5}
                            color={service.color}
                            iconSize={.8}
                            onClick={() => this.handleSetModeAdd(service)}
                        />
                    ))}

                    {this.state.services.map(service => (<ResourceIconButton
                            width={'44px'}
                            height={'44px'}
                            path={service.icon}
                            opacity={1}
                            radius={5}
                            color={service.color}
                            iconSize={.8}
                            onClick={() => this.handleSetModeAdd(service)}
                        />
                    ))}
                </Box>
            </SystemPopper>

            <SystemPopper
                animation={'left'}
                anchorEl={this.menu.current}
                placement={'right-start'}
                onClickAway={() => this.handleDisplayListPopup(false)}
                open={this.state.displayListPopup}
                sx={{
                    minWidth: 240,
                    maxHeight: 600,
                    overflow: 'auto',
                    backgroundColor: '#dbf1f0', //'#ebebef', //'#f4f5f7',
                    borderRadius: '6px',
                    padding: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                }}
            >
                {Object.entries(this.state.module || {}).map(([name, resource]) => <>
                    {resource && <ResourceTile
                        name={name}
                        resource={resource}
                        onClick={() => this.handleSelectResource(name)}
                    />}
                </>
                )}
            </SystemPopper>

        </Box>
        )
    }
}

export default chain(
    withContext(CacheContext)(({ getArtifact, getService, getServices }) => ({ getArtifact, getService, getServices })),
    withContext(ModuleContext)(({ mode, setMode }) => ({ mode, setMode })),
    withContext(ResourceContext)(({ artifactName, artifact, resourceClass }) => ({ artifactName, artifact, resourceClass })),
)(Resources);


const NewItemInput = styled((props) => (
    <AutoFocusInput {...props}></AutoFocusInput>
))(({ theme }) => ({
    //fontFamily: '"IBM Plex Sans", sans-serif',
    width: '100%',
    padding: '0',
    //background: '#FFF',
    color: '#333',
    fontSize: 13,
    height: 18,
    //outline: '1px solid blue',
    //borderRadius: '1px',
    '& .MuiInputBase-input': {
        height: 'initial',
        padding: '0px'
    }
}));

/**
 

<Box sx={{
            backgroundColor: '#ffffff',
            boxShadow: '0 3px 8px #00000016',
            borderRadius: '6px',
            padding: '0 4px',
            transition: 'background-color .2s',
        }}>
            
            <SystemIconButton ref={this.button} path={mdiPlus} onClick={() => this.handleDisplayAddPopup(true)} />

            <SystemPopper
                anchorEl={this.button.current}
                onClickAway={() => this.handleDisplayAddPopup(false)}
                open={this.state.displayAddPopup}
                sx={{
                    backgroundColor: '#f3f3f5',
                    //border: '1px solid rgba(0,0,0,.1)',
                    borderRadius: '4px',
                }}
            >
                <ResourcesAddPopup onSelect={this.handleDisplayCreateInput} />
            </SystemPopper>

        </Box>
        </Box>
 
 */



/*

<SystemToolbar
alwaysDisplayActions
icon={mdiChevronRight}
title={`Resources (${Object.keys(this.state.module || {}).length})`}
actions={<SystemIconButton ref={this.button} path={mdiPlus} onClick={event => {
    this.handleDisplayAddPopup(true);
    event.stopPropagation();
}} />}

//actions={                    <SystemButton ref={this.button} onClick={() => this.handleDisplayAddPopup(true)}><Icon style={{ flexShrink: 0 }} path={mdiPlus} size={.6} />{'ADD RESOURCE'}</SystemButton>}
>

<Box sx={{
    paddingBottom: '8px'
}}>
    {Object.entries(this.state.module || {}).map(([name, resource]) => <>
        {resource && <ResourceTile
            {...(this.props.selected == name) ? {
                backgroundColor: '#e6f3ff',
                border: '2px solid #0e87ff'
            } : {
                border: '2px solid transparent'
            }}
            name={name}
            resource={resource}
            onClick={() => this.handleSelectResource(name)}
        />}
    </>
    )}

    {!this.state.displayCreateService && this.state.module && Object.keys(this.state.module).length === 0 && <Box sx={{
        padding: '8px 16px',
        color: '#00000080'
    }}>
        <Typography variant={'body'} component={'p'}>This resource does not have any resource. This resource does not have any resou v  not have any rce. This resource does not have any resource. This resource does not have any resource. This resource does not have any resource. This resource does not have any resource.  not have any This resource does not have any resource. This resource does not have any not have any resource. This resource does not have any resource. This resource does not have any resource. This resource does not have any resource. This resource does not have any resource. </Typography>
        <SystemLink href={'#'} icon={mdiArrowRight}>Learn more about resources</SystemLink>
    </Box>}



    {this.state.displayCreateService && <Box sx={{
        width: '100%',
        height: 24,
        padding: '0px 4px 0px 8px',
        border: '2px solid transparent',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '5px',
        gap: '8px',
    }}>
        <Box
            sx={{
                flexShrink: 0,
                display: 'flex',
                alignItems: 'center',
                width: '15px',
                justifyContent: 'center',
            }}>
            <Icon color={'#E91E63'} path={this.state.displayCreateService.icon} size={.6} />
        </Box>
        <NewItemInput defaultValue={''} onChange={value => this.handleCreateResource(value)} />
    </Box>}
</Box>
</SystemToolbar>

*/