import Box from '@mui/material/Box';
import React from 'react';
import ResourceContext from '../resource/ResourceContext';
import SystemLoading from '../system/SystemLoading';
import withContext, { chain } from '../utils/WithContext';
import DependencyTile from './DependencyTile.js';
import AppContext from './AppContext';
import CacheContext from '../cache/CacheContext';


class ModuleResourcePanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            childResource: null,
            childResourceClass: null,
        };
    }

    /**
   * 
   */
    componentDidMount() {
        this.update();
    }

    /**
     * 
     * @param {*} oldProps 
     */
    componentDidUpdate(oldProps) {
        if (oldProps.resourceId !== this.props.resourceId) {
            this.update();
        }
    }

    /**
     * 
     * @param {*} resourceId 
     * @returns 
     */
    getArtifact = (resourceId, resource) => {
        // get the module global id modA/modB/modC...
        const src = resource.resource;
        let artifact;
        if (!src.startsWith('file:')) {
            const artifactPath = `${this.props.artifact.name}/${resourceId}`;
            artifact = this.props.getArtifact(`file:${artifactPath}`);
            artifact.setSrc({ 
                bucket: 'apps',
                path: src,
             });
        } else {
            artifact = this.props.getArtifact(src);
        }
        return artifact;
    }

    /**
     * 
     */
    update = () => {
        return this.props.artifact.getFileAsJson('/module.json').then(module => {
            const childResource = module[this.props.resourceId];
            const artifact = this.getArtifact(this.props.resourceId, childResource);
            return artifact.getFileAsJson('/resource.json').then(childResourceClass => {
                this.setState({
                    module,
                    childResource,
                    childResourceClass,
                });
            });
        });
    }


    handleAddNewFunctionality = role => {
        const resourceJson = { ...this.props.resourceClass };
        if (!resourceJson.hasOwnProperty('dependencies')) return;
        const dependency = resourceJson.dependencies[this.props.dependencyName] || {};
        if (!dependency.hasOwnProperty('roles')) {
            dependency.roles = [];
        }
        if (!dependency.roles.includes(role)) dependency.roles.push(role);
        resourceJson.dependencies[this.props.dependencyName] = dependency;
        this.props.artifact.editFile('/resource.json', JSON.stringify(resourceJson, null, 2));
    }

    handleRemoveNewFunctionality = role => {
        const resourceJson = { ...this.props.resourceClass };
        if (!resourceJson.hasOwnProperty('dependencies')) return;
        const dependency = resourceJson.dependencies[this.props.dependencyName] || {};
        if (!dependency.hasOwnProperty('roles')) {
            dependency.roles = [];
        }
        dependency.roles = dependency.roles.filter(r => r !== role);
        resourceJson.dependencies[this.props.dependencyName] = dependency;
        this.props.artifact.editFile('/resource.json', JSON.stringify(resourceJson, null, 2));
    }

    /**
     * 
     * @returns 
     */
    render() {
        if (!this.state.childResourceClass) return <SystemLoading />;

        const { sx } = this.props;
        return (
            <Box
                sx={{
                    ...sx,
                    dislay: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                }}>

                {this.state.childResourceClass && Object.entries(this.state.childResourceClass.dependencies || {}).map(([name, dependency]) => (
                    <DependencyTile
                        name={name}
                        dependency={dependency}
                        value={<Box>sdfdsfs</Box>}
                    />

                ))}

            </Box>
        )
    }
}

export default chain(
    withContext(CacheContext)(({ getArtifact }) => ({ getArtifact })),
    withContext(ResourceContext)(({ artifact }) => ({ artifact })),
)(ModuleResourcePanel);

//<DragValue resourceId={this.props.resourceId} allowedServices={[dependency.service]} dependency={name} />