import { mdiCubeOutline, mdiLockOutline, mdiSquareRoundedOutline } from '@mdi/js';
import Icon from '@mdi/react';
import Box from '@mui/material/Box';
import React from 'react';
import { ArtifactBrowserEvents } from '../artifact/ArtifactBrowser';
import ArtifactContext from '../artifact/ArtifactContext';
import CacheContext from '../cache/CacheContext';
import Editor from '../editor/Editor';
import EditorProvider from '../editor/EditorProvider';
import ErrorHandlerContext from '../error/ErrorHandlerContext';
import ResizableLayout from '../layout/ResizableLayout';
import ResizableToggleIconButton from '../layout/ResizableToggleIconButton';
import ResourceContext from '../resource/ResourceContext';
import SystemContentEditable from '../system/SystemContentEditable';
import SystemDivider from '../system/SystemDivider';
import SystemLoading from '../system/SystemLoading';
import SystemPopupToolbar from '../system/SystemPopupToolbar';
import withContext, { chain } from '../utils/WithContext';
import CodeView from '../views/CodeView';
import ModuleView from '../views/ModuleView';
import AppArtifactBrowser from './AppArtifactBrowser';
import Dependencies from './Dependencies';
import ModuleResourcePanel from './ModuleResourcePanel';
import ServiceFunctionalitiesSelectMini from './ServiceFunctionalitiesSelectMini';
import EditorContext from '../editor/EditorContext';
import EditorArtifactFile from '../editor/EditorArtifactFile';


/**
 * select node and display node view (may be null);
 * a node = PREFIX:NAME
 * possible prefixes:
 *  - file: artifact file path, file is displayed in a view (in a file browser or as a module, or ...)
 *  - fold: artifact folder path. no view displayed for a folder (= null tab)
 *  - depd: dependency
 */
class Resource extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            // when mouting the loading state is set to true, until all needed resources are loaded
            loading: true,
            // the resource class (= the resource.json file content)
            resourceClass: null,
            // the service version (from the database)
            service: null,
            // the selected node from the left panel menu (ArtifactBrowser)
            selectedNodeId: null,
            // all nodes in order (the first one is the one to display, only nodes with a view are ordered) ([file:XXX, file:YYYY, ...])
            tabsIdsOrdered: [],
            // nodes split per view (code, module) 
            views: {},
            // the right tab name (resc:XXX or depd:XXXX)
            selectedRightTabId: null,
        };
        // the current displayed module ref (is null if not displayed)
        this.module = React.createRef();
        this.layout = this.props.forwardedRef || React.createRef();
    }

    /**
     * 
     */
    componentDidMount() {
        // get folder filnames
        this.props.artifact.getFolder('/').then(folder => {

            if (!folder.files.includes('resource.json')) {
                this.props.raiseError('MISSING FILE', 'Unable to open the resource. Missing "resource.json" file');
                return;
            }

            // listen resource.json file.
            this.props.artifact.addFileChangeListener('/resource.json', event => {
                const resourceClass = JSON.parse(event.value);
                this.getServiceFromResourceClass(resourceClass);
            });

            // get resource.json file
            this.props.artifact.getFile('/resource.json').then(value => {
                const resourceClass = JSON.parse(value);
                this.getServiceFromResourceClass(resourceClass).then(() => {
                    // select the default file to open.
                    /*const main = this.state.service.resource.main;
                    if (main && folder.files.includes(main)) {
                        return `/${main}`;
                    } else {
                       
                    }*/

                     // auto detect
                     if (folder.files.includes('package.json')) {
                        return this.props.artifact.getFileAsJson('/package.json').then(pkg => {
                            // get the main file declared in the package.json file.
                            let main = pkg.main;
                            if (!main.startsWith('/')) main = `/${main}`;
                            return main;
                        });
                    } else {
                        // default file 
                        return '/resource.json';
                    }
                }).then(main => {
                    this.props.artifact.getFile(main).then(() => {
                        this.openFile(main);
                        this.setState({ loading: false });
                    });
                });
            });

        });
    }

    /**
     * Update the service in the state.
     * Return a promise
     * @param {*} event 
     */
    getServiceFromResourceClass = resourceClass => {
        const [serviceId, versionId] = resourceClass.service.split(':');
        return this.props.getService(serviceId, versionId || 'LATEST').then(service => {
            return new Promise((resolve) => this.setState({
                resourceClass,
                service,
            }, resolve));
        });
    }


    /**
     * 
     * @param {*} prefix 
     * @param {*} name 
     */
    setNode = (prefix, name) => {
        const node = `${prefix}:${name}`;
        return new Promise((resolve) => this.setState({
            selectedNodeId: node
        }, resolve));
    }

    /**
     * 
     * @param {*} prefix 
     * @param {*} name 
     */
    setTab = (prefix, name) => {
        const node = `${prefix}:${name}`;
        const state = {
            //selectedNodeId: node,
        };
        const view = this.getNodeView(node);
        // if the node as a view, if not do not open a tab
        if (view) {
            const tabsIdsOrdered = [...this.state.tabsIdsOrdered];
            // delete previous occurence if exists
            const indexOrdered = tabsIdsOrdered.indexOf(node);
            if (indexOrdered > 0) tabsIdsOrdered.splice(indexOrdered, 1);
            // set it at the beginning
            tabsIdsOrdered.unshift(node);
            state.tabsIdsOrdered = tabsIdsOrdered;

            const views = this.state.views;
            if (!views.hasOwnProperty(view)) views[view] = [];
            if (!views[view].includes(node)) views[view].push(node);
            state.views = views;
            return new Promise((resolve) => this.setState(state, resolve));
        } else {
            return Promise.resolve();
        }
    }

    /**
     * 
     * @param {*} prefix 
     * @param {*} name 
     * @returns 
     */
    setRightTab = (prefix, name) => {
        const node = `${prefix}:${name}`;
        return new Promise((resolve) => this.setState({
            selectedRightTabId: node
        }, resolve));
    }

    /**
     * 
     * @returns 
     */
    unsetRightTab = () => {
        return new Promise((resolve) => this.setState({
            selectedRightTabId: null
        }, resolve));
    }

    /**
     * 
     * @param {*} prefix 
     * @param {*} name 
     */
    unsetTab = (prefix, name) => {
        const node = `${prefix}:${name}`;
        const state = {};
        // delete from the ordered list
        const tabsIdsOrdered = [...this.state.tabsIdsOrdered];
        const indexOrdered = tabsIdsOrdered.indexOf(node);
        if (indexOrdered > -1) {
            tabsIdsOrdered.splice(indexOrdered, 1);
            if (tabsIdsOrdered.length == 0) tabsIdsOrdered.push('file:/resource.json');
            state.tabsIdsOrdered = tabsIdsOrdered;

            const views = { ...this.state.views };
            const view = this.getNodeView(node);
            if (views.hasOwnProperty(view) && views[view].includes(node)) {
                const index = views[view].indexOf(node);
                views[view].splice(index, 1);
                if (views[view].length == 0) delete views[view];
                state.views = views;
            }
        }
        return new Promise((resolve) => this.setState(state, resolve));
    }

    /**
     * 
     * @param {*} filepath 
     * @returns 
     */
    getNodeView = node => {
        if (node.startsWith('file:')) {
            const filepath = node.substring(5);
            const files = this.state.service.resource?.files;
            if (!files) return 'code';
            return files[filepath] || 'code';
        } else if (node.startsWith('depd:')) {
            return 'dependency';
        }
        return null;
    }

    /**
     * 
     * @param {*} text 
     */
    handleNameChange = text => {
        var resourceClass = this.state.resourceClass;
        resourceClass.name = text;
        this.props.artifact.editFile('/resource.json', JSON.stringify(resourceClass, null, 2));
    }

    /**
     * 
     * @param {*} text 
     */
    handleDescriptionChange = text => {
        var resourceClass = this.state.resourceClass;
        resourceClass.description = text;
        this.props.artifact.editFile('/resource.json', JSON.stringify(resourceClass, null, 2));
    }

    /**
     * 
     * @param {*} event 
     * @param {*} filepath 
     */
    handleFileTreeChange = (event, filepath) => {
        if (event === ArtifactBrowserEvents.FILE_SELECTED || event === ArtifactBrowserEvents.FILE_CREATED) {
            this.openFile(filepath);
        } else if (event === ArtifactBrowserEvents.FOLDER_SELECTED || event === ArtifactBrowserEvents.FOLDER_CREATED) {
            this.openFolder(filepath);
        } else if (event === ArtifactBrowserEvents.FILE_DELETED) {
            this.closeFile(filepath);
        } else if (event === ArtifactBrowserEvents.FOLDER_DELETED) {
            this.state.filepathsOrdered.filter(p => p.startsWith(filepath)).forEach(this.closeFile.bind(this));
            this.closeFolder(filepath);
        }
    }

    /**
     * ONLY FOR MODULE RESOURCES
     * @param {*} name 
     */
    handleSelectResource = name => {
        this.setNode('resc', name);
        this.setTab('file', '/module.json');
        this.setRightTab('resc', name).then(() => {
            this.layout.current.openRightPanel();
        });
    }

    /**
     * 
     * @param {*} event 
     */
    handleSelectDependency = name => {
        //this.setNode('depd', name);
        /*this.setNode('depd', name);
        this.setTab('depd', name);//.then(() => this.module.current.selectResource(name));
        this.setRightTab('depd', name).then(() => {
            this.layout.current.openRightPanel();
        });*/

        this.props.selectItem({
            type: 'Dependency',
            id: `depd:${name}`,
        });
        const tabId = `Dependency/depd:${name}`;
        this.props.openTab(tabId);
    }

    /**
     * 
     */
    handleCloseRightTab = () => {
        this.layout.current.closeRightPanel();
    }

    /**
     * 
     * @param {*} filepath 
     */
    openFolder = filepath => {
        this.setNode('fold', filepath);
        this.setTab('fold', filepath);
    }

    /**
     * 
     * @param {*} filepath 
     */
    closeFolder = filepath => {
        // TODO select the correct node
        this.unsetTab('fold', filepath);
    }

    /**
     * 
     * @param {*} filepath 
     */
    openFile = filepath => {
         this.props.selectItem({
            type: 'Files',
            id: `file:${filepath}`,
        });
        const tabId = `Files/file:${filepath}`;
        if (!this.props.getTab(tabId)) {
            if (this.state.service.serviceId === 'modules' && filepath === '/module.json') {
                this.props.createTab(tabId, new EditorArtifactFile(this.props.artifact, filepath, 'module'));
            } else {
                this.props.createTab(tabId, new EditorArtifactFile(this.props.artifact, filepath));
            }
        }
        this.props.openTab(tabId);
    }

    /**
     * 
     * @param {*} filepath 
     */
    closeFile = filepath => {
        // TODO select the correct node
        this.unsetTab('file', filepath);
    }

    /**
     * 
     */
    handleLayoutChange = () => {
        if (this.module.current) {
            this.module.current.computeDimensions();
        }
    }

    /**
     * 
     * @returns 
     */
    render() {

        if (this.state.loading) return <SystemLoading />;

        // the tab to display
        const selectedTabId = this.state.tabsIdsOrdered.length > 0 ? this.state.tabsIdsOrdered[0] : null;

        return <ResourceContext.Provider value={{
            artifact: this.props.artifact,
            resourceClass: this.state.resourceClass, // the resource.json
            service: this.state.service, // the service info (from the database)
        }}>
            
                <ResizableLayout
                    ref={this.layout}
                    onChange={this.handleLayoutChange.bind(this)}>

                    <Box sx={{
                        height: '100%',
                        minWidth: 280,
                        overflow: 'auto',
                    }}>

                        <Box sx={{
                            height: 48,
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center',
                            padding: '4px 10px',
                            borderBottom: '1px solid rgba(0,0,0,.04)',
                        }}>

                            <ResizableToggleIconButton sx={{ flexShrink: 0 }} />

                            <Box sx={{
                                flexShrink: 1,
                                flexGrow: 1,
                                display: 'flex',
                                gap: '2px',
                                alignItems: 'center',
                            }}>

                                <Icon style={{ marginLeft: '8px' }} path={this.state.service?.icon || mdiCubeOutline} size={.7} />

                                <SystemContentEditable
                                    sx={{
                                        flexShrink: 1,
                                        flexGrow: 1,
                                        fontWeight: 600,
                                        color: '#24292f',
                                    }}
                                    variant={'body'}
                                    onChange={this.handleNameChange.bind(this)}
                                >
                                    {this.state.resourceClass.name || "unnamed-resource"}
                                </SystemContentEditable>

                            </Box>
                            <Icon
                                style={{
                                    flexShrink: 0
                                }}
                                path={mdiLockOutline}
                                size={.6}
                            />
                        </Box>

                        <Box sx={{ padding: '8px 20px' }}>

                            <SystemContentEditable
                                variant={'body'}
                                sx={{
                                    minHeight: '62px',
                                    color: '#81848b',
                                }}
                                onChange={this.handleDescriptionChange.bind(this)}
                            >
                                {this.state.resourceClass.description}
                            </SystemContentEditable>

                            <SystemDivider />

                            <AppArtifactBrowser name={'Files'} artifact={this.props.artifact} />

                            <SystemDivider />

                            <Dependencies
                                service={this.state.service}
                                selected={(this.state.selectedNodeId && this.state.selectedNodeId.startsWith('depd:')) ? this.state.selectedNodeId.substring(5) : null}
                                onSelect={this.handleSelectDependency.bind(this)}
                            />

                            <SystemDivider />
                        </Box>

                    </Box>

                    <Box sx={{
                        width: '100%',
                        height: '100%',
                    }}>
                        <Editor views={{
                            'code': CodeView,
                            'module': ModuleView,
                            'dependency': CodeView,
                            //'service': ServiceView,
                        }} />
                    </Box>

                    <Box>dgdsds</Box>
                    {this.state.selectedRightTabId && <>
                        {this.state.selectedRightTabId.startsWith('depd:') && <>
                            <SystemPopupToolbar
                                icon={<Icon color={'#E91E63'} path={mdiSquareRoundedOutline} size={.7} />}
                                title={this.state.selectedRightTabId.substring(5)}
                                onClose={this.handleCloseRightTab.bind(this)} />
                            <Box sx={{
                                padding: '10px 24px',
                            }}>
                                <ServiceFunctionalitiesSelectMini
                                    dependencyName={this.state.selectedRightTabId.substring(5)}
                                />
                            </Box>
                        </>}
                        {this.state.selectedRightTabId.startsWith('resc:') && <>
                            <SystemPopupToolbar
                                icon={<Icon color={'#E91E63'} path={mdiSquareRoundedOutline} size={.7} />}
                                title={this.state.selectedRightTabId.substring(5)}
                                onClose={this.handleCloseRightTab.bind(this)} />
                            <Box sx={{
                                padding: '10px 24px',
                            }}>
                                <ModuleResourcePanel resourceId={this.state.selectedRightTabId.substring(5)} />
                            </Box>
                        </>
                        }
                    </>}

                </ResizableLayout>
        </ResourceContext.Provider>
    }
}

const ResourceWithContext = chain(
    withContext(ErrorHandlerContext)(({ raiseError }) => ({ raiseError })),
    withContext(CacheContext)(({ getService }) => ({ getService })),
    withContext(ArtifactContext)(({ artifact }) => ({ artifact })),
    withContext(EditorContext)(({
        selectedItem,
        selectItem,
        unselectItem,
        getTab,
        createTab,
        openTab,
        closeTab,
        deleteTab,
        getSelectedItemData
    }) => ({
        selectedItem,
        selectItem,
        unselectItem,
        getTab,
        createTab,
        openTab,
        closeTab,
        deleteTab,
        getSelectedItemData
    })),
)(Resource);


/**
 * Add a fwd ref
 */
export default React.forwardRef((props, ref) => <EditorProvider>
    <ResourceWithContext
    forwardedRef={ref}
    {...props}
/>
</EditorProvider>);


/*

TAGS

<Box sx={{
    margin: '4px 6px 16px 6px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
}}>
    <Box sx={{
        padding: '2px 8px 2px 8px',
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        borderRadius: '4px',
        cursor: 'default',
        backgroundColor: '#fcdae3'
    }}>
        <Box
            sx={{
                flexShrink: 0,
                display: 'flex',
                alignItems: 'center',
                width: '15px',
                justifyContent: 'center',
            }}>
            <Icon color={'#E91E63'} path={this.state.service.icon} size={.6} />
        </Box>

        <Typography
            variant={'body2'}
            sx={{
                fontWeight: 400,
                color: '#E91E63', //'#3d4047',
                flexGrow: 1,
            }}
        >
            {this.state.service.serviceId}
        </Typography>
    </Box>

    <Box sx={{
        padding: '2px 8px 2px 8px',
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        borderRadius: '4px',
        cursor: 'default',
        backgroundColor: '#fcf1da'
    }}>
        <Box
            sx={{
                flexShrink: 0,
                display: 'flex',
                alignItems: 'center',
                width: '15px',
                justifyContent: 'center',
            }}>
            <Icon color={'#9a7e42'} path={mdiLanguageJavascript} size={.6} />
        </Box>

        <Typography
            variant={'body2'}
            sx={{
                fontWeight: 400,
                color: '#9a7e42', //'#3d4047',
                flexGrow: 1,
            }}
        >
            {'Javascript'}
        </Typography>
    </Box>
</Box>

*/



/**
 * 
 

{false && <Box sx={{
                        position: 'relative',
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>

                        <SystemTitle
                            title={selectedTabId.substring(5)}
                            icon={getFileIcon(selectedTabId.substring(5))}
                        />

                        {Object.entries(this.state.views).map(([view, nodes]) => {
                            const display = nodes.includes(selectedTabId);

                            if (view === 'code') {
                                return <Box sx={{
                                    display: display ? 'flex' : 'none',
                                    flexGrow: 1,
                                }}>
                                    <ArtifactEditor filepath={display ? selectedTabId.substring(5) : null}>
                                        {nodes.map(node => (
                                            <ArtifactEditorFile
                                                key={node}
                                                filepath={node.substring(5)}
                                            />
                                        ))}
                                    </ArtifactEditor>
                                </Box>
                            }

                            else if (view === 'module') {
                                return nodes.map(node => <Box
                                    key={node.substring(5)}
                                    sx={{
                                        display: (selectedTabId == node) ? 'flex' : 'none',
                                        flexGrow: 1,
                                    }}>
                                    <ModuleView
                                        ref={(selectedTabId == node) ? this.module : null}
                                        filepath={node.substring(5)}
                                    />
                                </Box>)
                            }

                            else if (view === 'dependency') {
                                return nodes.map(node => <Box
                                    key={node.substring(5)}
                                    sx={{
                                        display: (selectedTabId == node) ? 'flex' : 'none',
                                        flexGrow: 1,
                                    }}>
                                    <ServiceFunctionalitiesSelectMini
                                        dependencyName={node.substring(5)}
                                    />
                                </Box>)
                            }

                        })}

                        <Box sx={{
                            borderTop: '1px solid #f4f4f4',
                            minHeight: 20
                        }}>

                        </Box>
                    </Box>}

 */