
import { Box, CircularProgress } from '@mui/material';
import * as React from 'react';


class SystemLoading extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <Box
            ref={this.props.forwardedRef}
            sx={{
                ...this.props.sx,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <CircularProgress sx={{ color: '#00000055' }} size={25} thickness={6} />
        </Box>
    }
}

/**
 * Add a fwd ref
 */
export default React.forwardRef((props, ref) => <SystemLoading
    forwardedRef={ref}
    {...props}
/>);