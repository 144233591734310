import { InputBase } from '@mui/material';
import { styled } from '@mui/system';

export default styled(InputBase)(({ theme }) => ({
    padding: '0px 4px',
    //background: '#FFF',
    background: 'rgba(255,255,255, .15)',
    border: '1px solid rgba(0,0,0,.06)',
    color: '#000',
    fontSize: 12,
    '&.Mui-focused': {
        outline: '1px solid blue'
    }
}));