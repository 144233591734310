import Box from '@mui/material/Box';
import React from 'react';
import CacheContext from '../cache/CacheContext';
import ErrorHandlerContext from '../error/ErrorHandlerContext';
import SystemTitle from '../system/SystemTitle';
import withContext, { chain } from '../utils/WithContext';
import EditorContext from './EditorContext.js';
import ResizableToggleIconButton from '../layout/ResizableToggleIconButton.js';
import ResizeLayoutContext from '../layout/ResizeLayoutContext.js';

/**
 * select node and display node view (may be null);
 * a node = PREFIX:NAME
 * possible prefixes:
 *  - file: artifact file path, file is displayed in a view (in a file browser or as a module, or ...)
 *  - fold: artifact folder path. no view displayed for a folder (= null tab)
 *  - depd: dependency
 */
class Editor extends React.Component {

    constructor(props) {
        super(props);
    }

    /**
     * 
     * @returns 
     */
    getViews = () => {
        if (!this.props.tabsIds) return {};
        const views = {};
        this.props.tabsIds.forEach(id => {
            const view = this.props.getTab(id).getView();
            if (!views.hasOwnProperty(view)) views[view] = [];
            if (!views[view].includes(id)) views[view].push(id);
        });
        return views;
    }

    /**
     * 
     * @returns 
     */
    render() {
        const selectedTabId = this.props.tabsIdsOrdered.length > 0 ? this.props.tabsIdsOrdered[0] : null;
        const selectedTab = selectedTabId ? this.props.getTab(selectedTabId) : null;
        const views = this.getViews();

        return <Box sx={{
            position: 'relative',
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        }}>

            {/* header */}
            <Box sx={{
                padding: '8px 10px', //top | right | bottom | left
                minHeight: '48px',
                color: '#000',
                borderBottom: '1px solid #f4f4f4',
                display: 'flex',
                alignItems: 'center',
                //justifyContent: 'center',
                gap: '8px',
            }}>
                {!this.props.displayLeftMenu && <ResizableToggleIconButton sx={{ flexShrink: 0 }} />}
                <SystemTitle
                sx={{flexGrow: 1}}
                    icon={selectedTab ? selectedTab.getIcon() : null}
                    title={selectedTab ? selectedTab.getTitle() : null}
                />
            </Box>
            

            {/* files edited with the correct file editor - only one is displayed */}
            {Object.entries(views).map(([view, tabs]) => {
                var TabsView = this.props.views[view];
                const display = tabs.includes(selectedTabId);
                return <TabsView
                    selectedTabId={display ? selectedTabId : undefined}
                    tabsIds={tabs}
                />
            })}

            {/* footer */}
            <Box sx={{
                borderTop: '1px solid #f4f4f4',
                minHeight: 20
            }}>

            </Box>
        </Box>
    }
}

export default chain(
    withContext(ErrorHandlerContext)(({ raiseError }) => ({ raiseError })),
    withContext(CacheContext)(({ getArtifact, getService }) => ({ getArtifact, getService })),
    withContext(EditorContext)(({ selectedNodeId, tabsIds, tabsIdsOrdered, getTab }) => ({ selectedNodeId, tabsIds, tabsIdsOrdered, getTab })),
    withContext(ResizeLayoutContext)(({ displayLeftMenu }) => ({ displayLeftMenu })),
)(Editor);


/*

 /*if (view === 'code') {
                    //"gcp/file:file:/service/Service.js"

                    const [ artifactName ] = selectedTabId ? selectedTabId.split('/') : [];
                    const filepath = selectedTabId ? selectedTabId.split(':')[2] : null;

                    return <Box sx={{
                        display: display ? 'flex' : 'none',
                        flexGrow: 1,
                    }}>
                        <ArtifactEditor 
                            filepath={display ? filepath : null}
                            artifact={(display && artifactName) ? this.props.getArtifact('file:' + artifactName) : null }
                            >
                            {nodes.map(node => (
                                <ArtifactEditorFile
                                    key={node}
                                    filepath={filepath}
                                />
                            ))}
                        </ArtifactEditor>
                    </Box>
                }*/

/*if (view === 'module') {
    return nodes.map(node => <Box
        key={node.substring(5)}
        sx={{
            display: (selectedTabId == node) ? 'flex' : 'none',
            flexGrow: 1,
        }}>
        <ModuleView
            ref={(selectedTabId == node) ? this.module : null}
            filepath={node.substring(5)}
        />
    </Box>)
}*/

/* DEPENDENCY EDITOR */
/*else if (view === 'dependency') {
    return nodes.map(node => <Box
        key={node.substring(5)}
        sx={{
            display: (selectedTabId == node) ? 'flex' : 'none',
            flexGrow: 1,
        }}>
        <ServiceFunctionalitiesSelectMini
            dependencyName={node.substring(5)}
        />
    </Box>)
}*/
