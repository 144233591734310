
import { Box } from '@mui/material';
import * as React from 'react';
import withContext, { chain } from '../utils/WithContext';
import Resizable from './Resizable.js';
import ResizeLayoutContext from './ResizeLayoutContext.js';
import ResizeLayoutProvider from './ResizableLayoutProvider.js';


/**
 * 
 */
class ResizableLayout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            initialized: false
        }

        this.element = React.createRef();

        this.leftWidth = this.props.leftWidth ?? 400;
        this.rightWidth = 0;

        this.draggingSide = null;
        this.draggingState = 'stop';

    }

    /**
     * 
     */
    componentDidMount() {
        window.addEventListener('resize', () => {
            this.computeDimensions({ resize: true });
        }, true);
        this.computeDimensions();
        this.setState({ initialized: true })
    }

    /**
     * 
     * @param {*} oldProps 
     */
    componentDidUpdate(oldProps) {
        /*if (this.props.displayLeftMenu != oldProps.displayLeftMenu) {
            if (!this.props.displayLeftMenu) {
                this.leftWidth = 0;
            } else {
                this.leftWidth = 400;
            }
        }*/
        this.computeDimensions({ resize: true });
    }



    handleLeftPanelChange = (width, dragging) => {
        this.leftWidth = width;
        this.draggingSide = 'left';
        this.draggingState = dragging;
        this.computeDimensions();
    }

    handleRightPanelChange = (width, dragging) => {
        this.rightWidth = width;
        this.computeDimensions({ dragging: dragging ? ('right-' + dragging) : null });
    }


    /*toggleLeftPanel = () => {
        debugger;
        const leftPanel = this.element.current.children[0];
        if (leftPanel.getBoundingClientRect().width == 0) {
            this.leftWidth = 400;
            this.computeDimensions({ resize: true });
        } else {
            this.leftWidth = 0;
            this.computeDimensions({ resize: true });
        }
    }*/

    openRightPanel = () => {
        if (this.rightWidth == 0) {
            this.rightWidth = (this.element.current.getBoundingClientRect().width - this.leftWidth) / 3;
            //this.element.current.children[2].setAttribute('init', false);
        }
        this.computeDimensions();
        //setTimeout(() => this.element.current.children[2].setAttribute('init', true), 500);
    }

    closeRightPanel = () => {
        this.rightWidth = 0;
        this.computeDimensions();
    }

    computeDimensions = params => {
        if (!this.element.current) return;

        const {  resize } = params || {};
        const dragging = this.draggingState !== 'stop' ? `${this.draggingSide}-${this.draggingState}` : null;

        // get the left and right panel elements
        const leftPanel = this.element.current.children[0];
        const rightPanel = this.element.current.children[2]; // may be null
        const rect = this.element.current.getBoundingClientRect();
        if (rect.width == 0 || rect.height == 0) return;

        // get the 3 panels widths
        const w = [];
        w[0] = this.leftWidth;
        w[1] = this.element.current.children[1].getBoundingClientRect().width;
        w[2] = rightPanel ? this.rightWidth : 0;

        // get the container width
        const paddings = 3 * 8; // remove the paddings
        const width = rect.width - paddings;

        // compute the width of the left panel first
        if (!this.props.displayLeftMenu && this.draggingState === 'stop') {
            w[0] = 0;
        } else if (w[0] <= 280 && this.props.displayLeftMenu && this.draggingState === 'stop') {
            w[0] = this.prevLeftWidth || 280;
        } else if (w[0] <= 24) {
            w[0] = 0;
        } else if ((dragging !== 'left-start' && dragging !== 'left-move') && w[0] <= 280) {
            w[0] = 280;
        } else if (w[0] >= 1200) {
            w[0] = 1200;
        }

        // compute the remaining available width
        let main = width - w[0];

        // when the remaining width is two small we try to reduce the left panel
        if (rightPanel && main <= 280 * 2) {
            main = 280 * 2;
            w[0] = width - 280 * 2;
            if (w[0] <= 280) {
                main = width;
                w[0] = 0;
            }
        }
        if (!rightPanel && main <= 280) {
            main = 280;
            w[0] = width - 280;
            if (w[0] <= 280) {
                main = width;
                w[0] = 0;
            }
        }

        if (w[0] === 0) main +=  8;
        this.element.current.style.paddingLeft = w[0] == 0 ? '0px' : '8px';

        w[1] = main - w[2];
        if (w[1] < 280) {
            w[1] = 280;
            w[2] = main - w[1];
        }
        if (w[2] < 40) {
            w[2] = 0;
            w[1] = main;
        } else if (!['right-start', 'right-move'].includes(dragging) && w[2] < 280) {
            w[2] = 280;
            w[1] = main - 280;
        } else if (w[2] >= 1000) {
            w[2] = 1000;
            w[1] = main - 1000;
        }

        if (this.leftWidth >= 280 && this.leftWidth <= 1200)
            this.prevLeftWidth = this.leftWidth;

        this.leftWidth = w[0];
        this.rightWidth = w[2];

        leftPanel.setAttribute('sticky', w[0] == 0);
        leftPanel.setAttribute('overlap', w[0] < 280);

        if (rightPanel) {
            rightPanel.setAttribute('sticky', w[2] == 0);
            rightPanel.setAttribute('overlap', w[2] < 280);
        }


        [0, 1, 2].forEach(i => {
            const childElement = this.element.current.children[i];
            if (childElement) {
                childElement.style.transition = (['left-start', 'left-move', 'right-start', 'right-move'].includes(dragging) || resize) ? 'none' : 'width .2s';
                childElement.style.width = w[i] + 'px';
                console.log(i, w[i]);
                const container = childElement.getElementsByClassName('container')[0];
                if (container) {
                    container.style.width = w[i] + 'px';
                }
            }
        });

        // close or open the left menu
        if (w[0] <= 24 && this.props.displayLeftMenu) {
            this.props.closeLeftMenu();
        }
        if (w[0] > 24 && !this.props.displayLeftMenu) {
            this.props.openLeftMenu();
        }

        if (this.props.onChange) this.props.onChange();
    }

    /**
     * 
     * @returns 
     */
    render() {
        const { children, sx, ...otherProps } = this.props;
        const arr = React.Children.toArray(children);
        const hasRightPanel = arr[2] != null;

        return <Box
            ref={this.element}
            {...otherProps}
            sx={{
                ...sx,
                width: '100%',
                height: '100%',
                minWidth: 480,
                position: 'relative',
                overflow: 'hidden',
                display: 'flex',
                gap: '8px',
                paddingBottom: '8px',
                paddingLeft: '8px',
            }}
        >
            {/* Left panel */}
            <Resizable
                side={'right'}
                sx={{
                    flexGrow: 0,
                    flexShrink: 0,
                    //width: 400,
                    backgroundColor: '#ffffffaa',
                    borderRadius: '5px',
                }}
                onSizeChange={this.handleLeftPanelChange.bind(this)}
            >
                <Box sx={{
                    minWidth: 280,
                    width: '100%',
                    height: '100%',
                    flexGrow: 0,
                    flexShrink: 0,
                }}>
                    {this.state.initialized && arr[0]}
                </Box>
            </Resizable>

            {/* Middle panel */}
            <Box sx={{
                height: '100%',
                overflow: 'hidden',
                backgroundColor: '#ffffff',
                borderRadius: '5px',
            }}>
                <Box sx={{
                    width: '100%',
                    minWidth: 280,
                    height: '100%',
                }}>
                    {this.state.initialized && arr[1]}
                </Box>
            </Box>

            {/* Right panel */}
            {hasRightPanel && <Resizable
                side={'left'}
                sx={{
                    flexGrow: 0,
                    flexShrink: 0,
                }}
                onSizeChange={this.handleRightPanelChange.bind(this)}
            >
                <Box sx={{
                    minWidth: 280,
                    overflow: 'hidden',
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#ffffffaa',
                    flexGrow: 0,
                    flexShrink: 0,
                    borderTopLeftRadius: '5px',
                    borderBottomLeftRadius: '5px',
                }}>
                    {this.state.initialized && arr[2]}
                </Box>
            </Resizable>}

        </Box>
    }
}

const ResizableLayoutWithContexts = chain(
    withContext(ResizeLayoutContext)(({ displayLeftMenu, openLeftMenu, closeLeftMenu }) => ({ displayLeftMenu, openLeftMenu, closeLeftMenu })),
)(ResizableLayout)

export default (props) => <ResizeLayoutProvider><ResizableLayoutWithContexts {...props} /></ResizeLayoutProvider>
