
import Icon from '@mdi/react';
import { Box } from '@mui/material';
import * as React from 'react';

/**
 * 
 */
class SystemIconButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mousedown: false,
        }
        this.element = this.props.forwardedRef || React.createRef();
    }

    componentDidMount() {
        this.element.current.addEventListener('mousedown', () => this.setState({
            mousedown: true,
        }));
        document.addEventListener('mouseup', () => this.setState({
            mousedown: false,
        }));
    }

    render() {
        const { sx, size, path, ...otherProps } = this.props;
        return <Box
            ref={this.element}
            {...otherProps}
            tabIndex={0}
            //disableRipple
            //disableTouchRipple
            sx={{
                ...sx,
                cursor: 'pointer',
                height: '26px',
                display: 'flex',
                alignItems: 'center',
                padding: '4px',
                borderRadius: '40px',
                lineHeight: 0,
                borderRadius: '4px',
                transition: 'background-color .2s',
                //boxSizing: 'border-box',
                backgroundColor: this.state.mousedown ? 'rgba(0,0,50,.03)' : 'rgba(0,0,50,.04)',
                outline: this.state.mousedown ? '1px solid rgb(15,135,255)' : 0, //rgb(15, 135, 255)
                '&:hover': {
                    backgroundColor: this.state.mousedown ? 'rgba(0,0,50,.03)' : 'rgba(0,0,50,.08)',
                },
                //border: '1px solid rgba(0,0,0,.01)',
            }}
        >
            <Icon className={'icon'} path={path} size={.8} />
        </Box>
    }
}

/**
 * Add a fwd ref
 */
export default React.forwardRef((props, ref) => <SystemIconButton
    forwardedRef={ref}
    {...props}
/>);