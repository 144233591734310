
import { mdiAlert } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Typography } from '@mui/material';
import * as React from 'react';
import SystemButton from '../system/SystemButton';
import ErrorHandlerContext from './ErrorHandlerContext';


class ErrorHandlerProvider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type: null,
            name: null,
            details: null,
        }
    }

    /**
     * 
     * @param {*} name 
     * @param {*} details 
     */
    raiseError = (name, details) => {
        this.setState({
            type: 'ERROR',
            name,
            details
        });
    }

    render() {
        return <ErrorHandlerContext.Provider value={{
            raiseError: this.raiseError.bind(this)
        }}>
            {this.props.children}
            
            {this.state.type === 'ERROR' && <Box
                onClick={event => event.stopPropagation()}
                sx={{
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0,0,0,.6)',
                    zIndex: 1300,
                }}>
                <Box sx={{
                    width: '50%',
                    maxWidth: 700,
                    minHeight: 300,
                    padding: '24px',
                    backgroundColor: '#ffb256',
                    color: '#10151d',
                    borderRadius: '8px',
                    border: '4px solid #000',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px',
                    }}>
                        <Icon path={mdiAlert} size={1.4} />
                        <Typography sx={{ fontWeight: 800 }} variant={'h1'}>{this.state.name}</Typography>
                    </Box>
                    <Box sx={{
                        borderTop: '1px solid #000',
                        padding: '16px 0',
                        flexGrow: 1,
                    }}>
                        {this.state.details}
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px',
                    }}>
                        <Box sx={{ flexGrow: 1 }}></Box>
                        <SystemButton>{'RELOAD'}</SystemButton>
                    </Box>
                </Box>
            </Box>}
        </ErrorHandlerContext.Provider>
    }
}

export default ErrorHandlerProvider;