/**
 * 
 * 
 *
 */
export default class MouseDownGestureListener {

    constructor(element) {
        this.element = element;
        this.isListening = false;
        this.listeners = [];
        this.trigger = event => {
            let taken = false;
            this.listeners.forEach(listener => taken |= listener(event));
            if (taken) {
                event.stopPropagation();
                event.preventDefault();
             } // first element to take it keep it, there is no propagation to the parent !
        }
    }

    /**
     * 
     */
    listenMouseEvents() {
        this.isListening = true;
        this.element.addEventListener('mousedown', this.trigger);
    }

    /**
     * 
     */
    unlistenMouseEvents() {
        this.isListening = false;
        this.element.removeEventListener('mousedown', this.trigger);
    }

    /**
     * 
     * @param {*} start 
     * @param {*} cancel 
     * @returns 
     */
    condition(start, cancel) {
        return this.onDrag(start, cancel)
    }

    /**
     * 
     * @param {*} startCondition 
     * @returns 
     */
    condition(startCondition) {
        let noop = () => {};
        let cancelCondition = null;
        let start = noop, move = noop, stop = noop, cancel = noop;
        let raf = null;
        
        let startListener = (event) => {
            if (!startCondition || !startCondition({event, duration: 0, distance: 0, deltaX: 0, deltaY: 0})) return false;
            var ms = new Date();
            var clientX = event.clientX;
            var clientY = event.clientY;

            let moveListener = (event) => {
                let deltaX = event.clientX - clientX;
                let deltaY = event.clientY - clientY;
                let duration = new Date() - ms;
                let distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                if (cancelCondition != null && cancelCondition({event, duration, distance, deltaX, deltaY})) {
                    window.removeEventListener('mousemove', moveListener);
                    window.removeEventListener('mouseup', upListener, { once: true });
                    if (this.isListening) cancel({cancelled: true, event, duration, distance, deltaX, deltaY});
                } else {
                    if (this.isListening) move({event, duration, distance, deltaX, deltaY});
                }
                raf = null;
            }
            
            let upListener = (event) => {
                let deltaX = event.clientX - clientX;
                let deltaY = event.clientY - clientY;
                let duration = new Date() - ms;
                let distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                window.removeEventListener('mousemove', moveListener)
                if (cancelCondition != null && cancelCondition({event, duration, distance, deltaX, deltaY})) { 
                    cancel({cancelled: true, event, duration, distance, deltaX, deltaY}); 
                    return;
                }
                if (this.isListening) stop({cancelled: false, event, duration, distance, deltaX, deltaY});
            }
            window.addEventListener('mousemove', moveListener);
            window.addEventListener('mouseup', upListener, { once: true });

            start({event});
            return true;
        }
        
        this.listeners.push(startListener);

        var o = {
            cancelWhen: (fn) => { cancelCondition = fn; return o; },
            onStart: (fn) => { start = fn; return o; },
            onMove: (fn) => { move = fn; return o; },
            onStop: (fn) => { stop = fn; return o; },
            onCancel: (fn) => { cancel = fn; return o; },
        }

        return o;
    }
}