import { Avatar, CircularProgress, Divider, FormControl, FormControlLabel, InputAdornment, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { createService } from '../BackendFunctions';
import Guillaume from '../component/guillaume.jpeg';
import Button from '../system/Button';
import Input from '../system/Input';
import InputSection from '../system/InputSection';
import TitledBox from '../system/TitledBox';

const styles = (theme) => ({
    main: {
        background: '#FFF',
        marginTop: 30,
        padding: 40,
        paddingTop: 30,
        paddingBottom: 40,
        maxWidth: 900,
        margin: 'auto',
    }
});


class PageCreateService extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: null,
        }
    }

    onChange = (field) => (event) => {
        this.setState({
            [field]: event.target.value
        })
    }

    create() {
        this.setState({
            creating: true
        });
        createService(this.state).then(service => {
            this.setState({
                creating: false
            });
            this.props.navigate(`/services/${service.id}`);
        }).catch(err => {
            this.setState({
                creating: false
            });
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <TitledBox
                className={classes.main}
                primary
                name='Create a new service'
                description='A service has to deployed on an env.'>

                <Divider sx={{ mb: 2 }} />

                <InputSection sx={{ mb: 2 }} name='Service owner'>
                    <Input
                        readOnly
                        sx={{ width: '300px', fontWeight: 500, backgroundColor: 'transparent' }}
                        value={"Guillaume"}
                        startAdornment={<InputAdornment position="start">
                            <Avatar sx={{ width: 28, height: 28 }} alt="Remy Sharp" src={Guillaume} />
                        </InputAdornment>}
                    >
                    </Input>
                </InputSection>

                <Divider sx={{ mt: 2, mb: 2 }} />

                <InputSection mandatory sx={{ mb: 2, width: '300px' }} name='Service name'>
                    <Input sx={{ width: '100%' }} readOnly={this.state.creating} onChange={this.onChange('id').bind(this)} />
                </InputSection>

                <Divider sx={{ mt: 2, mb: 2 }} />

                <InputSection sx={{ width: '100%' }} name="Roles (separated by a ,) ex for buckets: writer,reader" >
                    <Input sx={{ width: '100%' }} readOnly={this.state.creating} onChange={this.onChange('roles').bind(this)} />
                </InputSection>

                {this.state.creating ? <CircularProgress sx={{ ml: 2 }} size={25} thickness={6} /> : <Button variant="contained" onClick={this.create.bind(this)}>Create Service</Button>}

            </TitledBox>

        );
    }
}

export default withStyles(styles)(PageCreateService);