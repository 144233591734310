import firebase from "firebase/app";
import "firebase/firestore";

export const FieldValue = {
    SERVER_TIMESTAMP: firebase.firestore.FieldValue.serverTimestamp,
}

export function collection(name) {
    return new Database().collection(name);
}

class Database {

    constructor() {
        let firebaseRef = firebase.firestore();

        this.collection = (name) => {
            return new Collection(firebaseRef, name);
        }
    }

}

class Document {

    constructor(parentRef, name) {
        let firebaseRef = parentRef.doc(name);

        this.collection = (name) => {
            return new Collection(firebaseRef, name);
        }

        this.get = () => {
            return new Promise((resolve, reject) => {
                firebaseRef.get()
                    .then(doc => resolve(new QueryDocumentSnapshot(doc)))
                    .catch(reject);
            });
        }

        this.onSnapshot = (callback) => {
            return firebaseRef.onSnapshot((doc) => {
                callback(new QueryDocumentSnapshot(doc))
            });
        }

        this.update = parentRef.update;
    }

}

class Collection {

    constructor(parentRef, name) {
        let firebaseRef = parentRef.collection(name);
        let withOrderRef = firebaseRef;

        this.doc = (name) => {
            return new Document(firebaseRef, name);
        }

        this.orderBy = (key, order) => {
            withOrderRef = withOrderRef.orderBy(key, order);
            return this;
        };

        this.onSnapshot = (callback) => {
            return withOrderRef.onSnapshot((qs) => {
                callback(new QuerySnapshot(qs))
            });
        }

        this.get = () => {
            return new Promise((resolve, reject) => {
                withOrderRef.get()
                    .then(qs => resolve(new QuerySnapshot(qs)))
                    .catch(reject);
            });
        }
    }

}

class Metadata {

    constructor(firebaseMetadata) {
        this.fromCache = firebaseMetadata.fromCache;
        this.hasPendingWrites = firebaseMetadata.hasPendingWrites;
    }
}

class QuerySnapshot {

    constructor(snapshot) {
        this.empty = snapshot.empty;
        this.metadata = new Metadata(snapshot.metadata);
        this.size = snapshot.size;
        this.docs = snapshot.docs.map(doc => new QueryDocumentSnapshot(doc));
    }

}

class QueryDocumentSnapshot {

    constructor(snapshot) {
        console.log(snapshot)
        this.exists = snapshot.exists;
        this.id = snapshot.id;
        this.metadata = new Metadata(snapshot.metadata);
        this.data = () => {
            return snapshot.data()
        };
    }

}