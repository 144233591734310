import { Box } from '@mui/material';
import React from 'react';
import withContext, { chain } from '../utils/WithContext';
import InfiniteContext from './InfiniteContext';
import InfiniteStaticElement from './InfiniteStaticElement';

/**
 * 
 */
class InfiniteSelecting extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.selectingRect) return <></>
        return <InfiniteStaticElement
            align={'top-left'}
            coord={{
                x: this.props.selectingRect.x,
                y: this.props.selectingRect.y,
            }}
            size={{
                w: this.props.selectingRect.w,
                h: this.props.selectingRect.h,
            }}
        >
            <Box sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: '#0047ff06', //'#00000011', //'#00AA0011',//'#0000FF11',
                border: '1px solid #0047ffAA',//#00000005',
            }} />
        </InfiniteStaticElement>
    }
}

export default chain(
    withContext(InfiniteContext)(({ selectingRect }) => ({ selectingRect })),
)(InfiniteSelecting)