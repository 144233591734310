import { mdiArrowRightBold, mdiCircle, mdiClose, mdiCodeBraces, mdiContentSaveOutline, mdiCubeOutline, mdiFormatColorFill, mdiFunction, mdiHomeOutline, mdiInformationVariant, mdiJellyfishOutline, mdiLightningBolt, mdiSquareOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Avatar, CircularProgress, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import firebase from "firebase/app";
import 'firebase/firestore';
import React from 'react';
import uuid from 'react-uuid';
import { createServiceVersion } from '../BackendFunctions';
import ArtifactProvider from '../artifact/ArtifactProvider';
import AuthenticationContext from '../authentication/AuthenticationContext';
import CacheContext from '../cache/CacheContext';
import Guillaume from '../component/amadeus.png';
import ErrorHandlerContext from '../error/ErrorHandlerContext';
import { bucket, reference, uploadString } from '../sdk/Bucket';
import SystemAppBar from '../system/SystemAppBar';
import SystemButton from '../system/SystemButton';
import SystemContentEditable from '../system/SystemContentEditable';
import SystemIconButton from '../system/SystemIconButton';
import SystemLoading from '../system/SystemLoading';
import SystemPopper from '../system/SystemPopper';
import SystemTextInput from '../system/SystemTextInput';
import withContext, { chain } from '../utils/WithContext';
import ImplementationPanelComponent from './ImplementationPanelComponent';
import ServiceArtifact from './ServiceArtifact';
import ServiceContext from './ServiceContext';


/**
 * 
 */
class Service extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            // loading
            loading: true,
            // saving the service
            saving: false,
            // the loaded service (from db)
            service: null,
            // the opened artifacts
            openedSources: [],
            // the one selected
            selectedSource: null,
            // the updated artifacts
            updatedArtifacts: [],
            // popup open of not
            open: null,
        }

        this.changeId = uuid();

        // cache to avoid uploading several time the same file
        this.uploadCache = {};

        this.wheelListener = null;

        this.versionListener = (version, name) => {
            const updatedArtifacts = [...this.state.updatedArtifacts];
            if (!updatedArtifacts.includes(name)) {
                updatedArtifacts.push(name);
                this.setState({
                    updatedArtifacts
                });
            }
        }

        // refs
        this.tabs = React.createRef();
        this.descriptionButton = React.createRef();
        this.implementationButton = React.createRef();
    }

    componentDidMount() {
        // preload services
        this.props.getServices();

        // listen to host changes
        const db = firebase.firestore();

        // todo use the API
        db.collection("services").doc(this.props.serviceId).get().then(doc => {
            var service = doc.data().version;
            this.setState({
                loading: false,
                service
            });
        });

        this.unsubscribeServiceListener = db.collection("services").doc(this.props.serviceId).onSnapshot(doc => {
            // TODO when the service is updated 
        });
    }

    componentDidUpdate(oldProps, oldState) {
        // listen version changed on artifacts and unlisten for closed ones
        const prevArtifacts = oldState.openedSources;
        const nextArtifacts = this.state.openedSources;
        nextArtifacts.filter(a => !prevArtifacts.includes(a)).forEach(a => this.getArtifact(a).addVersionChangeListener(this.versionListener));
        prevArtifacts.filter(a => !nextArtifacts.includes(a)).forEach(a => this.getArtifact(a).removeVersionChangeListener(this.versionListener));

        // listen the wheel event for the tab component.
        if (!this.state.loading && !this.wheelListener) {
            this.wheelListener = this.tabs.current.addEventListener("wheel", this.handleWheelEvent.bind(this));
        }
    }

    /**
     * 
     * @param {*} icon 
     * @returns 
     */
    setIcon = icon => {
        const service = { ...this.state.service };
        service.icon = icon;
        return new Promise(resolve => this.setState({ service }, resolve));
    }

    /**
     * 
     * @param {*} description 
     * @returns 
     */
    setDescription = description => {
        const service = { ...this.state.service };
        service.description = description;
        return new Promise(resolve => this.setState({ service }, resolve));
    }
    
    /**
     * 
     * @param {*} service 
     * @returns 
     */
    addSource = (name, artifactName) => {
        const service = { ...this.state.service };
        if (!service.hasOwnProperty('sources')) service.sources = {};
        service.sources[name] = artifactName;
        return new Promise(resolve => this.setState({ service }, resolve));
    }

    /**
     * 
     * @param {*} name 
     * @returns 
     */
    removeSource = name => {
        if (!this.state.service.hasOwnProperty('sources')) return;
        const service = { ...this.state.service };
        delete service.sources[name];
        return new Promise(resolve => this.setState({ service }, resolve));
    }

    /**
     * 
     * @param {*} name 
     * @param {*} action 
     * @returns 
     */
    setAction = (name, action) => {
        const service = { ...this.state.service };
        if (!service.hasOwnProperty('actions')) service.actions = {};
        service.actions[name] = action;
        return new Promise(resolve => this.setState({ service }, resolve));
    }

    /**
     * 
     * @param {*} param0 
     */
    handleWheelEvent = ({ deltaX, deltaY }) => {
        const startX = this.tabs.current.scrollLeft;
        this.tabs.current.scroll({
            top: 0,
            left: startX + 4 * deltaY,
            behavior: "smooth",
        });
    }

    /**
     * 
     */
    handleClickHome = () => {
        this.setState({
            selectedSource: null,
        });
    }

    /**
     * 
     * @param {*} sourceName 
     * @returns 
     */
    getArtifact = sourceName => {
        const artifactName = `file:${sourceName}`;
        if (!this.props.existsArtifact(artifactName)) {
            const artifact = this.props.createArtifact(artifactName);
            const src = this.state.service.sources[sourceName];
            if (src) {
                artifact.setSrc({
                    bucket: 'services',
                    path: src,
                });
            }
        }
        return this.props.getArtifact(artifactName);
    }

    /**
     * 
     * @param {*} sourceName 
     * @returns 
     */
    existsArtifact = sourceName => {
        return this.state.service
            && this.state.service.sources
            && this.state.service.sources.hasOwnProperty(sourceName);
    }

    /**
     * 
     * @param {*} sourceName 
     * @returns 
     */
    openArtifact = sourceName => {
        var currentIndex = this.state.openedSources.indexOf(sourceName);
        var openedSources = [...this.state.openedSources];
        let state;
        if (currentIndex == -1) {
            openedSources.push(sourceName);
            state = {
                openedSources,
                selectedSource: sourceName,
            };
        } else {
            state = {
                selectedSource: sourceName,
            };
        }
        return new Promise(resolve => this.setState(state, resolve));
    }

    /**
     * 
     * @param {*} artifact 
     * @returns 
     */
    closeArtifact = sourceName => {
        let openedSources = [...this.state.openedSources];
        const currentIndex = openedSources.indexOf(sourceName);
        if (currentIndex >= 0) {
            openedSources.splice(currentIndex, 1);
            let selectedSource = this.state.selectedSource;
            if (selectedSource === sourceName) {
                selectedSource = openedSources[0];
            }
            return new Promise(resolve => this.setState({
                openedSources,
                selectedSource,
            }, resolve));
        } else {
            return Promise.resolve();
        }
    }

    /**
     * 
     * @param {*} sourceName 
     * @returns 
     */
    isArtifactCreated = sourceName => {
        return this.getArtifact(sourceName).lastSavedVersion() === null;
    }

    /**
     * 
     * @param {*} sourceName 
     * @returns 
     */
    isArtifactUpdated = sourceName => {
        return this.getArtifact(sourceName).lastSavedVersion() !== null && !this.getArtifact(sourceName).getSrc();
    }

    /**
     * 
     * @param {*} sourceName 
     * @returns 
     */
    isArtifactDeleted = sourceName => {
        const artifactName = `file:${sourceName}`;
        return !this.existsArtifact(sourceName) && this.props.existsArtifact(artifactName);
    }

    /**
     * 
     * @param {*} name 
     * @param {*} open 
     */
    open = (name, open) => {
        const value = this.state.open;
        if (!open && name === value) {
            this.setState({ open: null })
        } else if (open) {
            this.setState({ open: name })
        }
    }

    /**
     * 
     * @returns 
     */
    save = () => {
        if (this.state.saving) return; // no save in //
        this.setState({
            saving: true,
        });
        // 1. upload added and modified files
        const uploadBucket = bucket('blueforge-322008.appspot.com');
        const userAppFolder = `${this.props.user.uid}/services/${this.props.serviceId}/${this.changeId}`;
        const manifest = { id: this.changeId, artifacts: {} };
        let artifactVersions = {};
        const promises = [];

        const sources = this.state.service.sources || {};

        Object.entries(sources)
            .forEach(([sourceName, artifactName]) => {

                //const sourceName = artifact.name;
                const artifact = this.getArtifact(sourceName);

                var changes = artifact.getAllChanges();
                artifactVersions[sourceName] = [artifact, artifact.getVersion()];

                var upload = filePath => {
                    var storagePath = `${userAppFolder}/${sourceName}${filePath}`;
                    var version = artifact.getLocalFileVersion(filePath);

                    // check if the file have been already uploaded
                    var doUpload = (version == null)
                        || !this.uploadCache.hasOwnProperty(storagePath)
                        || (this.uploadCache[storagePath] != version);

                    if (doUpload) {
                        var text = artifact.getLocalFile(filePath);
                        if (text === null) {
                            throw new Error('Trying to upload a file not stored locally.');
                        }
                        promises.push(uploadString(reference(uploadBucket, storagePath), text, 'raw')
                            .then(() => {
                                this.uploadCache[storagePath] = version;
                            }));
                    } else {
                        console.log('Upload skipped', filePath, version, this.uploadCache[storagePath])
                    }
                }
                // upload created and modified files if any
                changes.createdFiles?.forEach(upload);
                changes.modifiedFiles?.forEach(upload);

                // get source name from artifactname
                const json = { ...changes };
                const service = artifact.getSrc(0); // remote resource
                if (service) {
                    if (service.bucket !== 'services') throw new Error('Only service artifact are allowed.');
                    json.from = service.path;
                }
                manifest.artifacts[sourceName] = json;

            });

        return Promise.all(promises).then(() => {
            // compare the version of all artifacts uploaded to verify there has not been any change during the upload
            // if so we cannot trust the version sent
            artifactVersions = Object.fromEntries(Object.entries(artifactVersions).filter(([sourceName, [artifact, version]]) => {
                var curr = artifact.getVersion();
                return curr == version;
            }));

            console.log('%c------ Change Manifest start -------', 'background: #222; color: #bada55');
            console.log('%c' + JSON.stringify(manifest, null, 2), 'color: #7ee6ec');
            console.log('%c------ Change Manifest end -------', 'background: #222; color: #bada55');

            return createServiceVersion(this.props.serviceId, {
                description: this.state.description || "no description",
                icon: this.state.icon || mdiFunction,
                color: this.state.color || '#F80',
                manifest
            }).then(result => {
                const artifacts = result.artifacts || {};
                const promises = Object.entries(artifacts).map(([name, src]) => {
                    const artifact = this.getArtifact(name);
                    return new Promise(resolve => setTimeout(() => {
                        artifact.setSrc({
                            bucket: 'services',
                            path: src,
                        });
                        this.forceUpdate(resolve);
                    }, Math.round(Math.random() * 1000)));
                });
                return Promise.all(promises).then(() => {
                    this.setState({
                        saving: false
                    });
                    console.log('New version created', JSON.stringify(result, null, 2));
                });
            });
        });
    }

    render() {
        return <ServiceContext.Provider value={{
            service: this.state.service,
            addSource: this.addSource.bind(this),
            removeSource: this.removeSource.bind(this),
            setAction: this.setAction.bind(this),
            setIcon: this.setIcon.bind(this),
            setDescription: this.setDescription.bind(this),
            isArtifactCreated: this.isArtifactCreated.bind(this),
            isArtifactUpdated: this.isArtifactUpdated.bind(this),
            isArtifactDeleted: this.isArtifactDeleted.bind(this),
            getArtifact: this.getArtifact.bind(this),
            existsArtifact: this.existsArtifact.bind(this),
            openArtifact: this.openArtifact.bind(this),
            closeArtifact: this.closeArtifact.bind(this),
        }}>

            <Box sx={{
                minWidth: 600,
                height: '100%',
                backgroundColor: '#d1eaf4', //'#ebeff5', //'#edf2fa',
                display: 'flex',
                flexDirection: 'column',
            }}>

                {this.state.loading && <SystemLoading />}

                {!this.state.loading && (
                    <>
                        <SystemAppBar sx={{
                            flexGrow: 0,
                            flexShrink: 0,
                        }}>

                            <Box sx={{
                                display: 'flex',
                                gap: '8px',
                            }}>

                                {/* Logo */}
                                <Box sx={{
                                    flexShrink: 0,
                                    //marginLeft: '8px',
                                    padding: '0px 8px',
                                    height: '34px',
                                    borderRadius: '5px',
                                    color: '#333',
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    transition: 'all .2s',
                                    '&:hover': {
                                        backgroundColor: '#ffffff',
                                        color: '#000',
                                        paddingTop: '2px',
                                    },
                                }}>
                                    <Icon path={mdiJellyfishOutline} size={1} />
                                </Box>

                                {/* Service name + buttons + popups */}
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                }}>
                                    <Box
                                        onClick={this.handleClickHome.bind(this)}
                                        sx={{
                                            display: 'flex',
                                            padding: '0px 14px 0 10px',
                                            height: '30px',
                                            alignItems: 'center',
                                            gap: '3px',
                                            borderRadius: '17px',
                                            color: '#000',
                                            cursor: 'pointer',
                                            //backgroundColor: this.state.selectedSource ? 'transparent' : '#FFFFFF',
                                            /*'&:hover': {
                                                backgroundColor: '#FFFFFF',
                                            }*/
                                        }}>
                                        <Icon style={{ color: '#e91e63' }} path={this.state.service.icon || mdiSquareOutline} size={.8} />
                                        <Typography variant={'h6'} sx={{ fontWeight: 500 }}>
                                            {this.state.service.serviceId}
                                        </Typography>
                                    </Box>


                                    {/* Butttons */}
                                    <SystemIconButton ref={this.descriptionButton} path={mdiInformationVariant} onClick={() => this.open('description', true)} />
                                    <SystemIconButton path={mdiArrowRightBold} />
                                    <SystemIconButton path={mdiLightningBolt} />
                                    <SystemIconButton ref={this.implementationButton} path={mdiCodeBraces} onClick={() => this.open('implementations', true)} />

                                    <SystemPopper
                                        anchorEl={this.descriptionButton.current}
                                        onClickAway={() => this.open('description', false)}
                                        open={this.state.open === 'description'}
                                        offset={8}
                                        sx={{
                                            borderRadius: '4px',
                                            backgroundColor: '#cae1ec',
                                            border: '1px solid rgba(0,0,0,.005)',
                                            marginTop: this.state.open ? 0 : '-10px',
                                            transition: 'margin .2s',
                                            padding: '16px 24px 24px 24px',
                                        }}
                                    >
                                        <Box sx={{
                                            width: '360px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '24px'
                                        }}>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px',
                                            }}>
                                                <Typography variant={'body'} sx={{ fontWeight: 500, color: '#000' }} component={'p'}>Icon path</Typography>
                                                <Typography variant={'body'} sx={{ fontWeight: 400, color: '#666' }} component={'p'}>Please enter the path of an SVG icon.</Typography>
                                                <SystemTextInput startAdornment={this.state.service.icon || mdiSquareOutline} sx={{ backgroundColor: 'rgba(255,255,255,.6)' }} defaultValue={this.state.service.icon || mdiSquareOutline} />
                                            </Box>

                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px',
                                            }}>
                                                <Typography variant={'body'} sx={{ fontWeight: 500, color: '#000' }} component={'p'}>Resource color</Typography>
                                                <Typography variant={'body'} sx={{ fontWeight: 400, color: '#666' }} component={'p'}>Please enter the color of the resource.</Typography>
                                                <SystemTextInput startAdornment={mdiFormatColorFill} sx={{ backgroundColor: 'rgba(255,255,255,.6)' }} defaultValue={this.state.service.color || 'FF9955'} />
                                            </Box>


                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px',
                                            }}>
                                                <Typography variant={'body'} sx={{ fontWeight: 500, color: '#000' }} component={'p'}>Description</Typography>
                                                <Typography variant={'body'} sx={{ fontWeight: 400, color: '#666' }} component={'p'}>Please enter a short description of the service.</Typography>
                                                <SystemContentEditable sx={{ backgroundColor: 'rgba(255,255,255,.6)', height: 80 }}></SystemContentEditable>
                                            </Box>
                                        </Box>
                                    </SystemPopper>

                                    <SystemPopper
                                        anchorEl={this.implementationButton.current}
                                        onClickAway={() => this.open('implementations', false)}
                                        open={this.state.open === 'implementations'}
                                        offset={8}
                                        sx={{
                                            borderRadius: '4px',
                                            backgroundColor: '#cae1ec',
                                            border: '1px solid rgba(0,0,0,.005)',
                                            marginTop: this.state.open ? 0 : '-10px',
                                            transition: 'margin .2s',
                                            padding: '16px 24px 24px 24px',
                                        }}
                                    >
                                        <ImplementationPanelComponent />
                                    </SystemPopper>

                                </Box>
                            </Box>

                            <Box
                                ref={this.tabs}
                                sx={{
                                    marginLeft: '24px',
                                    marginRight: '24px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexGrow: 1,
                                    gap: '24px',
                                    borderRadius: '5px',
                                    padding: '6px 16px',
                                    overflow: 'hidden',
                                }}>

                                {this.state.openedSources.map(name => (
                                    <Box
                                        sx={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '0px 8px',
                                            height: '30px',
                                            gap: '4px',
                                            borderRadius: '4px',
                                            transition: 'background-color .2s',
                                            color: this.isArtifactCreated(name) ? '#53c079' : this.isArtifactUpdated(name) ? '#e5a748' : '#373737',
                                            backgroundColor: this.state.selectedSource === name ? '#ffffff' : 'transparent',
                                            '&:hover': {
                                                backgroundColor: '#FFFFFF',
                                            }
                                        }}
                                        onClick={() => this.openArtifact(name)}
                                    >
                                        <Icon style={{ flexShrink: 0 }} path={false ? mdiHomeOutline : mdiCubeOutline} size={.6} />
                                        <Typography variant={'body'} sx={{ fontWeight: 400 }}>{name}</Typography>
                                        {this.isArtifactUpdated(name) ? <Box
                                            sx={{
                                                borderRadius: '10px',
                                                cursor: 'pointer',
                                                '& .close': {
                                                    display: 'none',
                                                },
                                                '&:hover': {
                                                    backgroundColor: 'rgba(0,0,0,.1)',
                                                    '& .circle': {
                                                        display: 'none',
                                                    },
                                                    '& .close': {
                                                        display: 'block',
                                                    },
                                                }
                                            }}
                                            onClick={event => { this.closeArtifact(name); event.stopPropagation(); }}
                                        >
                                            <Icon className={'circle'} path={mdiCircle} style={{ color: this.isArtifactCreated(name) ? '#53c079' : '#e5a748' }} size={.5} />
                                            <Icon className={'close'} path={mdiClose} size={.5} />
                                        </Box> : <Box
                                            sx={{
                                                borderRadius: '10px',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(0,0,0,.1)',
                                                }
                                            }}
                                            onClick={event => { this.closeArtifact(name); event.stopPropagation(); }}
                                        >
                                            <Icon className={'close'} path={mdiClose} size={.5} />
                                        </Box>}
                                    </Box>
                                ))}
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '48px',
                            }}>
                                <Avatar sx={{ m: 'auto', width: 32, height: 32 }} alt="Remy Sharp" src={Guillaume} />

                                <SystemButton
                                    disabled={this.state.saving}
                                    onClick={this.save.bind(this)}>
                                    {this.state.saving ? <CircularProgress sx={{ color: '#fff' }} size={12} thickness={5} /> : <Icon style={{ flexShrink: 0 }} path={mdiContentSaveOutline} size={.6} />}
                                    {'SAVE'}
                                </SystemButton>
                            </Box>
                        </SystemAppBar>

                        <Box sx={{ flexGrow: 1, minHeight: 0, overflow: 'auto' }}>
                            {this.state.openedSources.map(name => (
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        display: this.state.selectedSource === name ? 'block' : 'none',
                                    }}>
                                    <ArtifactProvider artifact={this.getArtifact(name)}>
                                        <ServiceArtifact />
                                    </ArtifactProvider>
                                </Box>
                            ))}
                        </Box>
                    </>
                )}

            </Box>
        </ServiceContext.Provider>
    }
}

export default chain(
    withContext(ErrorHandlerContext)(({ raiseError }) => ({ raiseError })),
    withContext(CacheContext)(({ createArtifact, existsArtifact, getArtifact, getServices }) => ({ createArtifact, existsArtifact, getArtifact, getServices })),
    withContext(AuthenticationContext)(({ user }) => ({ user })),
)(Service);