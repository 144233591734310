import DoUndoString from './RedoUndoString';

class RedoUndo {

    /**
     * 
     * @param {*} object 
     * @param {*} listener 
     */
    constructor(object, listener) {
        this.doUndoString = new DoUndoString(JSON.stringify(object), listener);
    }

    /**
     * 
     * @returns 
     */
    get() {
        return JSON.parse(this.doUndoString.get())
    }


    /**
     * 
     * @param {*} fn 
     */
    edit(fn) {
        var str = this.doUndoString.get();
        var object = fn(JSON.parse(str));
        this.doUndoString.set(JSON.stringify(object));
    }

    /**
     * 
     * @returns 
     */
    undo() {
        var value = this.doUndoString.undo();
        return JSON.parse(value);
    }

    /**
     * 
     * @returns 
     */
    redo() {
        var value = this.doUndoString.redo();
        return JSON.parse(value);
    }

    /**
     * 
     * @returns 
     */
    isModified() {
        return this.doUndoString.isModified();
    }

}

export default RedoUndo;