import React from 'react';
import ArtifactBrowser, { ArtifactBrowserEvents } from '../artifact/ArtifactBrowser';
import CacheContext from '../cache/CacheContext';
import EditorArtifactFile from '../editor/EditorArtifactFile';
import EditorContext from '../editor/EditorContext';
import ErrorHandlerContext from '../error/ErrorHandlerContext';
import withContext, { chain } from '../utils/WithContext';

/**
 * select node and display node view (may be null);
 * a node = PREFIX:NAME
 * possible prefixes:
 *  - file: artifact file path, file is displayed in a view (in a file browser or as a module, or ...)
 *  - fold: artifact folder path. no view displayed for a folder (= null tab)
 *  - depd: dependency
 */
class AppArtifactBrowser extends React.Component {

    constructor(props) {
        super(props);
    }

    /**
     * 
     * @param {*} event 
     * @param {*} filepath 
     */
    handleFileTreeChange = (event, filepath) => {
        if (event === ArtifactBrowserEvents.FILE_SELECTED || event === ArtifactBrowserEvents.FILE_CREATED) {
            this.openFile(filepath);
        } else if (event === ArtifactBrowserEvents.FOLDER_SELECTED || event === ArtifactBrowserEvents.FOLDER_CREATED) {
            this.openFolder(filepath);
        } else if (event === ArtifactBrowserEvents.FILE_DELETED) {
            this.closeFile(filepath);
        } else if (event === ArtifactBrowserEvents.FOLDER_DELETED) {
            this.state.filepathsOrdered.filter(p => p.startsWith(filepath)).forEach(this.closeFile.bind(this));
            this.closeFolder(filepath);
        }
    }

    /**
     * 
     * @param {*} filepath 
     */
    openFolder = filepath => {
        this.props.selectItem({
            type: this.props.name,
            id: `fold:${filepath}`
        });
    }

    /**
     * 
     * @param {*} filepath 
     */
    closeFolder = filepath => {
        // TODO select the correct node
        //this.props.unsetTab(`${this.props.name}/folder:${filepath}`);
        const itemId = `fold:${filepath}`;
        if (this.props.selectedItem && this.props.selectedItem.type === this.props.name && this.props.selectedItem.id === itemId) {
            this.props.unselectItem();
        }
    }

    /**
     * 
     * @param {*} filepath 
     */
    openFile = filepath => {
        this.props.selectItem({
            type: this.props.name,
            id: `file:${filepath}`,
        });
        const tabId = `${this.props.name}/file:${filepath}`;
        if (!this.props.getTab(tabId)) {
            if (filepath === '/module.json')
                this.props.createTab(tabId, new EditorArtifactFile(this.props.artifact, filepath, 'module'));
            else
                this.props.createTab(tabId, new EditorArtifactFile(this.props.artifact, filepath));
        }
        this.props.openTab(tabId);
    }

    /**
     * 
     * @param {*} filepath 
     */
    closeFile = filepath => {
        const itemId = `file:${filepath}`;
        if (this.props.selectedItem && this.props.selectedItem.type === this.props.name && this.props.selectedItem.id === itemId) {
            this.props.unselectItem();
        }
        const tabId = `${this.props.name}/file:${filepath}`;
        this.props.deleteTab(tabId);
        // TODO select the correct node
    }

    /**
     * 
     * @returns 
     */
    render() {
        let selectedNodeId = null;
        let selectedFilepath = null;
        if (this.props.selectedItem && this.props.selectedItem.type === this.props.name) {
            const selectedItemId = this.props.selectedItem.id;
            selectedNodeId = selectedItemId;
            if (selectedItemId.startsWith('file:')) {
                selectedFilepath = selectedItemId;
            }
        }
        return <ArtifactBrowser
            name={this.props.name}
            artifact={this.props.artifact}
            selectedNodeId={selectedNodeId}
            selectedFilepath={selectedFilepath}
            onFileTreeChange={this.handleFileTreeChange.bind(this)}
        />
    }
}

export default chain(
    withContext(ErrorHandlerContext)(({ raiseError }) => ({ raiseError })),
    withContext(CacheContext)(({ getService }) => ({ getService })),
    withContext(EditorContext)(({
        selectedItem,
        selectItem,
        unselectItem,
        getTab,
        createTab,
        openTab,
        closeTab,
        deleteTab,
        getSelectedItemData
    }) => ({
        selectedItem,
        selectItem,
        unselectItem,
        getTab,
        createTab,
        openTab,
        closeTab,
        deleteTab,
        getSelectedItemData
    })),
)(AppArtifactBrowser);



/*

    openFile = filepath => {
        this.props.selectItem({
            type: this.props.name,
            id: `file:${filepath}`,
        });
        const tabId = `${this.props.name}/file:${filepath}`;
        if (!this.props.getTab(tabId)) {
            if (this.props.artifact.name == 'gcp' && filepath === '/service.json')
                this.props.createTab(tabId, new EditorArtifactFile(this.props.artifact, filepath, 'service'));
            else
                this.props.createTab(tabId, new EditorArtifactFile(this.props.artifact, filepath));
        }
        this.props.openTab(tabId);
    }

*/