import { mdiContentSaveOutline, mdiCubeOutline, mdiOpenInNew } from '@mdi/js';
import Icon from '@mdi/react';
import { CircularProgress, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import firebase from "firebase/app";
import 'firebase/firestore';
import React from 'react';
import uuid from 'react-uuid';
import { getApp, getArchitecture, getHostApp, putHostApp } from '../BackendFunctions';
import Artifact from '../artifact/Artifact';
import BrowserStorage from '../artifact/BrowserStorage';
import AuthenticationContext from '../authentication/AuthenticationContext';
import CacheContext from '../cache/CacheContext';
import ErrorHandlerContext from '../error/ErrorHandlerContext';
import ResizableLayout from '../layout/ResizableLayout';
import ResizeLayoutProvider from '../layout/ResizableLayoutProvider';
import ResizableToggleIconButton from '../layout/ResizableToggleIconButton';
import SystemAppBar from '../system/SystemAppBar';
import SystemButton from '../system/SystemButton';
import SystemContentEditable from '../system/SystemContentEditable';
import SystemDivider from '../system/SystemDivider';
import SystemLink from '../system/SystemLink';
import SystemLoading from '../system/SystemLoading';
import withContext, { chain } from '../utils/WithContext';
import HostContext from './HostContext';

/**
 * 
 */
class Host extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            // loading
            loading: true,
            // whether or not we are saving the app
            saving: false,
            // the opened artifacts
            openedArtifacts: [],
            // the one selected
            selectedArtifact: null,
            // layout left side menu
            displayLeftMenu: true,
        }

        this.artifact = null;

        // the current app opened
        this.app = null;

        // the browser storage to use
        this.browserStorage = new BrowserStorage();

        // TODO
        this.changeId = uuid();

        // cache to avoid uploading several time the same file
        this.uploadCache = {};
        // service cache
        this.servicesCache = {}; // todo should we limit the size of the cache ? do we store them on the local storage ?
        // all references of artifacts requested
        this.artifacts = {};
    }

    componentDidMount() {
        // preload services
        this.props.getServices();

        // listen to host changes
        const db = firebase.firestore();
        this.unsubscribeEnvInfoListener = db.collection("hosts").doc(this.props.hostId).onSnapshot((doc) => {
            var host = doc.data().version;

            this.setState({
                host,
                loading: false,
            });

            const [appId, appVersion] = host.app.split(':');
            const state = getHostApp(this.props.hostId);

            

        });
    }

    handleDeploy() {
        getApp('bfbfbf-667075').then(app => {
            putHostApp(this.props.hostId, app.appId, app.versionId)
        })

    }

    /**
    * 
    */
    componentDidMountOLD() {
        // preload services
        this.props.getServices();

        // listen to app changes
        const db = firebase.firestore();
        this.unsubscribeEnvInfoListener = db.collection("hosts").doc(this.props.hostId).onSnapshot((doc) => {
            var version = doc.data().version;

            this.artifact = new Artifact('file:host', this.browserStorage);
            this.artifact.setSrc({
                bucket: 'hosts',
                path: `${version.hostId}/${version.versionId}.zip`,
            });

            this.artifact.getFolder('/').then(async ({ files }) => {
                this.artifact.getFileAsJson('/host.json').then(host => { // rename in manifest.json

                    const [appId, appVersion] = host.app.split(':');
                    // get the desired architecture
                    const architecturePromise = getArchitecture(appId, appVersion);

                    // get the effective architecture deployed
                    //const artchitectureEnvPromise = getEnvArchitecture();


                    // listen to app changes
                    return getApp(appId, appVersion).then(app => {

                        // TODO change that on backend (root path is not needed)
                        var resource = app.resource;
                        var path = 'file:main';
                        var src = resource?.src;

                        //
                        const artifact = this.props.getArtifact(path);
                        artifact.setSrc({
                            bucket: 'apps',
                            path: src,
                        });

                        // load layout and graph.json
                        // display loading while downloading
                        artifact.getFolder('/').then(async ({ files }) => {
                            // Open the artifact and then unset the loading state
                            this.props.preloadArtifact(artifact).then(() => {
                                return architecturePromise.then(architecture => {
                                    this.setState({
                                        host,
                                        loading: false,
                                        architecture,
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });

    }


    render() {
        return <Box sx={{
            minWidth: 600,
            height: '100%',
            //backgroundColor: '#dfd2d6', //'#ebeced',
            background: '#000',
            overflow: 'hidden',
        }}
        >
            <Box sx={{
                width: '100%',
                height: '100%',
                backgroundColor: '#ded9d5', //'#dfd2d6', //'#ebeced',
                //background: 'rgb(223,210,214)',
                //background: 'linear-gradient(180deg, rgba(223,210,214,1) 0%, #03A9F4 100%)', //'linear-gradient(180deg, rgba(223,210,214,1) 0%, rgba(209,179,189,1) 100%)', //'linear-gradient(180deg, rgba(223,210,214,1) 0%, #03A9F4 100%)'
                display: 'flex',
                flexDirection: 'column',
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
            }}>

                {this.state.loading && <SystemLoading />}

                {!this.state.loading && <HostContext.Provider value={{
                    architecture: this.state.architecture,

                }}>
                    <ResizeLayoutProvider>
                        <SystemAppBar sx={{
                            flexGrow: 0,
                            flexShrink: 0,
                        }}>

                            {true && <Box sx={{ margin: 'auto', display: 'flex', gap: '4px' }}>
                                <ResizableToggleIconButton />
                            </Box>}

                            {/* Logo */}
                            <Icon
                                path={mdiCubeOutline}
                                size={1}
                                style={{
                                    color: '#f05537',
                                    flexShrink: 0,
                                }}
                            />


                            <Box sx={{
                                flexGrow: 1,
                                display: 'flex',
                                overflow: 'hidden',
                                gap: '16px',
                            }}>

                            </Box>

                            <SystemButton
                                disabled={this.state.saving}>

                                {this.state.saving ? <CircularProgress sx={{ color: '#fff' }} size={12} thickness={5} /> : <Icon style={{ flexShrink: 0 }} path={mdiContentSaveOutline} size={.6} />}

                                <Typography variant={'body'}>SAVE</Typography>
                            </SystemButton>

                        </SystemAppBar>

                        <ResizableLayout
                            leftWidth={0}>
                            <Box sx={{
                                padding: '8px 16px 16px 16px',
                            }}>
                                <Typography variant={'h2'}>{'HOST CONFIGURATION & DEPLOYMENT'}</Typography>
                                <Typography variant={'h6'}>{this.state.host.location}</Typography>

                                <Box><SystemLink href={`/apps/${this.state.host.app}/edit`} icon={mdiOpenInNew}>View app source code</SystemLink></Box>
                                <Box><SystemLink href={`/apps/${this.state.host.app}/edit`} icon={mdiOpenInNew}>View app monitoring dashboard</SystemLink></Box>
                                <SystemDivider />


                                <SystemDivider />

                                <SystemButton onClick={this.handleDeploy.bind(this)}>DEPLOY</SystemButton>
                                <SystemDivider />

                            </Box>

                            <Box><SystemContentEditable>{JSON.stringify(this.state.architecture, null, 2)}</SystemContentEditable></Box>

                        </ResizableLayout>

                    </ResizeLayoutProvider>
                </HostContext.Provider>}
            </Box>
        </Box>
    }
}

export default chain(
    withContext(ErrorHandlerContext)(({ raiseError }) => ({ raiseError })),
    withContext(CacheContext)(({ getArtifact, preloadArtifact, getServices }) => ({ getArtifact, preloadArtifact, getServices })),
    withContext(AuthenticationContext)(({ user }) => ({ user })),
)(Host);