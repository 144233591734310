
import { mdiArrowLeft, mdiArrowRight, mdiChevronDown, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Typography } from '@mui/material';
import * as React from 'react';
import SystemIconButton from './SystemIconButton';


class SystemPopupToolbar extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { sx, icon, title, actions, children, onClose, forwardedRef, ...otherProps } = this.props;
        return <Box
            ref={forwardedRef}
            {...otherProps}
            sx={{
                ...sx,
                padding: '8px 10px', //top | right | bottom | left
                color: '#000',
                minHeight: 48,
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
            }}
        >

                {/* Icon */}
                {icon}

                {/* title */}

                {title && <Box sx={{
                    flexGrow: 1,
                }}>
                   <Typography sx={{ fontWeight: 500 }} variant="body">{title}</Typography>
                </Box>}

                {actions}

        </Box>
    }
}

/**
 * Add a fwd ref
 */
export default React.forwardRef((props, ref) => <SystemPopupToolbar
    forwardedRef={ref}
    {...props}
/>);
