import Icon from "@mdi/react";
import { Box } from "@mui/material";
import React from "react";


class ResourceIcon extends React.Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    render() {
        var {
            forwardedRef,
            sx,
            selected,
            color = '#333',
            variant,
            radius = 5,
            width = 28,
            height = 28,
            path,
            iconSize = .8,
            ...otherProps
        } = this.props;

        return <Box
            ref={forwardedRef}
            sx={{
                ...sx,
                cursor: 'pointer',
                position: 'relative',
                width,
                height,
                borderRadius: radius + 'px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                //background: selected ? color : 'rgba(0,0,50,.06)',
                //backgroundImage: selected ? `linear-gradient(${color}, #00000020)` : 'none',
                //color: selected ? '#FFFFFF' : '#5F5F5F',
                background: color,
                backgroundImage: `linear-gradient(${color}, #00000020)`,
                color: '#FFFFFF',
                transition: 'background .2s',
                '&:hover': {
                    color: '#FFFFFF',
                    background: color,
                    backgroundImage: `linear-gradient(${color}, #00000020)`,
                }
            }}
            {...otherProps}>
            <Icon path={path} size={iconSize} />
        </Box>
    }
}

export default React.forwardRef((props, ref) => {
    return <ResourceIcon {...props} forwardedRef={ref} />;
})
