
import * as React from 'react';
import ResizeLayoutContext from './ResizeLayoutContext.js';


/**
 * 
 */
class ResizeLayoutProvider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            displayLeftMenu: true,
        }
    }

    /**
     * 
     */
    toggleLeftMenu = () => {
        this.setState({ displayLeftMenu: !this.state.displayLeftMenu });
    }

    /**
     * 
     */
    openLeftMenu = () => {
        this.setState({ displayLeftMenu: true });
    }

    /**
     * 
     */
    closeLeftMenu = () => {
        this.setState({ displayLeftMenu: false });
    }

    /**
     * 
     * @returns 
     */
    render() {
        return <ResizeLayoutContext.Provider value={{
            displayLeftMenu: this.state.displayLeftMenu,
            toggleLeftMenu: this.toggleLeftMenu.bind(this),
            openLeftMenu: this.openLeftMenu.bind(this),
            closeLeftMenu: this.closeLeftMenu.bind(this),
        }}>
            {this.props.children}
        </ResizeLayoutContext.Provider>
    }
}

export default ResizeLayoutProvider;
