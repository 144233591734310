import React from 'react';
import withContext, { chain } from '../utils/WithContext';
import ArtifactEditorContext from './ArtifactEditorContext';

class ArtifactFileEditor extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.loadFile(this.props.artifact, this.props.filepath);
        this.props.artifact.addFileChangeListener(this.props.filepath, this.handleFileChange.bind(this));
    }

    handleFileChange() {
        this.props.loadFile(this.props.artifact, this.props.filepath);
    }

    componentWillMount() {
        this.props.unloadFile(this.props.artifact, this.props.filepath);
    }

    render() { return false; }

}

export default chain(
    withContext(ArtifactEditorContext)(({ loadFile, unloadFile }) => ({ loadFile, unloadFile })),
)(ArtifactFileEditor)