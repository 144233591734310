import { mdiArrowRight, mdiChevronRight, mdiCloudQuestion, mdiCog, mdiDotsVertical, mdiEyeArrowRight, mdiEyeOutline, mdiSquareRoundedOutline, mdiTarget } from '@mdi/js';
import Icon from '@mdi/react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import SystemIconButtonSmall from '../system/SystemIconButtonSmall';
import SystemLoading from '../system/SystemLoading';
import withContext, { chain } from '../utils/WithContext';
import AppContext from './AppContext';
import ResourceContext from '../resource/ResourceContext';
import CacheContext from '../cache/CacheContext';
import ResourceIcon from '../system/ResourceIcon';

/**
 * 
 */
class ResourceTile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            service: null,
        }

        this.roles = React.createRef();
    }

    /**
     * 
     * @param {*} resourceId 
     * @returns 
     */
    getArtifact = resourceId => {
        // get the module global id modA/modB/modC...
        const src = this.props.resource.resource;
        let artifact;
        if (!src.startsWith('file:')) {
            const artifactPath = `${this.props.artifact.name}/${resourceId}`;
            artifact = this.props.getArtifact(`file:${artifactPath}`);
            artifact.setSrc({ 
                bucket: 'apps',
                path: src,
             });
        } else {
            artifact = this.props.getArtifact(src);
        }
        return artifact;
    }

    /**
     * 
     */
    componentDidMount() {
        if (this.props.resource.resource) {
            this.update();
        }
    }

    /**
     * 
     * @param {*} oldProps 
     */
    componentDidUpdate(oldProps) {
        if (oldProps.resource.resource != this.props.resource.resource) {
            this.update();
        }
    }

    /**
     * 
     */
    update = () => {
        if (!this.props.resource.resource) {
            this.setState({ service: null });
        } else {
            const artifact = this.getArtifact(this.props.name)
            artifact.addFileChangeListener('/resource.json', event => this.onResourceChange(event));
            artifact.getFile('/resource.json').then(value => {
                this.onResourceChange({ value });
            });
        }
    }

    /**
     * 
     * @param {*} event 
     */
    onResourceChange(event) {
        const resourceClass = JSON.parse(event.value);
        const [serviceId, versionId] = resourceClass.service.split(':');
        this.props.getService(serviceId, versionId).then(service => {
            this.setState({
                resourceClass,
                service,
            });
        })
    }

    /**
     * 
     * @returns 
     */
    render() {
        const { sx, value, getService, ...otherprops } = this.props;
        return <Box
        {...otherprops}
            sx={{
                ...sx,
                cursor: 'pointer',
                width: '100%',
                height: 40,
                padding: '0px 0px 0px 4px',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '5px',
                gap: '8px',
                backgroundColor: 'rgba(0,0,0,.04)',
                '& .actions': {
                    visibility: 'hidden',
                },
                '&:hover': {
                    backgroundColor: '#fff',
                    '& .actions': {
                        visibility: 'visible',
                    }
                }
            }}
        >
                        {false && <Box

                        sx={{
                            flexShrink: 0,
                            display: 'flex',
                            alignItems: 'center',
                            width: '15px',
                            justifyContent: 'center',
                        }}>
                        <Icon color={'#E91E63'} path={this.state.service?.icon || mdiSquareRoundedOutline} size={.6} />
                    </Box>}

                    <Box sx={{
                                width: 28,
                                height: 28,
                                transition: 'all .2s',
                                cursor: 'pointer',
                                '&:hover': {
                                    transform: 'scale(1.1)'
                                }
                            }}>
                                <ResourceIcon
                                    width={'100%'}
                                    height={'100%'}
                                    path={this.state.service?.icon || mdiSquareRoundedOutline}
                                    opacity={1}
                                    radius={4}
                                    color={this.state.service?.color || '#E91E63'}
                                    iconSize={.6}
                                />
                            </Box>

                    <Typography
                        variant={'body'}
                        sx={{
                            fontWeight: 400,
                            color: '#000', //'#3d4047',
                            flexGrow: 1,
                        }}
                    >
                        {this.props.name}
                    </Typography>

                    {value}

                    {!value && <Box className={'actions'} sx={{
                        display: 'flex',
                        gap: '4px',
                    }}>
                        <SystemIconButtonSmall
                            path={mdiDotsVertical}
                        />
                    </Box>}
        </Box>
    }

}

const ResourceTileWithContexts = chain(
    withContext(CacheContext)(({ getArtifact, getService }) => ({ getArtifact, getService })),
    withContext(ResourceContext)(({ artifact }) => ({ artifact })),
)(ResourceTile);


/**
 * Add a fwd ref
 */
export default React.forwardRef((props, ref) => <ResourceTileWithContexts
    forwardedRef={ref}
    {...props}
/>);

/*

    {this.props.triggers && this.props.triggers.map(trigger => 
                                <Typography
                                    variant={'body2'}
                                    sx={{
                                        fontWeight: 300,
                                        color: '#4989ab', //'#3d4047',
                                        padding: '0 4px',
                                        backgroundColor: '#e7f3ff',
                                        borderRadius: '3px',

                                    }}
                                >
                                    {trigger}
                                </Typography>
                                )}



                        {false && <SystemContentEditable
                        //onChange={this.handleDescriptionChange.bind(this)}
                        //placeholder={'Write a description of the purpose of this dependency here'}
                        >{this.props.description}</SystemContentEditable>}



{false && this.props.adornment && <Box
                            sx={{
                                flexShrink: 0,
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <Typography sx={{
                                flexGrow: 0,
                                flexShrink: 0,
                                width: 'fit-content',
                                fontWeight: 400,
                                color: '#333',
                                padding: '4px 6px',
                                backgroundColor: '#eaeaea',
                                borderRadius: '4px',
                                //marginRight: '2px',
                                //fontSize: '10px',
                            }}
                                component={'span'}
                                variant={'body2'}>
                                {'invoker'}
                            </Typography>
                            {false && this.props.adornment}
                        </Box>}

                        {false && <SystemContentEditable
                        variant={'body2'}
                        sx={{
                            marginTop: '2px',
                            marginLeft: '22px',
                            color: '#5c5f66',
                            //backgroundColor: '#00000004',
                            //padding: '2px 8px 2px 8px', //top | right | bottom | left
                        }}
                    >
                        {this.props.description || "Edit this to add a description."}
                    </SystemContentEditable>}
                    {false && <Box
                        sx={{
                            padding: '2px 8px 2px 16px',
                            display: 'flex',
                        }}>
                        <Box
                            sx={{
                                flex: '0 0',
                                minWidth: '14px'
                            }}>
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                            }}>
                            <Box
                                sx={{
                                    //paddingLeft: '8px',
                                    lineHeight: 1,
                                    marginBottom: '8px',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '4px',
                                }}>
                                {this.props.roles && this.props.roles.map(r => <Box sx={{
                                    flexGrow: 0,
                                    flexShrink: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '2px 6px',
                                    borderRadius: '2px',
                                    backgroundColor: '#F0F0F0',//getRoleColor(r),
                                    gap: '4px',
                                }}>
                                    <Icon style={{ color: '#777' }} path={mdiAccountOutline} size={.6} />
                                    <Typography sx={{
                                        flexGrow: 0,
                                        flexShrink: 0,
                                        width: 'fit-content',
                                        fontWeight: 500,
                                        color: '#777',
                                        marginRight: '2px',
                                        fontSize: '10px',
                                    }}
                                        component={'span'}
                                        variant={'body2'}>
                                        {r}
                                    </Typography>
                                </Box>
                                )}
                                {this.props.triggers && this.props.triggers.map(t => <Box sx={{
                                    flexGrow: 0,
                                    flexShrink: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '2px 6px',
                                    borderRadius: '2px',
                                    backgroundColor: getRoleColor(t),
                                    gap: '4px',
                                }}>
                                    <Icon style={{ color: '#ffffff' }} path={mdiLightningBolt} size={.6} />
                                    <Typography sx={{
                                        flexGrow: 0,
                                        flexShrink: 0,
                                        width: 'fit-content',
                                        fontWeight: 500,
                                        color: 'white',
                                        marginRight: '2px',
                                        fontSize: '10px',
                                    }}
                                        component={'span'}
                                        variant={'body2'}>
                                        {t}
                                    </Typography>
                                </Box>
                                )}
                            </Box>


                        </Box>
                    </Box>}

*/