import { mdiCubeOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import 'firebase/firestore';
import React from 'react';
import ArtifactContext from '../artifact/ArtifactContext';
import CacheContext from '../cache/CacheContext';
import Editor from '../editor/Editor';
import EditorProvider from '../editor/EditorProvider';
import ErrorHandlerContext from '../error/ErrorHandlerContext';
import ResizableLayout from '../layout/ResizableLayout';
import ResizableToggleIconButton from '../layout/ResizableToggleIconButton';
import withContext, { chain } from '../utils/WithContext';
import CodeView from '../views/CodeView';
import ServiceArtifactBrowser from './ServiceArtifactBrowser';

/**
 * 
 */
class ServiceArtifact extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <EditorProvider>
                <ResizableLayout>

                    <Box sx={{
                        height: '100%',
                        minWidth: 280,
                        overflow: 'auto',
                    }}>
                        <Box sx={{
                            height: 48,
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center',
                            padding: '4px 10px',
                            borderBottom: '1px solid rgba(0,0,0,.04)',
                        }}>

                            <ResizableToggleIconButton sx={{ flexShrink: 0 }} />

                            <Icon style={{ marginLeft: '8px' }} path={mdiCubeOutline} size={.7} />

                            <Typography
                                variant={'body'}
                                sx={{
                                    flexGrow: 1,
                                    fontWeight: 600,
                                    color: '#24292f',
                                }}
                                component={'p'}>
                                {this.props.artifact.name}
                            </Typography>

                        </Box>

                        <Box sx={{ padding: '8px 20px' }}>
                            <ServiceArtifactBrowser name={'Files'} artifact={this.props.artifact} />
                        </Box>

                    </Box>

                    <Box sx={{
                        width: '100%',
                        height: '100%',
                    }}>
                        <Editor views={{
                            'code': CodeView,
                            //'service': ServiceView,
                        }} />
                    </Box>

                </ResizableLayout>
            </EditorProvider>
        )
    }
}

export default chain(
    withContext(ErrorHandlerContext)(({ raiseError }) => ({ raiseError })),
    withContext(CacheContext)(({ getArtifact, preloadArtifact, getServices }) => ({ getArtifact, preloadArtifact, getServices })),
    withContext(ArtifactContext)(({ artifact }) => ({ artifact })),
)(ServiceArtifact);