
import Icon from '@mdi/react';
import { Box, IconButton } from '@mui/material';
import * as React from 'react';

/**
 * 
 */
class SystemIconButtonSmall extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { sx, path, ...otherProps } = this.props;
        return <Box
        disableRipple
        disableTouchRipple
            ref={this.props.forwardedRef}
            {...otherProps}
            sx={{
                ...sx,
                cursor: 'pointer',
                height: 20,
                padding: '2px',
                borderRadius: '3px',
                lineHeight: 0,
                color: 'black',
                display: 'flex',
                alignItems: 'center',
                //backgroundColor: '#f8f8f9',
                '&:hover': {
                    backgroundColor: '#dddddc',
                }
                //background: 'red'
            }}
        >
            <Icon path={path} size={.6} />
        </Box>
    }
}

/**
 * Add a fwd ref
 */
export default React.forwardRef((props, ref) => <SystemIconButtonSmall
    forwardedRef={ref}
    {...props}
/>);