import * as React from 'react';
import withContext, { chain } from '../utils/WithContext';
import ResizeLayoutContext from './ResizeLayoutContext.js';
import SystemIconButton from '../system/SystemIconButton';
import { mdiArrowCollapseLeft, mdiArrowLeft, mdiArrowRight, mdiChevronLeft, mdiChevronLeftBoxOutline, mdiChevronRight, mdiMenu } from '@mdi/js';


/**
 * 
 */
class ResizableToggleIconButton extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <SystemIconButton path={this.props.displayLeftMenu ? mdiArrowLeft : mdiArrowRight} onClick={() => this.props.toggleLeftMenu()} />
    }
}

export default chain(
    withContext(ResizeLayoutContext)(({ displayLeftMenu, toggleLeftMenu }) => ({ displayLeftMenu, toggleLeftMenu })),
)(ResizableToggleIconButton)