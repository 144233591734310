import { mdiArrowRight, mdiContentCopy, mdiCopyright, mdiCubeOutline, mdiDeleteOutline, mdiDotsVertical, mdiPencilOutline, mdiShare } from '@mdi/js';
import Icon from '@mdi/react';
import { Backdrop, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import 'firebase/firestore';
import React from 'react';
import SystemIconButton from '../system/SystemIconButton';
import SystemLink from '../system/SystemLink';
import SystemPopper from '../system/SystemPopper';
import withContext, { chain } from '../utils/WithContext';
import ServiceContext from './ServiceContext';

/**
 * 
 */
class ImplementationArtifactComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
        this.button = React.createRef();
    }

    open = open => {
        this.setState({ open });
    }

    render() {
        const { artifactPrettyName, artifactName } = this.props;

        let color;
        if (this.props.isArtifactCreated(artifactName)) color = '#53c079';
        else if (this.props.isArtifactUpdated(artifactName)) color = '#e5a748';
        else color = '#0969DA';

        return <Box sx={{
            height: 30,
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            padding: '0 4px',
        }}>
            <Icon style={{ color }} path={mdiCubeOutline} size={.6} />
            <SystemLink icon={mdiArrowRight} sx={{ color }} onClick={() => this.props.openArtifact(artifactName)}>{artifactPrettyName}</SystemLink>
            <Box sx={{ flexGrow: 1, minWidth: '16px' }}></Box>
            <SystemIconButton
                ref={this.button}
                sx={{ color: '#333' }}
                path={mdiDotsVertical}
                onClick={() => this.open(!this.state.open)}
            />
            <SystemPopper
                noAnimation
                placement={'right-start'}
                anchorEl={this.button.current}
                onClickAway={() => this.open(false)}
                open={this.state.open}
                offset={8}
                sx={{
                    borderRadius: '4px',
                    backgroundColor: '#eaeef5',//'#dcdee5', 
                    border: '1px solid rgba(0,0,0,.04)',
                    marginTop: this.state.open ? 0 : '-10px',
                    transition: 'margin .2s',
                }}
            >
                <Box sx={{
                    //width: '200px',
                    padding: '0px',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        padding: '4px 22px 4px 16px',
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: 'rgba(0,0,0,.04)'
                        }
                    }}
                        onClick={() => {
                            this.open(false);
                            this.props.openArtifact(artifactName);
                        }}>
                        <Icon path={mdiPencilOutline} size={.7} />
                        <Typography variant={'body'} sx={{ color: '#3c414b' }} component={'p'}>Edit</Typography>
                    </Box>
                    
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        padding: '4px 22px 4px 16px',
                        cursor: 'pointer',
                        color: '#ea3c3c',
                        '&:hover': {
                            backgroundColor: 'rgba(0,0,0,.04)'
                        }
                    }}
                    onClick={() => {
                        this.open(false);
                        this.props.closeArtifact(artifactName);
                        this.props.removeSource(artifactName);
                    }}>
                        <Icon path={mdiDeleteOutline} size={.7} />
                        <Typography variant={'body'} component={'p'}>Delete</Typography>
                    </Box>

                </Box>
            </SystemPopper>
        </Box>
    }
}

export default chain(
    withContext(ServiceContext)(({ openArtifact, closeArtifact, removeSource, isArtifactCreated, isArtifactUpdated, }) => ({ openArtifact, closeArtifact, removeSource, isArtifactCreated, isArtifactUpdated })),
)(ImplementationArtifactComponent);
