import { Box } from '@mui/material';
import React from 'react';
import withContext, { chain } from '../utils/WithContext';
import { getRectFromProperties } from './Infinite.js';
import InfiniteContext from './InfiniteContext';
import MouseDownGestureListener from './InfiniteGesture.js';
import InfiniteStaticElement from './InfiniteStaticElement.js';

/**
 * 
 */
class InfiniteSelected extends React.Component {

    constructor(props) {
        super(props);
        this.element = React.createRef();
        this.container = React.createRef();
        this.state = {
            globalDeltaX: 0,
            globalDeltaY: 0,
            deltaX: 0,
            deltaY: 0,
        }
        this.rect = null;
        this.stackArr = [];

    }

    getCoord = (coord, { deltaX, deltaY }) => {
        if (this.props.grid) {
            deltaX = Math.round(deltaX / this.props.grid.x) * this.props.grid.x;
            deltaY = Math.round(deltaY / this.props.grid.y) * this.props.grid.y;
        }
        return {
            x: coord.x + deltaX,
            y: coord.y + deltaY,
        }
    }

    /**
     * 
     */
    setElementDragGestureListener() {
        let startCoord = {};
        let version;
        this.gesture.condition(({ event }) => !this.props.selected && event.which == 1 && !event.metaKey)
            .onStart(() => {
                this.props.selectedElements.forEach(id => {
                    const properties = this.props.selectedElementsProperties[id];
                    startCoord[id] = properties.coord;
                });
                version = this.props.version;
            })
            .onMove(({ deltaX, deltaY }) => {
                // map on the grid if any
                if (this.props.grid) {
                    deltaX = Math.round(deltaX / this.props.grid.x) * this.props.grid.x;
                    deltaY = Math.round(deltaY / this.props.grid.y) * this.props.grid.y;
                }

                if (deltaX != 0 || deltaY != 0) {
                    this.setState({
                        deltaX,
                        deltaY
                    });

                    const props = {};
                    this.props.selectedElements.forEach(id => {
                        //const properties = this.props.selectedElementsProperties[id];
                        const coord = this.getCoord(startCoord[id], { deltaX, deltaY });
                        props[id] = { coord }
                    });
                    if (Object.keys(props).length > 0) this.props.updateContextProperties(props);
                }
            })
            .onStop(({ deltaX, deltaY }) => {
                // map on the grid if any
                if (this.props.grid) {
                    deltaX = Math.round(deltaX / this.props.grid.x) * this.props.grid.x;
                    deltaY = Math.round(deltaY / this.props.grid.y) * this.props.grid.y;
                }

                if (deltaX != 0 || deltaY != 0) {
                    this.setState({
                        deltaX: 0,
                        deltaY: 0,
                        globalDeltaX: this.state.globalDeltaX + deltaX,
                        globalDeltaY: this.state.globalDeltaY + deltaY,
                    });

                    const props = {};
                    this.props.selectedElements.forEach(id => {
                        //const properties = this.props.selectedElementsProperties[id];
                        const coord = this.getCoord(startCoord[id], { deltaX, deltaY });
                        props[id] = { coord };
                        if (this.props.onElementPositionChanged && (deltaX != 0 || deltaY != 0)) this.props.onElementPositionChanged(id, coord);
                    });
                    if (Object.keys(props).length > 0) this.props.updateContextProperties(props);

                    /*this.props.selectedElements.forEach(id => {
                        const properties = this.props.selectedElementsProperties[id];
                        const coord = this.getCoord(startCoord[id], { deltaX, deltaY });
                        this.props.updateContextProperties({
                            [id]: {
                                ...properties,
                                coord
                            }
                        });
                    });*/
                }
            })
            .cancelWhen(() => this.props.version != version)
            .onCancel(() => {
                this.setState({
                    deltaX: 0,
                    deltaY: 0,
                });
                const props = {};
                this.props.selectedElements.forEach(id => {
                    //const properties = this.props.selectedElementsProperties[id];
                    props[id] = { coord: startCoord[id] }
                });
                if (Object.keys(props).length > 0) this.props.updateContextProperties(props);
            });
    }

    /**
     * 
     */
    componentDidMount() {
        // create gesture listeners
        this.gesture = new MouseDownGestureListener(this.element.current);
        this.gesture.listenMouseEvents();
        this.setElementDragGestureListener();
        this.props.selectedElements.forEach(id => this.moveElementNodeInGroup(id));
    }

    /**
     * 
     */
    componentWillUnmount() {
        if (this.gesture) this.gesture.unlistenMouseEvents();
        this.props.selectedElements.forEach(id => this.moveElementNodeFromGroup(id));
    }

    /**
     * 
     * @param {*} id 
     */
    moveElementNodeInGroup = id => {
        const element = this.props.doms[id];
        this.container.current.appendChild(element);
        const r = getRectFromProperties(this.props.selectedElementsProperties[id]);
        element.style.left = (r.x - this.props.selectedRect.x) + 'px';
        element.style.top = (r.y - this.props.selectedRect.y) + 'px';
    }

    /**
     * 
     * @param {*} id 
     */
    moveElementNodeFromGroup = id => {
        const element = this.props.doms[id];
        this.props.infiniteElement.appendChild(element);
    }

    /**
     * 
     * @returns 
     */
    render() {
        let rect = this.props.selectedRect;
        return <InfiniteStaticElement
            ref={this.element}
            align={'top-left'}
            coord={{
                x: rect.x + this.state.globalDeltaX + this.state.deltaX,
                y: rect.y + this.state.globalDeltaY + this.state.deltaY,
            }}
            size={{
                w: rect.w,
                h: rect.h,
            }}>
            <Box
                ref={this.container}
                sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    borderRadius: '8px',
                    ...(this.props.selectedElements?.length > 1 ? {
                        backgroundColor: '#0047ff06', //'#00000011', //'#00AA0011',//'#0000FF11',
                        outline: '1px solid #0047ffAA',//#00000005',
                    }: {}),
                }} />
        </InfiniteStaticElement>
    }
}

/**
 * 
 */
export default chain(
    withContext(InfiniteContext)(({ grid, selectedElements, selectedRect, doms, infiniteElement, properties, updateContextProperties, onElementPositionChanged }) => {
        const selectedElementsProperties = Object.fromEntries(selectedElements.map(id => ([id, properties[id]])))
        return {
            grid,
            selectedElements,
            selectedRect,
            selectedElementsProperties,
            doms,
            updateContextProperties,
            infiniteElement,
            onElementPositionChanged,
        }
    })
)(InfiniteSelected)