
import { Typography } from '@mui/material';
import * as React from 'react';

const LowercaseLetters = 'abcdefghijklmnopqrstuvwxyz'.split('');
const UppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
const Numbers = '0123456789'.split('');
const TabCharacter = 'Tab';
const NewLineCharacter = 'Enter';

const VariableNameAllowedCharacters = [...LowercaseLetters, ...UppercaseLetters, ...Numbers, '_', '$'];

export const AllowedCharacters = {
    VariableNameAllowedCharacters: 'VariableNameAllowedCharacters',
}

class SystemContentEditable extends React.Component {

    constructor(props) {
        super(props);
        this.ref = this.props.forwardedRef || React.createRef();
    }
    
    /**
     * 
     * @param {*} char 
     * @returns 
     */
    isAllowedCharacter(char) {
        if (!this.props.allowedCharacters) return true;
        else if (this.props.allowedCharacters == AllowedCharacters.VariableNameAllowedCharacters) {
            return VariableNameAllowedCharacters.includes(char);
        }
    }

    componentDidMount() {
        this.ref.current.addEventListener('paste', this.handlePaste.bind(this));
        this.ref.current.addEventListener('keypress', this.handleKeypress.bind(this));
        this.ref.current.addEventListener('keydown', this.handleDown.bind(this));
        this.ref.current.addEventListener('blur', this.handleBlur.bind(this));
        this.ref.current.spellcheck = false;
    }

    /**
     * 
     * @param {*} e 
     */
    handleDown(e) {
        if (e.which === 9 && this.isAllowedCharacter(TabCharacter)) { // tab
            //document.execCommand('insertText', false, '\t');
            this.insertText('\t');
            e.preventDefault();
        }
        console.log('handleDown', e.which, e.key, e)

    }

    /**
     * 
     * @param {*} e 
     */
    handleKeypress(e) {
        if (e.which === 13) {
            if (e.ctrlKey || e.shiftKey) {
                this.ref.current.blur();
                e.preventDefault();
            } else {
                if (this.isAllowedCharacter(NewLineCharacter)) {
                    document.execCommand('insertLineBreak');
                    //this.insertText('\n')
                    e.preventDefault();
                } else {
                    this.ref.current.blur();
                    e.preventDefault();
                }
            }
        }
        if (!this.isAllowedCharacter(e.key)) {
            e.preventDefault();
        }
    }

    /**
     * 
     * @param {*} e 
     */
    handleBlur(e) {
        if (this.props.onChange) {
            this.props.onChange(this.ref.current.textContent);
        }
    }

    /**
     * 
     * @param {*} e 
     */
    handlePaste(e) {
        // Prevent the default action
        e.preventDefault();

        // Get the copied text from the clipboard
        let text = e.clipboardData
            ? (e.originalEvent || e).clipboardData.getData('text/plain')
            : // For IE
            window.clipboardData
                ? window.clipboardData.getData('Text')
                : '';

        /*if (document.queryCommandSupported('insertText')) {
            document.execCommand('insertText', false, text);
        }*/
        // Insert text at the current position of caret
        if (this.props.allowedCharacters == AllowedCharacters.VariableNameAllowedCharacters) {
            text = text.replace(/[^0-9a-zA-Z_\-$]+/g, "");
        }
        this.insertText(text);
    }

    /**
     * 
     * @param {*} text 
     */
    insertText(text) {
        const range = document.getSelection().getRangeAt(0);
        range.deleteContents();

        const textNode = document.createTextNode(text);
        range.insertNode(textNode);
        range.selectNodeContents(textNode);
        range.collapse(false);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
    }

    render() {
        const { sx, variant, children, ...otherProps } = this.props;
        
        return <Typography
            ref={this.ref}
            {...otherProps}
            component={'p'}
            variant={variant || 'body'}
            contentEditable={true}
            suppressContentEditableWarning={true}
            sx={{
                padding: '4px', //top | right | bottom | left
                overflowY: 'auto',
                ...sx,
                borderRadius: '3px',
                //width: '100%',
                //whiteSpace: 'pre-wrap',
                //backgroundColor: 'rgba(0,0,0,.03)',
                //':hover': { backgroundColor: 'rgba(0,0,0,.08)', cursor: 'pointer' },
                ':focus': { outline: '1px solid rgb(15,135,255)', cursor: 'text' },
                //transition: 'background-color .2s',
            }}>
            {children}
        </Typography>
    }
}

/**
 * Add a fwd ref
 */
export default React.forwardRef((props, ref) => <SystemContentEditable
    forwardedRef={ref}
    {...props}
/>);