import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { withStyles } from '@mui/styles';
import 'firebase/firestore';
import React from 'react';
import Screen1 from '../component/screen1.png';

const styles = (theme) => ({
    root: {

    },
});

/**
 * 
 */
class Landing extends React.Component {

    constructor(props) {
        super(props);
    }



    /**
     * 
     * @returns 
     */
    render() {

        return (<>
        <Box sx={{
            width: '100%',
            backgroundColor: '#eff3f4',

        }}><Box sx={{
            width: 1280,
            margin: 'auto',
            //borderRight: '1px solid rgb(67, 118, 67, .05)',
            p: 5,
            pt: 13
        }}>
                <Box sx={{ pt: 5, pb: 4, width: '60%' }}>
                    <Typography sx={{ fontSize: 100, fontWeight: 600 }} variant={'h1'}>The cloud made crazy simple.</Typography>
                </Box>
                <Box sx={{ pb: 6 }}>
                    <Typography sx={{ color: '#57675f', fontSize: 30, fontWeight: 500 }} variant={'h2'}>For many developers, serverless architectures offer greater scalability, more flexibility, and quicker time to release, all at a reduced cost. With serverless architectures, developers do not need to worry about purchasing, provisioning, and managing backend servers.</Typography>
                </Box>
                <img style={{
                    width: '60%',
                    border: '30px solid white',
                    borderRadius: 30,
                    boxShadow: 'rgb(52 107 75 / 15%) 0px 0px 40px'
                }} src={Screen1} />
</Box></Box>

<Box sx={{
            width: '100%',

        }}><Box sx={{
            width: 1280,
            margin: 'auto',
            //borderRight: '1px solid rgb(67, 118, 67, .05)',
            p: 5,
            pt: 13
        }}>

                <Box sx={{ pt: 5, pb: 4, width: '60%' }}>
                    <Typography sx={{ fontSize: 60, fontWeight: 500, }} variant={'h1'}>For Everyone !.</Typography>
                </Box>
                <Box sx={{ pb: 6 }}>
                    <Typography sx={{ color: '#57675f', fontSize: 30, fontWeight: 500 }} variant={'h2'}>For many developers, serverless architectures offer greater scalability, more flexibility, and quicker time to release, all at a reduced cost. With serverless architectures, developers do not need to worry about purchasing, provisioning, and managing backend servers.</Typography>
                </Box>
                <img style={{
                    width: '60%',
                    border: '30px solid white',
                    borderRadius: 30,
                    boxShadow: 'rgb(52 107 75 / 15%) 0px 0px 40px'
                }} src={Screen1} />

                <Box sx={{ pt: 5, pb: 4, width: '60%' }}>
                    <Typography sx={{ fontSize: 60, fontWeight: 500, }} variant={'h1'}>Scale from 0 to .... infinite.</Typography>
                </Box>
                <Box sx={{ pb: 6 }}>
                    <Typography sx={{ color: '#57675f', fontSize: 30, fontWeight: 500 }} variant={'h2'}>For many developers, serverless architectures offer greater scalability, more flexibility, and quicker time to release, all at a reduced cost. With serverless architectures, developers do not need to worry about purchasing, provisioning, and managing backend servers.</Typography>
                </Box>
                <img style={{
                    width: '60%',
                    border: '30px solid white',
                    borderRadius: 30,
                    boxShadow: 'rgb(52 107 75 / 15%) 0px 0px 40px'
                }} src={Screen1} />
            </Box>
        </Box></>)
    }

}


export default withStyles(styles)(Landing);
