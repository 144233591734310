import { mdiArrowRight, mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import ResourceContext from '../resource/ResourceContext';
import SystemLoading from '../system/SystemLoading';
import SystemTextInput from '../system/SystemTextInput';
import withContext, { chain } from '../utils/WithContext';
import AppContext from './AppContext';
import CacheContext from '../cache/CacheContext';
import SystemButtonSecondary from '../system/SystemButtonSecondary';
import ResourceIconButton from '../system/ResourceIconButton';


class ServiceSelectMini extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            service: null,
            search: '',
            services: null,
            scrolled: false,
        };
    }

    /**
     * 
     */
    componentDidMount() {
        this.update();

    }

    /**
     * 
     * @param {*} oldProps 
     */
    componentDidUpdate(oldProps) {
        if (oldProps.service != this.props.service) {
            this.update();
        }
    }

    /**
     * 
     */
    update = () => {
        const allowedDependencies = this.props.service.resource?.dependencies || [];
        /*if (allowedDependencies.length === 0) {
            this.setState({ services: [] });
        } else {
            
        }*/
        const req = (allowedDependencies.length === 0 || allowedDependencies.includes('*')) ? undefined : allowedDependencies;
        this.props.getServices(req).then(services => {
            this.setState({ services });
        });
    }

    handleSelect = service => {
        if (this.props.onSelect) {
            this.props.onSelect(service);
        }
    }

    /**
     * 
     * @returns 
     */
    render() {
        return (

                <Box sx={{
                    width: 128,
                    gap: '8px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    padding: '16px',
                }}>
                    {this.state.services && this.state.services.map(service => (<ResourceIconButton
                            selected /* TODO rename in 'contained' or someting like that */
                            width={'44px'}
                            height={'44px'}
                            path={service.icon}
                            opacity={1}
                            radius={5}
                            color={service.color}
                            iconSize={.8}
                            onClick={() => this.handleSelect(service)}
                        />
                    ))}
                </Box>
        )
    }
}

export default chain(
    withContext(CacheContext)(({ getServices }) => ({ getServices })),
    withContext(ResourceContext)(({ service }) => ({ service })),
)(ServiceSelectMini);
