import uuid from 'react-uuid';

/**
 * 
 */
class BrowserStorage {

    constructor() { }

    set(str) {
        var isEmpty = false, key;
        var i = 0;
        while (isEmpty = (localStorage.getItem(key = uuid()) == null) && i++ < 100) { };
        if (isEmpty) return null;
        else {
            try {
                localStorage.setItem(key, str);
            } catch (err) {
                console.log(err);
                return null;
            }
            return key;
        }
    }

    unset(key) {
        return localStorage.removeItem(key);
    }

    get(key) {
        return localStorage.getItem(key);
    }


    clear() {
        return localStorage.clear();
    }

}

export default BrowserStorage;