
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import firebase from "firebase/app";
import * as React from 'react';
import Button from '../system/Button.js';
import Input from '../system/Input.js';
import InputSection from '../system/InputSection.js';
import TitledBox from '../system/TitledBox.js';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.blueforge.cloud/">
                Blueforge
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function SignIn() {

    var values = {}


    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });

        alert(data.get('email'))

    };

    const onChange = name => event => {
        console.log(event.target.value)
        values[name] = event.target.value;
    }

    const doSignin = () => {
        firebase.auth().signInWithEmailAndPassword(values.email, values.password)
            .then((userCredential) => {
                // Signed in
                var user = userCredential.user;
                console.log(user)
                // ...
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorMessage)
            });
    }

    return (
        <Box
            sx={{
                margin: 'auto',
                mt: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>

            <TitledBox
                primary
                sx={{ width: '360px', background: '#FFF', p: 3, mt: 2 }}
                name='Sign in to BlueForge'>


                <InputSection
                    sx={{ mb: 2 }}
                    name="Email">
                    <Input onChange={onChange("email")} sx={{ width: '100%' }} />
                </InputSection>

                <InputSection
                    sx={{ mb: 2 }}
                    name="Password"
                    helper={<Box>
                        <Link
                            href="#"
                            variant="body2"
                            sx={{
                                color: '#567'
                            }}>
                            Forgotten password?
                        </Link>
                    </Box>}>
                    <Input type={"password"} onChange={onChange("password")} sx={{ width: '100%' }} />
                </InputSection>


                <Button onClick={event => doSignin()} component={Link} to={'/new'} sx={{ width: '100%' }} variant="contained">Sign in</Button>

                <Divider
                    light
                    sx={{
                        mt: 2,
                        mb: 3
                    }}
                />

                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'center' }}>
                    <Typography sx={{ mr: 1, fontWeight: 400 }}
                        variant="body2">
                        New to Blueforge ?
                    </Typography>
                    <Link
                        href="#"
                        variant="body2"
                        sx={{
                            color: '#567'
                        }}>
                        Create an account
                    </Link>
                </Box>
            </TitledBox>
        </Box>

    );
}


/*
            <Copyright sx={{ mt: 8, mb: 4 }} />
*/