import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import firebase from "firebase/app";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes, useNavigate, useParams } from "react-router-dom";
import App from './app/App';
import Authentication from './authentication/Authentication';
import CacheProvider from './cache/CacheProvider';
import ErrorHandlerProvider from './error/ErrorHandlerProvider';
import Apps from "./home/Apps";
import Hosts from './home/Hosts';
import Services from './home/Services';
import Host from './host/Host';
import './index.css';
import Landing from './landing/Landing';
import PageCreateApp from "./pages/PageCreateApp";
import PageCreateHost from './pages/PageCreateHost';
import PageCreateService from './pages/PageCreateService';
import SignIn from "./pages/PageSignIn";
import Service from './services/Service';
import theme from './system/Theme.js';
import TabManager from './tabmanager/TabManager';

//import './roboto.css';

// load backend configuration
fetch('https://www.blueforge.cloud/firebase-config.json', {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
}).then(response => response.json()).then(config => {

    config.projectId = 'blueforge-322008' // todo add it in firebase config 
    /*const firebaseApp = */firebase.initializeApp(config);

    /*
 <Route path="create" element={<RouteCreateApp />} />
    <Route path="deploy" element={<RouteDeployApp />} />
    <Route path="domains:new" element={<RouteCreateDomain />} />
    <Route path="backend:new" element={<RouteCreateBackend />} />

    <Route path="domains/:domain">
        <Route path="" element={<RouteDomain />} />
        <Route path="new" element={<RouteCreateEnv />} />
        <Route path="envs/:envId">
            <Route path="apps/:appId" element={<RouteInstance />} />
        </Route>
    </Route>
    <Route path="apps">
        <Route path=":appId" element={<RouteApp />}>
            <Route path="configure" element={<RouteProjectServices />} />
            <Route path="services" element={<RouteProjectServices />} />
            <Route path="save" element={<RouteProjectSave />} />
        </Route>
    </Route>
    */
    ReactDOM.render(
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <CacheProvider>

                    <ErrorHandlerProvider>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/">
                                    <Route path="" element={<Landing />} />
                                    <Route path="/" element={<Authentication />}>
                                        <Route path="new">
                                            <Route path="app" element={<RouteCreateApp />} />
                                            <Route path="host" element={<RouteCreateHost />} />
                                            <Route path="service" element={<RouteCreateService />} />
                                        </Route>
                                        <Route path="apps">
                                            <Route path="" element={<RouteApps />} />
                                            <Route path=":appId">
                                                <Route path="edit" element={<RouteApp />} />
                                            </Route>
                                        </Route>
                                        <Route path="hosts">
                                            <Route path="" element={<RouteHosts />} />
                                            <Route path=":hostId">
                                                <Route path="edit" element={<RouteHost />} />
                                            </Route>
                                        </Route>
                                        <Route path="services">
                                            <Route path="" element={<RouteServices />} />
                                            <Route path=":serviceId">
                                                <Route path="edit" element={<RouteService />} />
                                            </Route>
                                        </Route>
                                    </Route>
                                </Route>

                                <Route path="/signin" element={<RouteSignIn />} />
 
                            </Routes>
                        </BrowserRouter>
                    </ErrorHandlerProvider>
                </CacheProvider>

            </ThemeProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
});

const RouteApps = (props) => {
    const navigate = useNavigate();
    return (
        <Apps
            navigate={navigate}
            {...props}
        />
    );
}

const RouteApp = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    return (
        <App
            key={params.appId}
            navigate={navigate}
            appId={params.appId}
            {...props}
        />
    );
};

const RouteHost = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    return (
        <Host
            key={params.hostId}
            navigate={navigate}
            hostId={params.hostId}
            {...props}
        />
    );
};

const RouteService = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    return (
        <Service
            key={params.serviceId}
            navigate={navigate}
            serviceId={params.serviceId}
            {...props}
        />
    );
};

const RouteHosts = (props) => {
    const navigate = useNavigate();
    return (
        <Hosts
            navigate={navigate}
            {...props}
        />
    );
}

const RouteServices = (props) => {
    const navigate = useNavigate();
    return (
        <Services
            navigate={navigate}
            {...props}
        />
    );
}

const RouteCreateApp = (props) => {
    const navigate = useNavigate();
    return (
        <PageCreateApp
            navigate={navigate}
            {...props}
        />
    );
};

const RouteCreateHost = (props) => {
    const navigate = useNavigate();
    return (
        <PageCreateHost
            navigate={navigate}
            {...props}
        />
    );
};

const RouteCreateService = (props) => {
    const navigate = useNavigate();
    return (
        <PageCreateService
            navigate={navigate}
            {...props}
        />
    );
};


const RouteSignIn = (props) => {
    const navigate = useNavigate();
    return (
        <SignIn
            navigate={navigate}
            {...props}
        />
    );
};

