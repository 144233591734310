
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Typography } from '@mui/material';
import * as React from 'react';

class SystemToolbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open
        }
        this.child = React.createRef();
        this.timeoutId = null;

        this.duration = 100;
    }

    componentDidMount() {
        this.updateStyle();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.open != this.state.open) {
            this.updateStyle();
        }
    }

    updateStyle = () => {
        const element = this.child.current;
        if (this.timeoutId) clearTimeout(this.timeoutId);
        if (this.state.open) {
            const height = element.scrollHeight;
            const maxHeight = this.props.maxHeight ? Math.min(this.props.maxHeight, height) : height;
            element.style.maxHeight = `${maxHeight}px`;
            this.timeoutId = setTimeout(() => {
                element.style.height = 'auto';
                element.style.maxHeight = 'none';
                this.timeoutId = null;
            }, this.duration); // = .2s the duration of the animation
        } else {
            const height = element.getBoundingClientRect().height;
            element.style.maxHeight = `${height}px`;
            this.timeoutId = setTimeout(() => {
                element.style.maxHeight = '0px';
                this.timeoutId = null;
            });
        }
    }

    handleClick = () => {
        this.setState({ open: !this.state.open });
    }

    handleActionClick = event => {
        this.setState({ open: true});
        event.stopPropagation();
    }

    render() {
        const { sx, icon, title, maxHeight, actions, alwaysDisplayActions, children, forwardedRef, ...otherProps } = this.props;
        return <Box>
            <Box
                ref={forwardedRef}
                {...otherProps}
                sx={{
                    ...sx,
                    padding: '4px 0px 4px 0px', //top | right | bottom | left
                    minHeight: '34px',
                    color: '#444',
                    display: 'flex', // already set by the Toolbar component
                    alignItems: 'center',
                    gap: '4px',
                    cursor: 'pointer',
                }}
                onClick={this.handleClick.bind(this)}>

                <Icon
                    path={mdiChevronDown}
                    size={.6}
                    style={{
                        flexShrink: 0,
                        transform: this.state.open ? 'none' : 'rotate(-90deg)',
                        transition: `transform ${this.duration}ms`,
                    }}
                />

                {/* Title */}
                {title && <Typography
                    sx={{
                        minWidth: 0,
                        fontWeight: 500,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                    variant="body">
                    {title}
                </Typography>}

                {/* Flex Box */}
                <Box sx={{ flexGrow: 1, flexShrink: 1 }} />

                {/* children */}
                <Box sx={{
                    display: 'flex',
                    opacity: (alwaysDisplayActions || this.state.open) ? 1 : 0,
                    transition: 'opacity .2s',
                    pointerEvents: (alwaysDisplayActions || this.state.open) ? 'auto' : 'none',
                    gap: '4px',
                    flexShrink: 0,
                }}
                onClick={this.handleActionClick.bind(this)}>
                    {actions}
                </Box>
            </Box>
            <Box
                ref={this.child}
                sx={{
                    transition: `max-height ${this.duration}ms`,
                    overflow: 'hidden',
                }}>
                {children}
            </Box>
        </Box>
    }
}

/**
 * Add a fwd ref
 */
export default React.forwardRef((props, ref) => <SystemToolbar
    forwardedRef={ref}
    {...props}
/>);