import { mdiChevronDown, mdiClose, mdiCubeOutline, mdiMagnify, mdiRedo, mdiUndo } from '@mdi/js';
import Icon from '@mdi/react';
import { Avatar, IconButton, Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import React from 'react';
import { Outlet } from "react-router-dom";
import Guillaume from '../component/guillaume.jpeg';
import Button from '../system/Button';
import Toolbar from '../system/Toolbar.js';
import ToolbarDivider from '../system/ToolbarDivider';
import Tooltip from '../system/Tooltip';
import TabManagerContext from './TabManagerContext';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        // backgroundColor: '#FAFAFA',
        [theme.breakpoints.down('md')]: {
            //backgroundColor: '#FAFAFA',
        }
    },
    main: {
        zIndex: 10,
        flexGrow: 1,
        minHeight: 0,
        position: 'relative',

    },
    tab: {
        '&:hover $close': {
            visibility: 'visible'
        }
    },
    close: {
        visibility: 'hidden'
    }

});

/**
 * 
 */
class TabManager extends React.Component {

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            selectedPageIndex: -1,
            selectedPageId: null,
            pages: [],
            action: {
                name: null,
                cb: null
            }
        }
    }

    /**
     * 
     * @param {*} data 
     */
    setPages = datas => {
        const pages = Object.entries(datas).map(([id, data]) => ({ id, timestamp: Date.now(), data }));
        this.setState({
            pages,
            selectedPageIndex: 0,
            selectedPageId: pages[0].id
        });
    }

    /**
     * 
     * @param {*} data 
     */
    addPage = (id, data) => {
        if (this.state.pages.findIndex(page => page.id === id) > -1) {
            throw new Error('Page already exists.');
        }
        this.state.pages.push({ id, timestamp: Date.now(), data });
        this.setState({
            pages: this.state.pages,
        });
        /*this.setState({
            pages: this.state.pages,
            selectedPageIndex: this.state.pages.length - 1, // select the one just opened
            selectedPageName: data.name, // select the one just opened
        });*/
    }

    renamePage = (id, name) => {
        const index = this.getPageIndex(id);
        var n = this.state.pages[index].data.name;
        if (n != name) {
            this.state.pages[index].data.name = name;
            this.setState({
                pages: this.state.pages
            })
        }
    }

    /**
     * 
     * @param {*} id 
     */
    getPageIndex = id => {
        return this.state.pages.findIndex(page => {
            return page.id === id;
        });
    }

    /**
     * Open a resource (= open a tab and load this resources)
     * @param {*} resourceName the resource name (= moduleA/moduleB/resourceId)
     * @param {*} src the version to open
     * @param {*} application the application to use to open it (ex: code editor, config editor ...)
     */
    openPage = id => {
        // check if the resource is already opened
        var index = this.getPageIndex(id);
        if (index === -1) throw new Error(`Page ${id} does not exists.`)
        this.openPageAt(index);
    }

    /**
     * 
     * @param {*} index 
     */
    openPageAt = index => {
        this.setState({
            selectedPageIndex: index,
            selectedPageId: this.state.pages[index].id,
        });
    }

    /**
     * Close the resource/tab at the specified index
     * @param {*} index 
     */
    removePage = id => {
        var index = this.getPageIndex(id);
        if (index === -1) throw new Error(`Page ${id} does not exists.`);
        this.removePageAt(index);
    }

    /**
     * 
     * @param {*} index 
     */
    removePageAt = index => {
        var pages = this.state.pages;
        pages.splice(index, 1);
        // get the tabs with the highest timestamp (last opened)
        var timestamp = 0;
        var selectedPageIndex = 0;
        var selectedPageId = null;
        pages.forEach((page, index) => {
            if (page.timestamp > timestamp) {
                timestamp = page.timestamp;
                selectedPageIndex = index;
                selectedPageId = page.id;
            }
        });
        this.setState({
            pages,
            selectedPageIndex,
            selectedPageId,
        });
    }

    /**
     * 
     */
    removeAllPages = () => {
        this.setState({
            selectedPageIndex: -1,
            selectedPageId: null,
            pages: []
        })
    }

    /**
     * 
     * @param {*} event 
     * @param {*} index 
     */
    handlePageChange = (event, index) => {
        this.openPageAt(index);
    };

    /**
     * 
     * @param {*} index 
     * @returns 
     */
    handlePageClose = index => event => {
        // avoid propagate the event to the tab (otherwise tabs will try to open it ...)
        event.stopPropagation();
        this.removePageAt(index);
    }

    setAction = (name, cb) => {
        this.setState({
            action: {
                name,
                cb
            }
        });
    }

    handleAction = event => {
        if (this.state.action.cb) this.state.action.cb();
    }

    /**
     * 
     * @returns 
     */
    render() {
        const { classes } = this.props;
        return (
            <Box className={classes.root}>
                <Box sx={{
                    //background: '#47737c', //'#f6f6f6', //'#F1F9FB',
                    backgroundColor: '#FFF', //'#F4F5F6', //'#eff3f4',
                    borderBottom: '1px solid rgb(67, 118, 67, .1)',// '1px solid #EBEBEB',
                }}>
                    <Toolbar sx={{ height: 48, pr: 2, pl: 3 }}>
                        {true && <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', flexShrink: 0 }}>
                            <Icon path={mdiCubeOutline} size={1.2} />
                        </Box>}

                        <Tabs
                            sx={{
                                flexGrow: 1,
                            }}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            TabIndicatorProps={{
                                style: {
                                    display: 'none',
                                    /*backgroundColor: '#95f8dd',
                                    height: '60%',
                                    borderRadius: '4px',
                                    marginBottom: '9px',
                                    border: '1px solid rgba(0,0,0,.08)',*/
                                }
                            }}
                            value={this.state.selectedPageIndex}
                            onChange={this.handlePageChange} >

                            {this.state.pages.map(p => p.data).map(({ name, description, icon }, index) => (
                                <Tooltip key={name} title={description}>
                                    <Tab
                                        className={classes.tab}
                                        sx={{ zIndex: 10 }}
                                        disableRipple
                                        onAuxClick={event => event.button == 1 && this.handlePageClose(index)(event)}
                                        label={
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                /*pb: '4px',
                                                borderBottom: this.state.selectedPageIndex == index ? '1px solid #000' : '1px solid transparent',
                                                pt: '4px',*/
                                            }}>
                                                {icon && <Icon path={icon} size={.5} />}
                                                <Typography
                                                    sx={{
                                                        ml: '3px',
                                                        fontWeight: 400
                                                    }}
                                                    variant="body">
                                                    {name}
                                                </Typography>
                                                <IconButton
                                                    className={classes.close}
                                                    sx={{ ml: '6px', p: '2px', color: '#000000AA' }} onClick={this.handlePageClose(index)}>
                                                    <Icon path={mdiClose} size={.6} />
                                                </IconButton>
                                            </Box>
                                        }
                                    />
                                </Tooltip>
                            ))}
                        </Tabs>

                        <Box sx={{ flexGrow: 1 }}></Box>

                        <ToolbarDivider />

                        <IconButton onClick={this.props.undo} ><Icon color={'#000'} path={mdiUndo} size={.8} /></IconButton>
                        <IconButton onClick={this.props.redo} ><Icon color={'#000'} path={mdiRedo} size={.8} /></IconButton>


                        <ToolbarDivider />
                        <Button color="primary" sx={{ textTransform: 'none', fontSize: 12, p: '6px 16px', fontWeight: 400 }}>Visual Editor<Icon style={{ marginLeft: 8 }} path={mdiChevronDown} size={.8} /></Button>


                        <ToolbarDivider />

                        <Avatar sx={{ m: 'auto', width: 34, height: 34, border: '1px solid #87fcc4' }} alt="Remy Sharp" src={Guillaume} />

                        <ToolbarDivider />

                        <IconButton onClick={() => alert('Search not implemented')} ><Icon color={'#000'} path={mdiMagnify} size={.9} /></IconButton>

                        {this.state.action.cb != null &&
                            <>
                                <ToolbarDivider />
                                <Button sx={{ p: '4px 8px', fontSize: 12 }} style={{
                                    backgroundColor: "#OOO",
                                    color: "#FFF",
                                }} variant="contained" onClick={(this.handleAction.bind(this))}>{this.state.action.name || 'GO'}</Button>
                            </>}
                    </Toolbar>
                </Box>

                <Box className={classes.main}>
                    <TabManagerContext.Provider value={{
                        selectedPageId: this.state.selectedPageId,
                        setPages: this.setPages.bind(this),
                        addPage: this.addPage.bind(this),
                        renamePage: this.renamePage.bind(this),
                        removePage: this.removePage.bind(this),
                        removeAllPages: this.removeAllPages.bind(this),
                        openPage: this.openPage.bind(this),
                        setAction: this.setAction.bind(this),
                    }}>
                        <Outlet />
                    </TabManagerContext.Provider>
                </Box>

            </Box>
        )
    }
}

export default withStyles(styles)(TabManager);