import { mdiBucketOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Box } from "@mui/material";
import React from "react";


class ResourceIcon extends React.Component {

    constructor(props) {
        super(props);
        if (this.props.width && this.props.height) {
            this.state = {
                size: {
                    w: this.props.width,
                    h: this.props.height,
                }
            }
        } else {
            this.state = {}
        }
        this.ref = React.createRef();
    }

    componentDidMount() {
        let rect = this.ref.current.getBoundingClientRect();
        this.setState({
            size: {
                w: rect.width,
                h: rect.height
            }
        });
    }

    render() {
        var { 
            forwardedRef, 
            sx, 
            color = '#333', 
            opacity = 1, 
            variant,
            radius = 0,
            width = 28, 
            height = 28,
             path,
             animation, 
             ...otherProps 
        } = this.props;

        return <Box
            ref={forwardedRef}
            sx={{
                ...sx,
                display: 'flex',
                position: 'relative',
                width,
                height,
                transition: 'opacity 150ms linear',
                opacity,
                //background: (variant == 'contained') ? (gradient ? `linear-gradient(45deg, ${color} 0%, ${shadeColor(color, 70)} 100%)` : color) : 'transparent',
                color: '#fff',
                borderRadius: '2px',
                animation,
                //border: '1px solid #FFFFFF50',
                //border: (variant == 'outlined') ? '1px solid ' + color : (variant == 'contained' ? '1px solid #FAFAFA' : 'none'),
            }}
            {...otherProps}>
            <Box ref={this.ref} sx={{
                //width: '100%',
                //height: '100%',
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: color,
                backgroundImage: `linear-gradient(${color}, #00000020)`,
                //border: '1px solid ' + color,
                //margin: '1px',
                borderRadius: radius + 'px',
                //border: '1px solid #fdfdfd',
                //boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.08), 0 4px 8px 0 rgba(55, 55, 55, 0.1)',
                //borderRadius: '2px',
            }}>
                <Icon path={path} size={this.props.iconSize || .8} />
            </Box>
        </Box>
    }
}

export default React.forwardRef((props, ref) => {
    return <ResourceIcon {...props} forwardedRef={ref} />;
})
