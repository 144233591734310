import React from 'react';
import ModuleFileView from '../module/ModuleFileView';
import EditorContext from '../editor/EditorContext.js';
import withContext, { chain } from '../utils/WithContext';

/**
 * 
 */
class ModuleView extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <>
            {this.props.tabsIds.map(tabId => {
                const tab = this.props.getTab(tabId);
                return <ModuleFileView
                    key={tabId}
                    sx={{
                        flexGrow: 1,
                        display: tabId === this.props.selectedTabId ? 'block' : 'none',
                    }}
                    artifact={tab.getArtifact()}
                    filepath={tab.getFilepath()}
                />
            })}
        </>
    }
}

export default chain(
    withContext(EditorContext)(({ getTab }) => ({ getTab })),
)(ModuleView);
