
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';


export default (props) => (
    <Toolbar
        disableGutters
        variant={'dense'}
        {...props}>
        {props.children}
    </Toolbar>
);

