import { mdiTarget } from '@mdi/js';
import Icon from '@mdi/react';
import { getTouchRippleUtilityClass, IconButton } from '@mui/material';
import React from 'react';
import AppContext from '../app/AppContext.js';
import MouseDownGestureListener from '../infinite/InfiniteGesture.js';
import ResourceContext from '../resource/ResourceContext.js';
import withContext, { chain } from '../utils/WithContext';
import ModuleContext from './ModuleContext.js';


export class DragValue extends React.Component {

    constructor(props) {
        super(props);
        this.element = React.createRef();
    }

    componentDidMount() {
        this.gesture = new MouseDownGestureListener(this.element.current);
        this.gesture.listenMouseEvents();
        this.setDragValueGestureListener();
    }
    

    setDragValueGestureListener() {
        var connectedTo = null;
        const shouldConnect = resourceId => {
            // get the service of the resource we are draggin into
            const resource = this.props.resources[resourceId];
            const serviceName = resource.service.split(':')[0];
            console.log('serviceName', serviceName)
            if (this.props.dependency.service === serviceName) {
            //if (resource.service.startsWith('functions:')) {
                connectedTo = resourceId;
                return true;
            } else {
                connectedTo = null;
                return false;
            }    
        }
        this.gesture.condition(({ event }) => event.which == 1 && !event.metaKey)
            .onStart(({ event }) => {
                connectedTo = null;
                this.props.setConnecting({
                    start: {
                        id: this.props.resourceId,
                    },
                    end: {
                        x: event.clientX,
                        y: event.clientY,
                        referentiel: 'client',
                        shouldConnect: (resourceId) => {
                            connectedTo = resourceId;
                            return true;
                        }, // TODO
                    }
                });
            })
            .onMove(({ event }) => {
                console.log('event', event.clientX)
                this.props.setConnecting({
                    start: {
                        id: this.props.resourceId,
                    },
                    end: {
                        x: event.clientX,
                        y: event.clientY,
                        referentiel: 'client',
                        shouldConnect: (resourceId) => {
                            connectedTo = resourceId;
                            return true;
                        }, // TODO
                    }
                });
            })
            .onStop(({ event }) => {
                if (connectedTo) {
                    const r = this.props.module[this.props.resourceId];
                    if (!r.hasOwnProperty('dependencies')) r.dependencies = {};
                    debugger
                    r.dependencies[this.props.dependencyName] = connectedTo;
                    this.props.artifact.editFile('/module.json', JSON.stringify(this.props.module, null, 2));
                }

                this.props.setConnecting(null);

            })
            .onCancel(() => {
                console.log('CANCEL')
            });
    }

    render() {
        return <IconButton ref={this.element} sx={{ p: '4px', background: 'rgba(0,0,0,.06)', color: 'black', border: '1px solid rgba(0,0,0,.01)' }}>
            <Icon path={mdiTarget} size={.6} />
        </IconButton>
    }

}

export default chain(
    withContext(ResourceContext)(({ artifact }) => ({ artifact })), // TODO clean
    withContext(ModuleContext)(({ module, resources, setConnecting }) => ({ module, resources, setConnecting })),
)(DragValue)
