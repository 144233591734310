
import { Box } from '@mui/material';
import * as React from 'react';

/**
 * 
 */
class SystemDivider extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <Box sx={{
            margin: '10px 0',
            height: '1px',
            width: 'auto',
            backgroundColor: 'rgba(0,0,0,.05)',
        }} />
    }
}

export default SystemDivider