
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';

/**
 * Bootstrapped button
 */
const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 12,
    padding: '6px 8px 6px 6px',
    lineHeight: 1.1,
    backgroundColor: '#171717', //'#1e883e',
    fontWeight: 500,
    //border: '1px solid rgba(0,0,0,.1)',
    color: 'white',
    borderRadius: '3px',
    display: 'flex',
    gap: '4px',
    '&:hover': {
        backgroundColor: '#19d57b',
    },
    '&:active': {
        backgroundColor: '#19d57b',
    },
    '&:focus': {
        //boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});


/**
 * 
 */
class SystemButton extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { children, forwardedRef, ...otherProps } = this.props;
        return <BootstrapButton
            ref={this.props.forwardedRef}
            {...otherProps}
        >
            {this.props.children}
        </BootstrapButton>
    }
}

/**
 * Add a fwd ref
 */
export default React.forwardRef((props, ref) => <SystemButton
    forwardedRef={ref}
    {...props}
/>);