import React from 'react';

/**
 * 
 */
export default Context => reducer => Component => React.forwardRef((props, ref) => {
    return <Context.Consumer>
        {(context) => {
            var opt = reducer(context, props);
            return <Component
                ref={ref}
                {...opt}
                {...props}>
                {props.children}
            </Component>
        }}
    </Context.Consumer>
})

/**
 * 
 * @param  {...any} args 
 * @returns 
 */
export const chain = (...args) => Component => {
    args = args.reverse();
    var fn = Component;
    for (var i = 0; i < args.length; i++) {
        fn = args[i](fn);
    }
    return fn;
}