
export const chain = (actions, delay) => {
    if (actions.length == 0) return;
    console.log('executing action', actions[0])
    let a = actions[0];
    let arr = actions.slice(1);
    if (delay) {
        setTimeout(() => {
            a();
            chain(arr, 1000);
        }, delay);
    } else {
        a();
        chain(arr, 1000);
    }
}

// move to utility class ?
const isObject = object => {
    return object != null && typeof object === 'object';
}

export const deepEqual = (object1 = {}, object2 = {}) => {
    if (!object1 && !object2) return true;
    if (!object1 || !object2) return false;
    const t1 = typeof object1;
    const t2 = typeof object2;
    if (t1 !== t2) return false;
    if (t1 === 'object') return deepEqualObject(object1, object2);
    else return object1 === object2;
}

const deepEqualObject = (object1 = {}, object2 = {}) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            areObjects && !deepEqual(val1, val2) ||
            !areObjects && val1 !== val2
        ) {
            return false;
        }
    }
    return true;
}

/**
 * 
 * @param {*} p1 
 * @param {*} p2 
 * @param {*} fields 
 */
export const arePropsEquals = (o1, o2, fields) => {
    let equals = true;
    fields.forEach(field => {
        equals = equals && deepEqual(o1[field], o2[field]);
    });
    return equals;
}

/**
 * 
 * @param {*} o1 
 * @param {*} o2 
 * @param {*} fields 
 * @returns 
 */
export const getDiffFields = (o1, o2, fields) => {
    const diff = [];
    fields.forEach(field => {
        if (!deepEqual(o1[field], o2[field])) diff.push(field);
    });
    return diff;
}