import { mdiCubeOutline, mdiDotsVertical, mdiRestore } from '@mdi/js';
import Icon from '@mdi/react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import 'firebase/firestore';
import React from 'react';
import SystemIconButton from '../system/SystemIconButton';
import SystemLink from '../system/SystemLink';
import SystemPopper from '../system/SystemPopper';
import withContext, { chain } from '../utils/WithContext';
import ServiceContext from './ServiceContext';

/**
 * 
 */
class ImplementationArtifactComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
        this.button = React.createRef();
    }

    open = open => {
        this.setState({ open });
    }

    /**
     * 
     * @param {*} name 
     */
    handleRestoreArtifact = name => {
        const artifact = this.props.getArtifact(name);
        this.props.addSource(name, 'file:' + artifact.name);
    }

    render() {
        const { artifactPrettyName, artifactName } = this.props;

        let color;
        if (this.props.isArtifactCreated(artifactName)) color = '#a6aab2';
        else color = 'red';

        return <Box sx={{
            width: '100%',
            height: 30,
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            padding: '0 4px',
        }}>
            <Icon style={{ color }} path={mdiCubeOutline} size={.6} />
            <SystemLink sx={{ color }} onClick={() => this.props.openArtifact(artifactName)}>{artifactPrettyName}</SystemLink>
            <Box sx={{ flexGrow: 1, minWidth: '16px' }}></Box>
            <SystemIconButton
                disabled={this.state.open}
                ref={this.button}
                sx={{ color: '#333' }}
                path={mdiDotsVertical}
                onClick={() => this.open(!this.state.open)}
            />
            <SystemPopper
                noAnimation
                placement={'right-start'}
                anchorEl={this.button.current}
                onClickAway={() => this.open(false)}
                open={this.state.open}
                offset={8}
                sx={{
                    borderRadius: '4px',
                    backgroundColor: '#eaeef5',//'#dcdee5', 
                    border: '1px solid rgba(0,0,0,.04)',
                    marginTop: this.state.open ? 0 : '-10px',
                    transition: 'margin .2s',
                }}
            >
                <Box sx={{
                    //width: '200px',
                    padding: '0px',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        padding: '8px 22px 8px 16px',
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: 'rgba(0,0,0,.04)'
                        }
                    }}
                        onClick={() => this.handleRestoreArtifact(artifactName)}>
                        <Icon path={mdiRestore} size={.7} />
                        <Typography variant={'body2'} sx={{ fontWeight: 400, color: '#3c414b', letterSpacing: '1px' }} component={'p'}>RESTORE</Typography>
                    </Box>

                </Box>
            </SystemPopper>
        </Box>
    }
}

export default chain(
    withContext(ServiceContext)(({ openArtifact, getArtifact, removeSource, isArtifactCreated, addSource, }) => ({ openArtifact, getArtifact, removeSource, isArtifactCreated, addSource })),
)(ImplementationArtifactComponent);
