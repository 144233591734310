import Icon from '@mdi/react';
import { Box, Typography } from "@mui/material";


export default ({ icon, name, small, more, description, children, primary, ...otherProps }) => (
    <Box {...otherProps}>

        <Box sx={{
            display: 'flex',
            mb: '4px'
        }}>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                {icon && <Icon style={{marginRight: 8}} path={icon} size={small ? .8 : 1.1} />}
                <Typography sx={{
                    fontWeight: primary ? 600 : 500,
                }}
                    variant={small ? "h4" : "h3"}>
                    {name}
                </Typography>
            </Box>
            {more}
        </Box>

        {description && <Typography component={'div'} sx={{ color: '#00000060', mb: 1, fontWeight: 400 }}
            variant={"body"}>
            {description}
        </Typography>}

        {children}

    </Box>
)

