import DiffMatchPatch from 'diff-match-patch';

const dmp = new DiffMatchPatch();

export const RedoUndoHistoryEvents = {
    REDO_CLEARED: 'REDO_CLEARED',
    UNDO_ADDED: 'UNDO_ADDED',
    REDO_ADDED: 'REDO_ADDED',
    UNDO_REMOVED: 'UNDO_REMOVED',
    REDO_REMOVED: 'REDO_REMOVED',
}

export const RedoUndoValueEvents = {
    SET: 'set',
    UNDO: 'undo',
    REDO: 'redo',
}

class RedoUndoString {

    /**
     * 
     * @param {*} value 
     * @param {*} valueListener 
     * @param {*} historyListener 
     */
    constructor(value, valueListener, historyListener) {
        this.version = 0;
        this.value = value; // the current value
        this.undoPatches = []; // all patches for undo
        this.redoPatches = []; // all patches for do (removed when a new value is set)
        // listener
        this.onHistoryChange = (type, timestamp) => {
            historyListener && historyListener({
                timestamp, // timestamp of the initial event
                type
            })
        }
        this.onValueChange = event => {
            // may optimize by doing a -- for an undo or a ++ when there is a do or a new set.
            // BUT it can be risky, maybe things won't be synchronized well and we can have an
            // incorrect version number.
            this.version = this.version + 1;
            valueListener && valueListener(event);
        }

    }



    /**
     * 
     * @returns 
     */
    get() {
        return this.value;
    }

    /**
     * 
     * @returns 
     */
    getVersion() {
        return this.version;
    }

    /**
     * 
     */
    clear() {
        this.redoPatches = [];
    }

    /**
     * 
     * @param {*} value 
     */
    set(value) {
        if (value == this.value) return;
        // clear the do patches
        if (this.redoPatches.length > 0) {
            this.clear();
            this.onHistoryChange(RedoUndoHistoryEvents.REDO_CLEARED);
        }
        // timestamp of the event
        var timestamp = new Date().getTime();
        // go from the new value to current value 
        var undoPatch = dmp.patch_make(value, this.value);
        var undoPatchFn = () => {
            var [undoValue, result] = dmp.patch_apply(undoPatch, this.value);
            // go from the current value to new value
            var redoPatch = dmp.patch_make(undoValue, this.value);
            var redoPatchFn = () => {
                var [redoValue, result] = dmp.patch_apply(redoPatch, this.value);
                this.undoPatches.push([undoPatchFn, timestamp]);
                this.onHistoryChange(RedoUndoHistoryEvents.UNDO_ADDED, timestamp);
                this.value = redoValue;
                this.onValueChange({ type: RedoUndoValueEvents.REDO, value: this.value });
                return this;
            };

            this.redoPatches.push([redoPatchFn, timestamp]);
            this.onHistoryChange(RedoUndoHistoryEvents.REDO_ADDED, timestamp);
            this.value = undoValue;
            this.onValueChange({ type: RedoUndoValueEvents.UNDO, value: this.value });
            return this;
        };
        
        this.undoPatches.push([undoPatchFn, timestamp]);
        this.onHistoryChange(RedoUndoHistoryEvents.UNDO_ADDED, timestamp);
        this.value = value;
        this.onValueChange({ type: RedoUndoValueEvents.SET, value: this.value });
    }

    /**
     * 
     * @returns 
     */
    undo() {
        var patch = this.undoPatches.pop();
        if (patch != null) {
            var [ fn, timestamp ] = patch;
            this.onHistoryChange(RedoUndoHistoryEvents.UNDO_REMOVED, timestamp);
            fn();
        }
        return this.value;
    }

    /**
     * 
     * @returns 
     */
    redo() {
        var patch = this.redoPatches.pop();
        if (patch != null) {
            var [ fn, timestamp ] = patch;
            this.onHistoryChange(RedoUndoHistoryEvents.REDO_REMOVED, timestamp);
            fn();
        }
        return this.value;
    }

    /**
     * 
     * @returns 
     */
    isModified() {
        return this.undoPatches.length > 0;
    }
}

export default RedoUndoString;