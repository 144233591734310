import { Avatar, CircularProgress, Divider, FormControl, FormControlLabel, InputAdornment, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { createHost } from '../BackendFunctions';
import AuthenticationContext from '../authentication/AuthenticationContext';
import Guillaume from '../component/guillaume.jpeg';
import Button from '../system/Button';
import Input from '../system/Input';
import InputSection from '../system/InputSection';
import TitledBox from '../system/TitledBox';
import withContext, { chain } from '../utils/WithContext';

const styles = () => ({
    main: {
        background: '#FFF',
        marginTop: 30,
        padding: 40,
        paddingTop: 30,
        paddingBottom: 40,
        maxWidth: 900,
        margin: 'auto',
    }
});

class PageCreateHost extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: null,
            uri: null, //location: 'GCP/europe-west1',
            app: null,
        }
    }

    onChange = (field) => (event) => {
        this.setState({
            [field]: event.target.value
        })
    }

    create() {
        this.setState({
            creating: true
        });
        createHost(this.state).then(host => {
            this.setState({
                creating: false
            });
            this.props.navigate(`/hosts/${host.id}`);
        }).catch(err => {
            this.setState({
                creating: false
            });
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <TitledBox
                className={classes.main}
                primary
                name='Create a new host'
                description='Apps are deployed on an host.'>

                <Divider sx={{ mb: 2 }} />

                <InputSection sx={{ mb: 2 }} name='Host owner'>
                    <Input
                        readOnly
                        sx={{ width: '300px', fontWeight: 500, backgroundColor: 'transparent' }}
                        value={"Guillaume"}
                        startAdornment={<InputAdornment position="start">
                            <Avatar sx={{ width: 28, height: 28 }} alt="Remy Sharp" src={Guillaume} />
                        </InputAdornment>}
                    >
                    </Input>
                </InputSection>

                <Divider sx={{ mt: 2, mb: 2 }} />

                <InputSection mandatory sx={{ mb: 2, width: '300px' }} name='Host name'>
                    <Input sx={{ width: '100%' }} readOnly={this.state.creating} onChange={this.onChange('name').bind(this)} />
                </InputSection>

                <Divider sx={{ mt: 2, mb: 2 }} />

                <InputSection mandatory sx={{ mb: 2, width: '300px' }} name='Host uri'>
                    <Input sx={{ width: '100%' }} readOnly={this.state.creating} onChange={this.onChange('uri').bind(this)} />
                </InputSection>

                {false && <InputSection
                    name="Project location"
                    mandatory
                    description="A project is backed by a cloud provider instance. A project is backed by a cloud provider instance. A project is backed by a cloud provider instance. A project is backed by a cloud provider instance.">
                    <FormControl sx={{ width: '320px' }} size="small" >
                        <Select
                            readOnly={this.state.creating}
                            variant="standard"
                            inputProps={{
                                sx: { padding: '6px 2px 6px 8px', background: '#EEE', color: '#333', fontSize: 14 }
                            }}

                            MenuProps={{
                                sx: { fontSize: 10 },
                                PaperProps: {
                                    sx: {
                                        maxHeight: '300px',
                                        '& .MuiMenuItem-root': {
                                            padding: '2px 12px 2px 12px',
                                        },
                                    },
                                },
                                MenuListProps: {
                                    dense: true,
                                }
                            }}
                            disableUnderline
                            value={this.state.location}
                            onChange={this.onChange('location').bind(this)}
                        >
                            <MenuItem disabled sx={{ fontSize: 12, fontWeight: 500 }}>NORTH AMERICA</MenuItem>
                            <MenuItem value={"GCP/europe-west1"}>europe-west1 (GCP)</MenuItem>
                            <MenuItem disabled sx={{ mt: 2, fontSize: 12, fontWeight: 500 }}>EUROPE</MenuItem>
                            <MenuItem value={"GCP/europe-west2"}>europe-west2 (GCP)</MenuItem>
                            <MenuItem value={"GCP/europe-west3"}>europe-west3 (GCP)</MenuItem>
                            <MenuItem value={"GCP/europe-west4"}>europe-west4 (GCP)</MenuItem>
                            <MenuItem disabled sx={{ mt: 2, fontSize: 12, fontWeight: 500 }}>ASIA</MenuItem>
                            <MenuItem value={"GCP/europe-westd2"}>europe-west2 (GCP)</MenuItem>
                            <MenuItem value={"GCP/europe-westd3"}>europe-west3 (GCP)</MenuItem>
                            <MenuItem value={"GCP/europe-westd4"}>europe-west4 (GCP)</MenuItem>
                            <MenuItem value={"GCP/eursope-westd2"}>europe-west2 (GCP)</MenuItem>
                            <MenuItem value={"GCP/eursope-westd3"}>europe-west3 (GCP)</MenuItem>
                            <MenuItem value={"GCP/eursope-westd4"}>europe-west4 (GCP)</MenuItem>
                        </Select>
                    </FormControl>
                </InputSection>}

                {true && <Divider sx={{ mt: 2, mb: 2 }} />}

                {false && <InputSection name="App" >
                    <FormControl size="small" readOnly={this.state.creating}>
                        <RadioGroup defaultValue="none">
                            <FormControlLabel componentsProps={{ typography: { fontSize: 14 } }} value="static" control={<Radio size="small" />} label="Static website hosting project" />
                            <FormControlLabel componentsProps={{ typography: { fontSize: 14 } }} value="dynamic" control={<Radio size="small" />} label="Dynamic website hosting project" />
                            <FormControlLabel componentsProps={{ typography: { fontSize: 14 } }} value="api" control={<Radio size="small" />} label="API hosting project" />
                        </RadioGroup>
                    </FormControl>
                </InputSection>}

                {true && <InputSection
                    name="App to deploy"
                    mandatory
                    description="A project is backed by a cloud provider instance. A project is backed by a cloud provider instance. A project is backed by a cloud provider instance. A project is backed by a cloud provider instance.">
                    <FormControl sx={{ width: '320px' }} size="small" >
                        <Select
                            readOnly={this.state.creating}
                            variant="standard"
                            inputProps={{
                                sx: { padding: '6px 2px 6px 8px', background: '#EEE', color: '#333', fontSize: 14 }
                            }}

                            MenuProps={{
                                sx: { fontSize: 10 },
                                PaperProps: {
                                    sx: {
                                        maxHeight: '300px',
                                        '& .MuiMenuItem-root': {
                                            padding: '2px 12px 2px 12px',
                                        },
                                    },
                                },
                                MenuListProps: {
                                    dense: true,
                                }
                            }}
                            disableUnderline
                            value={this.state.app}
                            onChange={this.onChange('app').bind(this)}
                        >

                            {this.props.userInfo.apps?.map(app => (
                                <MenuItem value={app.id}>{app.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </InputSection>}


                <Divider sx={{ mt: 2, mb: 2 }} />

                {this.state.creating ? <CircularProgress sx={{ ml: 2 }} size={25} thickness={6} /> : <Button variant="contained" onClick={this.create.bind(this)}>Deploy</Button>}

            </TitledBox>
        );
    }
}

export default chain(
    withContext(AuthenticationContext)(({ user, userInfo }) => ({ user, userInfo })),
    withStyles(styles),
)(PageCreateHost);
