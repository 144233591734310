
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Typography } from '@mui/material';
import * as React from 'react';


class SystemTitle extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { sx, icon, title, children, forwardedRef, ...otherProps } = this.props;
        return <Box
            ref={forwardedRef}
            {...otherProps}
            sx={{
                ...sx,

                cursor: 'pointer',
                padding: '3px 8px',
                flexGrow: 1,
                borderRadius: '5px',
                transition: 'background-color .2s',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                '&:hover': {
                    backgroundColor: '#f3f3f3',
                }
            }}>

            {/* Icon */}
            {icon && <Icon
                path={icon}
                size={.7}
                style={{
                    color: '#0e87ff',
                    flexShrink: 0,
                }}
            />}

            <Box sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
            }}>
                {title && title.split('/')
                    .filter(v => v)
                    .map((v, i, arr) => v
                        ? <Typography sx={{ fontWeight: (i + 1 === arr.length) ? 500 : 400 }} variant="body">{v}</Typography>
                        : <></>)
                    .reduce((accu, elem) => {
                        return accu === null
                            ? [elem]
                            : [...accu, <Typography sx={{ fontWeight: 400 }} variant="body">{'/'}</Typography>, elem]
                    }, null)}
            </Box>

            <Icon
                path={mdiChevronDown}
                size={.7}
                style={{
                    color: '#444',
                    flexShrink: 0,
                }}
            />
        </Box>
    }
}

/**
 * Add a fwd ref
 */
export default React.forwardRef((props, ref) => <SystemTitle
    forwardedRef={ref}
    {...props}
/>);
