
import { mdiCube, mdiCubeOutline } from '@mdi/js';
import { mdilHexagon } from '@mdi/light-js';
import Icon from '@mdi/react';
import { Box } from '@mui/material';
import * as React from 'react';

/**
 * 
 */
class SystemAppBar extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { sx, tabs, children, forwardedRef, ...otherProps } = this.props;
        return <Box
            ref={forwardedRef}
            {...otherProps}
            sx={{
                ...sx,
                //padding: '0px 8px 0px 8px', //top | right | bottom | left
                minHeight: '56px', //'28px',
                padding: '0 8px',
                //borderBottom: '1px solid #f0f0f2',
                display: 'flex',
                //flexDirection: 'column',
                alignItems: 'center',
                //gap: '16px'
                //backgroundColor: '#e1e5eb', //'#eef3f6', //'#f0f2f6',
            }}>



            {children}


        </Box>
    }
}

/**
 * Add a fwd ref
 */
export default React.forwardRef((props, ref) => <SystemAppBar
    forwardedRef={ref}
    {...props}
/>);


/*

            <Box sx={{ flexGrow: 1, flexShrink: 1 }} />

            <Avatar sx={{ m: 'auto', width: 34, height: 34, border: '1px solid #87fcc4' }} alt="Remy Sharp" src={Guillaume} />


*/