import { mdiArrowRightBold, mdiCircle, mdiClose, mdiContentSaveOutline, mdiCubeOutline, mdiHome, mdiJellyfishOutline, mdiLightningBolt, mdiSquareOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Avatar, CircularProgress, Fade, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import uuid from 'react-uuid';
import { createAppVersion, getApp, getArchitecture } from '../BackendFunctions';
import ArtifactProvider from '../artifact/ArtifactProvider';
import AuthenticationContext from '../authentication/AuthenticationContext';
import CacheContext from '../cache/CacheContext';
import Guillaume from '../component/amadeus.png';
import ErrorHandlerContext from '../error/ErrorHandlerContext';
import { bucket, reference, uploadString } from '../sdk/Bucket';
import SystemAppBar from '../system/SystemAppBar';
import SystemButton from '../system/SystemButton';
import SystemIconButton from '../system/SystemIconButton';
import SystemLoading from '../system/SystemLoading';
import withContext, { chain } from '../utils/WithContext';
import AppContext from './AppContext';
import Resource from './Resource';
import SystemIconButtonLarge from '../system/SystemIconButtonLarge';

/**
 * 
 */
class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            // loading
            loading: true,
            // the loaded app
            app: null,
            // whether or not we are saving the app
            saving: false,
            // the opened artifacts
            openedArtifacts: [],
            // the updated artifacts
            updatedArtifacts: [],
            // the one selected
            selectedArtifact: null,

        }
        // TODO
        this.changeId = uuid();
        // cache to avoid uploading several time the same file
        this.uploadCache = {};

        this.tabs = React.createRef();

        this.versionListener = (version, name) => {
            const updatedArtifacts = [...this.state.updatedArtifacts];
            if (!updatedArtifacts.includes(name)) {
                updatedArtifacts.push(name);
                this.setState({
                    updatedArtifacts
                });
            }
        }

        this.wheelListener = null;
    }

    /**
     * 
     */
    componentDidMount() {
        // preload services
        this.props.getServices();

        // listen to app changes
        return getApp(this.props.appId).then(app => {

            const artifact = this.props.createArtifact('file:main');
            artifact.setSrc({
                bucket: 'apps',
                path: app.resource.src,
            });
            artifact.getFolder('/').then(async ({ files }) => {
                // TODO - find the correct place to create resource files 
                if (!files.includes('resource.json')) {
                    await artifact.createFile('/resource.json', JSON.stringify(JSON.parse('{"name":"e5-mistral-7b-instruct", "service": "modules", "description": "CodeArtifact vous permet de stocker des artefacts en utilisant des gestionnaires de paquets et des outils de construction populaires comme Maven, Gradle, npm, Yarn, Twine, pip et NuGet. CodeArtifact peut récupérer automatiquement les packages logiciels à la demande à partir des dépôts de packages publics afin que vous puissiez accéder aux dernières versions des dépendances des applications."}'), null, 2));
                    await artifact.createFile('/module.json', '{}');
                }
                // Open the artifact and then unset the loading state
                this.props.preloadArtifact(artifact).then(() => {
                    this.openArtifact('main').then(() => {
                        this.setState({
                            loading: false,
                            app,
                        });
                    });
                });
            });

        });
    }

    componentDidUpdate(oldProps, oldState) {
        // listen version changed on artifacts and unlisten for closed ones
        const prevArtifacts = oldState.openedArtifacts;
        const nextArtifacts = this.state.openedArtifacts;
        nextArtifacts.filter(a => !prevArtifacts.includes(a)).forEach(a => this.getArtifact(a).addVersionChangeListener(this.versionListener));
        prevArtifacts.filter(a => !nextArtifacts.includes(a)).forEach(a => this.getArtifact(a).removeVersionChangeListener(this.versionListener));

        // listen the wheel event for the tab component.
        if (!this.state.loading && !this.wheelListener) {
            this.wheelListener = this.tabs.current.addEventListener("wheel", this.handleWheelEvent.bind(this));
        }
    }

    /**
     * 
     * @param {*} param0 
     */
    handleWheelEvent = ({ deltaX, deltaY }) => {
        const startX = this.tabs.current.scrollLeft;
        this.tabs.current.scroll({
            top: 0,
            left: startX + 4 * deltaY,
            behavior: "smooth",
        });
    }

    /**
     * 
     * @param {*} resourceName 
     * @returns 
     */
    getArtifact = resourceName => {
        const artifactName = `file:${resourceName}`;
        if (!this.props.existsArtifact(artifactName)) {
            const artifact = this.props.createArtifact(artifactName);
            const modulePath = resourceName.substring(0, resourceName.lastIndexOf('/'));
            const resourceId = resourceName.substring(resourceName.lastIndexOf('/') + 1);
            const module = this.getArtifact(modulePath).getLocalFileAsJson('/module.json');
            const resource = module[resourceId];
            const src = resource.resource;
            if (src) {
                artifact.setSrc({
                    bucket: 'apps',
                    path: src,
                });
            }
            return artifact;
        } else {
            return this.props.getArtifact(artifactName);
        }
    }

    /**
     * Open an artifact
     * @param {*} path 
     */
    openArtifact = artifactName => {
        var currentIndex = this.state.openedArtifacts.indexOf(artifactName);
        var openedArtifacts = [...this.state.openedArtifacts];
        let state;
        if (currentIndex == -1) {
            openedArtifacts.push(artifactName);
            state = {
                openedArtifacts,
                selectedArtifact: artifactName
            };
        } else {
            state = {
                selectedArtifact: artifactName
            };
        }
        return new Promise(resolve => this.setState(state, resolve));
    }

    /**
     * 
     * @param {*} artifact 
     * @returns 
     */
    closeArtifact = artifact => {
        let openedArtifacts = [...this.state.openedArtifacts];
        const currentIndex = openedArtifacts.indexOf(artifact);
        if (currentIndex >= 0) {
            openedArtifacts.splice(currentIndex, 1);
            let selectedArtifact = this.state.selectedArtifact;
            if (selectedArtifact === artifact) {
                selectedArtifact = openedArtifacts[0];
            }
            return new Promise(resolve => this.setState({
                openedArtifacts,
                selectedArtifact
            }, resolve));
        } else {
            return Promise.resolve();
        }
    }

    /**
     * 
     * @param {*} artifact 
     */
    selectArtifact = artifact => {
        this.setState({ selectedArtifact: artifact })
    }

    isArtifactUpdated = name => {
        return !this.getArtifact(name).lastSavedVersion() !== null && !this.getArtifact(name).getSrc();
    }

    isArtifactCreated = name => {
        return this.getArtifact(name).lastSavedVersion() === null;
    }

    /**
     * 
     * @returns 
     */
    save = () => {
        if (this.state.saving) return; // no save in parallel
        this.setState({ saving: true });

        // 1. upload added and modified files
        const uploadBucket = bucket('blueforge-322008.appspot.com');
        const userAppFolder = `${this.props.user.uid}/apps/${this.props.appId}/${this.changeId}`;
        const manifest = { id: this.changeId, artifacts: {} };
        let artifactVersions = {};
        const promises = [];

        //var loadedResources = this.getLoadedResources(null, 'main');
        Object.entries(this.props.artifacts)
            //.filter(([resourceName]) => loadedResources.includes(resourceName)) // is it really needed ?
            .forEach(([name, artifact]) => {
                const changes = artifact.getAllChanges();
                if (Object.keys(changes).length === 0) return; // no change for this artifact

                const path = name.substring(5).split('/').join('/artifacts/'); // remove the 'file:'
                const userArtifactFolder = `${userAppFolder}/${path}/artifact`;

                artifactVersions[name] = [artifact, artifact.getVersion()];

                //var resourcePath = getResourcePathFromName(localPath);

                const upload = filePath => {
                    const storagePath = `${userArtifactFolder}${filePath}`;
                    const version = artifact.getLocalFileVersion(filePath);

                    // check if the file have been already uploaded
                    var doUpload = (version == null)
                        || !this.uploadCache.hasOwnProperty(storagePath)
                        || (this.uploadCache[storagePath] != version);

                    if (doUpload) {
                        var text = artifact.getLocalFile(filePath);
                        if (text === null) throw new Error('Trying to upload a file not stored locally.');
                        promises.push(uploadString(reference(uploadBucket, storagePath), text, 'raw')
                            .then(() => this.uploadCache[storagePath] = version));
                    } else {
                        console.log('Upload skipped', filePath, version, this.uploadCache[storagePath])
                    }
                }

                // upload created and modified files if any
                changes.createdFiles?.forEach(upload);
                changes.modifiedFiles?.forEach(upload);

                // add this resource info to the manifest if there are at least one changes
                if (changes.createdFiles
                    || changes.modifiedFiles
                    || changes.deletedFolders
                    || changes.deletedFiles
                    || changes.createdFolders) {
                    let dependsOn = null;
                    // check if it is a module, if yes get all artifact that have to be built before
                    if (artifact.getLocalFileAsJson('/resource.json').service.split(':')[0] === 'modules') {
                        const arr = [...new Set(Object.values(artifact.getLocalFileAsJson('/module.json')).filter(r => r.resource.startsWith('file:')).map(r => r.resource))];
                        if (arr.length > 0) dependsOn = arr;
                    }
                    // get source name from artifactname
                    const json = { ...changes };
                    const resource = artifact.getSrc(0); // remote resource
                    if (resource) {
                        if (resource.bucket !== 'apps') throw new Error('Only app artifact are allowed.');
                        json.from = resource.path;
                    }
                    if (dependsOn) json.dependsOn = dependsOn;
                    manifest.artifacts[name] = json;

                }
            });

        return Promise.all(promises).then(() => {
            // compare the version of all artifacts uploaded to verify there has not been any change during the upload
            // if so we cannot trust the version sent
            /*artifactVersions = Object.fromEntries(Object.entries(artifactVersions).filter(([resourceName, [artifact, version]]) => {
                var curr = artifact.getVersion();
                return curr == version;
            }));*/

            console.log('%c------ Change Manifest start -------', 'background: #222; color: #bada55');
            console.log('%c' + JSON.stringify(manifest, null, 2), 'color: #7ee6ec');
            console.log('%c------ Change Manifest end -------', 'background: #222; color: #bada55');

            return createAppVersion(this.props.appId, {
                name: this.state.app.name || 'Unnamed', // TODO REMOVE
                description: this.state.app.description || 'TODO', // TODO REMOVE
                icon: 'TODO',
                manifest
            }).then(result => {
                console.log('New version created', JSON.stringify(result, null, 2));

                this.setState({
                    saving: false,
                });

                // get the architecture
                getArchitecture(this.props.appId, result.version).then(json => {
                    console.log('Infra', JSON.stringify(json, null, 2));
                })
            }).catch(error => {
                this.setState({ saving: false });
                this.props.raiseError('SAVE FAILED', 'Unable to save your changes.');
            });
        });
    }

    render() {

        return <AppContext.Provider value={{
            openArtifact: this.openArtifact.bind(this),
            closeArtifact: this.closeArtifact.bind(this),
            selectArtifact: this.selectArtifact.bind(this),
        }}>
            <Box sx={{
                minWidth: 600,
                height: '100%',
                backgroundColor: '#d0ebe8', //'#ded9d5', //'#dfd2d6', //'#ebeced',
                display: 'flex',
                flexDirection: 'column',
            }}
            >


                {this.state.loading && <SystemLoading />}

                {!this.state.loading && <>
                    <SystemAppBar sx={{
                        flexGrow: 0,
                        flexShrink: 0,
                    }}>

                        <Box sx={{
                            display: 'flex',
                            gap: '8px',
                        }}>

                            {/* Logo */}
                            <Box sx={{
                                flexShrink: 0,
                                //marginLeft: '8px',
                                padding: '0px 8px',
                                height: '34px',
                                borderRadius: '5px',
                                color: '#333',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                transition: 'all .2s',
                                '&:hover': {
                                    backgroundColor: '#ffffff',
                                    color: '#000',
                                    paddingTop: '2px',
                                },
                            }}>
                                <Icon path={mdiJellyfishOutline} size={1} />
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                            }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        padding: '0px 14px 0 10px',
                                        height: '30px',
                                        alignItems: 'center',
                                        gap: '3px',
                                        borderRadius: '17px',
                                        color: '#000',
                                        cursor: 'pointer',
                                        //backgroundColor: this.state.selectedArtifact ? 'transparent' : '#FFFFFF',
                                        /*'&:hover': {
                                            backgroundColor: '#FFFFFF',
                                        }*/
                                    }}>
                                    <Icon style={{ color: '#e91e63' }} path={mdiSquareOutline} size={.8} />
                                    <Typography variant={'h6'} sx={{ fontWeight: 500 }}>
                                        {this.state.app.appId}
                                    </Typography>
                                </Box>
                                <SystemIconButton path={mdiArrowRightBold} />
                                <SystemIconButton path={mdiLightningBolt} />
                            </Box>
                        </Box>


                        <Box
                            ref={this.tabs}
                            sx={{
                                marginLeft: '24px',
                                marginRight: '24px',
                                display: 'flex',
                                alignItems: 'center',
                                flexGrow: 1,
                                gap: '16px',
                                borderRadius: '5px',
                                padding: '6px 16px',
                                overflow: 'hidden',
                            }}>


                            <Box
                                sx={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '0px 8px',
                                    height: '30px',
                                    gap: '4px',
                                    borderRadius: '4px',
                                    transition: 'background-color .2s',
                                    color: this.isArtifactCreated('main') ? '#53c079' : this.isArtifactUpdated('main') ? '#e5a748' : '#373737',
                                    backgroundColor: this.state.selectedArtifact === 'main' ? '#ffffff' : 'rgba(0,0,50,.04)',
                                    '&:hover': {
                                        backgroundColor: '#FFFFFF',
                                    }
                                }}
                                onClick={() => this.openArtifact('main')}
                            >
                                <Icon style={{ flexShrink: 0 }} path={mdiHome} size={.6} />
                                <Typography variant={'body'} sx={{ fontWeight: 400 }}>main</Typography>
                            </Box>

                            {this.state.openedArtifacts.filter((a, i) => i > 0).map((name, index) => (
                                <Box
                                    sx={{
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '0px 8px',
                                        height: '30px',
                                        gap: '4px',
                                        borderRadius: '4px',
                                        transition: 'background-color .2s',
                                        color: this.isArtifactCreated(name) ? '#53c079' : this.isArtifactUpdated(name) ? '#e5a748' : '#373737',
                                        backgroundColor: this.state.selectedArtifact === name ? '#ffffff' : 'transparent',
                                        '&:hover': {
                                            backgroundColor: '#FFFFFF',
                                        }
                                    }}
                                    onClick={() => this.openArtifact(name)}
                                >
                                    <Icon style={{ flexShrink: 0 }} path={mdiCubeOutline} size={.6} />
                                    <Typography variant={'body'} sx={{ fontWeight: 400 }}>{name.substring(5)}</Typography>
                                    {this.isArtifactUpdated(name) ? <Box sx={{
                                        borderRadius: '10px',
                                        cursor: 'pointer',
                                        '& .close': {
                                            display: 'none',
                                        },
                                        '&:hover': {
                                            backgroundColor: 'rgba(0,0,0,.1)',
                                            '& .circle': {
                                                display: 'none',
                                            },
                                            '& .close': {
                                                display: 'block',
                                            },
                                        }
                                    }}
                                        onClick={event => { this.closeArtifact(name); event.stopPropagation(); }}
                                    >
                                        <Icon className={'circle'} path={mdiCircle} style={{ color: this.isArtifactCreated(name) ? '#53c079' : '#e5a748' }} size={.5} />
                                        <Icon className={'close'} path={mdiClose} size={.5} />
                                    </Box> : <Box sx={{
                                        borderRadius: '10px',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0,0,0,.1)',
                                        }
                                    }}
                                        onClick={event => { this.closeArtifact(name); event.stopPropagation(); }}
                                    >
                                        <Icon className={'close'} path={mdiClose} size={.5} />
                                    </Box>}

                                </Box>
                            ))}
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '48px',
                        }}>
                            <Avatar sx={{ m: 'auto', width: 32, height: 32 }} alt="Remy Sharp" src={Guillaume} />

                            <SystemButton
                                disabled={this.state.saving}
                                onClick={this.save.bind(this)}>
                                {this.state.saving ? <CircularProgress sx={{ color: '#fff' }} size={12} thickness={5} /> : <Icon style={{ flexShrink: 0 }} path={mdiContentSaveOutline} size={.6} />}
                                {'SAVE'}
                            </SystemButton>
                        </Box>
                    </SystemAppBar>

                    {this.state.openedArtifacts.map(resourceName =>
                        <Fade in={true} timeout={800}>
                            <Box
                                key={resourceName}
                                sx={{
                                    display: (this.state.selectedArtifact == resourceName) ? 'flex' : 'none', // only display the one selected
                                    flexGrow: 1,
                                    minWidth: 0,
                                    minHeight: 0,
                                }}>
                                <ArtifactProvider artifact={this.getArtifact(resourceName)}>
                                    <Resource />
                                </ArtifactProvider>
                            </Box>
                        </Fade>
                    )}
                </>
                }
            </Box>
        </AppContext.Provider>
    }
}

export default chain(
    withContext(ErrorHandlerContext)(({ raiseError }) => ({ raiseError })),
    withContext(CacheContext)(({ artifacts, createArtifact, existsArtifact, getArtifact, preloadArtifact, getServices }) => ({ artifacts, createArtifact, existsArtifact, getArtifact, preloadArtifact, getServices })),
    withContext(AuthenticationContext)(({ user }) => ({ user })),
)(App);
