import React from 'react';
import EditorContext from './EditorContext.js';

/**
 * 
 * @param {*} newObj 
 * @param {*} prevObj 
 * @returns 
 */
/*export const shallowCompare = (newObj, prevObj) => {
    if (!newObj && !prevObj) return false;
    if (newObj && !prevObj) return false;
    if (!newObj && prevObj) return false;
    for (let key in newObj) {
        if (newObj[key] !== prevObj[key]) return false;
    }
    return true;
}*/

/**
 * 
 * @param {*} arr 
 * @param {*} obj 
 * @returns 
 */
/*export const getIndexOf = (arr, obj) => {
    let index = -1;
    let i = -1;
    while (index == -1 && i++ < arr.length) {
        if (shallowCompare(obj, arr[i])) index = i;
    }
    return index;
}*/

/**
 * 
 */
class EditorProvider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // the selected node from the left panel menu
            selectedItem: null,
            // all tabs
            tabsIds: [],
            // all opened tabs in order (the first one is the one to display
            tabsIdsOrdered: [],
        };
        // the datza of the tabs
        this.tabsData = {};
    }

    /**
     * 
     * @param {*} prefix 
     * @param {*} name 
     */
    selectItem = ({ type, id }) => {
        return new Promise(resolve => {
            //this.selectedItemData = data;
            this.setState({
                selectedItem: {
                    type,
                    id
                },
            }, resolve);
        });
    }

    /**
     * 
     * @param {*} prefix 
     * @param {*} name 
     */
    unselectItem = () => {
        return new Promise(resolve => {
            //this.selectedItemData = null;
            this.setState({
                selectedItem: null,
            }, resolve);
        });
    }

    /**
     * 
     * @param {*} id 
     * @param {*} data 
     */
    createTab = (id, data) => {
        const arr = [...this.state.tabsIds];
        if (!arr.includes(id)) {
            this.tabsData[id] = data;
            arr.push(id);
            return new Promise((resolve) => this.setState({ tabsIds: arr }, resolve));
        }
        return Promise.reject(`Tab ${id} already created.`);
    }

    /**
     * 
     * @param {*} id 
     * @param {*} data 
     */
    deleteTab = id => {
        const arr = [...this.state.tabsIds];
        if (!arr.includes(id)) {
            return Promise.reject(`Tab ${id} does not exists.`);
        }
        return this.closeTab(id).then(() => {
            var index = arr.indexOf(id);
            if (index !== -1) arr.splice(index, 1);
            return new Promise((resolve) => this.setState({ tabsIds: arr }, resolve))
                .then(() => delete this.tabsData[id]);
        })
    }

    /**
     * 
     * @param {*} id 
     * @returns 
     */
    getTab = id => {
        if (!this.state.tabsIds.includes(id)) return null;
        return this.tabsData[id];
    }

    /**
     * 
     * @param {*} prefix 
     * @param {*} name 
     */
    openTab = id => {
        const state = {};
        const tabsIdsOrdered = [...this.state.tabsIdsOrdered];
        // delete previous occurence if exists
        const indexOrdered = tabsIdsOrdered.indexOf(id);
        if (indexOrdered > 0) tabsIdsOrdered.splice(indexOrdered, 1);
        // set it at the beginning
        tabsIdsOrdered.unshift(id);
        state.tabsIdsOrdered = tabsIdsOrdered;
        // set the data
        return new Promise((resolve) => this.setState(state, resolve));
    }

    /**
     * 
     * @param {*} prefix 
     * @param {*} name 
     */
    closeTab = id => {
        const tabsIdsOrdered = [...this.state.tabsIdsOrdered];
        const indexOrdered = tabsIdsOrdered.indexOf(id);
        if (indexOrdered > -1) {
            tabsIdsOrdered.splice(indexOrdered, 1);
            //if (tabsIdsOrdered.length == 0) tabsIdsOrdered.push('file:/resource.json');
            //state.tabsIdsOrdered = tabsIdsOrdered;
            return new Promise(resolve => this.setState({
                tabsIdsOrdered
            }, resolve));
        }
        return Promise.resolve();
    }

    /**
     * 
     * @returns 
     */
    render() {
        return <EditorContext.Provider value={{
            // items
            selectedItem: this.state.selectedItem,
            selectItem: this.selectItem.bind(this),
            unselectItem: this.unselectItem.bind(this),

            // tabs
            tabsIds: this.state.tabsIds,
            tabsIdsOrdered: this.state.tabsIdsOrdered,
            createTab: this.createTab.bind(this),
            deleteTab: this.deleteTab.bind(this),
            getTab: this.getTab.bind(this),
            openTab: this.openTab.bind(this),
            closeTab: this.closeTab.bind(this),
        }}>
            {this.props.children}
        </EditorContext.Provider>
    }
}

export default EditorProvider;

