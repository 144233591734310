import { Box } from '@mui/material';
import React from 'react';
import InfiniteElementContext from '../infinite/InfiniteElementContext.js';
import ResourceIcon from '../system/ResourceIcon.js';
import SystemContentEditable from '../system/SystemContentEditable.js';
import withContext, { chain } from '../utils/WithContext';
import ModuleContext from './ModuleContext.js';
import SystemIconButton from '../system/SystemIconButton.js';
import SystemIconButtonSmall from '../system/SystemIconButtonSmall.js';
import { mdiArrowRight, mdiCogOutline, mdiConnection, mdiDelete, mdiDeleteOutline, mdiDotsVertical, mdiInformation, mdiInformationOutline, mdiInformationVariant, mdiLightningBolt, mdiOpenInApp, mdiPencil, mdiPencilOutline, mdiTarget, mdiVariable } from '@mdi/js';
import SystemIconButtonLarge from '../system/SystemIconButtonLarge.js';
import ServiceSelectMini from '../app/ServiceSelectMini.js';
import SystemPopper from '../system/SystemPopper.js';
import SystemButton from '../system/SystemButton.js';
import SystemButtonSecondary from '../system/SystemButtonSecondary.js';
import Icon from '@mdi/react';
import SystemDivider from '../system/SystemDivider.js';

class ResourceNode extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openDependencies: false,
        }
        this.input = React.createRef();
        this.buttonDependencies = React.createRef();
    }

    componentDidMount() {
    }

    handleInputClick = event => {
        if (this.props.selected) {
            this.input.current.focus();
            event.stopPropagation();
        }
    }

    render() {
        const color = '#EDEDEF'; //'#dbf1f0';
        //console.log('ResourceNode render', this.props.id)
        return <Box sx={{
            cursor: 'pointer',
            width: 84,
            //height: '100%',
            padding: '2px',
            borderRadius: '8px',
            border: this.props.selectedResourceId === this.props.resourceId ? '1px solid rgb(15,135,255)' : '1px solid transparent',
            //backgroundColor: '#EDEDED',
            //backgroundColor: this.props.selectedResourceId === this.props.resourceId  ? '#F0F0F0' : 'transparent',
            transition: 'background-color .2s',
            backgroundColor: this.props.selected || this.props.selecting ? color : 'transparent',
            '& .input': {
                backgroundColor: this.props.selected || this.props.selecting ? color : '#FDFDFD',
            },
            '&:hover': {
                backgroundColor: this.props.selected || this.props.selecting ? color : '#F5F5F7',
                '& .input': {
                    backgroundColor: this.props.selected || this.props.selecting ? color : '#F5F5F7',
                }
            }
        }}>
            <Box sx={{
                margin: 'auto',
                padding: '14px 14px 0 14px',
                //border: this.props.selectedResourceId === this.props.resourceId ? '2px solid rgba(0,0,0,.01)' : '2px solid transparent', 
            }}>
                <ResourceIcon
                    width={'50px'}
                    height={'50px'}
                    id={this.props.resourceId}
                    path={this.props.resourceClass?.icon || this.props.service.icon}
                    opacity={1}
                    radius={5}
                    color={this.props.service.color}
                />
            </Box>
            <Box sx={{
                width: '100%',
                padding: '4px 0',
            }}>
                <SystemContentEditable
                    className={'input'}
                    onClick={this.handleInputClick.bind(this)}
                    ref={this.input}
                    sx={{
                        //fontFamily: 'Menlo, Monaco, "Courier New", monospace',
                        textAlign: 'center',
                        fontSize: 12,
                        fontWeight: 500,
                        lineHeight: '1rem',
                        overflow: 'hidden',
                        padding: '2px 1px',
                        lineHeight: '1rem',
                        //textShadow: '0px 1px 1px rgba(255, 255, 255, 1)',
                        //color: this.props.selectedResourceId === this.props.resourceId ? '#FFFFFF' : '#4C4C4C',
                        transition: 'background-color .2s',
                        color: '#5C5C5C',
                        //letterSpacing: '-.2px',
                    }}>
                    Enricher
                </SystemContentEditable>
            </Box>

            {this.props.selectedResourceId === this.props.resourceId && <Box sx={{
                position: 'absolute',
                display: 'flex',
                gap: '4px',
                
                top: '-50px',
                left: '50%',
                transform: 'translateX(-50%)',

                //flexDirection: 'column',
                //top: '50%',
                //right: '-60px',
                //transform: 'translateY(-50%)',

                background: '#FDFDFD',
                //boxShadow: '0px 4px 16px rgba(5, 0, 56, 0.12)',
                //padding: '4px',
                borderRadius: '8px',
                
            }}>
                <SystemIconButtonLarge path={mdiPencilOutline} />
                <SystemIconButtonLarge ref={this.buttonDependencies} path={mdiTarget} onClick={() => this.setState({ openDependencies: true })} />
                <SystemIconButtonLarge path={mdiCogOutline} />
                <SystemIconButtonLarge path={mdiOpenInApp} />
                <SystemIconButtonLarge sx={{ color: '#FF4444'}} path={mdiDeleteOutline} />

            <SystemPopper
                animation={'top'}
                anchorEl={this.buttonDependencies.current}
                placement={'bottom-end'}
                onClickAway={() => this.setState({ openDependencies: false })}
                open={this.state.openDependencies}
                sx={{
                    backgroundColor: '#F1F1F3', //'#e7eeef', //'#ebebef', //'#f4f5f7',
                    borderRadius: '6px',
                }}
            >
                <ServiceSelectMini />
            </SystemPopper>
                </Box>}
        </Box>
    }
}

export default chain(
    withContext(ModuleContext)(({ selectedResourceId }) => ({ selectedResourceId })),
    withContext(InfiniteElementContext)(({ selected, selecting }) => ({ selected, selecting })),
)(ResourceNode);




