
import { Box, ClickAwayListener, Popper } from '@mui/material';
import * as React from 'react';

/**
 * 
 */
class SystemPopper extends React.Component {

    /**
     * 
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.element = React.createRef();
    }

    onClickAway = () => {
        if (this.props.onClickAway) {
            this.props.onClickAway();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.animation && this.props.open && !prevProps.open) {
            setTimeout(() => {
                if (this.props.animation === 'left') this.element.current.style.marginLeft = '0px';
                if (this.props.animation === 'top') this.element.current.style.marginTop = '0px';
                this.element.current.style.opacity = 1;
            });
        }
    }

    /**
     * 
     * @returns 
     */
    render() {
        const { sx, placement, anchorEl, open, offset, animation, children } = this.props;
        const offsetValue = offset ?? 8;
        return <Popper
            anchorEl={anchorEl}
            placement={placement || 'bottom-start'}
            open={open}
            style={{
                zIndex: 100,
            }}
            modifiers={[
                {
                    name: 'offset',
                    options: {
                        offset: [0, offsetValue]
                    },
                },
            ]}>
            <ClickAwayListener /*mouseEvent={'onMouseDown'}*/ onClickAway={this.onClickAway.bind(this)}>
                <Box
                    ref={this.element}
                    sx={{
                        ...sx,
                        ...(animation === 'top' ? { marginTop: '-6px' } : {}),
                        ...(animation === 'left' ? { marginLeft: '-6px' } : {}),
                        transition: 'all .1s',
                        opacity: animation ? 0 : 1,
                        transitionTimingFunction: 'ease-out',
                        //boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 1px 0px, rgba(0, 0, 0, 0.04) 0px 4px 8px -4px, rgba(0, 0, 0, 0.06) 0px 16px 24px -8px',
                        //boxShadow: '0 3px 8px #00000016',
                    }}>
                    {children}
                </Box>
            </ClickAwayListener>
        </Popper>
    }
}

/**
 * Add a fwd ref
 */
export default React.forwardRef((props, ref) => <SystemPopper
    forwardedRef={ref}
    {...props}
/>);