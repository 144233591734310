import { mdiCubeOutline, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import SystemIconButton from '../system/SystemIconButton';
import withContext, { chain } from '../utils/WithContext';
import ServiceContext from './ServiceContext';

/**
 * 
 */
class ImplementationNewArtifactComponent extends React.Component {

    constructor(props) {
        super(props);
    }

    /**
     * 
     * @param {*} name 
     */
    handleNewArtifact = name => {
        const artifact = this.props.getArtifact(name);
        artifact.createFile('/readme.md', '').then(() => {
            this.props.addSource(name, 'file:' + artifact.name);
            this.props.openArtifact(name);
        });
    }

    /**
     * 
     * @returns 
     */
    render() {
        const { artifactPrettyName, artifactName } = this.props;
        return <Box sx={{
            width: '100%',
            height: 30,
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            padding: '0 4px',
        }}>
            <Icon style={{ color: '#a6aab2' }} path={mdiCubeOutline} size={.6} />
            <Typography sx={{ color: '#a6aab2' }} variant={'body'}>{artifactPrettyName}</Typography>
            <Box sx={{ flexGrow: 1, minWidth: '16px' }}></Box>
            <SystemIconButton path={mdiPlus} onClick={() => this.handleNewArtifact(artifactName)} />
        </Box>
    }
}

export default chain(
    withContext(ServiceContext)(({ getArtifact, addSource, openArtifact }) => ({ getArtifact, addSource, openArtifact })),
)(ImplementationNewArtifactComponent);