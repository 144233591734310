import { Box } from '@mui/material';
import React from 'react';
import AppContext from '../app/AppContext';
import Resources from '../app/Resources';
import CacheContext from '../cache/CacheContext';
import Infinite from '../infinite/Infinite';
import InfiniteConnection from '../infinite/InfiniteConnection';
import InfiniteContainer from '../infinite/InfiniteContainer';
import InfiniteElement from '../infinite/InfiniteElement';
import InfiniteStaticElement from '../infinite/InfiniteStaticElement';
import { removeResource, unselect } from "../redux/module_slice";
import withContext, { chain } from '../utils/WithContext';
import { createId } from '../utils/idgenerator';
import ActionToolbars from './ActionToolbars';
import ModuleContext from './ModuleContext';
import ResourceNode from './ResourceNode';
import ResourcePanel from './ResourcePanel';
import ResourceSelector from './ResourceSelector';

/**
 * 
 * @param {*} props 
 * @returns 
 */
const Center = props => {
    var arr = [];
    for (var i = -100; i <= 100; i++) {
        arr.push(i);
    }
    return <>
        <InfiniteStaticElement
            key={'_axisY'}
            align={'center'}
            coord={{
                x: 0,
                y: 0,
            }}
            size={{
                w: 2,
                h: 15,
            }}
            grid={{
                x: 1,
                y: 1,
            }}
        >
            <Box sx={{
                width: '100%',
                height: '100%',
                backgroundColor: '#DDD',
                borderRadius: '3px',
            }}
            />
        </InfiniteStaticElement>
        <InfiniteStaticElement
            key={'_axisX'}
            align={'center'}
            coord={{
                x: 0,
                y: 0,
            }}
            size={{
                w: 15,
                h: 2,
            }}
            grid={{
                x: 1,
                y: 1
            }}
        >
            <Box sx={{
                width: '100%',
                height: '100%',
                backgroundColor: '#DDD',
                borderRadius: '3px',
            }}
            />
        </InfiniteStaticElement></>
}

/**
 * 
 */
class ModuleFileView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //store: undefined, // the store of the current project opened
            src: props.src,

            module: {},

            //layout: { resources: {} },
            moduleVersion: null,

            connecting: null,

            resources: {}, // the resource file of each resource resourcePath => resource file
            services: {}, // the service of each resource servicePath => service

            contextMenuPosition: null, // set to null to hide the context menu
            moduleLoading: true,
            resourcesLoading: false,

            selectedResourceId: null, // the current selected resource id

            mode: {
                type: 'default',
            }
        }

        // artifact version listeners
        this.artifactsListeners = {};

        // resources files listeners
        this.resourcesFileListeners = {};

        // use to avoid editing the files when the change comes from an undo or redo
        this.redoOrUndoInProgress = false;

        // async state update to avoid multiple update when we modify several time in the same nodejs process
        this.asyncId = null;
        this.asyncState = {};
        this.asyncCbs = [];

        this.infinite = React.createRef();

        this.setStateLogged = (state, cb) => {
            //console.log('ModuleView State Update', JSON.stringify(state, null, 2));
            this.setState(state, cb);
        }
    }

    /**
     * 
     * @param {*} state 
     */
    setStateAsync = (state, cb) => {
        if (this.asyncId) clearTimeout(this.asyncId);
        this.asyncState = { ...this.asyncState, ...state };
        if (cb) this.asyncCbs = [...this.asyncCbs, cb];
        this.asyncId = setTimeout(() => {
            this.setStateLogged(this.asyncState, () => {
                this.asyncCbs.forEach(fn => fn())
            });
            this.asyncId = null;
            this.asyncState = {};
            this.asyncCbs = [];
        }, 0);
    }

    componentDidMount() {

        this.props.artifact.addFileChangeListener(this.props.filepath, ({ type, value }) => {
            /*if (type == RedoUndoValueEvents.UNDO || type == RedoUndoValueEvents.REDO) {
                this.redoOrUndoInProgress = true;
                this.state.store.dispatch(setGraph(JSON.parse(value)));
                setTimeout(() => this.redoOrUndoInProgress = false, 0);
            }*/

            const module = JSON.parse(value);
            this.setState({
                module,
                moduleVersion: this.props.artifact.getLocalFileVersion(this.props.filepath),
                resourcesLoading: true,
            }, () => {
                this.updateArtifactsListeners();
                this.syncResourcesListeners();
                this.getResourcesAndServicesFiles()
                    .then(({ resources, services }) => {
                        this.setState({
                            resources,
                            services,
                            resourcesLoading: false
                        })
                    });
            });

            /*const resourcesPaths = [];
            Object.values(module).forEach(resource => {
                if (!resourcesPaths.includes(resource.resource)) resourcesPaths.push(resource.resource);
            });*/

        });


        //this.props.artifact.addFileChangeListener('/layout.json', ({ type, value }) => {
        /*if (type == RedoUndoValueEvents.UNDO || type == RedoUndoValueEvents.REDO) {
            this.redoOrUndoInProgress = true;
            this.state.store.dispatch(setLayout(JSON.parse(value)));
            setTimeout(() => this.redoOrUndoInProgress = false, 0);
        }*/
        //    this.setStateAsync({ layout: JSON.parse(value), moduleVersion: this.props.artifact.getLocalFileVersion('/layout.json') })
        //});

        //if (this.props.selected) {
        window.addEventListener('keydown', (evt) => {
            evt.stopImmediatePropagation();
            // do redo functionality
            if ((evt.key === 'z' || evt.key === 'Z') && (evt.ctrlKey || evt.metaKey) && evt.shiftKey) {
                this.handleRedo();
            } else if ((evt.key === 'z' || evt.key === 'Z') && (evt.ctrlKey || evt.metaKey)) {
                this.handleUndo();
            }

            // delete functionality
            /*if (evt.key === 'Backspace' || evt.key === 'Delete') {
                var selected = this.state.store.getState().module.edit.selected;
                // unselect them
                this.state.store.dispatch(unselect());
                // unselect must have been done before removing
                // => the dom element must have been replaced under its real parent
                setTimeout(() => {
                    selected.forEach(resourceId => {
                        this.state.store.dispatch(removeResource({ resourceId }))
                    });
                }, 0);
            }*/
        });

        // load layout and graph.json
        // display loading while downloading
        this.props.artifact.getFolder('/').then(async ({ files }) => {
            const module = await this.props.artifact.getFile(this.props.filepath);

            /*if (!files.includes('layout.json')) {
                layout = await this.props.artifact.createFile('/layout.json', '{"resources":{}}');
            } else {
                layout = await this.props.artifact.getFile('/layout.json');
            }*/

            //if (layout) layout = JSON.parse(layout);

            // TODO, handle the case graph and/or layout are null 

            this.setStateLogged({
                module: JSON.parse(module),
                moduleVersion: this.props.artifact.getLocalFileVersion(this.props.filepath),
                moduleLoading: false,
                resourcesLoading: true,
            }, () => {

            });

            this.updateArtifactsListeners();
            this.syncResourcesListeners();
            this.getResourcesAndServicesFiles()
                .then(({ resources, services }) => {
                    this.setState({
                        resources,
                        services,
                        resourcesLoading: false,
                    }, () => {
                        //this.computeDimensions()
                    });
                });

            // get resources Paths
            /*const resourcesPaths = [];
            const resourcesIds = [];
            Object.values(module).forEach(resource => {
                if (!resourcesPaths.includes(resource.resource)) resourcesPaths.push(resource.resource);
            });*/

            // update listeners
            /*this.syncResourcesListeners();

            // get services and resources files and set them in the state
            return this.getResourcesAndServicesFiles()
                .then(({ resources, services }) => {
                    this.setStateLogged({
                        resources,
                        services,
                        resourcesLoading: false
                    })

                });
                */
        });

    }

    componentDidUpdate() {

    }

    /**
     * 
     */
    computeDimensions = () => {
        if (this.infinite.current) {
            this.infinite.current.computeDimensions();
        }
    }

    /**
     * 
     */
    updateArtifactsListeners = () => {

        const resourceIds = Object.keys(this.state.module);
        const listenersToAdd = resourceIds.filter(r => !this.artifactsListeners.hasOwnProperty(r));
        const listenersToDelete = Object.keys(this.artifactsListeners).filter(r => !resourceIds.includes(r));

        const createListener = resourceId => version => {
            const artifactName = this.props.artifact.name;
            const currentArtifactPath = this.state.module[resourceId].resource;
            if (currentArtifactPath.startsWith('file:')) return;
            if (version == 0) {
                // set the previous value
                // TODO
            } else {
                const artifactPath = artifactName ? `${artifactName}/${resourceId}` : resourceId;
                this.state.module[resourceId].resource = `file:${artifactPath}`;
                this.props.artifact.editFile(this.props.filepath, JSON.stringify(this.state.module, null, 2));
            }
        };

        listenersToAdd.forEach(resourceId => {
            const artifact = this.getArtifact(resourceId);
            const listener = createListener(resourceId);
            this.artifactsListeners[resourceId] = listener;
            artifact.addVersionChangeListener(listener);
        });

        listenersToDelete.forEach(resourceId => {
            const artifact = this.getArtifact(resourceId);
            const listener = this.artifactsListeners[resourceId];
            artifact.removeVersionChangeListener(listener);
            delete this.artifactsListeners[resourceId];
        })
    }

    /**
     * 
     * @param {*} resourceId 
     * @returns 
     */
    getArtifact = resourceId => {
        // get the module global id modA/modB/modC...
        const src = this.state.module[resourceId].resource;
        const artifactName = this.props.artifact.name;
        const artifactPath = artifactName ? `${artifactName}/${resourceId}` : resourceId;
        const name = `file:${artifactPath}`;
        let artifact;
        if (this.props.existsArtifact(name)) {
            artifact = this.props.getArtifact(name);
        } else {
            artifact = this.props.createArtifact(name);
            artifact.setSrc({
                bucket: 'apps',
                path: src,
            });
        }

        return artifact;
    }

    /**
     * 
     * @param {*} resourcesPaths 
     */
    syncResourcesListeners = () => {
        const resourceIds = Object.keys(this.state.module);
        const listenersToAdd = resourceIds.filter(r => !this.resourcesFileListeners.hasOwnProperty(r));
        const listenersToDelete = Object.keys(this.resourcesFileListeners).filter(r => !resourceIds.includes(r));
        listenersToAdd.map(resourceId => {
            const listener = event => this.handleResourceFileChange(resourceId, event.value);
            this.getArtifact(resourceId).addFileChangeListener('/resource.json', listener);
            this.resourcesFileListeners[resourceId] = listener;
        });
        listenersToDelete.forEach(resourceId => {
            this.getArtifact(resourceId).removeFileChangeListener('/resource.json', this.resourcesFileListeners[resourceId]);
            delete this.resourcesFileListeners[resourceId];
        });
    }

    /**
     * 
     * @param {*} resourcePath 
     * @param {*} text 
     */
    handleResourceFileChange = (resourcePath, text) => {
        // TODO handle file not parsable ..
        const resourceClass = JSON.parse(text);
        const stateResources = this.state.resources;
        stateResources[resourcePath] = resourceClass;
        this.setStateLogged({ resources: stateResources });
    }

    /**
     * 
     * @returns 
     */
    getResourcesAndServicesFiles = () => {
        const resourceIds = Object.keys(this.state.module);
        const stateResources = {}; // we update each time as the user might have modified the file locally
        const stateServices = this.state.services; // here, services CANNOT be modified, so we can reuse the existing state
        const resourcePromises = resourceIds.map(resourceId => {
            return this.getArtifact(resourceId).getFile('/resource.json').then(value => stateResources[resourceId] = JSON.parse(value)); // TODO handle file not JSON compatible
        });
        return Promise.all(resourcePromises).then(() => {
            const allServicesPaths = [...new Set(Object.values(stateResources).map(r => r.service))];
            // new services to download
            const servicesToAdd = allServicesPaths.filter(s => !this.state.services.hasOwnProperty(s));
            // services to delete
            const servicesToDelete = Object.keys(stateServices).filter(s => !allServicesPaths.includes(s));
            // delete services
            servicesToDelete.forEach(s => delete stateServices[s]);
            // add services
            return this.props.getServices(servicesToAdd).then(services => services.forEach(service => stateServices[`${service.serviceId}:${service.versionId}`] = service));
        }).then(() => ({
            resources: stateResources,
            services: stateServices,
        }));
    }


    /**
     * 
     * @param {*} resourceId 
     */
    selectResource = resourceId => {
        this.infinite.current.setSelectedElements([resourceId]);
    }


    /**
     * 
     * @param {*} resourceId 
     */
    removeResource = resourceId => {
        this.state.store.dispatch(unselect(resourceId));
        // unselect must have been done before removing
        // => the dom element must have been replaced under its real parent
        setTimeout(() => {
            this.state.store.dispatch(removeResource({ resourceId }));
        }, 0)
    }

    /**
     * 
     */
    handleUndo() {
        this.props.artifact.undoLastChangeOnFiles();
    }

    /**
     * 
     */
    handleRedo() {
        this.props.artifact.redoLastChangeOnFiles();
    }

    /*async handleStoreChange({ services, resources, architecture, layout }) {
        if (!this.redoOrUndoInProgress) {
            console.log('UPDATE FILES')
            this.props.artifact.editFile('/graph.json', JSON.stringify({ services, resources, architecture }));
            this.props.artifact.editFile('/layout.json', JSON.stringify(layout));
        }
    }*/

    /**
     * 
     * @param {*} resourceId 
     * @returns 
     */
    onElementPositionChanged = (resourceId, coord) => {
        const module = this.props.artifact.getLocalFileAsJson(this.props.filepath);
        module[resourceId].layout = {
            ...module[resourceId].layout,
            ...coord,
        };
        this.props.artifact.editFile(this.props.filepath, JSON.stringify(module, null, 2));
    }

    /**
     * 
     * @param {*} resourceId 
     */
    onDoubleClick = resourceId => {
        this.props.openArtifact(`${this.props.artifact.name}/${resourceId}`);
    }

    /**
     * 
     * @param {*} resourceIds 
     */
    onElementsSelected = resourceIds => {
        if (resourceIds.length == 1) {
            this.setState({
                selectedResourceId: resourceIds[0],
            });
        } else {
            this.setState({
                selectedResourceId: null,
            });
        }
    }

    /**
     * 
     */
    handleResourceBuilderClickAway = () => {
        this.setState({
            contextMenuPosition: null
        });
    }

    /**
     * 
     * @param {*} connecting 
     */
    setConnecting = connecting => {
        this.setState({ connecting })
    }

    handleResourcePanelClose = () => {
        this.setState({
            selectedResourceId: null
        });
    }

    setMode = mode => {
        this.setState({ mode })
    }

    handleAddElement = async ({ position, data }) => {

        // create default files
        const service = data.service;
        const resourceId = 'a' + createId();
        const artifactPath = `${this.props.artifact.name}/${resourceId}`;

        var newArtifact = this.props.createArtifact(`file:${artifactPath}`); // TODO attention au colision ?

        await newArtifact.createFile('/resource.json', `{
    "name": "my-resource",
    "author": "gdosser",
    "description": "No description set.",
    "service": "${service.serviceId}:${service.versionId}"
}
`);


        await newArtifact.createFile('/package.json', `{
    "name": "myFunction",
    "version": "1.0.1",
    "description": "myFunction service",
    "main": "index.js",
    "type": "module",
    "scripts": {
        "start": "node index.js"
    },
    "dependencies": {}
}
`);

        await newArtifact.createFile('/index.js', `import functions from '@service/functions';
functions.http((req, res) => {
    res.status(200).send("HELLO WORLD")
});
`);

        await newArtifact.createFile('/README.md', 'this is a sample file created for each resource.');
        // update module
        const module = this.props.artifact.getLocalFileAsJson(this.props.filepath);

        module[resourceId] = {
            resource: `file:${artifactPath}`,
            layout: { x: position.grid.infinite.x, y: position.grid.infinite.y }
        }

        this.props.artifact.editFile(this.props.filepath, JSON.stringify(module, null, 2));

        this.setState({
            mode: {
                type: 'default'
            }
        })
    }

    render() {
        return (

            <Box sx={{
                ...this.props.sx,
                position: 'relative',
                width: '100%',
                height: '100%',
            }}>

                <ModuleContext.Provider value={{
                    filepath: this.props.filepath,
                    module: this.state.module,

                    resources: this.state.resources,
                    services: this.state.services,

                    setConnecting: this.setConnecting.bind(this),

                    mode: this.state.mode,
                    setMode: this.setMode.bind(this),

                    selectedResourceId: this.state.selectedResourceId,
                }}>

                    {true && <Box sx={{
                        position: 'absolute',
                        top: 10,
                        left: 10,
                        //width: 0,
                        zIndex: 100,
                    }}>
                        <Resources />
                    </Box>}


                    <InfiniteContainer sx={{
                        width: '100%',
                        height: '100%',
                    }}>
                        <Infinite
                            ref={this.infinite}
                            elements={this.state.elements}
                            onAddElement={this.handleAddElement.bind(this)}
                            onElementsSelected={this.onElementsSelected.bind(this)}
                            grid={{
                                x: 16, y: 16
                            }}
                            onElementPositionChanged={this.onElementPositionChanged.bind(this)}
                            onDoubleClick={this.onDoubleClick.bind(this)}
                            mode={this.state.mode}
                        >

                            <Center />

                            {Object.keys(this.state.module).filter(resourceId => this.state.resources.hasOwnProperty(resourceId)).map(resourceId => {
                                // get layout
                                const layout = this.state.module[resourceId].layout;
                                const resourceClass = this.state.resources[resourceId];//resource.resource];
                                const service = this.state.services[resourceClass.service];
                                return <InfiniteElement
                                    key={resourceId}
                                    id={resourceId}
                                    version={this.state.moduleVersion}
                                    align={'center'}
                                    coord={{
                                        x: layout?.x || 0,
                                        y: layout?.y || 0,
                                    }}
                                    /*size={{
                                        w: 80, //(service.serviceId === 'modules') ? 56 : 52,
                                        //h: 84, //(service.serviceId === 'modules') ? 56 : 52,
                                    }}*/
                                    center={{
                                        y: 40,
                                    }}
                                >
                                    <ResourceNode
                                        resourceId={resourceId}
                                        resourceClass={resourceClass}
                                        service={service}
                                    />
                                </InfiniteElement>
                            })}

                            {Object.entries(this.state.module)
                                .filter(([id, r]) => r.dependencies && Object.keys(r.dependencies).length > 0)
                                .map(([id, r]) => Object.entries(r.dependencies)
                                    .map(([a, d]) => <InfiniteConnection
                                        text={a}
                                        start={{ id }}
                                        end={{ id: d }}
                                    />)
                                )}

                            {this.state.connecting && <InfiniteConnection
                                start={this.state.connecting.start}
                                end={this.state.connecting.end}
                            />}

                        </Infinite>

                    </InfiniteContainer>

                    {false && <ActionToolbars
                        undo={this.handleUndo.bind(this)}
                        redo={this.handleRedo.bind(this)}
                    />}

                    {false && <ResourcePanel resourceId={this.state.selectedResourceId} onClose={this.handleResourcePanelClose.bind(this)} />}

                </ModuleContext.Provider>
            </Box>
        )
    }
}

export default chain(
    withContext(CacheContext)(({ createArtifact, getArtifact, getService, getServices, existsArtifact }) => ({ createArtifact, getArtifact, getService, getServices, existsArtifact })),
    withContext(AppContext)(({ openArtifact }) => ({ openArtifact })),
)(ModuleFileView)


/**
 * Add a fwd ref
 */
/*export default React.forwardRef((props, ref) => <ModuleWithContexts
    ref={ref}
    {...props}
/>);*/


